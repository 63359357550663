import { combineReducers } from "redux";

import { TopicTypes, PostTypes, UserTypes, GeneralTypes } from "sigmaflow-redux/action_types";
import type {GenericAction} from 'sigmaflow-redux/types/actions';

import { ActionTypes, Constants } from "utils/constants";

function postVisibility(state: {[topicId: string]: number} = {}, action: GenericAction) {
    switch (action.type) {
        case TopicTypes.SELECT_TOPIC: {
            const nextState = {...state};
            nextState[action.data] = Constants.POST_CHUNK_SIZE / 2;
            return nextState;
        }
        case ActionTypes.INCREASE_POST_VISIBILITY: {
            const nextState = {...state};
            nextState[action.data] += action.amount;
            return nextState;
        }
        case ActionTypes.RECEIVED_FOCUSED_POST: {
            const nextState = {...state};
            nextState[action.topicId] = Constants.POST_CHUNK_SIZE / 2;
            return nextState;
        }

        case PostTypes.RECEIVED_NEW_POST: {
            if (action.data && state[action.data.topic_id]) {
                const nextState = {...state};
                nextState[action.data.topic_id] += 1;
                return nextState;
            }

            return state;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

function lastTopicViewTime(state: {[topicId: string]: number} = {}, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.SELECT_TOPIC_WITH_MEMBER: {
            if (action.member) {
                const nextState = {...state};
                nextState[action.data] = action.member.last_viewed_at;
                return nextState;
            }

            return state;
        }

        case ActionTypes.UPDATE_TOPIC_LAST_VIEWED_AT: {
            const nextState = {...state};
            nextState[action.topic_id] = action.last_viewed_at;
            return nextState;
        }

        case ActionTypes.POST_UNREAD_SUCCESS: {
            const data = action.data;
            return {...state, [data.topicId]: data.lastViewedAt};
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

function loadingPosts(state: {[topicId: string]: boolean} = {}, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.LOADING_POSTS: {
            const nextState = {...state};
            nextState[action.topicId] = action.data;
            return nextState;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

function focusedPostId(state = '', action: GenericAction) {
    switch (action.type) {
        case ActionTypes.RECEIVED_FOCUSED_POST:
            return action.data;
        case TopicTypes.SELECT_TOPIC:
            return '';
        case UserTypes.LOGOUT_SUCCESS: 
            return '';
        default:
            return state;
    }
}

function mobileView(state = false, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.UPDATE_MOBILE_VIEW:
            return action.data;

        default:
            return state;
    }
}

// lastUnreadTopic tracks if the current topic was unread and if it had mentions when the user
// switched to it.
function lastUnreadTopic(state: ({topicId: string; hadMentions: boolean}) | null = null, action: GenericAction) {
    switch (action.type) {
        case TopicTypes.LEAVE_TOPIC:
            if (action.data.id === state?.topicId) {
                return null;
            }
            return state;
        case ActionTypes.SET_LAST_UNREAD_TOPIC: {
            const {
                topicId, 
                hadMentions, 
                hadUnreads,
            } = action;

            if (hadMentions || hadUnreads) {
                return {
                    id: topicId, 
                    hadMentions,
                };
            }

            return null;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return null;
        default:
            return state;
    }
}

function lastGetPosts(state: {[topicId: string]: number} = {}, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.RECEIVED_POSTS_FOR_TOPIC_AT_TIME:
            return {
                ...state, 
                [action.topicId]: action.time,
            };
        case UserTypes.LOGOUT_SUCCESS: 
           return {};
        default:
            return state;
    }
}

function toastStatus(state = false, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.SELECT_TOPIC_WITH_MEMBER:
            return false;
        case ActionTypes.UPDATE_TOAST_STATUS:
            return action.data;
        case UserTypes.LOGOUT_SUCCESS:
            return false;
        default:
            return state;
    }
}

function topicPrefetchStatus(state: {[topicId: string]: string} = {}, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.PREFETCH_POSTS_FOR_TOPIC:
            return {
                ...state, 
                [action.topicId]: action.status,
            };
        case GeneralTypes.WEBSOCKET_FAILURE:
        case GeneralTypes.WEBSOCKET_CLOSED:
        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

export default combineReducers({
    postVisibility, 
    lastTopicViewTime,
    loadingPosts, 
    focusedPostId, 
    mobileView, 
    lastUnreadTopic,
    lastGetPosts, 
    toastStatus, 
    topicPrefetchStatus,
});