import keyMirror from "sigmaflow-redux/utils/key_mirror";


export default keyMirror({
    SEARCH_POSTS_REQUEST: null,
    SEARCH_POSTS_SUCCESS: null,

    SEARCH_TOPIC_THREADS_REQUEST: null,
    SEARCH_TOPIC_THREADS_SUCCESS: null,

    SEARCH_FILES_REQUEST: null, 
    SEARCH_FILES_SUCCESS: null, 

    SEARCH_PINNED_POSTS_REQUEST: null,
    SEARCH_PINNED_POSTS_SUCCESS: null,
    SEARCH_PINNED_POSTS_FAILURE: null,
    REMOVE_SEARCH_PINNED_POSTS: null,
    RECEIVED_SEARCH_TERM: null,
    RECEIVED_SEARCH_POSTS: null,
    RECEIVED_SEARCH_THREADS: null,
    RECEIVED_SEARCH_FILES: null,
    RECEIVED_SEARCH_PINNED_POSTS: null, 
    REMOVE_SEARCH_POSTS: null,
    REMOVE_SEARCH_THREADS: null,
    REMOVE_SEARCH_FILES: null,
    REMOVE_SEARCH_TERM: null,
});