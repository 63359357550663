import {createSelector} from 'reselect';

import {GlobalState} from 'sigmaflow-redux/types/store';
import { Topic, TopicMembership } from 'sigmaflow-redux/types/topics';
import { UserProfile } from 'sigmaflow-redux/types/users';
import { RelationOneToOne, IDMappedObjects } from 'sigmaflow-redux/types/utilities';
import { WorkspaceMembership } from 'sigmaflow-redux/types/workspaces';

export function getCurrentTopicId(state: GlobalState): string {
    return state.entities.topics.currentTopicId;
}

export function getMyTopicMemberships(state: GlobalState): RelationOneToOne<Topic, TopicMembership> {
    return state.entities.topics.myMembers;
}

export const getMyCurrentTopicMembership: (a: GlobalState) => TopicMembership | undefined = createSelector(
    'getMyCurrentTopicMembership',
    getCurrentTopicId,
    getMyTopicMemberships,
    (currentTopicId, topicMemberships) => {
        return topicMemberships[currentTopicId];
    },
);


export function getMembersInTopic(state: GlobalState, topicId: string): Record<string, TopicMembership> {
    return state.entities.topics?.membersInTopic?.[topicId] || {};
}

// Workspaces

export function getMembersInWorkspace(state: GlobalState, workspaceId: string): RelationOneToOne<UserProfile, WorkspaceMembership> {
    return state.entities.workspaces?.membersInWorkspace?.[workspaceId] || {};
}


// Users

export function getCurrentUser(state: GlobalState): UserProfile {
    return state.entities.users.profiles[getCurrentUserId(state)];
}

export function getCurrentUserId(state: GlobalState): string {
    return state.entities.users.currentUserId;
}

export function getUsers(state: GlobalState): IDMappedObjects<UserProfile> {
    return state.entities.users.profiles;
}

