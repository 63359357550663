import React from 'react';

export function makeAsyncComponent<ComponentProps>(displayName: string, LazyComponent: React.ComponentType<ComponentProps>, fallback: React.ReactNode = null) {
    const Component = (props: ComponentProps) => (
        <React.Suspense fallback = {fallback}>
            <LazyComponent {...props}/>
        </React.Suspense>
    );

    Component.displayName = displayName;
    return Component;
}