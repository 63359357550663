import {hot} from 'react-hot-loader/root';
import React from 'react';
import {Provider} from 'react-redux';
// import { Route, BrowserRouter as Router} from 'react-router-dom';

 import {Route, Router} from 'react-router-dom';


import { getHistory } from 'utils/browser_history';
import store from 'stores/redux_store.jsx';

//import Root from 'components/root';

import {makeAsyncComponent} from 'components/async_load';

const LazyRoot = React.lazy(() => import('components/root'));

const Root = makeAsyncComponent('Root', LazyRoot);

//const history = createBrowserHistory({ basename: window.basename });

class App extends React.PureComponent {
    render() {
        
        return (
            <Provider store={store}>
             <Router history={getHistory()}>  
          { /* <Router basename={window.basename}> */} 
                    <Route 
                    path={'/'}
                    component={Root}
                    />
                </Router>
            </Provider>
        );
    }
}

function testComponent() {
return (
    <div>
        <p>Hello world!!</p>
    </div>
)
}

export default hot(App);