import keyMirror from 'sigmaflow-redux/utils/key_mirror';

export default keyMirror({
    CREATE_THREAD: null,
    CREATE_THREAD_REQUEST: null,
    CREATE_THREAD_SUCCESS: null,
    CREATE_THREAD_FAILURE: null,
    GET_THREADS_REQUEST: null,
    GET_THREADS_SUCCESS: null,
    GET_THREADS_FAILURE: null,
    GET_THREAD_POSTS_REQUEST: null,
    GET_THREAD_POSTS_SUCCESS: null,
    GET_THREAD_POSTS_FAILURE: null,
    CREATE_THREAD_RESET_REQUEST: null,
    RECEIVED_THREAD: null,
    RECEIVED_NEW_THREAD: null,
    RECEIVED_THREADS: null,
    RECEIVED_UNREAD_THREADS: null,
    FOLLOW_CHANGED_THREAD: null,
    READ_CHANGED_THREAD: null,
    ALL_TOPIC_THREADS_READ: null,
    DECREMENT_THREAD_COUNTS: null,
    RECEIVED_THREAD_COUNTS: null,
    THREAD_REMOVED: null,
    THREADS_FIRST_LOAD_COMPLETED: null,
    
    RECEIVED_UNREAD_THREAD_COUNTS_FOR_TOPICS: null,
    INCREMENT_TOTAL_THREAD_COUNT_FOR_TOPIC: null,
    INCREMENT_TOTAL_UNREAD_THREAD_COUNT_FOR_TOPIC: null,
});