/* eslint-disable max-lines */
import { createSelector } from "reselect";

import {Posts, Preferences} from 'sigmaflow-redux/constants';

import { getCurrentUser, getCurrentUserId  } from "./common";
import { getMyPreferences } from "./preferences";
import {getUsers,  getUserStatuses} from 'sigmaflow-redux/selectors/entities/users';

import {PostWithFormatData} from 'sigmaflow-redux/types/posts';

import { Topic } from "sigmaflow-redux/types/topics";
import {
    MessageHistory, 
    OpenGraphMetadata, 
    Post, 
    PostOrderBlock,
} from 'sigmaflow-redux/types/posts';
import { Reaction } from "sigmaflow-redux/types/reactions";
import { GlobalState } from "sigmaflow-redux/types/store";
import { UserProfile } from "sigmaflow-redux/types/users";
import {
    IDMappedObjects, 
    RelationOneToOne, 
    RelationOneToMany,
} from 'sigmaflow-redux/types/utilities';

import { createIdsSelector } from "sigmaflow-redux/utils/helpers";

import {
    isPostEphemeral, 
    isSystemMessage, 
    shouldFilterJoinLeavePost,
    comparePosts, 
    isPostPendingOrFailed, 
    isPostCommentMention,
} from 'sigmaflow-redux/utils/post_utils';

import { getPreferenceKey } from "sigmaflow-redux/utils/preference_utils";
import { postsInThread } from "sigmaflow-redux/reducers/entities/posts";

export function getAllPosts(state: GlobalState) {
    return state.entities.posts.posts;

}

export type UserActivityPost = Post & {
    system_post_ids: string[];
    user_activity_posts: Post[];
}


export function getPost(state: GlobalState, postId: Post['id']): Post {
    return getAllPosts(state)[postId];
}

export function getPostRepliesCount(state: GlobalState, postId: Post['id']): number {
    return state.entities.posts.postsReplies[postId] || 0;
}

export function getPostsInThread(state: GlobalState): RelationOneToMany<Post, Post> {
    return state.entities.posts.postsInThread;
}

export function getReactionsForPosts(state: GlobalState): RelationOneToOne<Post, {
    [x: string]: Reaction;
}> {
    return state.entities.posts.reactions;
}

export function makeGetReactionsForPost(): (state: GlobalState, postId: Post['id']) => {
    [x: string]: Reaction;
} | undefined | null {
    return createSelector('makeGetReactionsForPost', getReactionsForPosts, (state: GlobalState, postId: string) => postId, (reactions, postId) => {
        if (reactions[postId]) {
            return reactions[postId];
        }

        return null;
    });
}

export function getOpenGraphMetadata(state: GlobalState): RelationOneToOne<Post, Record<string, OpenGraphMetadata>> {
    return state.entities.posts.openGraph;
}

export function getOpenGraphMetadataForUrl(state: GlobalState, postId: string, url: string) {
    const openGraphForPost = state.entities.posts.openGraph[postId];

    return openGraphForPost ? openGraphForPost[url] : undefined;
}


// getPostIdsInCurrentTopic returns the IDs of the posts loaded at the bottom of the topic.
// It does not include older posts such as those loaded by viewing a thread or a permalink.
export function getPostIdsInCurrentTopic(state: GlobalState): Array<Post['id']> | undefined | null {
    return getPostIdsInTopic(state, state.entities.topics.currentTopicId);
}

// getPostsInCurrentTopic returns the posts loaded at the bottom of the the topic. It does not include
// older posts such as those loaded by viewing a thread or a permalink.
export const getPostsInCurrentTopic: (state: GlobalState) => PostWithFormatData[] | undefined | null = (() => {
    const getPostsInTopic = makeGetPostsInTopic();
    return (state: GlobalState) => getPostsInTopic(state, state.entities.topics.currentTopicId, -1);
})();

export function makeGetPostIdsForThread(): (state: GlobalState, postId: Post['id']) => Array<Post['id']> {
    const getPostsForThread = makeGetPostsForThread();

    return createIdsSelector(
        'makeGetPostIdsForThread', 
        (state: GlobalState, rootId: Post['id']) => getPostsForThread(state, rootId),
        (posts) => {
            return posts.map((post) => post.id);
        }
    );
}

export function makeGetPostsChunkAroundPost(): (state: GlobalState, postId: Post['id'], topicId: Topic['id']) => PostOrderBlock | null | undefined {
    return createIdsSelector(
        'makeGetPostsChunkAroundPost',
        (state: GlobalState, postId: string, topicId: string) => state.entities.posts.postsInTopic[topicId],
        (state: GlobalState, postId) => postId,
        (postsForTopic, postId) => {
            if (!postsForTopic) {
                return null;
            }

            let postChunk

            for (const block of postsForTopic) {
                const index = block.order.indexOf(postId);

                if (index === -1) {
                    continue;
                }

                postChunk = block;
            }

            return postChunk;
        },
    );
}

export function makeGetPostIdsAroundPost(): (state: GlobalState, postId: Post['id'], topicId: Topic['id'], a: {
    postsBeforeCount: number;
    postsAfterCount: number;
}) => Array<Post['id']> | undefined | null {
    const getPostsChunkAroundPost = makeGetPostsChunkAroundPost();
    return createIdsSelector(
        'makeGetPostIdsAroundPost',
        (state: GlobalState, postId: string, topicId: string) => getPostsChunkAroundPost(state, postId, topicId),
        (state: GlobalState, postId) => postId,
        (state: GlobalState, postId, topicId, options) => options && options.postsBeforeCount, 
        (state: GlobalState, postId, topicId, options) => options && options.postsAfterCount,
        (postsChunk, postId, postsBeforeCount = Posts.POST_CHUNK_SIZE / 2, postsAfterCount = Posts.POST_CHUNK_SIZE / 2) => {
            if (!postsChunk || !postsChunk.order) {
                return null;
            }

            const postIds = postsChunk.order;
            const index = postIds.indexOf(postId);

            // Remember that posts that come after the post have a maller index.
            const minPostIndex = postsAfterCount === -1 ? 0 : Math.max(index-postsAfterCount, 0);
            const maxPostIndex = postsBeforeCount === -1 ? postIds.length : Math.min(index + postsBeforeCount + 1, postIds.length); // Needs the extra 1 to include the focused post

            return postIds.slice(minPostIndex, maxPostIndex);
        },
    );
}

function formatPostInTopic(post: Post, previousPost: Post | undefined | null, index: number, allPosts: IDMappedObjects<Post>, postsInThread: RelationOneToMany<Post, Post>, postIds: Array<Post['id']>, currentUser: UserProfile, focusedPostId: Post['id']): PostWithFormatData {
    let isFirstReply = false;
    let isLastReply = false;
    let highlight = false;
    let commentedOnPost: Post | undefined;

    if (post.id === focusedPostId) {
        highlight = true;
    }

    if (post.root_id) {
        if (previousPost && previousPost.root_id !== post.root_id) {
            // Post is the first reply in a list of consecutive replies.
            isFirstReply = true;

            if (previousPost && previousPost.id !==  post.root_id) {
                commentedOnPost = allPosts[post.root_id];
            }
        }

        if (index - 1 < 0 || allPosts[postIds[index - 1]].root_id !== post.root_id) {
            // Post is the last reply in a list of consecutive replies.
            isLastReply = true;
        }
    }

    let previousPostIsComment = false;

    if (previousPost && previousPost.root_id) {
        previousPostIsComment = true;
    }

    let consecutivePostByUser = false;
    if (previousPost && 
        previousPost.user_id === post.user_id && 
        post.create_at - previousPost.create_at <= Posts.POST_COLLAPSE_TIMEOUT && 
        !isSystemMessage(post) && !isSystemMessage(previousPost)) {
            // The last post and this post were made by the same user within the some time.
            consecutivePostByUser = true;
        }

        let threadRepliedToByCurrentUser = false;
        let replyCount = 0;
        let isCommentMention = false;

        if (currentUser) {
            const rootId = post.root_id || post.id; 
            const threadIds = postsInThread[rootId] || [];

            for (const pid of threadIds) {
                const p = allPosts[pid];
                if (!p) {
                    continue;
                }

                if (p.user_id === currentUser.id) {
                    threadRepliedToByCurrentUser = true;
                }

                if (!isPostEphemeral(p)) {
                    replyCount += 1;
                }
            }

            const rootPost = allPosts[rootId];

            isCommentMention = isPostCommentMention({post, currentUser, threadRepliedToByCurrentUser, rootPost});
        }

        return {
            ...post, 
            isFirstReply, 
            isLastReply, 
            previousPostIsComment, 
            commentedOnPost, 
            replyCount, 
            consecutivePostByUser, 
            isCommentMention, 
            highlight,
        };
}

// makeGetPostsInTopic creates a selector that returns up to the given number of posts loaded
// at the bottom of a given topic. It does not include older posts such as those loaded by
// viewing a thread or permalink.
export function makeGetPostsInTopic(): (state: GlobalState, topicId: Topic['id'], numPosts: number) => PostWithFormatData[] | undefined | null {
    return createSelector(
        'makeGetPostsInTopic',
        getAllPosts, 
        getPostsInThread, 
        (state: GlobalState, topicId: Topic['id']) => getPostIdsInTopic(state, topicId),
        getCurrentUser, 
        getMyPreferences, 
        (state: GlobalState, topicId: Topic['id'], numPosts: number) => numPosts || Posts.POST_CHUNK_SIZE,
        (allPosts, postsInThread, allPostIds, currentUser, myPreferences, numPosts) => {
            if (!allPostIds) {
                return null;
            }

            const posts: PostWithFormatData[] = [];

            const joinLeavePref = myPreferences[getPreferenceKey(Preferences.CATEGORY_ADVANCED_SETTINGS, Preferences.ADVANCED_FILTER_JOIN_LEAVE)];
            const showJoinLeave = joinLeavePref ? joinLeavePref.value !== 'false' : true;

            const postIds = numPosts === -1 ? allPostIds : allPostIds.slice(0, numPosts);

            for (let i = 0; i < postIds.length; i++) {
                const post = allPosts[postIds[i]];

                if (shouldFilterJoinLeavePost(post, showJoinLeave, currentUser ? currentUser.username : '')) {
                    continue;
                }

                const previousPost = allPosts[postIds[i+1]] || null;
                posts.push(formatPostInTopic(post, previousPost, i, allPosts, postsInThread, postIds, currentUser, ''));
            }

            return posts;
        },
    );
}

export function makeGetPostsAroundPost(): (state: GlobalState, postId: Post['id'], topicId: Topic['id']) => PostWithFormatData[] | undefined | null {
    const getPostIdsAroundPost = makeGetPostIdsAroundPost();
    const options = {
        postsBeforeCount: -1, // Where this is used in the webapp, view state is used to determine how far back to display
        postsAfterCount: Posts.POST_CHUNK_SIZE / 2,
    };

    return createSelector(
        'makeGetPostsAroundPost', 
        (state: GlobalState, focusedPostId: string, topicId: string) => getPostIdsAroundPost(state, focusedPostId, topicId, options),
        getAllPosts, 
        getPostsInThread, 
        (state: GlobalState, focusedPostId) => focusedPostId,
        getCurrentUser, 
        getMyPreferences, 
        (postIds, allPosts, postsInThread, focusedPostId, currentUser, myPreferences) => {
            if (!postIds || !currentUser) {
                return null;
            }

            const posts: PostWithFormatData[] = [];
            const joinLeavePref = myPreferences[getPreferenceKey(Preferences.CATEGORY_ADVANCED_SETTINGS, Preferences.ADVANCED_FILTER_JOIN_LEAVE)];
            const showJoinLeave = joinLeavePref ? joinLeavePref.value !== 'false' : true;

            for (let i = 0; i < postIds.length; i++) {
                const post = allPosts[postIds[i]];

                if (shouldFilterJoinLeavePost(post, showJoinLeave, currentUser.username)) {
                    continue;
                }

                const previousPost = allPosts[postIds[i+1]] || null;
                const formattedPost = formatPostInTopic(post, previousPost, i, allPosts, postsInThread, postIds, currentUser, focusedPostId);

                posts.push(formattedPost);
            }

            return posts;
        },
    );
}

// Returns a function that creates a selector that will get the posts for a given thread.
// That selector will take a props object (containing rootId field) as its only argument and will
// be memoized based on that argument.
export function makeGetPostsForThread(): (state: GlobalState, rootId: string) => Post[] {
    return createIdsSelector(
        'makeGetPostsForThread',
        getAllPosts, 
        (state: GlobalState, rootId: string) => state.entities.posts.postsInThread[rootId],
        (state: GlobalState, rootId: string) => state.entities.posts.posts[rootId],
        (posts, postsforThread, rootPost) => {
            const thread: Post[] = [];

            if (rootPost) {
                thread.push(rootPost);
            }

            postsforThread?.forEach((id) => {
                const post = posts[id];

                if (post) {
                    thread.push(post);
                }
            });

            thread.sort(comparePosts);
            return thread;
        }, 
    );
}

// The selector below filters current user if it exists. Excluding current user is just for 
// convenience.
export function makeGetProfilesForThread(): (state: GlobalState, rootId: string) => UserProfile[] {
    const getPostsForThread = makeGetPostsForThread();
    return createSelector(
        "makeGetProfilesForThread",
        getUsers, 
        getCurrentUserId, 
        getPostsForThread, 
        getUserStatuses, 
        (allUsers, currentUserId, posts, userStatuses) => {
            const profileIds = posts.map((post) => post.user_id);
            const uniqueIds = [...new Set(profileIds)];
            return uniqueIds.reduce((acc: UserProfile[], id: string) => {
                const profile: UserProfile = userStatuses ? {...allUsers[id], status: userStatuses[id]} : {...allUsers[id]};

                if (profile && Object.keys(profile).length > 0 && currentUserId !== id) {
                    return [
                        ...acc, 
                        profile,
                    ];
                }

                return acc;
            }, []);
        }
    );
}

export function makeGetCommentCountForPost(): (state: GlobalState, post: Post) => number {
    return createSelector(
        'makeGetCommentCountForPost',
        getAllPosts, 
        (state: GlobalState, post: Post) => state.entities.posts.postsInThread[post ? post.root_id || post.id : ''] || null,
        (state: GlobalState, post: Post) => post, 
        (posts, postsForThread, post) => {
            if (!post) {
                return 0;
            }

            if (!postsForThread) {
                return post.reply_count;
            }

            let count = 0;
            postsForThread.forEach((id) => {
                const post = posts[id];
                if (post && post.state !==  Posts.POST_DELETED && !isPostEphemeral(post)) {
                    count += 1;
                }
            });

            return count;
        },
    );
}

export const getSearchResults: (state: GlobalState) => Post[] = createSelector(
    'getSearchResults',
    getAllPosts, 
    (state: GlobalState) => state.entities.search.results, 
    (posts, postIds) => {
        if (!postIds) {
            return [];
        }

        return postIds.map((id) => posts[id]);
    },
);

// Returns the matched text from the search results, if the server has provided them.
// These matches will only be present if the server is running.. otehrwise null will be
// returned.
export function getSearchMatches(state: GlobalState): {
    [x: string]: string[];
} {
    return state.entities.search.matches;
}

export function makeGetMessageInHistoryItem(type: 'post' | 'comment'): (state: GlobalState) => string {
    return createSelector(
        'makeGetMessageInHistoryItem',
        (state: GlobalState) => state.entities.posts.messageHistory,
        (messageHistory: MessageHistory) => {
            const idx = messageHistory.index[type];
            const messages = messageHistory.messages;
            if (idx >= 0 && messages && messages.length > idx) {
                return messages[idx];
            }

            return '';
        },
    );
}



export function makeGetPostsForIds(): (state: GlobalState, postIds: Array<Post['id']>) => Post[] {
    return createIdsSelector(
        'makeGetPostsForIds',
        getAllPosts, 
        (state: GlobalState, postIds: Array<Post['id']>) => postIds,
        (allPosts, postIds) => {
            if (!postIds) {
                return [];
            }

            return postIds.map((id) => allPosts[id]);
        },
    );
}

export const getMostRecentPostIdInTopic: (state: GlobalState, topicId: Topic['id']) => Post['id'] | undefined | null = createSelector(
    'getMostRecentPostInTopic', 
    getAllPosts, 
    (state: GlobalState, topicId: string) => getPostIdsInTopic(state, topicId), 
    getMyPreferences, 
    (posts, postIdsInTopic, preferences) => {
        if (!postIdsInTopic) {
            return '';
        }

        const key = getPreferenceKey(Preferences.CATEGORY_ADVANCED_SETTINGS, Preferences.ADVANCED_FILTER_JOIN_LEAVE);
        const allowSystemMessages = preferences[key] ? preferences[key].value === 'true' : true;

        if (!allowSystemMessages) {
            // return the most recent non-system message in the topic.
            let postId;
            for (let i = 0; i < postIdsInTopic.length; i++) {
                const p = posts[postIdsInTopic[i]];
                if (!p.type || !p.type.startsWith(Posts.SYSTEM_MESSAGE_PREFIX)) {
                    postId = p.id;
                    break;
                }
            }

            return postId;
        }

        // return the most recent message in the topic.
        return postIdsInTopic[0];
    },
);

export function getRecentPostsChunkInTopic(state: GlobalState, topicId: Topic['id']): PostOrderBlock | null | undefined {
    const postsForTopic = state.entities.posts.postsInTopic[topicId];

    if (!postsForTopic) {
        return null;
    }

    return postsForTopic.find((block) => block.recent);
}

export function getOldestPostsChunkInTopic(state: GlobalState, topicId: Topic['id']): PostOrderBlock | null | undefined {
    const postsForTopic = state.entities.posts.postsInTopic[topicId];

    if (!postsForTopic) {
        return null;
    }

    return postsForTopic.find((block) => block.oldest);
}

// getPostIdsInTopic returns the IDs of posts loaded at the bottom of the given topic.
// It does not include older posts such as those loaded by viewing a thread or a permalink.
export function getPostIdsInTopic(state: GlobalState, topicId: Topic['id']): Array<Post['id']> | undefined | null {
    const recentBlock = getRecentPostsChunkInTopic(state, topicId);

    if (!recentBlock) {
        return null;
    }

    return recentBlock.order;
}

export function getPostsChunkInTopicAroundTime(state: GlobalState, topicId: Topic['id'], timestamp: number): PostOrderBlock | undefined | null {
    const postsEntity = state.entities.posts;
    const postsForTopic = postsEntity.postsInTopic[topicId];
    const posts = postsEntity.posts;
    if (!postsForTopic) {
        return null;
    }

    const blockAroundTimestamp = postsForTopic.find((block) => {
        const {order} = block;
        const recentPostInBlock = posts[order[0]];
        const oldestPostInBlock = posts[order[order.length - 1]];

        if (recentPostInBlock && oldestPostInBlock) {
            return (recentPostInBlock.create_at >= timestamp && oldestPostInBlock.create_at <= timestamp);
        }

        return false;
    });

    return blockAroundTimestamp;
}

export const makeIsPostCommentMention = (): ((state: GlobalState, postId: Post['id']) => boolean) => {
    return createSelector(
        'makeIsPostCommentMention', 
        getAllPosts, 
        getPostsInThread, 
        getCurrentUser, 
        getPost, 
        (allPosts, postsInThread, currentUser, post) => {
            if (!post) {
                return false;
            }

            let threadRepliedToByCurrentUser = false;
            let isCommentMention = false;
            if (currentUser) {
                const rootId = post.root_id || post.id;
                const threadIds = postsInThread[rootId] || [];

                for (const pid of threadIds) {
                    const p = allPosts[pid];

                    if (!p) {
                        continue;
                    }

                    if (p.user_id === currentUser.id) {
                        threadRepliedToByCurrentUser = true;
                    }
                }

                const rootPost = allPosts[rootId];

                isCommentMention = isPostCommentMention({post, currentUser, threadRepliedToByCurrentUser, rootPost});
            }

            return isCommentMention
        },
    );
};

export function getExpandedLink(state: GlobalState, link: string): string {
    return state.entities.posts.expandedURLs[link];
}

export const isPostIdSending = (state: GlobalState, postId: Post['id']): boolean => {
    return state.entities.posts.pendingPostIds.some((sendingPostId) => sendingPostId === postId);
}