import { combineReducers } from "redux";

// import {WorkspaceTypes, UserTypes} from 'sigmaflow-redux/action_types';
import { UserTypes } from "sigmaflow-redux/action_types";
import type { GenericAction } from "sigmaflow-redux/types/actions";

import {ActionTypes} from 'utils/constants';

function isOpen(state = false, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.TOGGLE_LHS:
            return !state;
        case ActionTypes.OPEN_LHS: 
           return true;
        case ActionTypes.CLOSE_LHS: 
            return false;
        case ActionTypes.TOGGLE_RHS_MENU: 
            return false;
        case ActionTypes.OPEN_RHS_MENU: 
             return false;
       // case WorkspaceTypes.SELECT_WORKSPACE:
        //    return false;
        case UserTypes.LOGOUT_SUCCESS:
            return false;
        default:
            return state;
    }
}

export default combineReducers({
    isOpen,
})