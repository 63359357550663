import keyMirror from "sigmaflow-redux/utils/key_mirror";

export default keyMirror({

    UPLOAD_FILES_REQUEST: null, 
    UPLOAD_FILES_SUCCESS: null, 
    UPLOAD_FILES_FAILURE: null, 
    UPLOAD_FILES_CANCEL: null,

    RECEIVED_FILES_FOR_SEARCH: null, 
    RECEIVED_FILES_FOR_POST: null, 
    RECEIVED_UPLOAD_FILES: null, 
    RECEIVED_FILE_PUBLIC_LINK: null,
});