import {General} from 'sigmaflow-redux/constants';

import { getCurrentUser } from 'sigmaflow-redux/selectors/entities/common';

import { GlobalState } from 'sigmaflow-redux/types/store';

export function getCurrentUserLocale(state: GlobalState, defaultLocale = General.DEFAULT_LOCALE) {
    const currentUser = getCurrentUser(state);

    if (!currentUser) {
       return defaultLocale;
    }

    return currentUser.locale || defaultLocale;
}