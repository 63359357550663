import {createSelector} from 'reselect';

import {General} from 'sigmaflow-redux/constants';

import { GlobalState } from 'sigmaflow-redux/types/store';

import { ClientConfig, ClientLicense } from 'sigmaflow-redux/types/config';

import {isMinimumServerVersion} from 'sigmaflow-redux/utils/helpers';


export function getConfig(state: GlobalState): Partial<ClientConfig> {
    return state.entities.general.config;
}

export function getLicense(state: GlobalState): ClientLicense {
    return state.entities.general.license;
}

export function getCurrentUrl(state: GlobalState): string {
    return state.entities.general.credentials.url;
}

export const getManagedResourcePaths: (state: GlobalState) => string[] = createSelector(
    'getManagedResourcePaths',
    getConfig, 
    (config) => {
        /*
        if (!config.ManagedResourcePaths) {
            return [];
        }

        return config.ManagedResourcePaths.split(',').map((path) => path.trim());
        */

        return [];
    },
);

export const getServerVersion = (state: GlobalState): string => {
    return state.entities.general.serverVersion;
}

export function getFirstAdminVisitMarketplaceStatus(state: GlobalState): boolean {
    return state.entities.general.firstAdminVisitMarketplaceStatus;
}

export function getFirstAdminSetupComplete(state: GlobalState): boolean {
    return state.entities.general.firstAdminCompleteSetup;
}

export function isPerformanceDebuggingEnabled(state: GlobalState): boolean {
   // return state.entities.general.config.EnableClientPerformanceDebugging === 'true';
   return false;
}

export const getAutoLinkedUrlSchemes: (a: GlobalState) => string[] = createSelector(
    'getAutolinkedUrlSchemes',
    getConfig, 
    (config: Partial<ClientConfig>): string[] => {
        if (!config.CustomUrlSchemes) {
            General.DEFAULT_AUTOLINKED_URL_SCHEMES;
        }

        return [
            ...General.DEFAULT_AUTOLINKED_URL_SCHEMES, 
            //...config.CustomUrlSchemes.split(','),
        ];
    }
);

export const isMarketplaceEnabled: (state: GlobalState) => boolean = createSelector(
    'isMarketplaceEnabled', 
    getConfig, 
    (config) => {
        return config.PluginsEnabled === 'true' && config.EnableMarketPlace === 'true';
    },
)

