import { combineReducers } from "redux";

import general from "./general";
import orgs from './orgs';
import users from './users';
import preferences from "./preferences";
import workspaces from "./workspaces";
import topics from "./topics";
import threads from './threads'
import posts from './posts';
import topicCategories from './topic_categories';
import roles from './roles';
import admin from './admin';
import bots from './bots';
import cloud from './cloud';
import files from './files';

export default combineReducers({
    general,
    orgs,
    users,
    bots,
    roles,
    preferences,
    workspaces,
    topics,
    threads, 
    posts,
    files,
    topicCategories,
    admin,
    cloud,
});