import {createSelector} from 'reselect';

import {
    getCurrentTopicId,
    getCurrentUser,
    getCurrentUserId,
    getMyCurrentTopicMembership,
    getUsers,
    getMembersInWorkspace,
    getMembersInTopic,
} from 'sigmaflow-redux/selectors/entities/common';

import {getConfig, getLicense} from 'sigmaflow-redux/selectors/entities/general';
import { getDirectShowPreferences } from 'sigmaflow-redux/selectors/entities/preferences';

import {
    displayUsername,
    filterProfilesStartingWithTerm,
    filterProfilesMatchingWithTerm,
    isSystemAdmin,
    includesAnAdminRole,
    profileListToMap, 
    sortByUsername,
    applyRolesFilters,
} from 'sigmaflow-redux/utils/user_utils';

import {Topic, TopicMembership} from 'sigmaflow-redux/types/topics';
import { GlobalState } from 'sigmaflow-redux/types/store';
import {Workspace, WorkspaceMembership} from 'sigmaflow-redux/types/workspaces';
import { UserProfile } from 'sigmaflow-redux/types/users';
import {
    IDMappedObjects,
    RelationOneToMany,
    RelationOneToManyUnique,
    RelationOneToOne
} from 'sigmaflow-redux/types/utilities';
import { GetStateFunc } from 'sigmaflow-redux/types/actions';
import { General } from 'sigmaflow-redux/constants';


export {getCurrentUser, getCurrentUserId, getUsers};

type Filters = {
    role?: string;
    inactive?: boolean;
    active?: boolean;
    roles?: string[];
    exclude_roles?: string[];
    topic_roles?: string[];
    workspace_roles?: string[];
};

export function getUserIdsInTopics(state: GlobalState): RelationOneToManyUnique<Topic, UserProfile> {
    return state.entities.users.profilesInTopic;
}

export function getUserIdsNotInTopics(state: GlobalState): RelationOneToManyUnique<Topic, UserProfile> {
    return state.entities.users.profilesNotInTopic;
}

export function getUserIdsInWorkspaces(state: GlobalState): RelationOneToMany<Workspace, UserProfile> {
    return state.entities.users.profilesInWorkspace;
}

export function getUserIdsNotInWorkspaces(state: GlobalState): RelationOneToMany<Workspace, UserProfile> {
    return state.entities.users.profilesNotInWorkspace;
}

export function getUserStatuses(state: GlobalState): RelationOneToOne<UserProfile, string> {
    return state.entities.users.statuses;
}

export function getUserSessions(state: GlobalState): any[] {
    return state.entities.users.mySessions;
}

export function getUserAudits(state: GlobalState):  any[] {
    return state.entities.users.myAudits;
}

export function getUser(state: GlobalState, id: UserProfile['id']): UserProfile {
    return state.entities.users.profiles[id];
}


export const getUsersByUsername: (a: GlobalState) => Record<string, UserProfile> = createSelector(
    'getUsersByUsername',
    getUsers,
    (users) => {
        const usersByUsername: Record<string, UserProfile> = {};

        for (const id in users) {
            if (users.hasOwnProperty(id)) {
                const user = users[id];
                usersByUsername[user.username] = user;
            }
        }

        return usersByUsername;
    },
);

export function getUserByUsername(state: GlobalState, username: UserProfile['username']): UserProfile {
    return getUsersByUsername(state)[username];
}


export const getUsersByEmail: (a: GlobalState) => Record<string, UserProfile> = createSelector(
    'getUsersByEmail',
    getUsers,
    (users) => {
        const usersByEmail: Record<string, UserProfile> = {};

        for (const user of Object.keys(users).map((key) => users[key])) {
            usersByEmail[user.email] = user;
        }

        return usersByEmail;
    },
);


export function getUserByEmail(state: GlobalState, email: UserProfile['email']): UserProfile {
    return getUsersByEmail(state)[email];
}


export const isCurrentUserSystemAdmin: (state: GlobalState) => boolean = createSelector(
    'isCurrentUserSystemAdmin',
    getCurrentUser,
    (user) => {
        const roles = user?.roles || '';
        return isSystemAdmin(roles);
    },
);


export const currentUserHasAnAdminRole: (state: GlobalState) => boolean = createSelector(
    'currentUserHasAnAdminRole',
    getCurrentUser,
    (user) => {
        const roles = user.roles || '';
        return includesAnAdminRole(roles);
    },
);

export const getCurrentUserRoles: (a: GlobalState) => UserProfile['roles'] = createSelector(
    'getCurrentUserRoles', 
    getMyCurrentTopicMembership,
   // (state) => state.entities.workspaces.myMembers[state.entities.workspaces.currentWorkspaceId],
    getCurrentUser, 
    (currentTopicMembership, currentUser) => {
        let roles = '';

        if (currentTopicMembership) {
            roles += `${currentTopicMembership.roles} `;
        }

        if (currentUser) {
            roles += currentUser.roles;
        }

        return roles.trim();
    },
)

export type UserMentionKey = {
    key: string;
    caseSensitive?: boolean;
}

export const getCurrentUserMentionKeys: (state: GlobalState) => UserMentionKey[] = createSelector(
    'getCurrentUserMentionKeys',
    getCurrentUser, 
    (user: UserProfile) => {
        let keys: UserMentionKey[] = [];

        if (!user || !user.notify_props) {
            return keys;
        }

        if (user.notify_props.mention_keys) {
            keys = keys.concat(user.notify_props.mention_keys.split(',').map((key) => {
                return {key};
            }));
        }

        if (user.notify_props.first_name === 'true' && user.first_name) {
            keys.push({key: user.first_name, caseSensitive: true});
        }

        if (user.notify_props.topic === 'true') {
            keys.push({key: '@topic'});
            keys.push({key: '@all'});
            keys.push({key: '@here'});
        }

        const usernameKey = '@' + user.username;
        if (keys.findIndex((key) => key.key === usernameKey) === -1) {
            keys.push({key: usernameKey});
        }

        return keys;
    },
);

export function getStatusForUserId(state: GlobalState, userId: UserProfile['id']): string {
    return getUserStatuses(state)[userId];
}

export function checkIsFirstAdmin(currentUser: UserProfile, users: IDMappedObjects<UserProfile>): boolean {
    if (!currentUser) {
        return false;
    }

    if (!currentUser.roles.includes('system_admin')) {
        return false;
    }

    for (const user of Object.values(users)) {
        if (user.roles.includes('system_admin') && user.create_at < currentUser.create_at) {
            // If the user in the list is an admin with create_at less than our user, then
            // the user is older than the current one, so it can't be the first admin.
            return false;
        }
    }

    return true;
}


export const isFirstAdmin = createSelector(
    'isFirstAdmin',
    (state: GlobalState) => getCurrentUser(state),
    (state: GlobalState) => getUsers(state),
    checkIsFirstAdmin,
);


export const  shouldShowTermsOfService: (state: GlobalState) => boolean = createSelector(
    'shouldShowTermsOfService',
    getConfig,
    getCurrentUser,
    getLicense, 
    (config, user, license) => {
        // default to false if the user is not logged in or if the setting does not exists.
        const acceptedTermsId = user ? user.terms_of_service_id : '';
        const acceptedAt = user ? user.terms_of_service_create_at: 0;

        const featureEnabled = license.IsLicensed === 'true' && config.EnableCustomTermsOfService === 'true';
        const reacceptanceTime = parseInt(config.CustomTermsOfServiceAcceptancePeriod!, 10) * 1000 * 60 * 60 * 24;
        const timeElapsed = new Date().getTime() - acceptedAt;
        return Boolean(user && featureEnabled && (config.CustomTermsOfServiceId !== acceptedTermsId || timeElapsed > reacceptanceTime));
    },
);

export function makeGetDisplayName(): (state: GlobalState, userId: UserProfile['id'], userFallbackUsername?: boolean) => string {
    return createSelector(
        'makeGetDisplayName', 
        (state: GlobalState, userId: string) => getUser(state, userId),
        (state, userId, useFallbackUsername = true) => useFallbackUsername, 
        (user, useFallbackUsername) => {
            return displayUsername(user, "", useFallbackUsername);
        }
    )
}

export const getProfileSetInCurrentTopic: (state: GlobalState) => Set<UserProfile['id']> = createSelector(
    'getProfileSetInCurrentTopic', 
    getCurrentTopicId, 
    getUserIdsInTopics, 
    (currentTopic, topicProfiles) => {
        return topicProfiles[currentTopic];
    },
);

export const getProfilesInCurrentTopic: (state: GlobalState) => UserProfile[] = createSelector(
    'getProfilesInCurrentTopic', 
    getUsers, 
    getProfileSetInCurrentTopic, 
    (profiles, currentTopicProfileSet) => {
        return sortAndInjectProfiles(profiles, currentTopicProfileSet);
    },
);

export const getProfileSetNotInCurrentTopic: (state: GlobalState) => Set<UserProfile['id']> = createSelector(
    'getProfileSetNotInCurrentTopic',
    getCurrentTopicId, 
    getUserIdsNotInTopics, 
    (currentTopic, topicProfiles) => {
        return topicProfiles[currentTopic];
    },
);

export const getActiveProfilesInCurrentTopic: (state: GlobalState) => UserProfile[] = createSelector(
    'getProfilesInCurrentTopic', 
    getUsers, 
    getProfileSetInCurrentTopic,
    (profiles, currentTopicProfileSet) => {
        return sortAndInjectProfiles(profiles, currentTopicProfileSet).filter((user) => user.delete_at === 0);
    },
);

export const getActiveProfilesInCurrentTopicByMemberOrg: (state: GlobalState, memberOrg: string) => UserProfile[] = createSelector(
    'getProfilesInCurrentTopic', 
    getUsers, 
    getProfileSetInCurrentTopic,
    (state: GlobalState, memberOrg: string) => memberOrg, 
    (profiles, currentTopicProfileSet, memberOrg) => {
        return sortAndInjectProfiles(profiles, currentTopicProfileSet).filter((user) => user.org_id === memberOrg && user.delete_at === 0);
    },
);


export const getActiveProfilesInCurrentTopicWithoutSorting: (state: GlobalState) => UserProfile[] = createSelector(
    'getProfilesInCurrentTopic', 
    getUsers, 
    getProfileSetInCurrentTopic, 
    (profiles, currentTopicProfileSet) => {
        return injectProfiles(profiles, currentTopicProfileSet).filter((user) => user.delete_at === 0);
    },
);


export const getProfilesNotInCurrentTopic: (state: GlobalState) => UserProfile[] = createSelector(
    'getProfilesNotInCurrentTopic', 
    getUsers, 
    getProfileSetNotInCurrentTopic, 
    (profiles, notInCurrentTopicProfileSet) => {
        return sortAndInjectProfiles(profiles, notInCurrentTopicProfileSet);
    }
)

export const getProfileSetInCurrentWorkspace: (state: GlobalState) => Array<UserProfile['id']> = createSelector(
    'getProfileSetInCurrentWorkspace',
    (state) => state.entities.workspaces.currentWorkspaceId,
    getUserIdsInWorkspaces, 
    (currentWorkspace, workspaceProfiles) => {
        return workspaceProfiles[currentWorkspace];
    },
);

export const getProfileSetNotInCurrentWorkspace: (state: GlobalState) => Array<UserProfile['id']> = createSelector(
    'getProfileSetNotInCurrentWorkspace', 
    (state) => state.entities.workspaces.currentWorkspaceId, 
    getUserIdsNotInWorkspaces, 
    (currentWorkspace, workspaceProfiles) => {
        return workspaceProfiles[currentWorkspace];
    },
);

export function getProfileSetInWorkspace(state: GlobalState, workspaceId: Workspace['id']): Array<UserProfile['id']> {
    return getUserIdsInWorkspaces(state)[workspaceId];
}

export function getProfileSetNotInWorkspace(state: GlobalState, workspaceId: Workspace['id']): Array<UserProfile['id']> {
    return getUserIdsNotInWorkspaces(state)[workspaceId];
}

export const getProfilesInWorkspace: (state: GlobalState, wspaceId: Workspace['id'], filters?: Filters) => UserProfile[] = createSelector(
    'getProfilesInWorkspace', 
    getUsers, 
    getUserIdsInWorkspaces, 
    getMembersInWorkspace,
    (state: GlobalState, wspaceId: string) => wspaceId, 
    (state: GlobalState, wspaceId: string, filters: Filters) => filters, 
    (profiles, usersInWorkspaces, memberships, wspaceId, filters) => {
       return sortAndInjectProfiles(filterProfiles(profiles, filters, memberships), usersInWorkspaces[wspaceId] || new Set())
    },
);

export const getProfilesNotInWorkspace: (state: GlobalState, wspaceId: Workspace['id'], filters?: Filters) => UserProfile[] = createSelector(
    'getProfilesNotInWorkspace',
    getUsers, 
    getUserIdsNotInWorkspaces, 
    (state: GlobalState, wspaceId: string) => wspaceId, 
    (state: GlobalState, wspaceId: string, filters: Filters) => filters, 
    (profiles, usersNotInWorkspaces, wspaceId, filters) => {
        return sortAndInjectProfiles(filterProfiles(profiles, filters), usersNotInWorkspaces[wspaceId] || new Set());
    },
);

const PROFILE_SET_ALL = 'all';

function sortAndInjectProfiles(profiles: IDMappedObjects<UserProfile>, profileSet?: 'all' | Array<UserProfile['id']> | Set<UserProfile['id']>): UserProfile[] {
    const currentProfiles = injectProfiles(profiles, profileSet);
    return currentProfiles.sort(sortByUsername);
}

function injectProfiles(profiles: IDMappedObjects<UserProfile>, profileSet?: 'all' | Array<UserProfile['id']> | Set<UserProfile['id']>): UserProfile[] {
    let currentProfiles: UserProfile[] = [];

    if (typeof profileSet === 'undefined') {
        return currentProfiles;
    } else if (profileSet === PROFILE_SET_ALL) {
        currentProfiles = Object.keys(profiles).map((key) => profiles[key]);
    } else {
        currentProfiles = Array.from(profileSet).map((p) => profiles[p]);
    }

    return currentProfiles.filter((profile) => Boolean(profile));
}

export const getProfiles: (state: GlobalState,  filters?: Filters) => UserProfile[] = createSelector(
    'getProfiles',
    getUsers, 
    (state: GlobalState, filters?: Filters) => filters, 
    (profiles, filters) => {
        return sortAndInjectProfiles(filterProfiles(profiles, filters), PROFILE_SET_ALL);
    },
);


export function filterProfiles(profiles: IDMappedObjects<UserProfile>, filters?: Filters, memberships?: RelationOneToOne<UserProfile, WorkspaceMembership> | RelationOneToOne<UserProfile, TopicMembership>): IDMappedObjects<UserProfile> {
    if (!filters) {
        return profiles;
    }

    let users = Object.keys(profiles).map((key) => profiles[key]);

    const filterRole = (filters.role && filters.role !== '') ? [filters.role] : [];
    const filterRoles = [...filterRole, ...(filters.roles || []), ...(filters.workspace_roles || []), ...(filters.topic_roles || [])];
    const excludeRoles = filters.exclude_roles || [];
    if (filterRoles.length > 0 || excludeRoles.length > 0) {
        users = users.filter((user) => {
            return user.roles.length > 0 && applyRolesFilters(user, filterRoles, excludeRoles, memberships?.[user.id]);
        });
    }

    if (filters.inactive) {
        users = users.filter((user) => user.delete_at !== 0);
    } else if (filters.active) {
        users = users.filter((user) => user.delete_at === 0);
    }

    return users.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
    }, {} as IDMappedObjects<UserProfile>);
}

/**
 * Returns a selector that returns all profiles in a given topic with given filters applied.
 * 
 * Note that filters, if provided, must be either a constant or memoized to prevent constant
 * recomputation of the selector.
 */
export function makeGetProfilesInTopic(): (state: GlobalState, topicId: Topic['id'], filters?: Filters) => UserProfile[] {
    return createSelector(
        'makeGetProfilesInTopic',
        getUsers, 
        getUserIdsInTopics, 
        getMembersInTopic,
        (state: GlobalState, topicId: string) => topicId, 
        (state, topicId, filters) => filters, 
        (users, userIds, membersInTopic, topicId, filters = {}) => {
            const userIdsInTopic = userIds[topicId];

            if (!userIdsInTopic) {
                return [];
            }

            return sortAndInjectProfiles(filterProfiles(users, filters, membersInTopic), userIdsInTopic);
        },
    );
}

/**
 * Returns a selector that returns all profiles not in a given topic.
 */
export function makeGetProfilesNotInTopic(): (state: GlobalState, topicId: Topic['id'], filters?: Filters) => UserProfile[] {
    return createSelector(
        'makeGetProfilesNotInTopic',
        getUsers,
        getUserIdsNotInTopics,
        (state: GlobalState, topicId: string) => topicId,
        (users, userIds, topicId) => {
            const userIdsNotInTopic = userIds[topicId];

            if (!userIdsNotInTopic) {
                return [];
            }

            return sortAndInjectProfiles(users, userIdsNotInTopic);
        },
    );
}


export function makeSearchProfilesStartingWithTerm(): (state: GlobalState, term: string, skipCurrent?: boolean, filters?: Filters) => UserProfile[] {
    return createSelector(
        'makeSearchProfilesStartingWithTerm', 
        getUsers, 
        getCurrentUserId, 
        (state: GlobalState, term: string) => term,
        (state: GlobalState, term: string, skipCurrent?: boolean) => skipCurrent || false, 
        (state: GlobalState, term: string, skipCurrent?: boolean, filters?: Filters) => filters,
        (users, currentUserId, term, skipCurrent, filters) => {
            const profiles = filterProfilesStartingWithTerm(Object.values(users), term);
            return filterFromProfiles(currentUserId, profiles, skipCurrent, filters);
        },
    );
}


export function makeSearchProfilesMatchingWithTerm(): (state: GlobalState, term: string, skipCurrent?: boolean, filters?: Filters) => UserProfile[] {
    return createSelector(
        'makeSearchProfilesMatchingWithTerm', 
        getUsers, 
        getCurrentUserId, 
        (state: GlobalState, term: string) => term,
        (state: GlobalState, term: string, skipCurrent?: boolean) => skipCurrent || false, 
        (state: GlobalState, term: string, skipCurrent?: boolean, filters?: Filters) => filters,
        (users, currentUserId, term, skipCurrent, filters) => {
            const profiles = filterProfilesMatchingWithTerm(Object.values(users), term);
            return filterFromProfiles(currentUserId, profiles, skipCurrent, filters);
        },
    );
}

export function makeSearchProfilesInTopic() {
    const doGetProfilesInTopic = makeGetProfilesInTopic();
    return (state: GlobalState, topicId: Topic['id'], term: string,  skipCurrent = false,  filters?: Filters): UserProfile[] => {
        const profiles = filterProfilesStartingWithTerm(doGetProfilesInTopic(state, topicId, filters), term);

        if (skipCurrent) {
            removeCurrentUserFromList(profiles, getCurrentUserId(state));
        }

        return profiles;
    };
}

export function searchProfilesInCurrentTopic(state: GlobalState, term: string, skipCurrent = false): UserProfile[] {
    const profiles = filterProfilesStartingWithTerm(getProfilesInCurrentTopic(state), term);

    if (skipCurrent) {
        removeCurrentUserFromList(profiles, getCurrentUserId(state));
    }

    return profiles;
}

// getActiveProfilesInCurrentTopicByMemberOrg

export function searchActiveProfilesInCurrentTopic(state: GlobalState, term: string, skipCurrent = false): UserProfile[] {
    return searchProfilesInCurrentTopic(state, term, skipCurrent).filter((user) => user.delete_at === 0);
}

export function searchProfilesNotInCurrentTopic(state: GlobalState, term: string, skipCurrent = false): UserProfile[] {
    const profiles = filterProfilesStartingWithTerm(getProfilesNotInCurrentTopic(state), term);
    if (skipCurrent) {
        removeCurrentUserFromList(profiles, getCurrentUserId(state));
    }

    return profiles;
}

export function searchProfilesInWorkspace(state: GlobalState, wspaceId: Workspace['id'], term: string, skipCurrent = false, filters?: Filters): UserProfile[] {
    const profiles = filterProfilesStartingWithTerm(getProfilesInWorkspace(state, wspaceId, filters), term);

    if (skipCurrent) {
        removeCurrentUserFromList(profiles, getCurrentUserId(state));
    }

    return profiles;
}

function removeCurrentUserFromList(profiles: UserProfile[], currentUserId: UserProfile['id']) {
    const index = profiles.findIndex((p) => p.id === currentUserId);
    if (index >= 0) {
        profiles.splice(index, 1);
    }
}

function filterFromProfiles(currentUserId: UserProfile['id'], profiles: UserProfile[], skipCurrent = false, filters?: Filters): UserProfile[] {
    const filteredProfilesMap = filterProfiles(profileListToMap(profiles), filters);
    const filteredProfiles = Object.keys(filteredProfilesMap).map((key) => filteredProfilesMap[key]);

    if (skipCurrent) {
        removeCurrentUserFromList(filteredProfiles, currentUserId);
    }

    return filteredProfiles;
}

export function getUserLastActivities(state: GlobalState): RelationOneToOne<UserProfile, number> {
    return state.entities.users.lastActivity;
}

export function getLastActivityForUserId(state: GlobalState, userId: UserProfile['id']): number {
    return getUserLastActivities(state)[userId];
}

export const displayLastActiveLabel: (state: GlobalState, userId: string) => boolean = createSelector(
    'displayLastActiveLevel',
    (state: GlobalState, userId: string) => getStatusForUserId(state, userId),
    (state: GlobalState, userId: string) => getLastActivityForUserId(state, userId), 
    (state: GlobalState, userId: string) => getUser(state, userId), 
    getConfig, 
    (userStatus, timestamp, user, config) => {
        const currentTime = new Date();
        const oneMin = 60 * 1000;

        if (
            (!userStatus || userStatus === General.ONLINE) || 
            (timestamp && (currentTime.valueOf() - new Date(timestamp).valueOf()) <= oneMin) || 
            user?.props?.show_last_active === 'false' || 
            user?.is_bot || 
            timestamp === 0  
            // config.EnableLastActiveTime !== 'true'
        ) {
            return false;
        }

        return true;
    },

);


export const getLastActiveTimestampUnits: (state: GlobalState, userId: string) => string[] = createSelector(
    'getLastActiveTimestampUnits', 
    (state: GlobalState, userId: string) => getLastActivityForUserId(state, userId), 
    (timestamp) => {
        const timestampUnits = [
            'now', 
            'minute', 
            'hour',
        ];

        const currentTime = new Date();
        const twoDaysAgo = 48 * 60 * 60 * 1000;
        if ((currentTime.valueOf() - new Date(timestamp).valueOf()) < twoDaysAgo) {
            timestampUnits.push('day');
        } 

        return timestampUnits;
    },
); 

export function getTotalUsersStats(state: GlobalState): any {
    return state.entities.users.stats;
}

export function getFilteredUsersStats(state: GlobalState): any {
    return state.entities.users.filteredStats;
}



