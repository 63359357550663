import { combineReducers } from 'redux';

import { PostTypes } from 'sigmaflow-redux/action_types';

import { GenericAction } from 'sigmaflow-redux/types/actions';
import { PostsRequestsStatuses, RequestStatusType } from 'sigmaflow-redux/types/requests';

import {handleRequest, initialRequestState} from './helpers';

function createPost(state: RequestStatusType = initialRequestState(), action: GenericAction): RequestStatusType {
    if (action.type === PostTypes.CREATE_POST_RESET_REQUEST) {
        return initialRequestState();
    }

    return handleRequest(
        PostTypes.CREATE_POST_REQUEST,
        PostTypes.CREATE_POST_SUCCESS, 
        PostTypes.CREATE_POST_FAILURE, 
        state, 
        action,
    );
}

function editPost(state: RequestStatusType = initialRequestState(), action: GenericAction): RequestStatusType {
    return handleRequest(
        PostTypes.EDIT_POST_REQUEST, 
        PostTypes.EDIT_POST_SUCCESS, 
        PostTypes.EDIT_POST_FAILURE, 
        state,
        action, 
    );
}


function getPostThread(state: RequestStatusType = initialRequestState(), action: GenericAction): RequestStatusType {
    return handleRequest(
        PostTypes.GET_POST_THREAD_REQUEST, 
        PostTypes.GET_POST_THREAD_SUCCESS, 
        PostTypes.GET_POST_THREAD_FAILURE, 
        state, 
        action,
    );
}

export default (combineReducers({
    createPost, 
    editPost, 
    getPostThread,
}) as (b: PostsRequestsStatuses, a: GenericAction) => PostsRequestsStatuses);