/* eslint-disable max-lines */

import keyMirror from 'key-mirror';

// import Permissions from 'sigmaflow-redux/constants/permissions';

import { CustomStatusDuration} from 'sigmaflow-redux/types/users';

import * as PostListUtils from 'sigmaflow-redux/utils/post_list';

import audioIcon from 'images/icons/audio.svg';
import codeIcon from 'images/icons/code.svg';
import excelIcon from 'images/icons/excel.svg';
import genericIcon from 'images/icons/generic.svg';
import patchIcon from 'images/icons/patch.svg';
import pdfIcon from 'images/icons/pdf.svg';
import pptIcon from 'images/icons/ppt.svg';
import videoIcon from 'images/icons/video.svg';
import wordIcon from 'images/icons/word.svg';
// import githubIcon from 'images/themes/code_themes/github.png';

//import logoImage from 'images/logo_image.png';

import {t} from  'utils/i18n';


export const SettingsTypes = {
    TYPE_TEXT: 'text',
    TYPE_LONG_TEXT: 'longtext',
    TYPE_NUMBER: 'number',
    TYPE_COLOR: 'color',
    TYPE_BOOL: 'bool',
    TYPE_PERMISSION: 'permission',
    TYPE_RADIO: 'radio',
    TYPE_BANNER: 'banner',
    TYPE_DROPDOWN: 'dropdown',
    TYPE_GENERATED: 'generated', 
    TYPE_USERNAME: 'username',
    TYPE_BUTTON: 'button',
    TYPE_LANGUAGE: 'language',
    TYPE_JOBSTABLE: 'jobstable',
    TYPE_FILE_UPLOAD: 'fileupload',
    TYPE_CUSTOM: 'custom',
};

export const InviteTypes = {
    INVITE_MEMBER: 'member',
    INVITE_GUEST: 'guest',
};

export const PreviousViewedTypes = {
    TOPICS: 'topics',
    THREADS: 'threads',
    INSIGHTS: 'insights',
    B2BSPACE: 'b2bspace',
};

export const Preferences = {
    CATEGORY_TOPIC_OPEN_TIME: 'topic_open_time',
    CATEGORY_DIRECT_TOPIC_SHOW: 'direct_topic_show',
    CATEGORY_GROUP_TOPIC_SHOW: 'group_topic_show',
    CATEGORY_DISPLAY_SETTINGS: 'display_settings',
    CATEGORY_SIDEBAR_SETTINGS: 'sidebar_settings',
    CATEGORY_ADVANCED_SETTINGS: 'advanced_settings',

    TOPIC_DISPLAY_MODE: 'topic_display_mode',
    TOPIC_DISPLAY_MODE_CENTERED: 'centered',
    TOPIC_DISPLAY_MODE_FULL_SCREEN: 'full',
    TOPIC_DISPLAY_MODE_DEFAULT: 'full',

    MESSAGE_DISPLAY: 'message_display',
    MESSAGE_DISPLAY_CLEAN: 'clean',
    MESSAGE_DISPLAY_COMPACT: 'compact',
    MESSAGE_DISPLAY_DEFAULT: 'clean',
    COLORIZE_USERNAMES: 'colorize_usernames',
    COLORIZE_USERNAMES_DEFAULT: 'true',

    CLEAK_TO_REPLY: 'click_to_reply',
    CLEAK_TO_REPLY_DEFAULT: 'true',

    LINK_PREVIEW_DISPLAY: 'link_preview_display',
    LINK_PREVIEW_DISPLAY_DEFAULT: 'true',

    COLLAPSE_DISPLAY: 'collapse_previews',
    COLLAPSE_DISPLAY_DEFAULT: 'false',
    AVAILABILITY_STATUS_ON_POSTS: 'availability_status_on_posts',
    AVAILABILITY_STATUS_ON_POSTS_DEFAULT: 'true',
    UNREAD_SCROLL_POSITION: 'unread_scroll_position',
    UNREAD_SCROLL_POSITION_START_FROM_LEFT: 'start_from_left_off',
    UNREAD_SCROLL_POSITION_START_FROM_NEWEST: 'start_from_newest',
    USE_MILITARY_TIME: 'use_military_time',
    USE_MILITARY_TIME_DEFAULT: 'false',

    CATEGORY_THEME: 'theme',
    CATEGORY_FLAGGED_POST: 'flagged_post',
    CATEGORY_NOTIFICATIONS: 'notifications',
    EMAIL_INTERVAL: 'email_interval',
    INTERVAL_IMMEDIATE: 30, // "immediate" is a 30 second interval
    INTERVAL_FIFTEEN_MINUTES: 15 * 60,

    CATEGORY_EMOJI: 'emoji',
    EMOJI_SKINTONE: 'emoji_skintone',
    ONE_CLICK_REACTIONS_ENABLED: 'one_click_reactions_enabled',
    ONE_CLICK_REACTIONS_ENABLED_DEFAULT: 'true',

    CLOUD_TRIAL_END_BANNER: 'cloud_trial_end_banner',
    CLOUD_TRIAL_BANNER: 'cloud_trial_banner',
    CLOUD_YEARLY_NUDGE_BANNER: 'cloud_yearly_nudge_banner',
    CLOUD_USER_EPHEMERAL_INFO: 'cloud_user_ephemeral_info',
    CATEGORY_CLOUD_LIMITS: 'cloud_limits', 
    TREE_DAYS_LEFT_TRIAL_MODAL: 'three_days_left_trial_modal',


    // For one off things that have a special, attention grabbing UI until you interact with them. 
    TOUCHED: 'touched',

    // Category for actions/interactions that will happen just once.
    UNIQUE: 'unique',

    RECENT_EMOJIS: 'recent_emojis',
    ONBOARDING: 'onboarding',
    ADVANCED_TEXT_EDITOR: 'advanced_text_editor',

    FORWARD_POST_VIEWED: 'forward_post_viewed',

};

// For one off things that have a special, attention grabbing UI until you interact with them. 
export const Touched = {
    INVITE_MEMBERS: 'invite_members',
}



export const ActionTypes = keyMirror({
    SET_PRODUCT_SWITCHER_OPEN: null,
    RECEIVED_FOCUSED_POST: null,
    SELECT_POST: null,
    HIGHLIGHT_REPLY: null,
    CLEAR_HIGHLIGHT_REPLY: null,
    SELECT_POST_CARD: null,
    LOADING_POSTS: null,
    INCREASE_POST_VISIBILITY: null,
    INCREASE_THREAD_VISIBILITY: null,

    UPDATE_RHS_STATE: null,
    UPDATE_RHS_SEARCH_TERMS: null,
    UPDATE_RHS_SEARCH_TYPE: null, 
    UPDATE_RHS_SEARCH_RESULTS_TERMS: null,

    RHS_GO_BACK: null,

    SET_RHS_EXPANDED: null,
    TOGGLE_RHS_EXPANDED: null, 

    UPDATE_MOBILE_VIEW: null,

    SET_NAVIGATION_BLOCKED: null,
    DEFER_NAVIGATION: null,
    CANCEL_NAVIGATION: null,
    CONFIRM_NAVIGATION: null,

    TOGGLE_IMPORT_THEME_MODAL: null,
    TOGGLE_DELETE_POST_MODAL: null,
    TOGGLE_EDITING_POST: null,

    BROWSER_CHANGE_FOCUS: null,
    BROWSER_WINDOW_RESIZED: null,

    RECEIVED_ADMIN_CONSOLE_REDUCER: null, 
    REMOVED_ADMIN_CONSOLE_REDUCER: null,
    RECEIVED_ADMIN_CONSOLE_CUSTOM_COMPONENT: null, 
    RECEIVED_PLUGIN_STATS_HANDLER: null,

    MODAL_OPEN: null,
    MODAL_CLOSE: null,

    SELECT_TOPIC_WITH_MEMBER: null, 
    SET_LAST_UNREAD_TOPIC: null, 
    UPDATE_TOPIC_LAST_VIEWED_AT: null,

    INCREMENT_EMOJI_PICKER_PAGE: null, 
    SET_RECENT_SKIN: null,


    STATUS_DROPDOWN_TOGGLE: null,
    ADD_TOPIC_DROPDOWN_TOGGLE: null,

    TOGGLE_LHS: null,
    OPEN_LHS: null,
    CLOSE_LHS: null,
    SELECT_STATIC_PAGE: null,

    SET_SHOW_PREVIEW_ON_CREATE_COMMENT: null, 
    SET_SHOW_PREVIEW_ON_CREATE_POST: null, 
    SET_SHOW_PREVIEW_ON_EDIT_TOPIC_HEADER_MODAL: null, 

    TOGGLE_RHS_MENU: null, 
    OPEN_RHS_MENU: null, 
    CLOSE_RHS_MENU: null,

    DISMISS_NOTICE: null,
    SHOW_NOTICE: null,

    SELECT_ATTACHMENT_MENU_ACTION: null,


    RECEIVED_TRANSLATIONS: null,

    INCREMENT_WS_ERROR_COUNT: null, 
    RESET_WS_ERROR_COUNT: null, 
    RECEIVED_POSTS_FOR_TOPIC_AT_TIME: null, 
    TOPIC_POSTS_STATUS: null, 
    TOPIC_SYNC_STATUS: null,
    ALL_TOPIC_SYNC_STATUS: null,

    UPDATE_ACTIVE_SECTION: null,

    POST_UNREAD_SUCCESS: null,

    SET_UNREAD_FILTER_ENABLED: null, 
    UPDATE_TOAST_STATUS: null,
    UPDATE_THREAD_TOAST_STATUS: null,


    SIDEBAR_DRAGGING_SET_STATE: null,
    SIDEBAR_DRAGGING_STOP: null,
    ADD_NEW_CATEGORY_ID: null,
    MULTISELECT_TOPIC: null, 
    MULTISELECT_TOPIC_ADD: null, 
    MULTISELECT_TOPIC_TO: null, 
    MULTISELECT_TOPIC_CLEAR: null, 


    TRACK_ANNOUNCEMENT_BAR: null,
    DISMISS_ANNOUNCEMENT_BAR: null,


    PREFETCH_POSTS_FOR_TOPIC: null,

    SET_SHOW_NEXT_STEPS_VIEW: null,
    SET_FILES_FILTER_BY_EXT: null,

    SUPPRESS_RHS: null,
    UNSUPPRESS_RHS: null,

    FIRST_TOPIC_NAME: null,

    RECEIVED_BOARDS_INSIGHTS: null, 
    SET_EDIT_TOPIC_MEMBERS: null,
    RESET_MEMBER_ORG_ID: null,

});

export const PostRequestTypes = keyMirror({
    BEFORE_ID: null,
    AFTER_ID: null,
});


export const ModalIdentifiers = {
    ABOUT: 'about',
    WORKSPACE_SETTINGS: 'workspace_settings',
    TOPIC_INFO: 'topic_info',
    CREATE_TOPIC_THREAD: 'create_topic_thread',
    DELETE_TOPIC: 'delete_topic',
    UNARCHIVE_TOPIC: 'unarchive_topic',
    TOPIC_NOTIFICATIONS: 'topic_notifications',
    TOPIC_INVITE: 'topic_invite',
    TOPIC_MEMBERS: 'topic_members',
    WORKSPACE_MEMBERS: 'workspace_members',
    ADD_USER_TO_TOPIC: 'add_user_to_topic',
    ADD_USER_TO_ROLE: 'add_user_to_role',
    ADD_USER_TO_WORKSPACE: 'add_user_to_workspace',
    INVITE_ORG_TO_XWORKSPACE: 'invite_org_to_workspace',
    CREATE_DM_TOPIC: 'create_dm_topic',
    EDIT_TOPIC_HEADER: 'edit_topic_header',
    EDIT_TOPIC_PURPOSE: 'edit_topic_purpose',
    DELETE_POST: 'delete_post',
    RENAME_TOPIC: 'rename_topic',
    RESET_STATUS: 'reset_status',
    LEAVE_WORKSPACE: 'leave_workspace',
    USER_SETTINGS: 'user_settings',
    QUICK_SWITCH: 'quick_switch',
    REMOVED_FROM_TOPIC: 'removed_from_topic',
    EMAIL_INVITE: 'email_invite',
    INTERACTIVE_DIALOG: 'interactive_dialog',
    APPS_MODAL: 'apps_modal',
    ADD_WORKSPACES_TO_SCHEME: 'add_workspaces_to_scheme',
    INVITATION: 'invitation',
    ADD_GROUPS_TO_WORKSPACE: 'add_groups_to_workspace',
    ADD_GROUPS_TO_TOPIC: 'add_groups_to_topic',
    MOBILE_SUBMENU: 'mobile_submenu',
    PLUGIN_MARKETPLACE: 'plugin_marketplace',
    EDIT_CATEGORY: 'edit_category',
    DELETE_CATEGORY: 'delete_category',
    SIDEBAR_WHATS_NEW_MODAL: 'sidebar_whats_new_modal',
    CONFIRM_NOTIFY_ADMIN: 'confirm_notify_admin',
    MORE_TOPICS: 'more_topics',
    NEW_TOPIC_MODAL: 'new_topic_modal',
    SUCCESS_MODAL: 'success_modal',
    ERROR_MODAL: 'error_modal',
    DND_CUSTOM_TIME_PICKER: 'dnd_custom_time_picker',
    CUSTOM_STATUS: 'custom_status',
    NO_INTERNET_CONNECTION: 'internet_connection',
    JOIN_TOPIC_PROMPT: 'join_topic_prompt',
    NOTIFY_CONFIRM_MODAL: 'notify_confirm_modal',
    CONFIRM: 'confirm',

    POST_DELETED_MODAL: 'post_deleted_modal',
    FILE_PREVIEW_MODAL: 'file_preview_modal',
    IMPORT_THEME_MODAL: 'import_theme_modal',
    LEAVE_PRIVATE_TOPIC_MODAL: 'leave_private_topic_modal',
    GET_PUBLIC_LINK_MODAL: 'get_public_link_modal',
    KEYBOARD_SHORTCUTS_MODAL: 'keyboard_shortcuts_modal',
    USERS_TO_BE_REMOVED: 'users_to_be_removed',
    UPLOAD_LICENSE: 'upload_license',

    DELETE_DRAFT: 'delete_draft_modal',
    SEND_DRAFT: 'send_draft_modal',

    MARK_ALL_THREADS_AS_READ: 'mark_all_threads_as_read_modal',
    PRICING_MODAL: 'pricing_modal',
    CLOUD_PURCHASE: 'cloud_purchase',
    BILLING_HISTORY: 'billing_history',
    CLOUD_INVOICE_PREVIEW:  'cloud_invoice_preview',
};

export const UserStatuses = {
    OUT_OF_OFFICE: 'ooo',
    OFFLINE: 'offline',
    AWAY: 'away',
    ONLINE: 'online',
    DND: 'dnd',
};

export const EventTypes = Object.assign(
    {
        KEY_DOWN: 'keydown',
        KEY_UP: 'keyup',
        CLICK: 'click',
        FOCUS: 'focus',
        BLUR: 'blur',
        SHORTCUT: 'shortcut',
        MOUSE_DOWN: 'mousedown',
        MOUSE_UP: 'mouseup',
    },
    keyMirror({
        POST_LIST_SCROLL_TO_BOTTOM: null,
    }),
);

export const A11yClassNames = {
    REGION: 'a11y__region',
    SECTION: 'a11y__section',
    ACTIVE: 'a11y--active',
    FOCUSED: 'a11y--focused',
    MODAL: 'a11y__modal',
    POPUP: 'a11y__popup',
};

export const A11yAttributeNames = {
    SORT_ORDER: 'data-a11y-sort-order',
    ORDER_REVERSE: 'data-a11y-order-reversed',
    FOCUS_CHILD: 'data-a11y-focus-child',
    LOOP_NAVIGATION: 'data-a11y-loop-navigation',
    DISABLE_NAVIGATION: 'data-a11y-disable-nav',
};

export const A11yCustomEventTypes = {
    ACTIVATE: 'a11yactivate',
    DEACTIVATE: 'a11ydeactivate',
    UPDATE: 'a11yupdate',
    FOCUS: 'a11yfocus',
};


export type A11yFocusEventDetail = {
    target: HTMLElement | null | undefined;
    keyboardOnly: boolean;
}

export function isA11yFocusEventDetail(o: unknown): o is A11yFocusEventDetail {
    return Boolean(o && typeof o === 'object' && 'keyboardOnly' in o);
}


export const AppEvents = {
    FOCUS_EDIT_TEXTBOX: 'focus_edit_textbox',
};

export const SocketEvents = {
    POSTED: 'posted',
    POSTED_ON_THREAD: 'posted_on_thread',
    THREAD_READ_CHANGED: 'thread_read_changed',
    POST_EDITED: 'post_edited',
    POST_DELETED: 'post_deleted',
    THREAD_CREATED: 'thread_created',

    TOPIC_CREATED: 'topic_created',
    TOPIC_DELETED: 'topic_deleted',
    TOPIC_UNARCHIVED: 'topic_unarchived',
    TOPIC_UPDATED: 'topic_updated',
    TOPIC_VIEWED: 'topic_viewed',
    NEW_USER: 'new_user',
    ADDED_TO_WORKSPACE: 'added_to_workspace',
    JOIN_WORKSPACE: 'join_workspace',
    LEAVE_WORKSPACE: 'leave_workspace',
    UPDATE_WORKSPACE: 'update_workspace',
    DELETE_WORKSPACE: 'delete_workspace',
    USER_ADDED: 'user_added',
    USER_REMOVED: 'user_removed',
    USER_ROLE_UPDATED: 'user_role_updated',
    MEMBERROLE_UPDATED: 'memberrole_updated',
    ROLE_ADDED: 'role_added',
    ROLE_REMOVED: 'role_removed',
    ROLE_UPDATED: 'role_updated',
    TYPING: 'typing',
};

export const TutorialSteps = {
    ADD_FIRST_TOPIC: -1,
    POST_POPOVER: 0,
    TOPIC_POPOVER: 1,
    ADD_TOPIC_POPOVER: 2,
    MENU_POPOVER: 3,
    SETTINGS: 5,
    FINISHED: 999,
};

export const RecommendedNextSteps = {
    COMPLETE_PROFILE: 'complete_profile',
    WORKSPACE_SETUP: 'workspace_setup',
    INVITE_MEMBERS: 'invite_members',
    PREFERENCES_SETUP: 'preferences_setup',
    NOTIFICATION_SETUP: 'notification_setup',
    DOWNLOAD_APPS: 'download_apps',
    CREATE_FIRST_TOPIC: 'create_first_topic',
    HIDE: 'hide',
    SKIP: 'skip',
};

export enum ItemStatus {
    NONE = 'none',
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
};

export const Threads = {
    CHANGED_SELECTED_THREAD: 'changed_selected_thread',
    CHANGED_LAST_VIEWED_AT: 'changed_last_viewed_at',
    MANUALLY_UNREAD_THREAD: 'manually_unread_thread',
};

export const AdvancedTextEditor = {
    COMMENT: 'comment', 
    POST: 'post', 
    EDIT: 'edit',
};

export const PostTypes = {
    JOIN_LEAVE: 'system_join_leave',
    JOIN_TOPIC: 'system_join_topic',
    LEAVE_TOPIC: 'system_leave_topic',
    ADD_TO_TOPIC: 'system_add_to_topic',
    REMOVE_FROM_TOPIC: 'system_remove_from_topic',
    ADD_REMOVE: 'system_add_remove',
    JOIN_WORKSPACE: 'system_join_workspace',
    LEAVE_WORKSPACE: 'system_leave_workspace',
    HEADER_CHANGE: 'system_header_change',
    DISPLAYNAME_CHANGE: 'system_displayname_change',
    REMOVE_LINK_PREVIEW: 'remove_link_preview',
    ME: 'me',
    EPHEMERAL: 'ephemeral',
    EPHEMERAL_ADD_TO_TOPIC: 'system_ephemeral_add_to_topic' as const,
    SYSTEM_GENERIC: 'system_generic' as const, 
    FAKE_PARENT_DELETED: 'system_fake_parent_deleted' as const, 
};

export const SearchUserWorkspaceFilter = {
    ALL_USERS: '',
    NO_WORKSPACE: 'no_workspace',
};

export const SearchUserDomainFilter = {
    ALL_USERS: '',
    NO_DOMAIN: 'no_domain',
};

// UserSearchOptions are the possible option keys for a user search request.
export const UserSearchOptions = {
    ALLOW_INACTIVE: 'allow_inactive',
    WORKSPACE_ID: 'workspace_id',
    NOT_IN_WORKSPACE_ID: 'not_in_workspace_id',
    WITHOUT_WORKSPACE: 'without_workspace',
    IN_TOPIC_ID: 'in_topic_id',
    NOT_IN_TOPIC_ID: 'not_in_topic_id',
    GROUP_CONSTRAINED: 'group_constrained',
    ROLE: 'role',
    LIMIT: 'limit',
};

// possible option keys for get users page request.
export const UserListOptions = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    IN_WORKSPACE: 'in_workspace',
    NOT_IN_WORKSPACE: 'not_in_workspace',
    WITHOUT_WORKSPACE: 'without_workspace',
    IN_TOPIC: 'in_topic',
    NOT_IN_TOPIC: 'not_in_topic',
    GROUP_CONSTRAINED: 'group_constrained',
    SORT: 'sort',
    ROLE: 'role',
};

// UserFilters are the values for UI get/search user filters.
export const UserFilters = {
    INACTIVE: 'inactive',
    ACTIVE: 'active',
    SYSTEM_ADMIN: 'system_admin',
    SYSTEM_GUEST: 'system_guest',
};

export const SearchTypes = keyMirror({
    SET_MODAL_SEARCH: null,
    SET_MODAL_FILTERS: null,
    SET_POPOVER_SEARCH: null,
    SET_SYSTEM_USERS_SEARCH: null,
    SET_USER_GRID_SEARCH: null,
    SET_USER_GRID_FILTERS: null,
    SET_ORG_GRID_SEARCH: null,
    SET_ORG_GRID_FILTERS: null, 
    SET_WORKSPACE_GRID_SEARCH: null,
    SET_WORKSPACE_GRID_FILTERS: null,
    SET_TOPIC_GRID_SEARCH: null,
    SET_TOPIC_GRID_FILTERS: null,
    SET_WORKSPACE_LIST_SEARCH: null,
    SET_TOPIC_LIST_SEARCH: null,
    SET_TOPIC_LIST_FILTERS: null,
    SET_TOPIC_MEMBERS_RHS_SEARCH: null,
    SET_TOPIC_ORG_MEMBERS_RHS_SEARCH: null,
    SET_TOPIC_THREAD_SEARCH: null,
});

export const StorageTypes = keyMirror({
    SET_ITEM: null,
    REMOVE_ITEM: null,
    SET_GLOBAL_ITEM: null,
    REMOVE_GLOBAL_ITEM: null,
    ACTION_ON_GLOBAL_ITEMS_WITH_PREFIX: null,
    STORAGE_REHYDRATE: null,
    SET_WORKSPACE_GRID_SEARCH: null, 
    SET_WORKSPACE_GRID_FILTERS: null,
    SET_TOPIC_GRID_SEARCH: null, 
    SET_TOPIC_GRID_FILTERS: null,
    SET_ORG_GRID_SEARCH: null, 
    SET_ORG_GRID_FILTERS: null,

});



export const StoragePrefixes = {
    EMBED_VISIBLE: 'isVisible_',
    COMMENT_DRAFT: 'comment_draft_',
    THREAD_DRAFT: 'thread_draft_',
    DRAFT: 'draft_',
    EDIT_DRAFT: 'edit_draft_',
    LOGOUT: '__logout__',
    LOGIN: '__login__',
    ANNOUNCEMENT: '__announcement__',
    LANDING_PAGE_SEEN: '__landingPageSeen__',
    LANDING_PREFERENCE: '__landing-preference__',
    TOPIC_CATEGORY_COLLAPSED: 'topicCategoryCollapsed_',
    INLINE_IMAGE_VISIBLE: 'isInlineImageVisible_',
};

export const LandingPreferenceTypes = {
    SIGMAFLOWAPP: 'sigmaflowapp',
    BROWSER: 'browser',
};

export const ErrorPageTypes = {
    LOCAL_STORAGE: 'local_storage',
    OAUTH_ACCESS_DENIED: 'oauth_access_denied',
    OAUTH_MISSING_CODE: 'oauth_missing_code',
    OAUTH_INVALID_PARAM: 'oauth_invalid_param',
    OAUTH_INVALID_REDIRECT_URL: 'oauth_invalid_redirect_url',
    PAGE_NOT_FOUND: 'page_not_found',
    PERMALINK_NOT_FOUND: 'permalink_not_found',
    WORKSPACE_NOT_FOUND: 'workspace_not_found',
    TOPIC_NOT_FOUND: 'topic_not_found',
};

export const AnnouncementBarTypes = {
    ANNOUNCEMENT: 'announcement',
    CRITICAL: 'critical',
    DEVELOPER: 'developer',
    SUCCESS: 'success',
    ADVISOR: 'advisor',
    ADVISOR_ACK: 'advisor-ack',
    GENERAL: 'general',
};

export const AnnouncementBarMessages = {
    EMAIL_VERIFICATION_REQUIRED: t('announcement_bar.error.email_verification_required'),
    EMAIL_VERIFIED: t('announcement_bar.notification.email_verified'),
    LICENSE_EXPIRED: t('announcement_bar.error.license_expired'),
    LICENSE_EXPIRING: t('announcement_bar.error.license_expiring'),
    PREVIEW_MODE: t('announcement_bar.error.preview_mode'),
    WEBSOCKET_PORT_ERROR: t('topic_loader.socketError'),
};


export const VerifyEmailErrors = {
    FAILED_EMAIL_VERIFICATION: 'failed_email_verification',
    FAILED_USER_STATE_GET: 'failed_get_user_state',
}

export const FileTypes = {
    TEXT: 'text',
    IMAGE: 'image',
    AUDIO: 'audio',
    VIDEO: 'video',
    SPREADSHEET: 'spreadsheet',
    CODE: 'code',
    WORD: 'word',
    PRESENTATION: 'presentation',
    PDF: 'pdf',
    PATCH: 'patch',
    SVG: 'svg', 
    OTHER: 'other',
}

export const NotificationLevels = {
    DEFAULT: 'default',
    ALL: 'all',
    MENTION: 'mention', 
    NONE: 'none', 
} as const;

export const RHSStates = {
    MENTION: 'mention', 
    SEARCH: 'search', 
    FLAG: 'flag',
    PIN: 'pin',
    PLUGIN: 'plugin', 
    TOPIC_FILES: 'topic-files',
    TOPIC_INFO: 'topic-info',
    TOPIC_MEMBERS: 'topic-members',
    TOPIC_ORG_MEMBERS: 'topic-org-members',
}


export const SidebarTopicGroups = {
    UNREADS: 'unreads', 
    FAVORITE: 'favorite',
};


export const DraggingStates = {
    CAPTURE: 'capture',
    BEFORE: 'before',
    DURING: 'during',
};

export const DraggingStateTypes = {
    CATEGORY: 'category', 
    TOPIC: 'topic',
    DM: 'DM',
    MIXED_TOPICS: 'mixed_topics',
}


export const Locations = {
    CENTER: 'CENTER' as const,
    RHS_ROOT: 'RHS_ROOT' as const,
    RHS_COMMENT: 'RHS_COMMENT' as const,
    SEARCH: 'SEARCH' as const,
    NO_WHERE: 'NO_WHERE' as const,
    MODAL: 'MODAL' as const,
};

export const PostListRowListIds = {
    DATE_LINE: PostListUtils.DATE_LINE, 
    START_OF_NEW_MESSAGES: PostListUtils.START_OF_NEW_MESSAGES, 
    TOPIC_INTRO_MESSAGE: 'TOPIC_INTRO_MESSAGE',
    OLDER_MESSAGES_LOADER: 'OLDER_MESSAGES_LOADER',
    NEWER_MESSAGES_LOADER: 'NEWER_MESSAGES_LOADER',
    LOAD_OLDER_MESSAGES_TRIGGER: 'LOAD_OLDER_MESSAGES_TRIGGER',
    LOAD_NEWER_MESSAGES_TRIGGER: 'LOAD_NEWER_MESSAGES_TRIGGER',
};

export const exportFormats = {
    EXPORT_FORMAT_CSV: 'csv',
    EXPORT_FORMAT_ACTIANCE: 'actiance',
    EXPORT_FORMAT_GLOBALRELAY: 'globalrelay',
}

export const ZoomSettings = {
    DEFAULT_SCALE: 1.75, 
    SCALE_DELTA: 0.25, 
    MIN_SCALE: 0.25, 
    MAX_SCALE: 3.0,
};



export const Constants = {
    SettingsTypes, 
   // JobTypes,
    Preferences,
    SocketEvents, 
    ActionTypes,
    UserSearchOptions,
    TutorialSteps,
    PostTypes, 
    ErrorPageTypes,
    AnnouncementBarTypes,
    FileTypes,
    Locations,
    PostListRowListIds,
    MAX_POST_VISIBILITY: 1000000,
    IGNORE_POST_TYPES: [PostTypes.JOIN_LEAVE, PostTypes.JOIN_WORKSPACE, PostTypes.LEAVE_WORKSPACE, PostTypes.JOIN_TOPIC, PostTypes.LEAVE_TOPIC, PostTypes.REMOVE_FROM_TOPIC, PostTypes.ADD_REMOVE],
    
    PayloadSources: keyMirror({
        SERVER_ACTION: null, 
        VIEW_ACTION: null,
    }),

    USER_LIMIT: 10,


    ScrollTypes: {
        FREE: 1,
        BOTTOM: 2,
        SIDEBAR_OPEN: 3,
        NEW_MESSAGE: 4,
        POST: 5,
    },

    MAXIMUM_LOGIN_ATTEMPTS_DEFAULT: 10,

    MAX_ADD_MEMBERS_BATCH: 256, 

    SPECIAL_MENTIONS: ['all', 'topic', 'here'],
    SPECIAL_MENTIONS_REGEX: /(?:\B|\b_+)@(topic|all|here)(?!(\.|-|_)*[^\W_])/gi,
    SUM_OF_MEMBERS_MENTIONS_REGEX: /\d+ members/i,
    NOTIFY_ALL_MEMBERS: 5,
    ALL_MENTION_REGEX: /(?:\b|\b_+)@(all)(?!(\.|-|_)*[^\W_])/gi,
    TOPIC_MENTION_REGEX: /(?:\b|\b_+)@(topic)(?!(\.|-|_)*[^\W_])/gi,
    HERE_MENTION_REGEX: /(?:\b|\b_+)@(here)(?!(\.|-|_)*[^\W_])/gi,
    MENTIONS_REGEX: /(?:\B|\b_+)@[a-z0-9.\-_]+/gi,
    DEFAULT_CHARACTER_LIMIT: 4000, 
    IMAGE_TYPE_GIF: 'gif',
    TEXT_TYPES: ['txt', 'rtf'],
    IMAGE_TYPES: ['jpg', 'gif', 'bmp', 'png', 'jpeg', 'tiff', 'tif', 'psd'],
    AUDIO_TYPES: ['mp3', 'wav', 'wma', 'm4a', 'flac', 'aac', 'ogg', 'm4r'],
    VIDEO_TYPES: ['mp4', 'avi', 'webm', 'mkv', 'wmv', 'mpg', 'mov', 'flv' ],
    PRESENTATION_TYPES: ['ppt', 'pptx'],
    SPREADSHEET_TYPES: ['xlsx', 'csv'],
    WORD_TYPES: ['doc', 'docx'],
    TOPIC_HEADER_HEIGHT: 62, 
    CODE_TYPES: ['applescript', 'as', 'atom',  'js', 'jsp', 'kt', 'mk', 'mkd'],
    PDF_TYPES: ['pdf'],
    PATCH_TYPES: ['patch'],
    SVG_TYPES: ['svg'],
    ICON_FROM_TYPE: {
        audio: audioIcon,
        video: videoIcon,
        spreadsheet: excelIcon, 
        presentation: pptIcon, 
        code: codeIcon, 
        word: wordIcon, 
        patch: patchIcon, 
        other: genericIcon,    
    },
    ICON_NAME_FROM_TYPE: {
        text: 'text', 
        audio: 'audio',
        video: 'video', 
        spreadsheet: 'excel',
        presentation: 'ppt', 
        pdf: 'pdf',
        code: 'code',
        word: 'word',
        patch: 'patch',
        other: 'generic',
        image: 'image',
    },
    MAX_UPLOAD_FILES: 10, 
    MAX_FILENAME_LENGTH: 35, 
    EXPANDABLE_INLINE_IMAGE_MIN_HEIGHT: 100,
    THUMBNAIL_WIDTH: 128,
    THUMBNAIL_HEIGHT: 100, 
    PREVIEWER_HEIGHT: 170,
    WEB_VIDEO_WIDTH: 640,
    WEB_VIDEO_HEIGHT: 480, 
    MOBILE_VIDEO_WIDTH: 480,
    MOBILE_VIDEO_HEIGHT: 360,
   

    DESKTOP_SCREEN_WIDTH: 1679, 
    TABLET_SCREEN_WIDTH: 1020, 
    MOBILE_SCREEN_WIDTH: 768,

    
    POST_MODAL_PADDING: 170, 
    SCROLL_DELAY: 2000, 
    SCROLL_PAGE_FRACTION: 3, 
    DEFAULT_TOPIC: 'twon-square',
    INVITATIONS_TOPIC: 'invitations',

    GOOGLE_SERVICE: 'google',
    GITLAB_SERVICE: 'gitlab',
    OFFICE365_SERVICE: 'office365',
    OAUTH_SERVICES: ['gitlab', 'google', 'office365', 'openid'],
    OPENID_SERVICE: 'openid',
    OPENID_SCOPES: 'profile openid email',
    EMAIL_SERVICE: 'email',
    LDAP_SERVICE: 'ldap',
    SAML_SERVICE: 'saml',
    USERNAME_SERVICE: 'username',
    SIGNIN_CHANGE: 'signin_change',
    PASSWORD_CHANGE: 'password_change',
    GET_TERMS_ERROR: 'get_terms_error',
    TERMS_REJECTED: 'terms_rejected',
    SIGNIN_VERIFIED: 'verified',
    CREATE_LDAP: 'create_ldap',
    SESSION_EXPIRED: 'expired',
    POST_AREA_HEIGHT: 80, 
    POST_CHUNK_SIZE: 60, 
    PROFILE_CHUNK_SIZE: 100, 
    POST_FOCUS_CONTEXT_RADIUS: 10,
    POST_LOADING: 'loading',
    POST_FAILED: 'failed',
    POST_DELETED: 'deleted',
    POST_UPDATED: 'updated',
    SYSTEM_MESSAGE_PREFIX: 'system_',
    SUGGESTION_LIST_MAXHEIGHT: 292, 
    SUGGESTION_LIST_MAXWIDTH: 496, 
    SUGGESTION_LIST_SPACE_RHS: 420, 
    SUGGESTION_LIST_MODAL_WIDTH: 496,
    MENTION_NAME_PADDING_LEFT: 2.4, 
    AVATAR_WIDTH: 24,
    AUTO_RESPONDER: 'system_auto_responder',
   SYSTEM_MESSAGE_PROFILE_IMAGE: "demo", //logoImage,
    
   RESERVED_ORG_DOMAINS: [
    'topeic.com',
    'topeic.net',
    'topeic.org',
   ],

    RESERVED_USERNAMES: [
        'valet',
        'all',
        'topic',
        'channel',
        'here',
        'sigmabot',
        'topbot',
        'system',
    ],
    MONTHS: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    MAX_DMS: 20, 
    MAX_USERS_IN_GM: 8, 
    MIN_USERS_IN_GM: 3, 
    MAX_TOPIC_POPOVER_COUNT: 100, 
    DM_TOPIC: 'D',
    GM_TOPIC: 'G',
    OPEN_TOPIC: 'public',
    PRIVATE_TOPIC: 'private',
    WORKSPACE_TYPE_INTERNAL: 'internal',
    WORKSPACE_TYPE_CROSSORG: 'xorg',
    WORKSPACE_TYPE_EXTERNAL_B2B: 'ext_b2b',
    WORKSPACE_TYPE_EXTERNAL_B2B_SUPPORT: 'ext_b2b_sp',
    WORKSPACE_TYPE_EXTERNAL_B2C: 'ext_b2c',
    WORKSPACE_TYPE_EXTERNAL_B2C_SUPPORT: 'ext_b2c_sp',
    WORKSPACE_TYPE_CROSSORG_B2B_SUPPORT: 'xorg_b2b_sp', // multi-provider one client
    WORKSPACE_TYPE_CROSSORG_B2C_SUPPORT: 'xorg_b2c_sp',
    // one client with changing multi providers, multi-party, asset mgnt, ext/int feedback,
    // legal, healthcare, insurance, education grade
    WORKSPACE_TYPE_C2B_MULTIORG: 'c2b_xorg',
    ARCHIVED_TOPIC: 'archived',
    THREADS: 'threads',
    MAX_POST_LEN: 4000, 
    EMOJI_SIZE: 16, 
    DEFAULT_EMOJI_PICKER_LEFT_OFFSET: 87, 
    DEFAULT_EMOJI_PICKER_RIGHT_OFFSET: 15,
    EMOJI_PICKER_WIDTH_OFFSET: 295,
    THEME_ELEMENTS: [
        {
            group: 'sidebarElements',
            id: 'sidebarBg',
            uiName: 'Sidebar BG',
        },
        {
            group: 'sidebarElements',
            id: 'sidebarText',
            uiName: 'Sidebar Text',
        },
        {
            group: 'sidebarElements',
            id: 'sidebarHeaderBg',
            uiName: 'Sidebar Header BG',
        },

        {
            group: 'sidebarElements',
            id: 'sidebarOrgBarBg',
            uiName: 'Org Sidebar BG',
        },

        {
            group: 'sidebarElements',
            id: 'sidebarHeaderTextColor',
            uiName: 'Sidebar Header Text',
        },
        {
            group: 'sidebarElements',
            id: 'sidebarUnreadText',
            uiName: 'Sidebar Unread Text',
        },

        {
            group: 'sidebarElements',
            id: 'sidebarTextHoverBg',
            uiName: 'Sidebar Text Hover BG',
        },
        {
            group: 'sidebarElements',
            id: 'sidebarTextActiveBorder',
            uiName: 'Sidebar Text Active Border',
        },

        {
            group: 'sidebarElements',
            id: 'sidebarTextActiveColor',
            uiName: 'Sidebar Text Active Color',
        },
        {
            group: 'sidebarElements',
            id: 'onlineIndicator',
            uiName: 'Online Indicator',
        },
        {
            group: 'sidebarElements',
            id: 'awayIndicator',
            uiName: 'Away Indicator',
        },
        {
            group: 'sidebarElements',
            id: 'dndIndicator',
            uiName: 'Away Indicator',
        },
        {
            group: 'sidebarElements',
            id: 'mentionBg',
            uiName: 'Mention Jewel BG',
        },
        {
            group: 'sidebarElements',
            id: 'mentionColor',
            uiName: 'Mention Jewel Text',
        },
        {
            group: 'centerTopicElements',
            id: 'centerTopicBg',
            uiName: 'Center Topic BG',
        },
        {
            group: 'centerTopicElements',
            id: 'centerTopicColor',
            uiName: 'Center Topic Text',
        },
    ],

    DEFAULT_CODE_THEME: 'github',

    // KeyCodes
    // key[0]: used for KeyboardEvent.key
    // key[1]: used for KeyboardEvent.keyCode
    // key[2]: used for keyboardEvent.code

    // KeyboardEvent.code is used as parimary check to support multiple keyboard layouts
    // support of KeyboardEvent.code is just in chrome and firefox, so using key and keyCode for better
    // browser support.
    KeyCodes: ({
        BACKSPACE: ['Backspace', 8],
        TAB: ['Tab', 9],
        ENTER: ['Enter', 13],
        SHIFT: ['Shift', 16],
        CTRL: ['Control', 17],
        ALT: ['Alt', 18],
        CAPS_LOCK: ['CapsLock', 20],
        ESCAPE: ['Escape', 27],
        SPACE: [' ', 32],
        PAGE_UP: ['PageUp', 33],
        PAGE_DOWN: ['PageDown', 34],
        END: ['End', 35],
        HOME: ['Home', 36],
        LEFT: ['ArrowLeft', 37],
        UP: ['ArrowUp', 38],
        RIGHT: ['ArrowRight', 39],
        DOWN: ['ArrowDown', 40],
        INSERT: ['Insert', 45],
        DELETE: ['Delete', 46],
        ZERO: ['0', 48],
        ONE: ['1', 49],
        TWO: ['2', 50],
        THREE: ['3', 51],
        FOUR: ['4', 52],
        FIVE: ['5', 53],
        SIX: ['6', 54],
        SEVEN: ['7', 55],
        EIGHT: ['8', 56],
        NINE: ['9', 57],
        A: ['a', 65],
        B: ['b', 66],
        C: ['c', 67],
        D: ['d', 68],
        E: ['e', 69],
        F: ['f', 70],
        G: ['g', 71],
        H: ['h', 72],
        I: ['i', 73],
        J: ['j', 74],
        K: ['k', 75],
        L: ['l', 76],
        M: ['m', 77],
        N: ['n', 78],
        O: ['o', 79],
        P: ['p', 80],
        Q: ['q', 81],
        R: ['r', 82],
        S: ['s', 83],
        T: ['t', 84],
        U: ['u', 85],
        V: ['v', 86],
        W: ['w', 87],
        X: ['x', 88],
        Y: ['y', 89],
        Z: ['z', 90],
        CMD: ['Meta', 91],
        MENU: ['ContextMenu', 93],
        NUMPAD_0: ['0', 96],
        NUMPAD_1: ['1', 97],
        NUMPAD_2: ['2', 98],
        NUMPAD_3: ['3', 99],
        NUMPAD_4: ['4', 100],
        NUMPAD_5: ['5', 101],
        NUMPAD_6: ['6', 102],
        NUMPAD_7: ['7', 103],
        NUMPAD_8: ['8', 104],
        NUMPAD_9: ['9', 105],
        MULTIPLY: ['*', 106],

        F1: ['F1', 112],
        F2: ['F2', 113],
        F3: ['F3', 114],
        F4: ['F4', 115],
        F5: ['F5', 116],
        F6: ['F6', 117],
        F7: ['F7', 118],
        F8: ['F8', 119],
        F9: ['F9', 120],
        F10: ['F10', 121],
        F11: ['F11', 122],
        F12: ['F12', 123],
        NUM_LOCK: ['NumLock', 144], 
        SEMICOLON: [';', 186], 
        EQUAL: ['=', 187],
        COMMA: [',', 188], 
        DASH: ['-', 189], 
        PERIOD: ['.', 190], 
        FORWARD_SLASH: ['/', 191],
        OPEN_BRACKET: ['[', 219],
        BACK_SLASH: ['\\', 220],
        CLOSE_BRACKET: [']', 221],
        COMPOSING: ['Composing', 229],
    } as Record<string, [string, number]>),
    CODE_PREVIEW_MAX_FILE_SIZE: 500000, // 500 kb
    
    HighlightedLanguages: {
        '1c': {name: '1C:Enterprise', extensions: ['bsl', 'os'], aliases: ['bsl']},
        actionscript: {name: 'ActionScript', extensions: ['as'], aliases: ['as', 'as3']},
        applescript: {name: 'AppleScript', extensions: ['applescript', 'osascript', 'scpt'], aliases: ['osascript']},
        bash: {name: 'Bash', extensions: ['sh'], aliases: ['sh', 'zsh']},
        clojure: {name: 'Clojure', extensions: ['clj', 'boot', 'cl2', 'cljc', 'cljs', 'cljs.hl', 'cljscm', 'cljx', 'hic'], aliases: ['clj']},
        coffeescript: {name: 'CoffeeScript', extensions: ['coffee', '_coffee', 'cake', 'cjsx', 'cson', 'iced'], aliases: ['coffee', 'coffee-script']},
        cpp: {name: 'C/C++', extensions: ['cpp', 'c', 'cc', 'h', 'c++', 'h++', 'hpp'], aliases: ['c++', 'c']},
        csharp: {name: 'C#', extensions: ['cs', 'csharp'], aliases: ['c#', 'cs', 'csharp']},
        css: {name: 'CSS', extensions: ['css']},
        d: {name: 'D', extensions: ['d', 'di'], aliases: ['dlang']},
        dart: {name: 'Dart', extensions: ['dart']},
        delphi: {name: 'Delphi', extensions: ['delphi', 'dpr', 'dfm', 'pas', 'pascal', 'freepascal', 'lazarus', 'lpr', 'lfm'], aliases: ['pas', 'pascal']},
        diff: {name: 'Diff', extensions: ['diff', 'patch'], aliases: ['patch', 'udiff']},
        django: {name: 'Django', extensions: ['django', 'jinja'], aliases: ['jinja']},
        dockerfile: {name: 'Dockerfile', extensions: ['dockerfile', 'docker'], aliases: ['docker']},
        elixir: {name: 'Elixir', extensions: ['ex', 'exs'], aliases: ['ex', 'exs']},
        erlang: {name: 'Erlang', extensions: ['erl'], aliases: ['erl']},
        fortran: {name: 'Fortran', extensions: ['f90', 'f95'], aliases: ['f90', 'f95']},
        fsharp: {name: 'F#', extensions: ['fsharp', 'fs'], aliases: ['fs']},
        gcode: {name: 'G-Code', extensions: ['gcode', 'nc']},
        go: {name: 'Go', extensions: ['go'], aliases: ['golang']},
        groovy: {name: 'Groovy', extensions: ['groovy']},
        handlebars: {name: 'Handlebars', extensions: ['handlebars', 'hbs', 'html.hbs', 'html.handlebars'], aliases: ['hbs', 'mustache']},
        haskell: {name: 'Haskell', extensions: ['hs'], aliases: ['hs']},
        haxe: {name: 'Haxe', extensions: ['hx'], aliases: ['hx']},
        java: {name: 'Java', extensions: ['java', 'jsp']},
        javascript: {name: 'JavaScript', extensions: ['js', 'jsx'], aliases: ['js']},
        json: {name: 'JSON', extensions: ['json']},
        julia: {name: 'Julia', extensions: ['jl'], aliases: ['jl']},
        kotlin: {name: 'Kotlin', extensions: ['kt', 'ktm', 'kts'], aliases: ['kt']},
        latex: {name: 'LaTeX', extensions: ['tex'], aliases: ['tex']},
        less: {name: 'Less', extensions: ['less']},
        lisp: {name: 'Lisp', extensions: ['lisp']},
        lua: {name: 'Lua', extensions: ['lua']},
        makefile: {name: 'Makefile', extensions: ['mk', 'mak'], aliases: ['make', 'mf', 'gnumake', 'bsdmake', 'mk']},
        markdown: {name: 'Markdown', extensions: ['md', 'mkdown', 'mkd'], aliases: ['md', 'mkd']},
        matlab: {name: 'Matlab', extensions: ['matlab', 'm'], aliases: ['m']},
        objectivec: {name: 'Objective C', extensions: ['mm', 'objc', 'obj-c'], aliases: ['objective_c', 'objc']},
        ocaml: {name: 'OCaml', extensions: ['ml'], aliases: ['ml']},
        perl: {name: 'Perl', extensions: ['perl', 'pl'], aliases: ['pl']},
        pgsql: {name: 'PostgreSQL', extensions: ['pgsql', 'postgres', 'postgresql'], aliases: ['postgres', 'postgresql']},
        php: {name: 'PHP', extensions: ['php', 'php3', 'php4', 'php5', 'php6'], aliases: ['php3', 'php4', 'php5', 'php6']},
        powershell: {name: 'PowerShell', extensions: ['ps', 'ps1'], aliases: ['posh']},
        puppet: {name: 'Puppet', extensions: ['pp'], aliases: ['pp']},
        python: {name: 'Python', extensions: ['py', 'gyp'], aliases: ['py']},
        r: {name: 'R', extensions: ['r'], aliases: ['r', 's']},
        ruby: {name: 'Ruby', extensions: ['ruby', 'rb', 'gemspec', 'podspec', 'thor', 'irb'], aliases: ['rb']},
        rust: {name: 'Rust', extensions: ['rs'], aliases: ['rs']},
        scala: {name: 'Scala', extensions: ['scala']},
        scheme: {name: 'Scheme', extensions: ['scm', 'sld'], aliases: ['scm']},
        scss: {name: 'SCSS', extensions: ['scss']},
        smalltalk: {name: 'Smalltalk', extensions: ['st'], aliases: ['st', 'squeak']},
        sql: {name: 'SQL', extensions: ['sql']},
        stylus: {name: 'Stylus', extensions: ['styl'], aliases: ['styl']},
        swift: {name: 'Swift', extensions: ['swift']},
        text: {name: 'Text', extensions: ['txt', 'log'], aliases: ['txt']},
        typescript: {name: 'TypeScript', extensions: ['ts', 'tsx'], aliases: ['ts', 'tsx']},
        vbnet: {name: 'VB.Net', extensions: ['vbnet', 'vb', 'bas'], aliases: ['vb', 'visualbasic']},
        vbscript: {name: 'VBScript', extensions: ['vbs'], aliases: ['vbs']},
        verilog: {name: 'Verilog', extensions: ['v', 'veo', 'sv', 'svh']},
        vhdl: {name: 'VHDL', extensions: ['vhd', 'vhdl'], aliases: ['vhd']},
        vtt: {name: 'WebVTT', extensions: ['vtt'], aliases: ['vtt', 'webvtt']},
        xml: {name: 'HTML, XML', extensions: ['xml', 'html', 'xhtml', 'rss', 'atom', 'xsl', 'plist']},
        yaml: {name: 'YAML', extensions: ['yaml'], aliases: ['yml']},
    },
    
    PostViewJumpTypes: {
        BOTTOM: 1, 
        POST: 2, 
        SIDEBAR_OPEN: 3,
    },
    Integrations: {
        COMMAND: 'commands',
        PAGE_SIZE: '10000',
        START_PAGE_NUM: 0, 
        INCOMING_WEBHOOK: 'incoming_webhooks',
        OUTGOING_WEBHOOK: 'outgoing_webhooks',
        OAUTH_APP: 'oauth2-apps',
        BOT: 'bots',
        EXECUTE_CURRENT_COMMAND_ITEM_ID: '_execute_current_command',
        OPEN_COMMAND_IN_MODAL_ITEM_ID: '_open_command_in_modal',
        COMMAND_SUGGESTION_ERROR: 'error',
        COMMAND_SUGGESTION_TOPIC: 'topic',
        COMMAND_SUGGESTION_USER: 'user',
    },

    OVERLAY_TIME_DELAY_SMALL: 100,
    OVERLAY_TIME_DELAY: 400,
    OVERLAY_DEFAULT_TRIGGER: ['hover', 'focus'],
    PERMALINK_FADEOUT: 5000,
    MAX_TOPICPURPOSE_LENGTH: 250,
    DEFAULT_TOPICURL_SHORTEN_LENGTH: 52,
    MAX_FIRSTNAME_LENGTH: 64,
    MAX_LASTNAME_LENGTH: 64,
    MAX_EMAIL_LENGTH: 128,
    MIN_WORKSPACENAME_LENGTH: 2,
    MAX_WORKSPACENAME_LENGTH: 64,
    MIN_ORGNAME_LENGTH: 2,
    MAX_ORGNAME_LENGTH: 64,
    MIN_ORGDOMAIN_LENGTH: 2,
    MAX_ORGDOMAIN_LENGTH: 64,
    MIN_TOPICNAME_LENGTH: 1,
    MAX_TOPICNAME_LENGTH: 64,
    MIN_USERNAME_LENGTH: 3,
    MAX_USERNAME_LENGTH: 22,
    MAX_NICKNAME_LENGTH: 22,
    MIN_PASSWORD_LENGTH: 5,
    MAX_PASSWORD_LENGTH: 64,
    MAX_POSITION_LENGTH: 128,
    MAX_SITENAME_LENGTH: 30,
    MAX_CUSTOM_BRAND_TEXT_LENGTH: 500,
    MAX_TERMS_OF_SERVICE_TEXT_LENGTH: 16383,
    DEFAULT_TERMS_OF_SERVICE_RE_ACCEPTANCE_PERIOD: 365,
    EMOJI_PATH: '/static/emoji',
    SAVE_DRAFT_TIMEOUT: 500,

    PERMISSIONS_ALL: 'all',
    PERMISSIONS_TOPIC_ADMIN: 'topic_admin', 
    PERMISSIONS_WORKSPACE_ADMIN: 'workspace_admin', 
    PERMISSIONS_SYSTEM_ADMIN: 'system_admin',
    PERMISSIONS_SYSTEM_READ_ONLY_ADMIN: 'system_read_only_admin',
    PERMISSIONS_SYSTEM_USER_MANAGER: 'system_user_manager',
    PERMISSIONS_SYSTEM_MANAGER: 'system_manager',
    PERMISSIONS_DELETE_POST_ALL: 'all',
    ALLOW_EDIT_POST_ALWAYS: 'always', 
    ALLOW_EDIT_POST_NEVER: 'never', 
    PERMISSIONS_SYSTEM_CUSTOM_GROUP_ADMIN: 'system_custom_group_admin',


    ALLOW_EDIT_POST_ALWAYS: 'always',
    ALLOW_EDIT_POST_NEVER: 'never',
    ALLOW_EDIT_POST_TIME_LIMIT: 'time_limit',
    UNSET_POST_EDIT_TIME_LIMIT: -1, 
    MENTION_TOPICS: 'mention.topics',
    MENTION_MORE_TOPICS: 'mention.moretopics',
    MENTION_UNREAD_TOPICS: 'mention.unread.topics',
    MENTION_UNREAD: 'mention.unread',
    MENTION_MEMBERS: 'mention.members',
    MENTION_MORE_MEMBERS: 'mention.moremembers',
    MENTION_NONMEMBERS: 'mention.nonmembers',
    MENTION_SPECIAL: 'mention.special',
    MENTION_GROUPS: 'search.group',
    DEFAULT_NOTIFICATION_DURATION: 5000, 
    STATUS_INTERVAL: 60000, 
    AUTOCOMPLETE_TIMEOUT: 100,
    AUTOCOMPLETE_SPLIT_CHARACTERS: ['.', '-', '_'],
    ANIMATION_TIMEOUT: 1000, 
    SEARCH_TIMEOUT_MILLISECONDS: 100,
    WSPACEMATE_NAME_DISPLAY: {
        SHOW_USERNAME: 'username',
        SHOW_NICKNAME_FULLNAME: 'nickname_full_name',
        SHOW_FULLNAME: 'full_name',
    },
    SEARCH_POST: 'searchpost',
    TRIPLE_BACK_TICKS: /```/g, 
    MAX_ATTACHMENT_FOOTER_LENGTH: 300, 
    ACCEPT_STATIC_IMAGE: '.jpeg, .jpg, .png, .bmp',
    ACCEPT_EMOJI_IMAGE: '.jpeg, .jpg, .png, .gif',
    THREADS_PAGE_SIZE: 25,
    THREADS_LOADING_INDICATOR_ITEM_ID: 'threads_loading_indicator_item_id',
    THREADS_NO_RESULTS_ITEM_ID: 'threads_no_results_item_id',
    FIRST_ADMIN_ROLE: 'first_admin',

   

}


export const WindowSizes = {
    MOBILE_VIEW: 'mobileView',
    TABLET_VIEW: 'tabletView',
    SMALL_DESKTOP_VIEW: 'smallDesktopView',
    DESKTOP_VIEW: 'desktopView',
};

export const AcceptedProfileImageTypes = ['image/jpeg', 'image/png', 'image/bmp'];

const {
    DONT_CLEAR,
    THIRTY_MINUTES,
    ONE_HOUR,
    FOUR_HOURS,
    TODAY,
    THIS_WEEK,
    DATE_AND_TIME,
    CUSTOM_DATE_TIME,
} = CustomStatusDuration;

export const durationValues = {
    [DONT_CLEAR]: {
        id: t('custom_status.expiry_dropdown.dont_clear'),
        defaultMessage: "Don't clear",
    },
    [THIRTY_MINUTES]: {
        id: t('custom_status.expiry_dropdown.thirty_minutes'),
        defaultMessage: '30 minutes',
    },

    [ONE_HOUR]: {
        id: t('custom_status.expiry_dropdown.one_hour'),
        defaultMessage: '1 hour',
    },

    [FOUR_HOURS]: {
        id: t('custom_status.expiry_dropdown.four_hours'),
        defaultMessage: '4 hours',
    },
    [TODAY]: {
        id: t('custom_status.expiry_dropdown.today'),
        defaultMessage: 'today',
    },
    [THIS_WEEK]: {
        id: t('custom_status.expiry_dropdown.this_week'),
        defaultMessage: 'this_week',
    },
    [CUSTOM_DATE_TIME]: {
        id: t('custom_status.expiry_dropdown.custom_date_time'),
        defaultMessage: 'custom',
    }
}

export const ValidationErrors = {
    USERNAME_REQUIRED:  'USERNAME_REQUIRED',
    INVALID_LENGTH: 'INVALID_LENGTH',
    INVALID_CHARACTERS: 'INVALID_CHARACTERS',
    INVALID_FIRST_CHARACTER: 'INVALID_FIRST_CHARACTER',
    RESERVED_NAME: 'RESERVED_NAME',
    INVALID_LAST_CHARACTER: 'INVALID_LAST_CHARACTER',
};

export const ConsolePages = {
    AD_LDAP: '/admin_console/authentication/ldap',
    COMPLIANCE_EXPORT: '/admin_console/compliance/export',
    CUSTOM_TERMS: '/admin_console/compliance/custom_terms_of_service',
    SAML: '/admin_console/authentication/saml',
    SESSION_LENGTHS: '/admin_console/environment/session_lengths',

};


export const WidnowSizes = {
    MOBILE_VIEW: 'mobileView',
    TABLET_VIEW: 'tabletView',
    SMALL_DESKTOP_VIEW: 'smallDesktopView',
    DESKTOP_VIEW: 'desktopView',
}

export const AboutLinks = {
    TERMS_OF_SERVICE: 'https://topeic.com/terms-of-use',
    PRIVACY_POLICY: 'https://topeic.com/privacy-policy',
};

export const CloudLinks = {
    BILLING_DOCS: 'https://docs.topeic.com/b2b/billing/billing.html',
    PRICING: 'https://topeic.com/pricing',
    PRORATED_PAYMENT: 'https://topeic.com/pl/topeic-prorate-documentation',
    B2B_SIGNUP_PAGE: 'https://topeic.com/b2b/signup',
    DELINQUENCY_DOCS: 'https://docs.topeic.com/about/b2b-subscription.html#failed-or-late-payments',
};

export const BillingSchemes = {
    FLAT_FEE: 'flat_fee', 
    PER_SEAT: 'per_seat', 
    SALES_SERVE: 'sales_serve',
};

export const RecurringInterval = {
    YEAR: 'year', 
    MONTH: 'month',
};


export const CloudProducts = {
    STARTER: 'cloud-starter',
    PROFESSIONAL: 'cloud-professional',
    ENTERPRISE: 'cloud-enterprise',
}


export const TopeicFeatures = {
    UNLIMITTED_THREADS: 'topeic.feature.unlimited_messages',
    UNLIMITED_FILE_STORAGE: 'topeic.feature.unlimited_file_storage',
    ALL_PROFESSIONAL_FEATURES: 'topeic.feature.all_professional',
    ALL_ENTERPRISE_FEATURES: 'topeic.feature.all_enterprise',
    PLUGIN_FEATURE: 'topeic.feature.plugin',
};

export const CloudBanners = {
    HIDE: 'hide', 
    TRIAL: 'trial', 
    UPGRADE_FROM_TRIAL: 'upgrade_from_trial', 
    THREE_DAYS_LEFT_TRIAL_MODAL_DISMISSED: 'dismiss_3_days_left_trial_modal', 
    NUDGE_TO_YEARLY_BANNER_DISMISSED: 'nudge_to_yearly_banner_dismissed',
}

export const TrialPeriodDays = {
    TRIAL_30_DAYS: 30, 
    TRIAL_14_DAYS: 14, 
    TRIAL_WARNING_THRESHOLD: 3, 
    TRIAL_2_DAYS: 2, 
    TRIAL_1_DAYS: 1, 
    TRIAL_0_DAYS: 0,
}

export const TELEMETRY_CATEGORIES = {
    CLOUD_PURCHASING: 'cloud_purchasing',
    CLOUD_PRICING: 'cloud_pricing',
    SELF_HOSTED_PURCHASING: 'self_hosted_purchasing',
    CLOUD_ADMIN: 'cloud_admin',
    CLOUD_DELINQUENCY: 'cloud_delinquency',
    SELF_HOSTED_ADMIN: 'self_hosted_admin',
    POST_INFO_MORE: 'post_info_more_menu',
    POST_INFO: 'post_info',
    SELF_HOSTED_START_TRIAL_AUTO_MODAL: 'self_hosted_start_trial_auto_modal',
    SELF_HOSTED_START_TRIAL_MODAL: 'self_hosted_start_trial_modal',
    CLOUD_START_TRIAL_BUTTON: 'cloud_start_trial_button',
    CLOUD_THREE_DAYS_LEFT_MODAL: 'cloud_three_days_left_modal',
    SELF_HOSTED_START_TRIAL_TASK_LIST: 'self_hosted_start_trial_task_list',
    SELF_HOSTED_LICENSE_EXPIRED: 'self_hosted_license_expired',
    WORKSPACE_OPTIMIZATION_DASHBOARD: 'workspace_optimization_dashboard',
    REQUEST_BUSINESS_EMAIL: 'request_business_email',
    TRUE_UP_REVIEW: 'true_up_review',
}

export const ThreadTypes = {
    ThreadTypeConvRegular: 'thread_conv_regular',
	ThreadTypeConvGuestOrg: 'thread_conv_guest_org',  
	ThreadTypeConvGuestIndv: 'thread_conv_guest_indv',
	ThreadTypeConvGuestGrp: 'thread_conv_guest_grp',  
	ThreadTypeConvHostOrg: 'thread_conv_host_org',
}





export default Constants;