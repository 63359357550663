import {TopicTypes, WorkspaceTypes, ThreadTypes, UserTypes} from 'sigmaflow-redux/action_types';
import { GenericAction } from 'sigmaflow-redux/types/actions';
import { ThreadsState, TopicThread } from 'sigmaflow-redux/types/threads';
import {Topic, TopicUnread} from 'sigmaflow-redux/types/topics';

import Constants from 'utils/constants';

import { ExtraData } from './types';

function handleAllTopicThreadsRead(state: ThreadsState['counts'], action: GenericAction):  ThreadsState['counts'] {
    const counts = state[action.data.topic_id] ?? {};

    return {
        ...state, 
        [action.data.topicId]: {
            ...counts,
            total_unread_mentions: 0, 
            total_unread_threads: 0,
        },
    };
}



function isEqual(state: ThreadsState['counts'], action: GenericAction, unreads: boolean) {
    const counts = state[action.data.topic_id] ?? {};

    const {
        total, 
        total_unread_threads: totalUnreadThreads,
        total_unread_mentions: totalUnreadMentions,
    } = action.data;

    if (
        totalUnreadMentions !== counts.total_unread_mentions || 
        totalUnreadThreads !== counts.total_unread_threads
    ) {
        return false;
    }

    // in unread threads we exclude saving the total number, since it doesn't reflect 
    // the actual total of threads but only the total of unread thraeds.
    if (!unreads && total !== counts.total) {
        return false;
    }

    return true;
}


function handleReadChangedThread(state: ThreadsState['counts'], action: GenericAction): ThreadsState['counts'] {
    const {
        topicId,
        prevUnreadMentions = 0, 
        newUnreadMentions = 0, 
        prevUnreadReplies = 0, 
        newUnreadReplies = 0,
    } = action.data;

    const counts = state[topicId] ? {
        ...state[topicId],
    }: {
        total_unread_threads: 0, 
        total: 0, 
        total_unread_mentions: 0,
    };

    const unreadMentionDiff = newUnreadMentions - prevUnreadMentions;

    counts.total_unread_mentions  += unreadMentionDiff;

    if (newUnreadReplies > 0 && prevUnreadReplies === 0) {
        counts.total_unread_threads += 1
    } else if (prevUnreadReplies > 0 && newUnreadReplies === 0) {
        counts.total_unread_threads -= 1;
    } else if (prevUnreadReplies === 0 && newUnreadReplies === 0) { // case where user clicks on New thread.
        counts.total_unread_threads -= 1;
    } // TODO: what happens when user 'Mark Unread' a new thread

    return {
        ...state, 
        [action.data.topicId]: counts,
    };
}


function handleLeaveTopic(state: ThreadsState['counts'], action: GenericAction): ThreadsState['counts'] {
    const topic: Topic = action.data;

    if (!state[topic.id]) {
        return state;
    }

    const nextState = {...state};
    Reflect.deleteProperty(nextState, topic.id);

    return nextState;
}


function handleDecrementThreadCounts(state: ThreadsState['counts'], action: GenericAction) {
    const {topicId, replies, mentions} = action;
    const counts = state[topicId];

    if (!counts) {
        return state;
    }

    return {
        ...state, 
        [topicId]: {
            total: Math.max(counts.total - 1, 0),
            total_unread_mentions: Math.max(counts.total_unread_mentions - mentions, 0),
            total_unread_threads: Math.max(counts.total_unread_threads - replies, 0),
        },
    };
}

export function countsIncludingDirectReducer(state: ThreadsState['counts'] = {}, action: GenericAction, extra: ExtraData) {
    switch (action.type) {
        case ThreadTypes.ALL_TOPIC_THREADS_READ:
            return handleAllTopicThreadsRead(state, action);
        case ThreadTypes.READ_CHANGED_THREAD:
            return handleReadChangedThread(state, action);
        case ThreadTypes.FOLLOW_CHANGED_THREAD: {
            const {topic_id: topicId, following} = action.data;
            const counts = state[topicId];

            if (counts?.total == null) {
                return state;
            }

            return {
                ...state, 
                [topicId]: {
                    ...counts, 
                    total: following ? counts.total +1 : counts.total- 1,
                },
            };
        }

        case TopicTypes.LEAVE_TOPIC:
            return handleLeaveTopic(state, action);

        case ThreadTypes.RECEIVED_THREAD_COUNTS:{
            if (isEqual(state, action, false)) {
                return state;
            }

            return {
                ...state, 
                [action.data.topic_id]: {
                    total: action.data.total, 
                    total_unread_threads: action.data.total_unread_threads,
                    total_unread_mentions: action.data.total_unread_mentions,
                },
            };
        }
        
        case ThreadTypes.INCREMENT_TOTAL_THREAD_COUNT_FOR_TOPIC: {
            const {topic_id: topicId} = action.data;
            const counts = state[topicId];
                return {
                    ...state, 
                    [topicId]: {
                        ...counts, 
                        total: counts.total +1,
                    },
                };
            }

            case ThreadTypes.INCREMENT_TOTAL_UNREAD_THREAD_COUNT_FOR_TOPIC: {
                const {topic_id: topicId} = action.data;
                const counts = state[topicId];
                    return {
                        ...state, 
                        [topicId]: {
                            ...counts, 
                            total_unread_threads: counts.total_unread_threads + 1,
                        },
                    };
                }
        
        case ThreadTypes.RECEIVED_UNREAD_THREAD_COUNTS_FOR_TOPICS:
            const nextState = {...state};
            Object.keys(action.data).forEach((topicId) => {
                if (nextState[topicId]) {
                    nextState[topicId] = {...nextState[topicId], total_unread_threads: action.data[topicId]};
                } else {
                    nextState[topicId] = {total_unread_threads: action.data[topicId], total: 0, total_unread_mentions: 0}
                }  
            });
            return nextState;

        case ThreadTypes.DECREMENT_THREAD_COUNTS:
            return handleDecrementThreadCounts(state, action);

        case UserTypes.LOGOUT_SUCCESS:
            return {};
    }

    return state;
}