import { combineReducers } from "redux";

import {GenericAction} from 'sigmaflow-redux/types/actions';

import {ActionTypes, WindowSizes} from 'utils/constants';

function focused(state = true, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.BROWSER_CHANGE_FOCUS:
            return action.focus;
        default:
            return state;
    }
}

function windowSize(state = WindowSizes.DESKTOP_VIEW, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.BROWSER_WINDOW_RESIZED:
            return action.data;
        default:
            return state;
    }
}

export default combineReducers({
    focused, 
    windowSize,
});