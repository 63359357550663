import {t} from 'utils/i18n';
import memoize from 'memoize-one';

import emojis from 'utils/emoji.json';

import spriteSheet from 'images/emoji-sheets/apple-sheet.png';

export const Emojis = emojis;

export const EmojiIndicesByAlias = new Map([["grinning", 0], ["smiley", 1]]);

export const EmojiIndicesByUnicode = new Map([["1f600", 0], ["1f603", 1]]);

export const CategoryNames = ["recent", "smilys-emoticon", "people-body"];