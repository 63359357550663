import keyMirror from 'sigmaflow-redux/utils/key_mirror';

export default keyMirror({
    RECEIVED_CLOUD_CUSTOMER: null, 
    RECEIVED_CLOUD_PRODUCTS: null, 
    RECEIVED_CLOUD_SUBSCRIPTION: null,
    RECEIVED_CLOUD_INVOICES: null, 
    RECEIVED_CLOUD_LIMITS: null, 
    RECEIVED_MESSAGE_USAGE: null, 
    RECEIVED_FILES_USAGE: null, 
    RECEIVED_WORKSPACES_USAGE: null, 
    
    CLOUD_CUSTOMER_FAILED: null, 
    CLOUD_INVOICES_FAILED: null, 
    CLOUD_SUBSCRIPTION_FAILED: null, 
    CLOUD_LIMITS_FAILED: null, 
    CLOUD_PRODUCTS_FAILED: null, 

    CLOUD_CUSTOMER_REQUEST: null, 
    CLOUD_INVOICES_REQUEST: null, 
    CLOUD_LIMITS_REQUEST: null, 
    CLOUD_SUBSCRIPTION_REQUEST: null, 
    CLOUD_PRODUCTS_REQUEST: null,
    
    RECEIVED_SELF_HOSTED_SIGNUP_PROGRESS: null,

})