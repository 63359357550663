import {combineReducers} from 'redux';

import { UserTypes } from 'sigmaflow-redux/action_types';

import type { GenericAction } from 'sigmaflow-redux/types/actions';

import { ActionTypes } from 'utils/constants';

function websocketConnectionErrorCount(state = 0, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.INCREMENT_WS_ERROR_COUNT: {
            return state + 1;
        }

        case ActionTypes.RESET_WS_ERROR_COUNT: {
            return 0;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return 0;
        default: 
        return state;
    }
}

export default combineReducers({
    websocketConnectionErrorCount,
});