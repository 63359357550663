import { TopicTypes, PostTypes, WorkspaceTypes,  ThreadTypes, UserTypes} from "sigmaflow-redux/action_types";
import type { GenericAction } from "sigmaflow-redux/types/actions";
import type { Workspace } from "sigmaflow-redux/types/workspaces";
import type { Topic } from "sigmaflow-redux/types/topics";
import type { ThreadsState, TopicThread } from "sigmaflow-redux/types/threads";
import type { IDMappedObjects, RelationOneToMany } from "sigmaflow-redux/types/utilities";

import type {ExtraData} from './types';

type State = ThreadsState['threadsInTopic'] | ThreadsState['unreadThreadsInTopic'];

// return true only if it's newer than other threads
// older threads will be added by scrolling so no need to manually add.
// furthermore manually adding older thread will BREAK pagination.
function shouldAddThreadId(ids: Array<TopicThread['id']>, thread: TopicThread, threads: IDMappedObjects<TopicThread>) {
    return ids.some((id) => {
        const t = threads![id];
        return thread.last_reply_at > t.last_reply_at;
    });
}


function handlePostRemoved(state: State, action: GenericAction): State {
    const post = action.data;
    if (post.root_id) {
        return state;
    }

    const topics = Object.keys(state).
          filter((id) => state[id].indexOf(post.id) !== -1);

    if (!topics?.length) {
        return state;
    }

    const topicState: RelationOneToMany<Topic, TopicThread> = {};

    for (let i = 0; i < topics.length; i++) {
        const topicId = topics[i];
        const index = state[topicId].indexOf(post.id);

        topicState[topicId] = [
            ...state[topicId].slice(0, index),
            ...state[topicId].slice(index + 1),
        ];
    } 

    return {
        ...state, 
        ...topicState,
    };
}

// add threads to all topics in state.
function handleAllTopicsReceivedThread(state: State, thread: TopicThread, topicId: Topic['id'], extra: ExtraData) {
    const topicsIds = Object.keys(state);

    let newState = {...state};
    for (const topicId of topicsIds) {
        newState = handleSingleTopicReceivedThread(newState, thread, topicId, extra);
    }

    return newState;
}

// adds thread to a single topic.
function handleSingleTopicReceivedThread(state: State, thread: TopicThread, topicId: Topic['id'], extra: ExtraData) {
    const nextSet = new Set(state[topicId] || []);

    // condition to support first thread in the topic
    if (nextSet.size === 0) {
        return {
            ...state, 
            [topicId]: [thread.id],
        };
    }
    // thread exists in state
    if (nextSet.has(thread.id)) {
        return state;
    }

    // check if thread is newer than any of the existing thread
    const shouldAdd = shouldAddThreadId([...nextSet], thread, extra.threads);

    if (shouldAdd) {
        nextSet.add(thread.id);


        return {
            ...state, 
            [topicId]: [...nextSet],
        };
    }

    return state;
}


export function handleReceivedThread(state: State, action: GenericAction, extra: ExtraData) {
    const {thread, topic_id: topicId} = action.data;

    if (!topicId) {
        return handleAllTopicsReceivedThread(state, thread, topicId, extra);
    }

    return handleSingleTopicReceivedThread(state, thread, topicId, extra);
}

// add the thread only if it's 'newer' than other threads.
// older threads will be added by scrolling so no need to manually add.
// Furthermore manually adding older thread will BREAK pagination.
export function handleFollowChanged(state: State, action: GenericAction, extra: ExtraData) {
    const {id, topic_id: topicId, following} = action.data;
    const nextSet = new Set(state[topicId] || []);

    const thread = extra.threads[id];

    if (!thread) {
        return state;
    }


    // threads exists in state
    if (nextSet.has(id)) {
        // remove if we unfollowed.
        if (!following) {
            nextSet.delete(id);
            return {
                ...state, 
                [topicId]: [...nextSet],
            };
        }

        return state;
    }

    // check if the thread is newer than any of the existing threads.
    const shouldAdd = shouldAddThreadId([...nextSet], thread, extra.threads);

    if (shouldAdd && following) {
        nextSet.add(thread.id);

        return {
            ...state, 
            [topicId]: [...nextSet],
        };
    }

    return state;
}


function handleReceiveThreads(state: State, action: GenericAction) {
    const nextSet = new Set(state[action.data.topic_id] || []);

    action.data.threads.forEach((thread: TopicThread) => {
        nextSet.add(thread.id);
    });

    return {
        ...state, 
        [action.data.topic_id]: [...nextSet],
    };
}

function handleLeaveTopic(state: State, action: GenericAction) {
   

    const topic: Topic = action.data;

    if (!state[topic.id]) {
        return state;
    }

    const nextState = {...state};
    Reflect.deleteProperty(nextState, topic.id);

    return nextState;
}

export const threadsInTopicReducer = (state: ThreadsState['threadsInTopic'] = {}, action: GenericAction, extra: ExtraData) => {
    switch (action.type) {
        case ThreadTypes.RECEIVED_THREAD:
            return handleReceivedThread(state, action, extra);
        case PostTypes.POST_REMOVED:
            return handlePostRemoved(state, action);
        case ThreadTypes.RECEIVED_THREADS: 
           return handleReceiveThreads(state, action);
        case TopicTypes.LEAVE_TOPIC:
            return handleLeaveTopic(state, action);
        case UserTypes.LOGOUT_SUCCESS:
            return {};
    }

    return state;
};

export const unreadThreadsInTopicReducer = (state: ThreadsState['unreadThreadsInTopic'] = {}, action: GenericAction, extra: ExtraData) => {
    switch (action.type) {
        case ThreadTypes.READ_CHANGED_THREAD: {
            const {
                id, 
                topicId, 
                newUnreadMentions, 
                newUnreadReplies,
            } =  action.data;

            const topic = state[topicId] || [];
            const index = topic.indexOf(id);

            // the thread is not in the unread list.
            if (index === -1) {
                const thread = extra.threads[id];

                // the thread is unread.
                if (thread && (newUnreadReplies > 0 || newUnreadMentions > 0)) {
                    // if it's newer add it, we don't care  about ordering here since
                    // we order on the selector.
                    if (shouldAddThreadId(topic, thread, extra.threads)) {
                        return {
                            ...state, 
                            [topicId]: [
                                ...topic, 
                                id,
                            ],
                        };
                    }
                }

                // do nothing when the thread is read.
                return state;
            }

            // do nothing when the thread exists and it's unread.
            if (newUnreadReplies > 0 || newUnreadMentions > 0) {
                return state;
            }

            // if the thread is read, remove it.
            return {
                ...state, 
                [topicId]: [
                    ...topic.slice(0, index), 
                    ...topic.slice(index + 1),
                ],
            };
        }

        case ThreadTypes.RECEIVED_THREAD:
            if (action.data.thread.unread_replies > 0 ||  action.data.thread.unread_mentions > 0) {
                return handleReceivedThread(state, action, extra);
            }
            return state;

        case PostTypes.POST_REMOVED:
            return handlePostRemoved(state, action);
        case ThreadTypes.RECEIVED_UNREAD_THREADS:
            return handleReceiveThreads(state, action);
        case TopicTypes.LEAVE_TOPIC:
            return handleLeaveTopic(state, action);
        case UserTypes.LOGOUT_SUCCESS:
            return {};
        case ThreadTypes.FOLLOW_CHANGED_THREAD:
            return handleFollowChanged(state, action, extra);
    }

    return state;
}


export const threadsFirstLoadCompletedReducer = (state: ThreadsState['threadsFirstLoadCompleted'] = {}, action: GenericAction) => {
    switch (action.type) {
        case ThreadTypes.THREADS_FIRST_LOAD_COMPLETED:
            return {
                ...state,
                [action.data.topic_id]: action.data.first_load,
            }
        case UserTypes.LOGOUT_SUCCESS:
            return {};
    }

    return state;
};