import { combineReducers } from 'redux';

import { AdminTypes, UserTypes } from 'sigmaflow-redux/action_types';
//import {Stats} from 'sigmaflow-redux/constants';
//import PluginState from 'sigmaflow-redux/constants/plugins';

import { GenericAction } from 'sigmaflow-redux/types/actions';
import { Audit } from 'sigmaflow-redux/types/audits';
import { Compliance } from 'sigmaflow-redux/types/compliance';
import { AdminConfig, EnvironmentConfig } from 'sigmaflow-redux/types/config';
import { MixedUnlinkedGroupRedux } from 'sigmaflow-redux/types/groups';
import { PluginRedux, PluginStatusRedux } from 'sigmaflow-redux/types/plugins';
import { SamlCertificateStatus, SamlMetadataResponse } from 'sigmaflow-redux/types/saml';
import { Workspace } from 'sigmaflow-redux/types/workspaces';
import { UserAccessToken, UserProfile } from 'sigmaflow-redux/types/users';
import { RelationOneToOne, IDMappedObjects } from 'sigmaflow-redux/types/utilities';
import { DataRetentionCustomPolicy } from 'sigmaflow-redux/types/data_retention';

function logs(state: string[] = [], action: GenericAction) {
    switch (action.type) {
        case AdminTypes.RECEIVED_LOGS: {
            return action.data;
        }
        case UserTypes.LOGOUT_SUCCESS:
            return [];
        default:
            return state;
    }
}

function audits(state: Record<string, Audit> = {}, action: GenericAction) {
    switch (action.type) {
        case AdminTypes.RECEIVED_AUDITS: {
            const nextState = {...state}; 
            for (const audit of action.data) {
                nextState[audit.id] = audit;
            }

            return nextState;
        }

        case UserTypes.LOGOUT_SUCCESS: 
          return {};
        default:
            return state;
    }
}


function config(state: Partial<AdminConfig> = {}, action: GenericAction) {
    switch (action.type) {
        case AdminTypes.RECEIVED_CONFIG:
            return action.data;
        case AdminTypes.ENABLED_PLUGIN:
            const nextPluginSettings = {...state.PluginSettings!};
            const nextPluginStates = {...nextPluginSettings.PluginStates};
            nextPluginStates[action.data] = {Enable: true};
            nextPluginSettings.PluginStates = nextPluginStates;
            return {...state, PluginSettings: nextPluginSettings};

        case AdminTypes.DISABLED_PLUGIN: {
            const nextPluginSettings = {...state.PluginSettings!};
            const nextPluginStates = {...nextPluginSettings.PluginStates};
            nextPluginStates[action.data] = {Enable: false};
            nextPluginSettings.PluginStates = nextPluginStates;
            return {...state, PluginSettings: nextPluginSettings};
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};

        default: 
          return state;
    }

}


function environmentConfig(state: Partial<EnvironmentConfig> = {}, action: GenericAction) {
    switch (action.type) {
        case AdminTypes.RECEIVED_ENVIRONMENT_CONFIG: {
            return action.data;
        }
        case UserTypes.LOGOUT_SUCCESS: 
            return {};
        
        default: 
          return state;
    }
}

function complianceReports(state: Record<string, Compliance> = {}, action: GenericAction) {
    switch (action.type) {
        case AdminTypes.RECEIVED_COMPLIANCE_REPORT: {
            const nextState = {...state};
            nextState[action.data.id] = action.data;
            return nextState;
        }

        case AdminTypes.RECEIVED_COMPLIANCE_REPORTS: {
            const nextState = {...state};
            for (const report of action.data) {
                nextState[report.id] = report;
            }

            return nextState;
        }
        case UserTypes.LOGOUT_SUCCESS: 
            return {};

        default: 
          return state;
    }
}

function samlCertStatus(state: Partial<SamlCertificateStatus> = {}, action: GenericAction) {
    switch (action.type) {
        case AdminTypes.RECEIVED_SAML_CERT_STATUS: {
            return action.data;
        }

        case UserTypes.LOGOUT_SUCCESS: 
          return {};

        default: 
           return state;
    }
}

function userAccessTokens(state: Record<string, UserAccessToken> = {}, action: GenericAction) {
    switch (action.type) {
        case AdminTypes.RECEIVED_USER_ACCESS_TOKEN: {
            return {...state, [action.data.id]: action.data};
        }

        case AdminTypes.RECEIVED_USER_ACCESS_TOKENS_FOR_USER: {
            const nextState: any = {};

            for (const uat of action.data) {
                nextState[uat.id] = uat;
            }

            return {...state, ...nextState};
        }

        case AdminTypes.RECEIVED_USER_ACCESS_TOKENS:{
            const nextState: any = {};

            for (const uat of action.data) {
                nextState[uat.id] = uat;
            }

            return {...state, ...nextState};
        }

        case UserTypes.REVOKED_USER_ACCESS_TOKEN: {
            const nextState = {...state};
            Reflect.deleteProperty(nextState, action.data);
            return {...nextState};
        }

        case UserTypes.ENABLED_USER_ACCESS_TOKEN: {
            const token = {...state[action.data], is_active: true};
            return {...state, [action.data]: token};
        }

        case UserTypes.DISABLED_USER_ACCESS_TOKEN: {
            const token = {...state[action.data], is_active: false};
            return {...state, [action.data]: token};
        }

        case UserTypes.LOGOUT_SUCCESS: 
           return {};
        default:
              return state;
    }

    
}



function userAccessTokensByUser(state: RelationOneToOne<UserProfile, Record<string, UserAccessToken>> = {}, action: GenericAction) {
    switch (action.type) {
        case AdminTypes.RECEIVED_USER_ACCESS_TOKEN: { 
            const nextUserState: UserAccessToken | Record<string, UserAccessToken> = {...(state[action.data.user_id] || {})}
            nextUserState[action.data.id] = action.data;

            return {...state, [action.data.user_id]: nextUserState};
        }

        case AdminTypes.RECEIVED_USER_ACCESS_TOKENS_FOR_USER: {
            const nextUserState = {...(state[action.userId]) || {}};

            for (const uat of action.data) {
                nextUserState[uat.id] = uat;
            }

            return {
                ...state, 
                [action.userId]: nextUserState,
            }
        }
        case AdminTypes.RECEIVED_USER_ACCESS_TOKENS: {
            const nextUserState: any = {};

            for (const uat of action.data) {
                nextUserState[uat.user_id] = nextUserState[uat.user_id] || {};
                nextUserState[uat.user_id][uat.id] = uat;
            }

            return {...state, ...nextUserState};
        }

        case UserTypes.REVOKED_USER_ACCESS_TOKEN: {
            const userIds = Object.keys(state);

            for (let i = 0; i < userIds.length; i++) {
                const userId = userIds[i];
                if (state[userId] && state[userId][action.data]) {
                    const nextUserState = {...state[userId]};
                    Reflect.deleteProperty(nextUserState, action.data);
                    return {...state, [userId]: nextUserState}
                }
            }

            return state;
        }

        case UserTypes.ENABLED_USER_ACCESS_TOKEN: {
            const userIds = Object.keys(state);
            for (let i = 0; i < userIds.length; i++) {
                const userId = userIds[i];
                if (state[userId] && state[userId][action.data]) {
                    const nextUserState = {...state[userId]};
                    const token = {...nextUserState[action.data], isactive: true};
                    nextUserState[token.id] = token;
                    return {...state, [userId]: nextUserState};
                }
            }

            return state;
        }
        case UserTypes.DISABLED_USER_ACCESS_TOKEN: {
            const userIds = Object.keys(state);
            for (let i = 0; i < userIds.length; i++) {
                const userId = userIds[i];
                if (state[userId] && state[userId][action.data]) {
                    const nextUserState = {...state[userId]};
                    const token = {...nextUserState[action.data], is_active: false};
                    nextUserState[token.id] = token;
                    return {...state, [userId]: nextUserState};
                }
            }

            return state;
        }

        case UserTypes.LOGOUT_SUCCESS: 
          return {};

        default:
            return state;
    }
}


function ldapGroupsCount(state = 0, action: GenericAction) {
    switch (action.type) {
        case AdminTypes.RECEIVED_LDAP_GROUPS: 
           return action.data.count; 
        case UserTypes.LOGOUT_SUCCESS: 
           return 0;
        default: 
           return state;
    }
}


function ldapGroups(state: Record<string, MixedUnlinkedGroupRedux> = {}, action: GenericAction) {
    switch (action.type) {
        case AdminTypes.RECEIVED_LDAP_GROUPS: {
            const nextState: any = {};
            for (const group of action.data.groups) {
                nextState[group.primary_key] = group; 
            }

            return nextState;
        }

        case AdminTypes.LINKED_LDAP_GROUP: {
            const nextState = {...state};
            if (nextState[action.data.primary_key]) {
                nextState[action.data.primary_key] = action.data;
            }

            return nextState;
        }

        case AdminTypes.UNLINKED_LDAP_GROUP: {
            const nextState = {...state};
            if (nextState[action.data]) {
                nextState[action.data] = {
                    ...nextState[action.data], 
                    topeic_group_id: undefined, 
                    has_syncables: undefined, 
                    failed: false, 
                };
            }

            return nextState;
        }

        case AdminTypes.LINK_LDAP_GROUP_FAILURE: {
            const nextState = {...state};
            if (nextState[action.data]) {
                nextState[action.data] = {
                    ...nextState[action.data], 
                    failed: true,
                };
            }

            return nextState;
        }

        case UserTypes.LOGOUT_SUCCESS: 
           return {};

        default: 
          return state;
    }
}

function samlMetadataResponse(state: Partial<SamlMetadataResponse> = {}, action: GenericAction) {
    switch (action.type) {
        case AdminTypes.RECEIVED_SAML_METADATA_RESPONSE: {
            return action.data;
        }
        default: 
          return state;
    }
}

function dataRetentionCustomPolicies(state: IDMappedObjects<DataRetentionCustomPolicy> = {}, action: GenericAction): IDMappedObjects<DataRetentionCustomPolicy> {
    switch (action.type) {
        case AdminTypes.CREATE_DATA_RETENTION_CUSTOM_POLICY_SUCCESS: 
        case AdminTypes.RECEIVED_DATA_RETENTION_CUSTOM_POLICY:
        case AdminTypes.UPDATE_DATA_RETENTION_CUSTOM_POLICY_SUCCESS: {
            return {
                ...state, 
                [action.data.id]: action.data,
            };
        } 

        case AdminTypes.RECEIVED_DATA_RETENTION_CUSTOM_POLICIES: {
            const nextState = {...state};
            if (action.data.polcies) {
                for (const dataRetention of action.data.policies) {
                    nextState[dataRetention.id] = dataRetention;
                }
            }

            return nextState;
        }

        case AdminTypes.DELETE_DATA_RETENTION_CUSTOM_POLICY_SUCCESS: {
            const nextState = {...state};
            Reflect.deleteProperty(nextState, action.data.id);
            return nextState;
        }

        case UserTypes.LOGOUT_SUCCESS: 
           return {};

        default:
            return state;
    }
}

function dataRetentionCustomPoliciesCount(state = 0, action: GenericAction) {
    switch (action.type) {
        case AdminTypes.RECEIVED_DATA_RETENTION_CUSTOM_POLICIES:
            return action.data.total_count;
        case UserTypes.LOGOUT_SUCCESS: 
            return 0;

        default:
            return state;
    }
}

export default combineReducers({
    logs, 
    audits, 
    config, 
    environmentConfig, 
    complianceReports, 
    // object with certificate type as key and boolean statuses as values
    samlCertStatus, 

    // object with usersiDs as keys and objects, with token ids as kets and user
    // access tokens as values without actual token
    userAccessTokensByUser, 
    
    userAccessTokens, 

    // object representing ldap groups.
    ldapGroups, 

    // total ldap groups
    ldapGroupsCount, 

    // object representing the metadata response from the Idp
    samlMetadataResponse,

    // object representing the custom data retention policies
    dataRetentionCustomPolicies, 

    // total custom retention policies
    dataRetentionCustomPoliciesCount, 

});