import { combineReducers } from "redux";

import users from './users';
import roles from './roles';
import posts from './posts';
import threads from './threads';

export default combineReducers({
    users,
    roles,
    posts,
    threads,
});