import {createBrowserHistory} from 'history';


import {isDesktopApp, getDesktopVersion} from 'utils/user_agent';
//import {getModule} from 'module_registry';

function appendBaseName(basename, to, state, callback) {
    if (typeof to === 'string') {
        to = basename + to;
    }

    if (typeof to === 'object' && to.pathname) {
        to.pathname = basename + to.pathname;
    }

    if (state !== undefined && state.pathname) {
        to.pathname = basename + state.pathname;
    }

    return callback(to, state);
}

export default function createBrowserHistoryWithBasename(basename = '/') {
    let history = createBrowserHistory();
    history.basename = basename;

    const push = history.push;
    const replace = history.replace;
    history.push = (to, state = undefined) => appendBaseName(basename, to, state, push);
    history.replace = (to, state = undefined) => appendBaseName(basename, to, state, replace);

    return history;
}


console.log("ishaan got 1.0::", window.basename);
 const b = createBrowserHistory({basename: window.basename});

//const b = createBrowserHistoryWithBasename(window.basename);

console.log("ishaan got 2.0::", b);
const isDesktop = isDesktopApp()
window.addEventListener('message', ({origin, data: {type, message = {}} = {}} = {}) => {
    if (origin !== window.location.origin) {
        return;
    }

    switch (type) {
        case 'browser-history-push-return': {
            if (message.pathName) {
                const {pathName} = message;
                b.push(pathName);
            }
            break;
        }
    }
});

export const browserHistory = {
    ...b, 
    push: (path, ...args) => {
        if (isDesktop) {
            window.postMessage(
                {
                    type: 'browser-history-push',
                    message: {
                        path: path.pathname || path, 
                    },
                },
                window.location.origin,
            );
        } else {
            b.push(path, ...args);
        }
    },
}

//console.log("ishaan got 3.0::", browserHistory);

/**
 * Returns the current history object. 
 * If you are calling this from within a React component, consider using the useHistory hook 
 * from react-router-dom. 
 */
export function getHistory() {
    return browserHistory;
  
}

let historyObject = {};

export function setHistoryObject(history) {
    historyObject = {...history};
}

export function getHistoryObject() {
    return historyObject
}

export function resetHistoryObject() {
    historyObject = {};
}