import { GlobalState } from "sigmaflow-redux/types/store";
import { getCurrentTopicId } from "./common";


export function haveITopicPermission(state: GlobalState, topicId: string, permission: string): boolean {
    return true;
}

export function haveICurrentTopicPermission(state: GlobalState, permission: string): boolean {
    return haveITopicPermission(state, getCurrentTopicId(state), permission);
}