import { combineReducers } from "redux";

import admin from './admin';
import announcementBar from './announcement_bar';
import browser from "./browser";
import modals from "./modals";
import i18n from './i18n';
import lhs from "./lhs";
import rhs from "./rhs";
import statusDropdown from './status_dropdown';
import topic from './topic';
import threads from './threads';
import posts from './posts';
import topicSidebar from './topic_sidebar';
import addTopicDropdown from './add_topic_dropdown';
import system from './system';
import textbox from './textbox';
import search from './search';
import productMenu from './product_menu';

export default combineReducers({
    admin,
    announcementBar,
    browser,
    modals,
    i18n,
    lhs,
    rhs,
    statusDropdown,
    search,
    topic,
    threads,
    posts,
    textbox,
    topicSidebar,
    addTopicDropdown,
    system,
    productMenu,
}); 