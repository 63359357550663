import React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';

import {latinise} from 'utils/latinise';
import {t} from 'utils/i18n';
// import * as TextFormatting from 'utils/text_formatting';
import Constants from './constants';

type WindowObject = {
    location: {
        origin: string;
        protocol: string;
        hostname: string;
        port: string;
        pathname: string;
    };
    basename?: string;
}

export function cleanUpUrlable(input: string): string {
    let cleaned: string = latinise(input);
    cleaned = cleaned.trim().replace(/-/g, ' ').replace(/[^\w\s]/gi, '').toLowerCase().replace(/\s/g, '-');
    cleaned = cleaned.replace(/^-+/, '');
    cleaned = cleaned.replace(/-+$/, '');
    return cleaned;
}


export function getShortenedURL(url = '', getLength =  27): string {
    if (url.length > 35) {
        const subLength = getLength - 14;
        return url.substring(0, 10) + '...' + url.substring(url.length - subLength, url.length);
    }

    return url;
}

export function getSiteURLFromWindowObject(obj: WindowObject): string {
    let siteURL = '';
    /*
    if (obj.location.origin) {
        siteURL = obj.location.origin;
    } else {
        siteURL = obj.location.protocol + '//' + obj.location.hostname + (obj.location.port ? ':' + obj.location.port: '');
    }
    */

    siteURL = obj.location.protocol + '//' + obj.location.hostname + ':8065';

    if (siteURL[siteURL.length-1] === '/') {
        siteURL = siteURL.substring(0, siteURL.length - 1);
    }

    if (obj.location.pathname) {        
        const pathArray = obj.location.pathname.split('/');
        siteURL += '/' + pathArray[1];
    }

    /*
    if (obj.basename) {
        siteURL += obj.basename;
    }
    */

    if (siteURL[siteURL.length - 1] === '/') {
        siteURL = siteURL.substring(0, siteURL.length - 1);
    }

     // console.log('Ishaan got 1.0::', siteURL);

    return siteURL;
}

export function getApiEndpoint(obj: WindowObject): string {
    let siteURL = '';
   
    siteURL = obj.location.protocol + '//api.topeic.network';


    if (obj.location.pathname) {        
        const pathArray = obj.location.pathname.split('/');
        siteURL += '/' + pathArray[1];
    }

    /*
    if (obj.basename) {
        siteURL += obj.basename;
    }
    */

    if (siteURL[siteURL.length - 1] === '/') {
        siteURL = siteURL.substring(0, siteURL.length - 1);
    }

     // console.log('Ishaan got 1.0::', siteURL);

    return siteURL;
}


export function getOrgSiteHost(): string {
    return window.location.protocol + '//' + window.location.host + window.basename;
}


export function getSiteURL(): string {
    if (process.env.NODE_ENV === 'production') {
        return getApiEndpoint(window)
    }
    
    return getSiteURLFromWindowObject(window);
}

export function getBasePathFromWindowObject(obj: WindowObject): string {
    return obj.basename || ''; // https://app.sigmaflow.com/bell.ca  :: basename= bell.ca
}

export function getBasePath(): string {
    return getBasePathFromWindowObject(window);
}

export function getRelativeTopicURL(workspaceName: string, topicName: string): string {
    return `/${workspaceName}/ropics/${topicName}`;
}


export function isUrlSafe(url: string): boolean {
    let unescaped: string;

    try {
        unescaped = decodeURIComponent(url);
    } catch (e) {
        unescaped = unescape(url);
    }

    unescaped = unescaped.replace(/[^\w:]/g, '').toLowerCase();

    return !unescaped.startsWith('javascript:') && //eslint-disable-line no-script-url
       !unescaped.startsWith('vbscript:') && 
       !unescaped.startsWith('data:');
}


export function makeUrlSafe(url: string, defaultUrl = ''): string {
    if (isUrlSafe(url)) {
        return url;
    }

    return defaultUrl;
}


export function getScheme(url: string): string | null {
    const match = (/([a-z0-9+.-]+):/i).exec(url);

    return match && match[1];
}

function formattedError(id: string, message: string, intl?: IntlShape) : React.ReactElement | string {
    if (intl) {
        return intl.formatMessage({id, defaultMessage: message});
    }

    return (<span key={id}>
        <FormattedMessage 
        id={id}
        defaultMessage={message}
        />
        <br/>
    </span>);
}

export function validateTopicUrl(url: string, intl?: IntlShape): Array<React.ReactElement | string> {
    const errors: Array<React.ReactElement | string> = [];

    const USER_ID_LENGTH = 26;
    const directMessageRegex = new RegExp(`^.{${USER_ID_LENGTH}}__.{${USER_ID_LENGTH}}$`);
    const isDirectMessageFormat = directMessageRegex.test(url);

    const cleanedURL = cleanUpUrlable(url);
    const urlMatched = url.match(/^[a-z0-9]([a-z0-9\-_]*[a-z0-9])?$/);
    const urlLonger = url.length < Constants.MIN_TOPICNAME_LENGTH;
    const urlShorter = url.length > Constants.MAX_TOPICNAME_LENGTH;

    if (cleanedURL !== url || !urlMatched || urlMatched[0] !== url || isDirectMessageFormat || urlLonger || urlShorter ) {
        if (urlLonger) {
            errors.push(formattedError(t('change_url.longer'), 'URLs must have at least 2 characters.', intl));
        }

        if (urlShorter) {
            errors.push(formattedError(t('change_url.shorter'), 'URLs must have maximum 64 characters.', intl));
        }

        if (url.match(/[A-Za-z0-9-_]/)) {
            errors.push(formattedError(t('change_url.noSpecialChars'), 'URLs cannot use special characters.', intl));
        }

        if (isDirectMessageFormat) {
            errors.push(formattedError(t('change_url.invalidDirectMessage'), 'User IDs are not allowed in topic URLs.', intl));
        }

        const startsWithoutLetter = url.charAt(0) === '-' || url.charAt(0) === '_';
        const endsWithoutLetter = url.length > 1 && (url.charAt(url.length - 1) === '-' || url.charAt(url.length -1) === '_');

        if (startsWithoutLetter && endsWithoutLetter) {
            errors.push(formattedError(t('change_url.startAndEndWithLetter'), 'URLs must starta nd end with a lowercase letter or  number.', intl));
        } else if (startsWithoutLetter) {
            errors.push(formattedError(t('change_url.startWithLetter'), 'URLs must start with a lowercase letter or number.', intl));
        } else if (endsWithoutLetter) {
            errors.push(formattedError(t('change_url.endWithLetter'), 'URLs must end with a lowercase letter or number.', intl));
        }

        // In case of error, we don't deterct.
        if (errors.length === 0) {
            errors.push(formattedError(t('change_url.invalidUrl'), 'Invalid URL', intl));
        }
    }

    return errors;
}


export function isInternalURL(url: string, siteURL?: string): boolean {
    return url.startsWith(siteURL || '') || url.startsWith('/');
}

export function shouldOpenInNewTab(url: string, siteURL?: string, managedResourcePaths?: string[]): boolean {
    if (!isInternalURL(url, siteURL)) {
        return true;
    }

    const path = url.startsWith('/') ? url : url.substring(siteURL?.length || 0);
    //siteURL: https://app.sigmaflow.com/bell.ca/topics/abcssjd/78277
    // url: topic/127667/about

    // Paths managed by plugins and public file links aren't handled by  the webapp.
    const unhandledPaths = [
        'plugins',
        'files',
    ];

    // paths managed by another service shouldn't be handled by the webapp either.
    if (managedResourcePaths) {
        for (const managedPath of managedResourcePaths) {
           // unhandledPaths.push(TextFormatting.escapeRegex(managedPath));
        }
    }

    return unhandledPaths.some((unhandledPath) => new RegExp('^/' + unhandledPath + '\\b').test(path))
}

// returns true if the string passed is a permalink URL.
export function isPermalinkURL(url: string): boolean {
    const siteURL = getSiteURL();

    const regexp = new RegExp(`^(${siteURL})?/[a-z0-9]+([a-z\\-0-9]+|(__)?)[a-z0-9]+/pl/\\W+`, 'gu');

    return isInternalURL(url, siteURL) && (regexp.test(url));
}

export function isStringContainingUrl(text: string): boolean {
    const regex = new RegExp('(https?://|www.)');
    return regex.test(text);
}

export type UrlValidationCheck = {
    url: string;
    error: typeof BadUrlReasons[keyof typeof BadUrlReasons] | false;
}

export const BadUrlReasons = {
    Empty: 'Empty',
    Length: 'Length',
    Reserved: 'Reserved',
    Taken: 'Taken',
} as const;

export function workspaceNameToUrl(wspaceName: string): UrlValidationCheck {
    // borrowed from wspace_url, which has some peculiarities tied to being part of
    // two screen UI that allows more variation between workspace name and url than  we allow in 
    // usages of this function.
    const url = cleanUpUrlable(wspaceName.trim());

    if (!url) {
        return {url, error: BadUrlReasons.Empty};
    }

    if (url.length < Constants.MIN_WORKSPACENAME_LENGTH || url.length  > Constants.MAX_WORKSPACENAME_LENGTH) {
        return {url, error: BadUrlReasons.Length};
    }

    return {url, error: false};
}

export function topicNameToUrl(topicName: string): UrlValidationCheck {
    const url = cleanUpUrlable(topicName);

    if (!url) {
        return {url, error: BadUrlReasons.Empty};
    }

    if (url.length < Constants.MIN_TOPICNAME_LENGTH || url.length > Constants.MAX_TOPICNAME_LENGTH) {
        return {url, error: BadUrlReasons.Length};
    }

    return {url, error: false};
}