import React, {LinkHTMLAttributes} from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import moment from 'moment';
import { constants } from 'buffer';

import { ClientSf } from 'sigmaflow-redux/client';
import {Posts, Preferences, General} from 'sigmaflow-redux/constants';

import {t} from 'utils/i18n';
import store from 'stores/redux_store.jsx';

import {getCurrentLocale, getTranslations} from 'selectors/i18n';
import { getIsMobileView } from 'selectors/views/browser';

import Constants, {A11yCustomEventTypes, A11yFocusEventDetail, ValidationErrors} from './constants';
import { UserProfile } from 'sigmaflow-redux/types/users';

import { getConfig } from 'sigmaflow-redux/selectors/entities/general';
import { ClientConfig } from 'sigmaflow-redux/types/config';
import { GlobalState } from 'types/store';
import { Topic } from 'sigmaflow-redux/types/topics';
import { getCurrentUser, getCurrentUserId } from 'sigmaflow-redux/selectors/entities/common';
import { Org } from 'sigmaflow-redux/types/orgs';
import { getRedirectTopicNameForUser } from 'sigmaflow-redux/selectors/entities/topics';

import {getHistory} from 'utils/browser_history';
import { Post } from 'sigmaflow-redux/types/posts';
import * as UserAgent from 'utils/user_agent';

import {TextboxElement} from 'components/textbox';
import { number } from 'yargs';
import { FileTypes } from './constants';
import { FileInfo } from 'sigmaflow-redux/types/files';
import { file } from '@babel/types';
import { getWspaceMateNameDisplaySetting } from 'sigmaflow-redux/selectors/entities/preferences';
import { displayUsername, isSystemAdmin } from 'sigmaflow-redux/utils/user_utils';
import { isFirstAdmin } from 'sigmaflow-redux/selectors/entities/users';
import { Workspace } from 'sigmaflow-redux/types/workspaces';
import { isDesktopApp } from 'utils/user_agent';
import { getName } from 'country-list';


export function isMac() {
    return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
}

export function createSafeId(prop: {props: {defaultMessage: string}} | string): string | undefined {
   let str = '';

   if (typeof prop !== 'string' && prop.props && prop.props.defaultMessage) {
       str = prop.props.defaultMessage;
   } else {
       str = prop.toString();
   }

   return str.replace(new RegExp(' ', 'g'), '_')
}

export function cmdOrCtrlPressed(e: React.KeyboardEvent | KeyboardEvent, allowAlt = false) {
    if (allowAlt) {
        return (isMac() && e.metaKey) || (!isMac() && e.ctrlKey);
    }

    return (isMac() && e.metaKey) || (!isMac() && e.ctrlKey && !e.altKey);
}

export function isKeyPressed(event: React.KeyboardEvent | KeyboardEvent, key: [string, number]) {
    // There are two types of keyboards
    // 1. English with different layout(Ex: Dvorak)
    // 2. Different Language keyboards(Ex: Russian)

    if (event.keyCode === Constants.KeyCodes.COMPOSING[1]) {
        return false;
    }

    // checks for event.key for older browser and also for the case of different English layout keyboards.
    if (typeof event.key !== 'undefined' && event.key !== 'Unidentified' && event.key !== 'Dead') {
        const isPressedByCode = event.key === key[0] || event.key === key[0].toUpperCase();
        if (isPressedByCode) {
            return true;
        }
    }

    // used for a different language keyboards to detect the position of keys.
    return event.keyCode === key[1];
}

/**
 * 
 * Check keydown event for line break combo. Should catch  alt/option + enter not all browser except safari. 
 * 
 */
export function isUnhandledLineBreakKeyCombo(e: React.KeyboardEvent | KeyboardEvent): boolean {
    return Boolean(
        isKeyPressed(e, Constants.KeyCodes.ENTER) && 
        !e.shiftKey && // shift + enter is already handled everywhere, so don't handle again.
        (e.altKey && !UserAgent.isSafari() && !cmdOrCtrlPressed(e)), // alt/option + enter already ahndled in Safari, so don't handle it again
    );
}

/**
 * insert a new line character at keyboard cursor (or overwrites selection)
 * WARNING: HAS DOM SIDE EFFECTS 
 */
export function insertLineBreakFromKeyEvent(e: React.KeyboardEvent<TextboxElement>): string {
    const el = e.target as TextboxElement; 
    const {selectionEnd, selectionStart, value} = el; 

    // replace text selection (or insert if no selection) with new line character.
    const newValue = `${value.substr(0, selectionStart!)}\n${value.substr(selectionEnd!, value.length)}`;
    
    // update value on DOM element immediately and restore key cursor to correct position
    el.value = newValue;

    setSelectionRange(el, selectionStart! + 1, selectionStart! + 1);

    // return the updated string so the component state can be updated.
    return newValue;
}

export function getFullName(user: UserProfile) {
    if (user.first_name && user.last_name) {
        return user.first_name + ' ' + user.last_name;
    } else if (user.first_name) {
        return user.first_name;
    } else if (user.last_name) {
        return user.last_name;
    }

    return '';
}

export function getDisplayName(user: UserProfile) {
    if (user.nickname && user.nickname.trim().length > 0) {
        return user.nickname;
    }

    const fullName = getFullName(user);

    if (fullName) {
        return fullName;
    }

    return user.username;
}

export function getLongDisplayName(user: UserProfile) {
    let displayName = '@' + user.username;
    const fullName = getFullName(user);

    if (fullName) {
        displayName = displayName + ' - ' + fullName;
    }

    if (user.nickname && user.nickname.trim().length > 0) {
        displayName = displayName + ' (' + user.nickname + ')';
    }

    if (user.position && user.position.trim().length > 0) {
        displayName = displayName + ' -' + user.position;
    }

    return displayName;
}

export function getLongDisplayNameParts(user: UserProfile) {
    return {
        displayName: '@' + user.username, 
        fullName: getFullName(user), 
        nickname: user.nickname && user.nickname.trim() ? user.nickname : null, 
        position: user.position && user.position.trim() ?  user.position : null,
    };
}

/**
 * Get the display name of the specified user, respecting the WspaceMateDisplay configuration setting.
 * 
 */
export function getDisplayNameByUser(state: GlobalState, user?: UserProfile) {
    const wspaceMateNameDisplay = getWspaceMateNameDisplaySetting(state);
    if (user) {
        return displayUsername(user, wspaceMateNameDisplay);
    }

    return '';
}

/**
 * Gets the entire name, including username, fullname, and nick name, of the specified user.
 */
export function displayEntireNameForUser(user: UserProfile) {
    if (!user) {
        return '';
    }

    let displayName: React.ReactNode = '';
    const fullName = getFullName(user);

    if (fullName) {
        displayName = ' - ' + fullName;
    }

    if (user.nickname) {
        displayName = displayName + ' (' + user.nickname + ')';
    }

    if (user.position) {
        displayName = displayName + ' - ' + user.position;
    }

    displayName = (
        <span id={'displayedUserName' + user.username}>
            {'@' + user.username}
            <span className='light'>{displayName}</span>
        </span>
    );

    return displayName;
}

export function setCSRFFromCookie() {
    if (typeof document !== 'undefined' && typeof document.cookie !== 'undefined') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('SFCSRF=')) {
                ClientSf.setCSRF(cookie.replace('SFCSRF=', ""));
                break;
            }
        }
    }
}

export function isMobile() {
    return getIsMobileView(store.getState());
}

/**
 * Returns true if in dev mode, false otherwise.
 */
export function isDevMode(state = store.getState()) {
    const config = getConfig(state);
   // return config.EnableDeveloper === 'true';
   return false;
}

export function localizeMessage(id: string, defaultMessage?: string) {
    const state = store.getState();

    const locale = getCurrentLocale(state);
    const translations = getTranslations(state, locale);

    if (!translations || !(id in translations)) {
        return defaultMessage || id;
    }

    return translations[id];
}

export function localizeAndFormatMessage(id: string, defaultMessage: string, template: {[name: string]: any} | undefined) {
    const base = localizeMessage(id, defaultMessage);

    if (!template) {
        return base;
    }

    return base.replace(/{[\w]+}/g, (match) => {
        const key = match.substr(1, match.length - 2);
        return template[key] || match;
    });
}

export function mod(a: number, b: number): number {
    return ((a % b) + b ) % b;
}

export function isValidUsername(name: string) {
    let error;

    if (!name) {
        error = {
            id: ValidationErrors.USERNAME_REQUIRED,
        };
    } else if (name.length < Constants.MIN_USERNAME_LENGTH || name.length > Constants.MAX_USERNAME_LENGTH) {
        error = {
            id: ValidationErrors.INVALID_LENGTH,
        };
    } else if (!(/^[a-z0-9.\-_]+$/).test(name)) {
        error = {
            id: ValidationErrors.INVALID_CHARACTERS,
        };
    } else if (!(/[a-z]/).test(name.charAt(0))) {
        error = {
            id: ValidationErrors.INVALID_FIRST_CHARACTER,
        };
    } else {
        for (const reserved of Constants.RESERVED_USERNAMES) {
            if (name === reserved) {
                error = {
                    id: ValidationErrors.RESERVED_NAME,
                };
                break;
            }
        }
    }
    return error;
}


export function getPasswordConfig(config: Partial<ClientConfig>) {
    return {
        minimumLength: parseInt(config.PasswordMinimumLength!, 10),
        requireLowercase: config.PasswordRequireLowercase === 'true',
        requireUppercase: config.PasswordRequireUppercase === 'true',
        requireNumber: config.PasswordRequireNumber === 'true',
        requireSymbol: config.PasswordRequireSymbol === 'true',
    };
}

export function isValidPassword(password: string, passwordConfig: ReturnType<typeof getPasswordConfig>, intl?: IntlShape) {
    let errorId = t('user.settings.security.passwordError');
    let valid = true;

    const minimumLength = passwordConfig.minimumLength || Constants.MIN_PASSWORD_LENGTH;

    if (password.length < minimumLength || password.length > Constants.MAX_PASSWORD_LENGTH) {
        valid = false;
    }

    if (passwordConfig.requireLowercase) {
        if (!password.match(/[a-z]/)) {
            valid = false;
        }
        errorId += 'Lowercase';
    }

    if (passwordConfig.requireUppercase) {
        if (!password.match(/[A-Z]/)) {
            valid = false;
        }

        errorId += 'Uppercase';
    }

    if (passwordConfig.requireNumber) {
        if (!password.match(/[0-9]/)) {
            valid = false;
        }

        errorId += 'Number';
    }

    if (passwordConfig.requireSymbol) {
        if (!password.match(/[ !"\\#$%&'()*+,-./:;<=>?@[\]^_`|~]/)) {
            valid = false;
        }
        errorId += 'Symbol';
    }

    let error;
    if (!valid) {
        error = intl ? (
            intl.formatMessage(
                {
                    id: errorId, 
                    defaultMessage: 'Must be {min}-{max} characters long.',
                },
                {
                    min: minimumLength,
                    max: Constants.MAX_PASSWORD_LENGTH,
                },
            )
        ) : (
            <FormattedMessage 
            id={errorId}
            defaultMessage='Your password must contain between  {min} and {max} characters.'
            values={{
                min: minimumLength,
                max:Constants.MAX_PASSWORD_LENGTH,
            }}
            />
        );
    }

    return {valid, error};
}

export function getTopicURL(state: GlobalState, topic: Topic): string {
    let notificationURL;
    if (topic && (topic.type === Constants.DM_TOPIC || topic.type === Constants.GM_TOPIC)) {
        notificationURL = '/mytopics/' + topic.name;
    } else if (topic) {
        notificationURL = '/mytopics/' + topic.name;
    } else {
        const redirectTopic = getRedirectTopicNameForUser(state);
        notificationURL = `/mytopics/${redirectTopic}`;
    }

    return notificationURL;
}


export function hasSoundOptions() {
    return (!UserAgent.isEdge());
}

export function getDateForUnixTicks(ticks: number): Date {
    return new Date(ticks);
}

// returns unix timestamp in milliseconds.
export function getTimestamp(): number {
    return Date.now();
}

export function getRemainingDaysFromFutureTimestamp(timestamp?: number): number {
    const MS_PER_DAY = 24 * 60 * 60 * 1000;
    const futureDate = new Date(timestamp as number);
    const utcFuture = Date.UTC(futureDate.getFullYear(), futureDate.getMonth(), futureDate.getDate());
    const today = new Date();
    const utcToday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());

    return Math.floor((utcFuture - utcToday)/ MS_PER_DAY);
}


export function replaceHtmlEntities(text: string) {
    const tagsToReplace = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
    };

    let newtext = text;
    Object.entries(tagsToReplace).forEach(([tag, replacement]) => {
        const regex = new RegExp(tag, 'g');
        newtext = newtext.replace(regex, replacement);
    });

    return newtext;
}


export function isGIFImage(extin: string): boolean {
    return extin.toLowerCase() === Constants.IMAGE_TYPE_GIF;
}


const  removeQueryStringOrHash = (extin: string): string => {
    return extin.split(/[?#]/)[0];
}

export const getFileType = (extin: string): typeof FileTypes[keyof typeof FileTypes] => {
    const ext = removeQueryStringOrHash(extin.toLowerCase());

    if (Constants.TEXT_TYPES.indexOf(ext) > -1) {
        return FileTypes.TEXT;
    }

    if (Constants.IMAGE_TYPES.indexOf(ext) > -1) {
        return FileTypes.IMAGE;
    }

    if (Constants.AUDIO_TYPES.indexOf(ext) > -1) {
        return FileTypes.AUDIO;
    }

    if (Constants.VIDEO_TYPES.indexOf(ext) > -1) {
        return FileTypes.VIDEO;
    }

    if (Constants.SPREADSHEET_TYPES.indexOf(ext) > -1) {
        return FileTypes.SPREADSHEET;
    }

    if (Constants.CODE_TYPES.indexOf(ext) > -1) {
        return FileTypes.CODE;
    }

    if (Constants.WORD_TYPES.indexOf(ext) > -1) {
        return FileTypes.WORD;
    }

    if (Constants.PRESENTATION_TYPES.indexOf(ext) > -1) {
        return FileTypes.PRESENTATION;
    }

    if (Constants.PDF_TYPES.indexOf(ext) > -1) {
        return FileTypes.PDF;
    }

    if (Constants.PATCH_TYPES.indexOf(ext) > -1) {
        return FileTypes.PATCH;
    }

    if (Constants.SVG_TYPES.indexOf(ext) > -1) {
        return FileTypes.SVG;
    }

    // Container types -docker, kata, operator container
    //TSX/JSX type (code with website pages)

    return FileTypes.OTHER;

};


export function getFileIconPath(fileInfo: FileInfo) {
    const fileType = getFileType(fileInfo.extension) as keyof typeof Constants.ICON_FROM_TYPE;

    let icon;
    if (fileType in Constants.ICON_FROM_TYPE) {
        icon = Constants.ICON_FROM_TYPE[fileType];
    } else {
        icon = Constants.ICON_FROM_TYPE.other;
    }

    return icon;
}

export function getIconClassName(fileTypeIn: string) {
    const fileType = fileTypeIn.toLowerCase() as keyof typeof Constants.ICON_FROM_TYPE;

    if (fileType in Constants.ICON_NAME_FROM_TYPE) {
        return Constants.ICON_NAME_FROM_TYPE[fileType];
    }

    return 'generic';
}

export function getMenuItemIcon(name: string, dangerous?: boolean) {
    const colorClass = dangerous ? 'MenuItem__compass-icon-dangerous' : 'MenuItem__compass-icon';

    return (
        <span className={`${name} ${colorClass}`}></span>
    );
}

export function toTitleCase(str: string): string {
    function doTitleCase(txt: string) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }

    return str.replace(/\w\S*/g, doTitleCase);
}

// Converts a file size in bytes into a human-readable string  of form '123MB'.
export function fileSizeToString(bytes: number) {
    // It's unlikely that we'll have files bigger than this.
    if (bytes > 1024 ** 4) {
        // Check if file is smaller than 10 to display functions.
        if (bytes < (1024 ** 4) * 10) {
            return (Math.round((bytes / (1024 ** 4)) * 10) /10) + 'TB';
        }

        return Math.round(bytes / (1024 ** 4)) + 'TB';
    } else if (bytes > 1024 ** 3) {
        if (bytes < (1024 ** 3) * 10) {
            return (Math.round((bytes / 1024 **3) * 10)/10) + 'GB';
        }

        return Math.round(bytes / (1024 ** 3)) + 'GB';
    } else if (bytes > 1024 ** 2) {
        if (bytes < (1024 ** 2) * 10) {
            return (Math.round((bytes / 1024 ** 2) * 10)/10) + 'MB';
        }

        return Math.round(bytes / (1024 ** 2)) + 'MB';
    } else if (bytes > 1024) {
        return Math.round(bytes / 1024) + 'KB';
    }

    return bytes + 'B';
}

export function loadImage(
    url: string, 
    onLoad: ((this: XMLHttpRequest, ev: ProgressEvent) => any) | null, 
    onProgress?: (completedPercentage: number) => any | null, 
) {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'arraybuffer';
    request.onload = onLoad;
    request.onprogress = (e) => {
        if (onProgress) {
            let total = 0;
            if (e.lengthComputable) {
                total = e.total;
            } else {
                total = parseInt((e.target as any).getResponseHeader('X-Uncompressed-Content-Length'), 10);
            }

            const completedPercentage = Math.round((e.loaded / total) * 100);

            (onProgress as any)(completedPercentage);
        }
    };
    request.send();
}



// generates RFC-4122 version 4 compliant globally unique identifier.
export function generateId() {
    let id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

    id = id.replace(/[xy]/g, (c) => {
        const r = Math.floor(Math.random() * 16);

        let v;
        if (c === 'x') {
            v = r;
        } else {
            v = (r & 0x3) | 0x8;
        }

        return v.toString(16);
    });

    return id;
}

export function isEmptyObject(object: any) {
    if (!object) {
        return true;
    }

    if (Object.keys(object).length === 0) {
        return true;
    }

    return false;
}

export function removePrefixFromLocalStorage(prefix: string) {
    const keys = [];
    for (let i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i)!.startsWith(prefix)) {
            keys.push(localStorage.key(i));
        }
    }

    for (let i = 0; i < keys.length; i++) {
        localStorage.removeItem(keys[i]!);
    }
}

export function getDirectTopicName(id: string, otherId: string): string {
    let handle;

    if (otherId > id) {
        handle = id +'__' + otherId;
    } else {
        handle = otherId + '__' + id;
    }

    return handle;
}

// Used to get the id of the other user from a DM topic.
export function getUserIdFromTopicName(topic: Topic) {
    return getUserIdFromTopicId(topic.name);
}

// Used to get the id of the otehr user from a DM topic id (id1__id2)
export function getUserIdFromTopicId(topicId: Topic['id'], currentUserId = getCurrentUserId(store.getState())) {
    const ids = topicId.split('__');
    let otherUserId = '';
    if (ids[0] === currentUserId) {
        otherUserId = ids[1];
    } else {
        otherUserId = ids[0];
    }

    return otherUserId;
}

export function imageURLForUser(userId: UserProfile['id'], orgId: Org['id'] | 'own', lastPictureUpdate = 0) {
   // return ClientSf.getUsersRoute() +'/' + orgId + '/ids' + userId + '/image?_='+lastPictureUpdate
   return ClientSf.getXProfileRoute(orgId, userId) + '/image?_='+lastPictureUpdate
}

export function defaultImageURLForUser(userId: UserProfile['id'], orgId: Org['id'] | 'own') {
   // return ClientSf.getUsersRoute() + '/' + orgId + '/ids' + userId + '/image/default';
   return ClientSf.getXProfileRoute(orgId, userId) + '/image/default';
}

export function imageURLForWorkspace(workspace: Workspace & {last_workspace_icon_update?: number}) {
    return workspace.last_workspace_icon_update ? ClientSf.getWorkspaceIconUrl(workspace.id, workspace.org_id, workspace.last_workspace_icon_update): null;
}


/*
export function imageURLForOrg(org: Org & {last_org_icon_update?: number}) {
    return workspace.last_workspace_icon_update ? ClientSf.getWorkspaceIconUrl(workspace.id, workspace.org_id, workspace.last_workspace_icon_update): null;
}

*/




export function copyToClipboard(data: string) {
    // Attempt to use the newer clipboard API when possible.
    const clipboard = navigator.clipboard;
    if (clipboard) {
        clipboard.writeText(data);
        return;
    }

    // Creates a tiny temporary text area to copy text out of.
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = data;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy')
    document.body.removeChild(textArea);
}

export async function handleFormattedTextClick(e: React.MouseEvent, currentRelativeTopicUrl = '') {
    const hashtagAttribute = (e.target as any).getAttributeNode('data-hashtag');
    const linkAttribute = (e.target as any).getAttributeNode('data-link');
    const topicMentionAttribute = (e.target as any).getAttributeNode('data-topic-mention');

    if (hashtagAttribute) {
        e.preventDefault();
        store.dispatch(searchForTerm(hashtagAttribute.value));
    } else if (linkAttribute) {
        const MIDDLE_MOUSE_BUTTON = 1;

        if (!(e.button === MIDDLE_MOUSE_BUTTON || e.altKey || e.ctrlKey || e.metaKey || e.shiftKey)) {
            e.preventDefault();

            const state = store.getState();
            const user = getCurrentUser(state);
            const match = isTopicOrPermalink(linkAttribute.value);

            let isReply = true;

        } 
    } else if (topicMentionAttribute) {
        e.preventDefault();
        getHistory().push(currentRelativeTopicUrl + '/topics/' + topicMentionAttribute.value);
    }

}

export function fillRecord<T>(value: T, length: number): Record<number, T> {
    const arr: Record<number, T> = {};

    for (let i = 0; i < length; i++) {
        arr[i] = value;
    }

    return arr;
}

// Checks if data transfer contains files not text, forlders etc..
// Slightly modified from stackoverflow.com/questions
export function isFileTransfer(files: DataTransfer) {
    if (UserAgent.isInternetExplorer() || UserAgent.isEdge()) {
        return files.types != null && files.types.includes('Files');
    }

    return files.types != null && (files.types.indexOf ? files.types.indexOf('Files') !== -1 : files.types.includes('application/x-moz-file'));
}

export function isUriDrop(dataTransfer: DataTransfer) {
    if (UserAgent.isInternetExplorer() || UserAgent.isEdge() || UserAgent.isSafari()) {
        for (let i = 0; i < dataTransfer.items.length; i++) {
            if (dataTransfer.items[i].type === 'text/uri-list') {
                return true;
            }
        }
    }

    return false; // We don't care about otehrs, they handle as they want it.
}

export function isTextTransfer(dataTransfer: DataTransfer) {
    return ['text/plain', 'text/unicode', 'Text'].some((type) =>  dataTransfer.types.includes(type));
}

export function isTextDroppableEvent(e: Event) {
    return (e instanceof DragEvent) && 
    (e.target instanceof HTMLTextAreaElement || e.target instanceof HTMLInputElement) &&
    e.dataTransfer !== null && 
    isTextTransfer(e.dataTransfer);
}

export function clearFileInput(elm: HTMLInputElement) {
    // clear file input for all modern browsers.
    try {
        elm.value = '';
        if (elm.value) {
            elm.type = 'text';
            elm.type = 'file';
        }
    } catch (e) {
        // Do nothing
    }
}

export function isPostEphemeral(post: Post) {
    return post.type === Constants.PostTypes.EPHEMERAL || post.state === Posts.POST_DELETED;
}

export function getRootId(post: Post) {
    return post.root_id === '' ? post.id : post.root_id;
}

export function getRootPost(postList: Post[]) {
    return postList.find((post) => post.root_id === '');
}

export const REACTION_PATTERN = /^(\+|-):([^:\s]+):\s*$/;



export function placeCaretAtEnd(el: HTMLInputElement | HTMLTextAreaElement) {
    el.focus();
    el.selectionStart = el.value.length;
    el.selectionEnd = el.value.length;
}

export function scrollToCaret(el: HTMLInputElement | HTMLTextAreaElement) {
    el.scrollTop =  el.scrollHeight;
}


export function setSelectionRange(input: HTMLInputElement | HTMLTextAreaElement, selectionStart: number, selectionEnd: number) {
    input.focus();
    input.setSelectionRange(selectionStart, selectionEnd);
}

export function setCaretPosition(input: HTMLInputElement | HTMLTextAreaElement, pos: number) {
    if (!input) {
        return;
    }

    setSelectionRange(input, pos, pos);
}

export function scrollbarWidth(el: HTMLElement) {
    return el.offsetWidth - el.clientWidth;
}

/**
 * Adjust selection to correct text when there is  Italic Markdown(_)
 * 
 */
export function adjustSelection(inputBox: HTMLInputElement, e: React.SyntheticEvent<TextboxElement>) {
    const el = e.target as TextboxElement;
    const {selectionEnd, selectionStart, value} = el;

    if (selectionStart === selectionEnd) {
        // nothing selected
        return;
    }

    e.preventDefault();

    const firstUnderscore = value.charAt(selectionStart!) === '_';
    const lastUnderscore = value.charAt(selectionEnd! -1) === '_';

    const spaceBefore = value.charAt(selectionStart! - 1) === ' ';
    const spaceAfter = value.charAt(selectionEnd!) === ' ';

    if (firstUnderscore && lastUnderscore && (spaceBefore || spaceAfter)) {
        setSelectionRange(inputBox, selectionStart! + 1, selectionEnd! - 1);
    }
}


export function deleteKeysFromObject(value: Record<string, any>, keys: string[]) {
    for (const key of keys) {
        delete value[key];
    }
    return value;
}

const TrackFlowRoles: Record<string, string> = {
    fa: Constants.FIRST_ADMIN_ROLE,
    sa: General.SYSTEM_ADMIN_ROLE, 
    su: General.SYSTEM_USER_ROLE,
};


export function getTrackFlowRole() {
    const state = store.getState();
    let trackFlowRole = 'su';

    if (isFirstAdmin(state)) {
        trackFlowRole = 'fa';
    } else if (isSystemAdmin(getCurrentUser(state).roles)) {
        trackFlowRole = 'sa';
    }

    return trackFlowRole;
}

export function getRoleForTrackFlow() {
    const startedByRole = TrackFlowRoles[getTrackFlowRole()];

    return {started_by_role: startedByRole};
}

export function getRoleFromTrackFlow() {
    const params = new URLSearchParams(window.location.search);
    const sbr = params.get('sbr') ?? '';
    const startedByRole = TrackFlowRoles[sbr] ?? '';

    return {started_by_role: startedByRole};
}

export function getMediumFromTrackFlow() {
    const params = new URLSearchParams(window.location.search)
    const source = params.get('md') ?? '';

    return {source};
}

const TrackFlowSources: Record<string, string> = {
    wd: 'webapp-desktop',
    wm: 'webapp-mobile',
    d: 'desktop-app',
}

function getTrackFlowSource() {
    if (isMobile()) {
        return TrackFlowSources.wm;
    } else if (isDesktopApp()) {
        return TrackFlowSources.d;
    }

    return TrackFlowSources.wd;
}

//Returns the minimal number of zeroes needed to render a number,
// upto the given number of places.
// eg.
// numberToFixedDynamic(3.12345, 4) -> 3.1234
export function numberToFixedDynamic(num: number, places: number):string {
    const str = num.toFixed(Math.max(places, 0));
    if (!str.includes('.')) {
        return str; 
    }

    let indexToExclude = -1;
    let i = str.length - 1;
    while (str[i] === '0') {
        indexToExclude = i;
        i -= 1;
    }

    if (str[i] === '.') {
        indexToExclude -= 1;
    }

    if (indexToExclude === -1) {
        return str;
    }

    return str.slice(0, indexToExclude)
}

export function getBlankAddressWithCountry(country?: string): Address {
    let c = '';
    if (country) {
        c = getName(country) || '';
    }

    return {
        city: '', 
        country: c || '', 
        line1: '', 
        line2: '', 
        postal_code: '', 
        state: '',
    };
}

export function a11yFocus(element: HTMLElement | null | undefined, keyboardOnly = true) {
    document.dispatchEvent(new CustomEvent<A11yFocusEventDetail>(
        A11yCustomEventTypes.FOCUS, {
            detail: {
                target: element, 
                keyboardOnly,
            },
        },
    ));
}

export function moveCursorToEnd(e: React.MouseEvent | React.FocusEvent) {
    const val = (e.target as any).value; 
    if (val.length) {
        (e.target as any).value = '';
        (e.target as any).value = val;
    }
}




