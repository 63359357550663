import { AnyAction, combineReducers } from 'redux';

import {
    PostTypes, 
    WorkspaceTypes, 
    UserTypes,
} from 'sigmaflow-redux/action_types';

import type { GenericAction } from 'sigmaflow-redux/types/actions';

import type { RhsState } from 'types/store/rhs';

import { ActionTypes, RHSStates } from 'utils/constants';


function selectedPostId(state = '', action: GenericAction) {
    switch (action.type) {
        case ActionTypes.SELECT_POST:
            return action.postId;
        case ActionTypes.SELECT_POST_CARD:
            return '';
        case ActionTypes.UPDATE_RHS_STATE:
            return '';
         case UserTypes.LOGOUT_SUCCESS:
            return '';
        default:
            return state;
    }
}

function selectedTopicId(state = '', action: GenericAction) {
    switch (action.type) {
        case ActionTypes.SELECT_POST:
            return action.topicId;
        case ActionTypes.SELECT_POST_CARD:
            return action.topicId;
        case ActionTypes.UPDATE_RHS_STATE:
            if ([
                RHSStates.TOPIC_FILES, 
                RHSStates.TOPIC_INFO, 
                RHSStates.TOPIC_MEMBERS, 
                RHSStates.TOPIC_ORG_MEMBERS, 
            ].includes(action.state)) {
                return action.topicId;
            }
            return '';

        case UserTypes.LOGOUT_SUCCESS:
            return '';
        default:
            return state;
    }
}

function selectedMemberOrgId(state = '', action: GenericAction) {
    switch (action.type) {
        case ActionTypes.UPDATE_RHS_STATE:
            if ([
                RHSStates.TOPIC_MEMBERS,
                // RHSStates.TOPIC_ORG_MEMBERS,
            ].includes(action.state)) {
                return action.memberOrgId || ''
            }

            return '';

        case ActionTypes.RESET_MEMBER_ORG_ID:
            return '';

        case UserTypes.LOGOUT_SUCCESS:
            return '';
        
            default: 
        return state;
    }
}

function previousRhsStates(state: any = [], action: GenericAction) {
    switch (action.type) {
        case ActionTypes.SELECT_POST:
            if (action.previousRhsState) {
                return [
                    ...state, 
                    action.previousState,
                ];
            }

            return [];

        case ActionTypes.UPDATE_RHS_STATE:
            if (action.previousRhsState) {
                return [
                    ...state, 
                    action.previousRhsState,
                ];
            }
            return [];

        case ActionTypes.RHS_GO_BACK: {
            // eslint-disable-next-line no-case-declarations
            const newState = [...state];
            newState.pop();
            return newState;
        }
        case UserTypes.LOGOUT_SUCCESS:
            return [];
        default: 
           return state;
    }
}


function rhsState(state: RhsState = null, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.UPDATE_RHS_STATE:
            return action.state;
        case ActionTypes.RHS_GO_BACK:
            return action.state;
        case ActionTypes.SELECT_POST:
            return null;
        case UserTypes.LOGOUT_SUCCESS:
            return null;
        default:
            return state;
    }
}

function isSidebarOpen(state = false, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.UPDATE_RHS_STATE:
            return Boolean(action.state);
        case ActionTypes.SELECT_POST:
            return Boolean(action.postId);
        case ActionTypes.SELECT_POST_CARD:
            return Boolean(action.postId);
        case ActionTypes.TOGGLE_RHS_MENU:
            return false;
        case ActionTypes.OPEN_RHS_MENU:
            return false;
        case ActionTypes.TOGGLE_LHS:
            return false;
        case ActionTypes.OPEN_LHS:
            return false;
        case UserTypes.LOGOUT_SUCCESS:
            return false;
        default:
            return state;
    }
}

function isSidebarExpanded(state = false, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.SET_RHS_EXPANDED:
            return action.expanded;
        case ActionTypes.TOGGLE_RHS_EXPANDED: 
            return !state;
        case ActionTypes.UPDATE_RHS_STATE: 
            return action.state ? state : false;
        case ActionTypes.TOGGLE_RHS_MENU:
            return false;
        case ActionTypes.SUPPRESS_RHS: 
            return false;
        case ActionTypes.OPEN_RHS_MENU: 
            return false;
        case ActionTypes.TOGGLE_LHS: 
            return false;
        case ActionTypes.OPEN_LHS:
            return false;
        case UserTypes.LOGOUT_SUCCESS:
            return false;
        default: 
            return state; 
    }
}

function editTopicMembers(state = false, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.SET_EDIT_TOPIC_MEMBERS:
            return action.active;
        case ActionTypes.UPDATE_RHS_STATE:
            if (!action.state) {
                return false;
            }
            return state;
        case UserTypes.LOGOUT_SUCCESS:
            return false;
        default:
            return state;
    }
}

function isMenuOpen(state = false, action: AnyAction) {
    switch (action.type) {
        case ActionTypes.TOGGLE_RHS_MENU:
            return !state;
        case ActionTypes.OPEN_RHS_MENU:
            return true;
        case ActionTypes.CLOSE_RHS_MENU:
            return false;
        case ActionTypes.TOGGLE_LHS:
            return false;
        case ActionTypes.OPEN_LHS:
            return false;
        case UserTypes.LOGOUT_SUCCESS:
            return false;
        default:
            return state;
    }
}

export default combineReducers({
    selectedPostId, 
    selectedTopicId,
    selectedMemberOrgId,
    previousRhsStates,
    rhsState,
    isSidebarOpen,
    isSidebarExpanded, 
    editTopicMembers,
    isMenuOpen,
});