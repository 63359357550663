import { createSelector } from 'reselect';

import { getCurrentUserId } from 'sigmaflow-redux/selectors/entities/common';
import { getConfig } from 'sigmaflow-redux/selectors/entities/general';
import {get} from 'sigmaflow-redux/selectors/entities/preferences';
import { CustomEmoji } from 'sigmaflow-redux/types/emojis';
import { GlobalState } from 'types/store';

import { Preferences } from 'utils/constants';
import EmojiMap from 'utils/emoji_map';

const getCustomEmojisByName = (state: GlobalState) => {
    return new Map<string, CustomEmoji>([["a", {}]]);
}

export const getEmojiMap = createSelector(
    'getEmojiMap',
    getCustomEmojisByName,
    (customEmojisByName) => {
        return new EmojiMap(customEmojisByName)
    }
);

export const getRecentEmojisNames = (state: GlobalState) => {
    return ['a', 'b'];
}

export const getShortcutReactToLastPostEmittedFrom = (state: GlobalState) => 
     // TODO: state.views.emoji.shortcutReactToLastPostEmittedFrom;
     ":test:"


      export const getOneClickReactionEmojis = createSelector(
        'getOneClickReactionEmojis',
        getEmojiMap, 
        getRecentEmojisNames, 
        (emojiMap, recentEmojis: string[]) => {
            if (recentEmojis.length === 0) {
                return [];
            }

            return (recentEmojis). 
                 map((recentEmoji) => emojiMap.get(recentEmoji)). 
                 filter(isDefined). 
                 slice(-3). 
                 reverse();
        },
      );

      function isDefined<T>(t: T | undefined): t is T {
        return Boolean(t);
      }