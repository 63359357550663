import keyMirror from "sigmaflow-redux/utils/key_mirror";

export default keyMirror({
    TOPIC_REQUEST: null, 
    TOPIC_SUCCESS: null, 
    TOPIC_FAILURE: null, 

    TOPICS_REQUEST: null, 
    TOPICS_SUCCESS: null, 
    TOPICS_FAILURE: null, 

    CREATE_TOPIC_REQUEST: null, 
    CREATE_TOPIC_SUCCESS: null, 
    CREATE_TOPIC_FAILURE: null, 

    UPDATE_TOPIC_REQUEST: null, 
    UPDATE_TOPIC_SUCCESS: null, 
    UPDATE_TOPIC_FAILURE: null, 

    DELETE_TOPIC_SUCCESS: null,
    UNARCHIVED_TOPIC_SUCCESS: null,

    GET_TOPICS_REQUEST: null, 
    GET_TOPICS_SUCCESS: null, 
    GET_TOPICS_FAILURE: null,

    GET_ALL_TOPICS_REQUEST: null, 
    GET_ALL_TOPICS_SUCCESS: null, 
    GET_ALL_TOPICS_FAILURE: null,

    GET_TOPICS_TIMEZONE_REQUEST: null, 
    GET_TOPICS_TIMEZONE_SUCCESS: null, 
    GET_TOPICS_TIMEZONE_FAILURE: null, 

    TOPIC_STATS_REQUEST: null,
    TOPIC_STATS_SUCCESS: null,
    TOPIC_STATS_FAILURE: null,

    ADD_TOPIC_MEMBER_REQUEST: null, 
    ADD_TOPIC_MEMBER_SUCCESS: null,

    REMOVE_TOPIC_MEMBER_SUCCESS: null, 

    SELECT_TOPIC: null, 
    LEAVE_TOPIC: null, 
    REMOVE_MEMBER_FROM_TOPIC: null, 
    RECEIVED_TOPIC: null, 
    RECEIVED_TOPICS: null, 
    RECEIVED_ALL_TOPICS: null, 
    RECEIVED_TOPICS_LIST: null,
    RECEIVED_MY_TOPIC_MEMBERS: null, 
    RECEIVED_MY_TOPIC_MEMBER: null, 
    RECEIVED_TOPIC_MEMBERS: null, 
    RECEIVED_TOPIC_MEMBER: null, 
    RECEIVED_TOPIC_STATS: null, 
    RECEIVED_TOPIC_PROPS: null, 
    RECEIVED_TOPIC_DELETED: null, 
    RECEIVED_TOPIC_UNARCHIVED: null, 
    UPDATE_TOPIC_HEADER: null, 
    UPDATE_TOPIC_PURPOSE: null, 
    TOPIC_MEMBER_ADDED: null, 
    TOPIC_MEMBER_REMOVED: null,

    SET_TOPIC_MUTED: null, 

    INCREMENT_TOTAL_MSG_COUNT: null, 
    INCREMENT_UNREAD_MSG_COUNT: null,
    DECREMENT_UNREAD_MSG_COUNT: null, 
    INCREMENT_UNREAD_MENTION_COUNT: null, 
    DECREMENT_UNREAD_MENTION_COUNT: null,

    UPDATED_TOPIC_SCHEME: null, 
    UPDATED_TOPIC_MEMBER_SCHEME_ROLES: null,

    RECEIVED_TOPIC_MEMBERS_MINUS_GROUP_MEMBERS: null, 
    
    RECEIVED_TOPIC_MODERATIONS: null, 

    RECEIVED_TOPIC_MEMBER_COUNTS_BY_GROUP: null, 

    RECEIVED_TOTAL_TOPIC_COUNT: null, 

    POST_UNREAD_SUCCESS: null,

    ADD_MANUALLY_UNREAD: null, 
    REMOVE_MANUALLY_UNREAD: null, 

    INCREMENT_PINNED_POST_COUNT: null, 
    DECREMENT_PINNED_POST_COUNT: null, 

    INCREMENT_FILE_COUNT: null,

    // sidebar pagination support, and future topic console (like workspace console)
    RECEIVED_TOPIC_LIST_FOR_USER: null,
    RECEIVED_TOPIC_MEMBERS_FOR_USER: null,
    RECEIVED_FILTERED_TOPICS_STATS_FOR_USER: null,
    RECEIVED_TOPIC_STATS_FOR_USER: null,
    INCREMENT_TOPIC_STATS_FOR_USER: null,
    DECREMENT_TOPIC_STATS_FOR_USER: null,


    RECEIVED_TOPIC_FOR_USER: null,
    RECEIVED_TOPICS_FOR_USER: null,
    TOPIC_NO_LONGER_VISIBLE: null, 
    RECEIVED_TOPIC_MEMBER_FOR_USER: null, 
    REMOVE_TOPIC_MEMBER: null,




});