import keyMirror from 'sigmaflow-redux/utils/key_mirror';

export default keyMirror({
    GET_ORGS_REQUEST: null,
    GET_ORGS_SUCCESS: null,
    GET_ORGS_FAILURE: null, 

    CREATE_ORG_REQUEST: null,
    CREATE_ORG_SUCCESS: null, 
    CREATE_ORG_FAILURE: null, 

    ORG_INVITE_INFO_REQUEST: null, 
    ORG_INVITE_INFO_SUCCESS: null, 
    ORG_INVITE_INFO_FAILURE: null,

    ADD_TO_ORG_FROM_INVITE_REQUEST: null, 
    ADD_TO_ORG_FROM_INVITE_SUCCESS: null,
    ADD_TO_ORG_FROM_INVITE_FAILURE: null,

    CREATED_ORG: null,
    SELECT_ORG: null,
    UPDATED_ORG: null,
    PATCHED_ORG: null,
    REGENERATED_ORG_INVITE_ID: null,
    RECEIVED_ORG: null,
    RECEIVED_MY_ORG: null,
    RECEIVED_ORGS: null,
    RECEIVED_ORG_DELETED: null,
    RECEIVED_ORG_UNARCHIVED: null,
    RECEIVED_ORGS_LIST: null,
    RECEIVED_ORGS_LIST_IN_WORKSPACE: null,
    RECEIVED_ORGS_IN_WORKSPACE: null, 
    RECEIVED_ORG_IN_WORKSPACE: null, 
   
    ORG_NO_LONGER_VISIBLE: null,
    RECEIVED_ORG_STATS: null,
    RECEIVED_FILTERED_ORG_STATS: null,
    UPDATED_ORG_SCHEME: null,

    RECEIVED_TOTAL_ORG_COUNT: null,


    RECEIVED_ORG_XWORKSPACES_STATS: null,
    RECEIVED_WORKSPACE_ORG_MEMBER_FOR_ORG: null,
    RECEIVED_WORKSPACE_ORG_MEMBERS_FOR_ORG: null,
    REMOVE_WORKSPACE_ORG_MEMBER_FOR_ORG: null,

    GET_ORG_INVITES_REQUEST: null,
    GET_ORG_INVITES_SUCCESS: null,
    GET_ORG_INVITES_FAILURE: null, 

    RECEIVED_ORG_INVITES_LIST: null,
    RECEIVED_ORG_INVITE: null,
    RECEIVED_ORG_INVITE_DELETED: null,
    RECEIVED_TOTAL_ORG_INVITES_COUNT: null,

    UPDATED_ORG_INVITE: null,
    
    
});