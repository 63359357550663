import { combineReducers } from "redux";

import type { GenericAction } from "sigmaflow-redux/types/actions";

import type { Translations } from "types/store/i18n";

import { ActionTypes } from "utils/constants";


function translations(state: Translations = {}, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.RECEIVED_TRANSLATIONS:
            return {
                ...state, 
                [action.data.locale]: action.data.translations,
            };

            default:
                return state
    }
}

export default combineReducers({
    translations,
});