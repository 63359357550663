import { combineReducers } from "redux";

import { TopicCategoryTypes, UserTypes } from "sigmaflow-redux/action_types";
import { GenericAction } from "sigmaflow-redux/types/actions";
import { TopicCategory } from "sigmaflow-redux/types/topic_categories";

import { removeItem } from "sigmaflow-redux/utils/array_utils";

import { DraggingState } from "types/store";

import { ActionTypes } from "utils/constants";

export function unreadFilterEnabled(state = false, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.SET_UNREAD_FILTER_ENABLED:
            return action.enabled;
        case UserTypes.LOGOUT_SUCCESS:
            return false;
        default:
            return state;
    }
}

export function draggingState(state: DraggingState = {}, action: GenericAction): DraggingState {
    switch (action.type) {
        case ActionTypes.SIDEBAR_DRAGGING_SET_STATE:
            return {
                state: action.data.state || state?.state, 
                type: action.data.type || state?.type,
                id: action.data.id || state?.id, 
            };

        case ActionTypes.SIDEBAR_DRAGGING_STOP:
        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

export function newCategoryIds(state: string[] = [], action: GenericAction): string[] {
    switch (action.type) {
        case ActionTypes.ADD_NEW_CATEGORY_ID:
            return [...state, action.data];
        case TopicCategoryTypes.RECEIVED_CATEGORY: {
            const category: TopicCategory = action.data;

            if (category.topic_ids.length > 0) {
                return removeItem(state, category.id);
            }

            return state;
        }

        case TopicCategoryTypes.RECEIVED_CATEGORIES: {
            const categories = action.data;

            return categories.reduce((nextState: string[], category: TopicCategory) => {
                if (category.topic_ids.length > 0) {
                    return removeItem(nextState, category.id)
                }
                return nextState;
            }, state);
        }

        case UserTypes.LOGOUT_SUCCESS:
            return [];
        default:
            return state;

    }
}

export function multiSelectedTopicIds(state: string[] = [], action: GenericAction): string[] {
    switch (action.type) {
        case ActionTypes.MULTISELECT_TOPIC:
            // Topic was not previously selected
            // now will be the only selected item.
            if (!state.includes(action.data)) {
                return [action.data];
            }

            // topi was part of a selected group
            // will now become the only selected item.
            if (state.length > 1) {
                return [action.data];
            }

            // Topic was previously selected but not in a group
            // we will clear the selection.
            return [];

        case ActionTypes.MULTISELECT_TOPIC_ADD:
            // if not selected - add it to the selected items
            if (state.indexOf(action.data) === -1) {
                return [
                    ...state, 
                    action.data,
                ];
            }

         // It was previously selected and now needs to be removed from the group.
         return removeItem(state, action.data);
        case ActionTypes.MULTISELECT_TOPIC_TO:
            return action.data;
        case ActionTypes.MULTISELECT_TOPIC_CLEAR:
            return state.length > 0 ? [] : state;
        case UserTypes.LOGOUT_SUCCESS:
            return [];
        default:
            return state;
    }
}

export function lastSelectedTopic(state = '', action: GenericAction): string {
    switch (action.type) {
        case ActionTypes.MULTISELECT_TOPIC:
        case ActionTypes.MULTISELECT_TOPIC_ADD:
            return action.data;
        case ActionTypes.MULTISELECT_TOPIC_CLEAR:
        case UserTypes.LOGOUT_SUCCESS:
            return '';
        default:
            return state;
    }
}

function firstTopicName(state = '', action: GenericAction) {
    switch (action.type) {
        case ActionTypes.FIRST_TOPIC_NAME:
            return action.data;

        case UserTypes.LOGOUT_SUCCESS:
            return '';
        default:
            return state;
    }
}

export default combineReducers({
    unreadFilterEnabled, 
    draggingState, 
    newCategoryIds, 
    multiSelectedTopicIds, 
    lastSelectedTopic, 
    firstTopicName,
});