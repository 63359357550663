import { TopicTypes, PostTypes, ThreadTypes, UserTypes, SearchTypes } from "sigmaflow-redux/action_types";
import { GenericAction } from "sigmaflow-redux/types/actions";
import { Post } from "sigmaflow-redux/types/posts";
import { ThreadsState, TopicThread } from "sigmaflow-redux/types/threads";
import { UserProfile } from "sigmaflow-redux/types/users";
import { IDMappedObjects } from "sigmaflow-redux/types/utilities";

import { threadsInTopicReducer, unreadThreadsInTopicReducer, threadsFirstLoadCompletedReducer } from "./threadsInTopic";
import { countsIncludingDirectReducer } from "./counts";


import { ExtraData } from "./types";

export const threadsReducer = (state: ThreadsState['threads'] = {}, action: GenericAction, extra: ExtraData) => {
    switch (action.type) {
        case ThreadTypes.RECEIVED_UNREAD_THREADS:
        case SearchTypes.RECEIVED_SEARCH_THREADS:
        case ThreadTypes.RECEIVED_THREADS: {
            const {threads} = action.data;
            return {
                ...state, 
                ...threads.reduce((results: IDMappedObjects<TopicThread>, thread: TopicThread) => {
                    results[thread.id] = thread;
                    return results;
                }, {}),
            };
        }
        case PostTypes.POST_REMOVED: {
            const post = action.data;

            if (post.root_id || !state[post.id]) {
                return state;
            }

            const nextState = {...state};
            Reflect.deleteProperty(nextState, post.id);

            return nextState;
        }

        case ThreadTypes.RECEIVED_NEW_THREAD: {
            const {thread} = action.data;
            return {
                ...state,
                [thread.id]: thread,
            }
        }
        

        case ThreadTypes.RECEIVED_THREAD: {
            const {thread} = action.data;
            const nextState = {...state};
             // Delete any pending thread that existed for this thread.
            if (thread.pending_thread_id && thread.id !== thread.pending_thread_id && nextState[thread.pending_thread_id]) {
                    Reflect.deleteProperty(nextState, thread.pending_thread_id);
            }
            nextState[thread.id] = thread;

            return nextState;
        }
        case ThreadTypes.READ_CHANGED_THREAD: {
            const {
                id,
                newUnreadMentions, 
                newUnreadReplies,
                lastViewedAt,
                lastReplyAt,
                replyCount,
            } = action.data;

            return {
                ...state, 
                [id]: {
                    ...(state[id] || {}),
                    last_viewed_at: lastViewedAt, 
                    unread_mentions: newUnreadMentions, 
                    unread_replies: newUnreadReplies, 
                    is_following: true, 
                    last_reply_at: lastReplyAt  || state[id].last_reply_at, 
                    reply_count: replyCount || state[id].reply_count,
                },
            };
        }
        case ThreadTypes.FOLLOW_CHANGED_THREAD: {
            const {id, following} = action.data;

            if (!state[id]) {
                return state;
            }

            return {
                ...state, 
                [id]: {
                    ...state[id],
                    is_following: following,
                },
            };
        }

        case PostTypes.RECEIVED_NEW_POST: {
            const post: Post = action.data;
            const thread: TopicThread | undefined = state[post.root_id];
            if (post.root_id && thread) {
               // const participants = thread.participants || [];
                const nextThread = {...thread};
                nextThread.last_reply_at = post.create_at;
                /*
                if (!participants.find((user: UserProfile | {id: string}) => user.id === post.user_id)) {
                    nextThread.participants = [...participants, {id: post.user_id}];
                }
                */

                /*
                if (post.reply_count) {
                    nextThread.reply_count = post.reply_count;
                }
                */
               nextThread.reply_count = nextThread.reply_count + 1;

                return {
                    ...state, 
                    [post.root_id]: nextThread,
                };
            }
            return state;
        }

        case ThreadTypes.ALL_TOPIC_THREADS_READ: {
            return Object.entries(state).reduce<ThreadsState['threads']>((newState, [id, thread]) => {
                newState[id] = {
                    ...thread, 
                    unread_mentions: 0, 
                    unread_replies: 0,
                };
                return newState;
            }, {});
        }

        case ThreadTypes.THREAD_REMOVED: {
            const thread = action.data;

            if (!state[thread.id]) {
                return state;
            }

            // Remove the post itself.
            const nextState = {...state};
            Reflect.deleteProperty(nextState, thread.id);

            return nextState;
        }


        case UserTypes.LOGOUT_SUCCESS:
            return {};
        
    }

    return state;
}

export function handlePendingThreads(state: string[] = [], action: GenericAction) {
    switch (action.type) {
        case ThreadTypes.RECEIVED_NEW_THREAD: {
            const {thread} = action.data;

            if (!thread.pending_thread_id) {
                // this is not a pending thread.
                return state;
            }

          const index = state.indexOf(thread.pending_thread_id);

          if (index !== -1) {
            // An entry already exists for this thread.
            return state;
          }

          // Add the new penidng thread ID.
          const nextState = [...state];
          nextState.push(thread.pending_thread_id);

          return nextState;
        }
        case ThreadTypes.RECEIVED_THREAD: {
            const {thread} = action.data;

            if (!thread.pending_thread_id) {
                // This isn't a pending thread.
                return state;
            }

            const index = state.indexOf(thread.pending_thread_id);

            if (index === -1) {
                // There's nothing to remove
                return state;
            }

            // The thread has actually been created, so remove the entry for it.
            const nextState = [...state];
            nextState.splice(index, 1);

            return nextState;
        }

        default:
            return state;
    }
}

const initialState = {
    threads: {},
    pendingThreadIds: [],
    threadsInTopic: {},
    unreadThreadsInTopic: {},
    threadsFirstLoadCompleted: {},
    counts: {},
    countsIncludingDirect: {},
};

// custom combineReducers function
// enables passing data between reducers
function reducer(state: ThreadsState = initialState, action: GenericAction): ThreadsState {
    const extra: ExtraData = {
        threads: state.threads,
    };

    // acting as a middleware.
    // For channel when threads are by Workspace.

    const nextState = {
        // Object mapping thread ids to thread objects
        threads: threadsReducer(state.threads, action, extra),

        // Array that contains the pending thread ids for those threads that are in
        // transition of being created.
        pendingThreadIds: handlePendingThreads(state.pendingThreadIds, action),


        // Object mapping topic ids to thread ids.
        threadsInTopic: threadsInTopicReducer(state.threadsInTopic, action, extra),

        // Object mapping topic ids to unread thread ids
        unreadThreadsInTopic: unreadThreadsInTopicReducer(state.unreadThreadsInTopic, action, extra),

    
        // object mapping topic id to boolean to checked whether user clicked the topic to load thread once.
        threadsFirstLoadCompleted: threadsFirstLoadCompletedReducer(state.threadsFirstLoadCompleted, action),

        // Object mapping topic ids to unread counts including DM/GMs.
        counts: countsIncludingDirectReducer(state.counts, action, extra),
    };

    if (state.threads === nextState.threads && 
        state.threadsInTopic === nextState.threadsInTopic && 
        state.unreadThreadsInTopic === nextState.unreadThreadsInTopic &&
        state.threadsFirstLoadCompleted === nextState.threadsFirstLoadCompleted &&
        state.counts === nextState.counts) {
            // None of the children have changed so don't even let the parent object change.
            return state;
        } 

        return nextState;
}

export default reducer;