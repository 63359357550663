import marked from 'marked';


/** A markdown renderer that converts post into plain text that we can search for mentions */
export default class MentionableRenderer extends marked.Renderer {
    public code() {
        // cod block can contains mentions.
        return '\n';
    }

    public blockquote(text: string) {
        return text + '\n';
    }

    public heading(text: string) {
        return text + '\n';
    }

    public hr() {
        return '\n';
    }

    public list(body: string) {
        return body + '\n';
    }

    public listitem(text: string) {
        return text + '\n';
    }

    public paragraph(text: string) {
        return text + '\n';
    }

    public table(header: string, body: string) {
        return header + '\n' + body;
    }

    public tablerow(content: string): string {
        return content;
    }

    public tablecell(content: string) {
        return content + '\n';
    }

    public strong(text: string) {
        return ' ' + text + ' ';
    }

    public em(text: string) {
        return ' ' + text + ' ';
    }

    public codespan() {
        return ' ';
    }

    public br() {
        return '\n';
    }

    public del(text: string) {
        return ' ' + text + ' ';
    }

    public link(href: string, title: string, text: string) {
        return ' ' + text + ' ';
    }

    public image(href: string, title: string, text: string) {
        return ' ' + text + ' ';
    }

    public text(text: string) {
        return text;
    }
}