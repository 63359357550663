import { Workspace } from "sigmaflow-redux/types/workspaces";
import { IDMappedObjects } from "sigmaflow-redux/types/utilities";
import { General } from "sigmaflow-redux/constants";

export function workspaceListToMap(workspaceList: Workspace[]): IDMappedObjects<Workspace> {
    const workspaces: Record<string, Workspace> = {};
    if (!Boolean(workspaceList) || workspaceList.length === 0) {
        return workspaces;
    }
    for (let i = 0; i < workspaceList.length; i++) {
        workspaces[workspaceList[i].id] = workspaceList[i];
    }

    return workspaces;
}

export function sortWorkspacesWithLocale(locale: string): (a: Workspace, b: Workspace) => number {
    return (a: Workspace, b: Workspace) => {
        if (a.display_name !== b.display_name) {
            return a.display_name.toLowerCase().localeCompare(b.display_name.toLowerCase(), locale || General.DEFAULT_LOCALE, {numeric: true});
        }

        return a.name.toLowerCase().localeCompare(b.name.toLowerCase(), locale || General.DEFAULT_LOCALE, {numeric: true});
    };
}


export function filterWorkspacesStartingWithTerm(workspaces: Workspace[], term: string): Workspace[] {
    const lowercasedTerm = term.toLowerCase();

    return workspaces.filter((workspace: Workspace) => {
        if (!workspace) {
            return false;
        }
        const name = workspace.name?.toLowerCase();
        const displayName = workspace.display_name?.toLowerCase();

        return name.startsWith(lowercasedTerm) || 
        displayName.startsWith(lowercasedTerm);
    });
}

export function sortByWorkspaceName(a: Workspace, b: Workspace): number {
    const nameA = a.name;
    const nameB = b.name;

    return nameA.localeCompare(nameB);
}