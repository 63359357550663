import isEqual from 'lodash/isEqual';
import { combineReducers } from "redux";

import { OrgTypes, UserTypes, WorkspaceTypes } from "sigmaflow-redux/action_types";
import {orgInviteListToMap, orgListToMap, removeOrgFromList} from 'sigmaflow-redux/utils/org_utils';
import { Org, OrgInvite } from "sigmaflow-redux/types/orgs";
import { RelationOneToOne, IDMappedObjects, RelationOneToMany } from "sigmaflow-redux/types/utilities";
import { GenericAction } from "sigmaflow-redux/types/actions";
import { Workspace, WorkspaceOrgMembership } from 'sigmaflow-redux/types/workspaces';
import users from './users';
import { userInfo } from 'os';
import { UserProfile } from 'sigmaflow-redux/types/users';


function orgListToSet(state: RelationOneToMany<Workspace, Org>, action: GenericAction, replace = false) {
    const id = action.id;
    const orgs: Org[] = action.data || [];

    if (replace) {
        return {
            ...state, 
            [id]: new Set(orgs.map((org) => org.id)),
        };
    }

    return orgs.reduce((nextState, org) => addOrgToSet(nextState, id, org.id), state);
}

function addOrgToSet(state: RelationOneToMany<Workspace, Org>, id: string, orgId: string) {
    if (state[id]) {
        // The type definition for this function expect state[id] to be an array, but we
        // seem to use Sets, so handle both of those just in case.
        if (Array.isArray(state[id]) && state[id].includes(orgId)) {
            return state;
        } else if (!Array.isArray(state[id]) && (state[id] as unknown as Set<string>).has(orgId)) {
            return state;
        }
    }

    const nextSet = new Set(state[id]);
    nextSet.add(orgId);
    return {
        ...state, 
        [id]: nextSet,
    } as RelationOneToMany<Workspace, Org>;
}

function removeOrgFromWorkspaces(state: RelationOneToMany<Workspace, Org>, action: GenericAction) {
    const newState = {...state};
    let removed = false;
    Object.keys(state).forEach((key) => {
        if (newState[key][action.data.member_org_id]) {
            delete newState[key][action.data.member_org_id];
            removed = true;
        }
    });

    return removed ? newState : state;
}

function currentOrgId(state = '', action: GenericAction) {
    switch (action.type) {
        case OrgTypes.RECEIVED_MY_ORG:{
            const data = action.data || action.payload;
            return data.id;
        }
           

        case UserTypes.LOGOUT_SUCCESS:
            return '';
        default:
            return state;
    }
}

function orgs(state: IDMappedObjects<Org> ={}, action: GenericAction) {
    switch (action.type) {
        case OrgTypes.RECEIVED_ORGS_LIST:
            return Object.assign({}, state, orgListToMap(action.data));
        case UserTypes.LOGIN:
             return Object.assign({}, state, orgListToMap(action.data.orgs));
        case OrgTypes.RECEIVED_ORGS:
            return Object.assign({}, state, action.data);
        case OrgTypes.CREATED_ORG:
        case OrgTypes.UPDATED_ORG:
        case OrgTypes.PATCHED_ORG:
        case OrgTypes.REGENERATED_ORG_INVITE_ID:
        case OrgTypes.RECEIVED_ORG:
       
            return {
                ...state,
                [action.data.id]: action.data,
            };
        case OrgTypes.RECEIVED_MY_ORG:
            const data = action.data || action.paylaod;
            const org = {...data};
            const oldOrg = state[data.id];
            if (oldOrg) {
                if (isEqual(org, oldOrg)) {
                    return state;
                }
            }
            return {
                ...state,
                [data.id]: action.data,
            };

        case OrgTypes.RECEIVED_ORG_DELETED: {
            const nextState = {...state};
            const orgId = action.data.id;
            if (nextState.hasOwnProperty(orgId)) {
                Reflect.deleteProperty(nextState, orgId);
                return nextState;
            }

            return state;
        }

       case UserTypes.LOGOUT_SUCCESS:
           return {};

        default:
            return state;
    }
}



function stats(state: any = {}, action: GenericAction) {

    switch (action.type) {
        case OrgTypes.RECEIVED_ORG_STATS: {
            const stat = action.data;
            return {
                ...state,
                [state.org_id]: stat,
            };
        }
        case OrgTypes.RECEIVED_ORG_DELETED: {
            const nextState = {...state};
            const orgId = action.data.id;
            if (nextState.hasOwnProperty(orgId)) {
                Reflect.deleteProperty(nextState, orgId);
                return nextState;
            }

            return state;
        }
        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

function filteredStats(state = {}, action: GenericAction) {
    switch (action.type) {
        case OrgTypes.RECEIVED_FILTERED_ORG_STATS: {
            const stat = action.data;
            return {
                ...state, 
                ...stat, 
            };
        }

        default:
            return state;
    }
}

function totalCount(state = 0, action: GenericAction) {
    switch (action.type) {
        case OrgTypes.RECEIVED_TOTAL_ORG_COUNT: {
            return action.data;
        }
        default:
            return state;
    }
}

function orgsInWorkspace(state: RelationOneToMany<Workspace, Org> = {}, action: GenericAction) {
    switch (action.type) {
        case OrgTypes.RECEIVED_ORG_IN_WORKSPACE:
            return addOrgToSet(state, action.data.id, action.data.member_org_id)
        case OrgTypes.RECEIVED_ORGS_LIST_IN_WORKSPACE:
            return orgListToSet(state, action);
        
        case OrgTypes.RECEIVED_ORGS_IN_WORKSPACE: 
            return orgListToSet(state, action);
        
        case OrgTypes.ORG_NO_LONGER_VISIBLE:
            return removeOrgFromWorkspaces(state, action); 
            
        case UserTypes.LOGOUT_SUCCESS:
            return {};

        default: 
           return state;
    }
}

function xworkspaceOrgMembersForOrg(state: RelationOneToOne<Org, RelationOneToOne<Workspace, WorkspaceOrgMembership>> = {}, action: GenericAction) {
    switch (action.type) {
        case OrgTypes.RECEIVED_WORKSPACE_ORG_MEMBER_FOR_ORG: {
            const data = action.data;
            const members = {...(state[data.member_org_id] || {})};
            members[data.workspace_id] = data;

            return {
                ...state, 
                [data.member_org_id]: members,
            };
        }

        case OrgTypes.RECEIVED_WORKSPACE_ORG_MEMBERS_FOR_ORG: {
            const data = action.data;
            if (data && data.length) {
                const nextState = {...state};
                for (const member of data) {
                    if (nextState[member.member_org_id]) {
                        nextState[member.member_org_id] = {...nextState[member.member_org_id]};
                    } else {
                        nextState[member.member_org_id] = {};
                    }

                    nextState[member.member_org_id][member.workspace_id] = member;
                }

                return nextState;
            }

            return state;
        }

        case OrgTypes.RECEIVED_WORKSPACE_ORG_MEMBERS_FOR_ORG: {
            const data = action.data;
            if (data && data.length) {
                const orgId = data[0].member_org_id;
                const members = {...(state[orgId] || {})};
                for (const member of data) {
                    members[member.workspace_id] = member;
                }

                return {
                    ...state, 
                    [orgId]: members,
                };
            }

            return state;
        }

        case OrgTypes.REMOVE_WORKSPACE_ORG_MEMBER_FOR_ORG: {
            const data = action.data;
            const members = state[data.member_org_id];
            if (members) {
                const nextState = {...members};
                Reflect.deleteProperty(nextState, data.workspace_id);
                return {
                    ...state, 
                    [data.member_org_id]: nextState,
                };
            }

            return state;
        }

        case OrgTypes.RECEIVED_ORG_DELETED: {
            const nextState = {...state};
            const orgId = action.data.id;
            if (nextState.hasOwnProperty(orgId)) {
                Reflect.deleteProperty(nextState, orgId);
                return nextState;
            }
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

function orgInvites(state: IDMappedObjects<OrgInvite> = {}, action: GenericAction) {
    switch (action.type) {
        case OrgTypes.RECEIVED_ORG_INVITES_LIST:
        case UserTypes.RECEIVED_ORG_INVITES_FOR_USER_LIST:
            return Object.assign({}, state, orgInviteListToMap(action.data))
        case OrgTypes.UPDATED_ORG_INVITE:
        case OrgTypes.RECEIVED_ORG_INVITE:
            return {
                ...state, 
                [action.data.id]: action.data,
            }
        case OrgTypes.RECEIVED_ORG_INVITE_DELETED: {
            const nextState = {...state};
            const inviteId = action.data.id;
            if (nextState.hasOwnProperty(inviteId)) {
                Reflect.deleteProperty(nextState, inviteId);
                return nextState;
            }

            return state;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};

        default:
            return state;
    }

}

function totalOrgInvitesCount(state = 0, action: GenericAction) {
    switch (action.type) {
        case OrgTypes.RECEIVED_TOTAL_ORG_INVITES_COUNT: {
            return action.data;
        }
        default: 
            return state;
    }
}

function removeOrgInviteFromUser(state: RelationOneToMany<UserProfile, OrgInvite>, action: GenericAction) {
    const newState = {...state};
    let removed = false;

    Object.keys(state).forEach((key) => {
        if (newState[key][action.data.id]) {
            delete newState[key][action.data.id];
            removed = true;
        }
    });

    return removed ? newState : state;
}

function addOrgInviteToSet(state: RelationOneToMany<UserProfile, OrgInvite>, userId: string, inviteId: string) {
    if (state[userId]) {
        // the type definition for this function expect state[id] to be an array, but we
        // seem to use Sets, so handle both of these just in case.
        if (Array.isArray(state[userId]) && state[userId].includes(inviteId)) {
            return state;
        } else if (!Array.isArray(state[userId]) && (state[userId] as unknown as Set<string>).has(inviteId)) {
            return state;
        }
    }

    const nextSet = new Set(state[userId]);
    nextSet.add(inviteId);

    return {
        ...state, 
        [userId]: nextSet,
    } as RelationOneToMany<UserProfile, OrgInvite>;
}

function orgInvitesListToSet(state: RelationOneToMany<UserProfile, OrgInvite>, action: GenericAction, replace = false) {
    const id = action.id;
    const orgInvites: OrgInvite[] = action.data || [];

    if (replace) {
        return {
            ...state,
            [id]: new Set(orgInvites.map((orgInvite) => orgInvite.id)), 
        };
    }

    return orgInvites.reduce((nextState, orgInvite) => addOrgInviteToSet(nextState, id, orgInvite.id), state);
}

function orgInvitesForUser(state: RelationOneToMany<UserProfile, OrgInvite> = {}, action: GenericAction) {
    switch (action.type) {
        
        case OrgTypes.RECEIVED_ORG_INVITE:
            return addOrgInviteToSet(state, action.data.recipient_id, action.data.id);
            
        case UserTypes.RECEIVED_ORG_INVITES_FOR_USER_LIST:
            return orgInvitesListToSet(state, action, true);
        case UserTypes.LOGOUT_SUCCESS:
            return {};
            
        case OrgTypes.RECEIVED_ORG_INVITE_DELETED:
            return removeOrgInviteFromUser(state, action);
            

        default:
            return state;
    }
}

function orgInvitesCountForUser(state = 0, action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_TOTAL_ORG_INVITES_COUNT: {
            return action.data;
        }
        default: 
            return state;
    }
}

export default combineReducers({
    // the current selected Org
    currentOrgId, 

    // Object where every key is the org id and has an object with the org details.
    orgs,

    xworkspaceOrgMembersForOrg,

    orgsInWorkspace,

    // object where every key is the org id and has an object with the org stats
    stats,

    filteredStats,

    totalCount,

    orgInvites, 

    orgInvitesForUser,

    totalOrgInvitesCount,

    orgInvitesCountForUser,
});