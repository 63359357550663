import { Org, OrgInvite } from "sigmaflow-redux/types/orgs";
import { IDMappedObjects } from "sigmaflow-redux/types/utilities";
import { General } from "sigmaflow-redux/constants";

export function orgListToMap(orgList: Org[]): IDMappedObjects<Org> {
    const orgs: Record<string, Org> = {};
    for (let i = 0; i < orgList.length; i++) {
        orgs[orgList[i].id] = orgList[i];
    }

    return orgs;
}

export function orgInviteListToMap(orgInviteList: OrgInvite[]): IDMappedObjects<OrgInvite> {
    const orgInvites: Record<string, OrgInvite> = {};
    if (!orgInviteList) {
        return orgInvites;
    }
    for (let i = 0; i < orgInviteList.length; i++) {
        orgInvites[orgInviteList[i].id] = orgInviteList[i];
    }

    return orgInvites;
}


export function filterOrgsStartingWithTerm(orgs: Org[], term: string): Org[] {
    const lowerCasedTerm = term.toLowerCase();

    return orgs.filter((org: Org) => {
        if (!org) {
            return false;
        }

        const name = org.domain_name?.toLowerCase();
        const displayName = org.display_name?.toLowerCase();

        return name.startsWith(lowerCasedTerm) || 
           displayName.startsWith(lowerCasedTerm);
    });
}

export function removeOrgFromList(orgId: Org['id'], list: Org[]): Org[] {
    for (let i = list.length - 1; i >= 0; i--) {
        if (list[i].id === orgId) {
            list.splice(i, 1);
            return list;
        }
    }

    return list;
}

export function sortByOrgDomain(a: Org, b: Org): number {
    const domainA = a.domain_name;
    const domainB = b.domain_name;

    return domainA.localeCompare(domainB);
}