export const NotificationLevel = {
    DEFAULT: 'default', 
    ALL: 'all',
    MENTION: 'mention',
    NONE: 'none',
};

export const MarkUnread = {
    ALL: 'all',
    MENTION: 'mention',
};