import shallowEqual from 'shallow-equals';

import * as reselect from 'reselect';


// eslint-disable-next-line @typescript-eslint/ban-types
export function memoizeResult<F extends Function>(func: F, measure: Function | undefined = undefined): F {
    let lastArgs: IArguments | null = null;
    let lastResult: any = null;

    // we reference arguments instead of spreading them for performance reasons.
    return function memoizedFunc() {
        if (!shallowEqual(lastArgs, arguments)) { // eslint-disable-line prefer-rest-params
            // eslint-disable-line prefer-rest-params
            // apply arguments instead of spareading for performance.
            const result = Reflect.apply(func, null, arguments); // eslint-disable-line prefer-rest-params
            if (!shallowEqual(lastResult, result)) {
                lastResult = result;
            }
        }

        if (measure) {
            measure();
        }

        lastArgs = arguments; // eslint-disable-line prefer-rest-params
        return lastResult;
    } as unknown as F;
}


// Use this selector when you want a shallow comparision of the arguments and you want 
// to memoize the result, try and use this only when your selector returns an array of ids.
export const createIdsSelector = reselect.createSelectorCreator(memoizeResult);

// use this selector whenyou want a shallow comparision of the arguments and you don't
// need to memoize the result.
export const createShallowSelector = reselect.createSelectorCreator(reselect.defaultMemoize, shallowEqual as any);


// isMinimumServerVersion will return true if currentVersion is equal to or higher than 
// the provided minimum verion. A non-equal major version will ignore minor and dot
// versions, and a non-equal minor version will ignore dot version.
// currentVersion is a string, e.g. 4.6.0 
// minMajorVersion, minMinorVersion, minDotVersion are integers.
export const isMinimumServerVersion = (currentVersion: string, minMajorVersion = 0, minMinorVersion = 0, minDotVersion = 0): boolean => {
    if (!currentVersion || typeof currentVersion !== 'string') {
        return false;
    }

    const split = currentVersion.split('.');

    const major = parseInt(split[0], 10);
    const minor = parseInt(split[1] || '0', 10);
    const dot = parseInt(split[2] || '0', 10);

    if (major > minMajorVersion) {
        return true;
    }

    if (major < minMajorVersion) {
        return false;
    }

    // Major version is equal, check minor.
    if (minor > minMinorVersion) {
        return true;
    }
    if (minor < minMinorVersion) {
        return false;
    }

    // Minor version is equal, check dot.
    if (dot > minDotVersion) {
        return true;
    }

    if (dot < minDotVersion) {
        return false;
    }

    // Dot version is equal
    return true;
};

// Generares a RFC-4122 version 4 compliant globally unique identifier.
export function generateId(): string {
    let id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    id = id.replace(/[xy]/g, (c) => {
        const r = Math.floor(Math.random() * 16);
        let v;

        if (c === 'x') {
            v = r;
        } else {
            // eslint-disable-next-line no-mixed-operators
            v = r & 0x3 | 0x8;
        }

        return v.toString(16);
    });

    return id;
}

export function isEmail(email: string): boolean {
    // this regex ensures
    // - at least one character that is not a space, comma, or @ symbol.
    // - followed by a single @ symbol
    // - followed by at least one character that is not a space, comma or @ symbol
    // This prevents <Outlook Style> outlooklook.style@domain.com addresses and multiple
    // comma separated addresses from being accepted
    return (/^[^ ,@]+@[^ ,@]+$/).test(email);
}