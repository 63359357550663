const values = {
    INVITE_USER: 'invite_user',
    ADD_USER_TO_ORG: 'add_user_to_org',
    ADD_USER_TO_WORKSPACE: 'add_user_to_workspace',
    
    CREATE_PUBLIC_TOPIC: 'create_public_topic',
    CREATE_PRIVATE_TOPIC: 'create_private_topic',
    MANAGE_PUBLIC_TOPIC_MEMBERS: 'manage_public_topic_members',
    MANAGE_PRIVATE_TOPIC_MEMBERS: 'manage_private_topic_members',

    ASSIGN_SYSTEM_ADMIN_ROLE: 'assign_system_admin_role',
    MANAGE_ROLES: 'manage_roles',
    MANAGE_WORKSPACE_ROLES: 'manage_workspace_roles',
    MANAGE_TOPIC_ROLES: 'manage_topic_roles',
    MANAGE_SYSTEM: 'manage_system',
    CREATE_DIRECT_TOPIC: 'create_direct_topic',
    CREATE_GROUP_TOPIC: 'create_group_topic',
    MANAGE_PUBLIC_TOPIC_PROPERTIES: 'manage_public_topic_properties',
    MANAGE_PRIVATE_TOPIC_PROPERTIES: 'manage_private_topic_properties',
    LIST_WORKSPACE_TOPICS: 'list_workspace_topics',
    JOIN_PUBLIC_TOPICS: 'join_public_topics',
    DELETE_PUBLIC_TOPIC: 'delete_public_topic',
    EDIT_OTHER_USERS: 'edit_other_users',
    READ_TOPIC: 'read_topic',
    READ_PUBLIC_TOPIC: 'read_public_topic',
    ADD_REACTION: 'add_reaction',

    CREATE_POST: 'create_post',
    CREATE_POST_PUBLIC: 'create_post_public',
    EDIT_POST: 'edit_post',
    EDIT_OTHERS_POSTS: 'edit_others_posts',
    CREATE_THREAD: 'create_thread',
    USE_TOPIC_MENTIONS: 'use_topic_mentions',

    REMOVE_USERS_FROM_WORKSPACE: 'remove_user_from_workspace', 
    CREATE_WORKSPACE: 'create_workspace',
    MANAGE_WORKSPACE: 'manage_workspace',
    VIEW_WORKSPACE: 'view_workspace', 
    CREATE_USER_ACCESS_TOKEN: 'create_user_access_token',
    READ_USER_ACCESS_TOKEN: 'read_user_access_token',
    REVOKE_USER_ACCESS_TOKEN: 'revoke_user_access_token',
    VIEW_MEMBERS: 'view_members', 
    READ_OTHER_USERS_WORKSPACES: 'read_other_users_workspaces', 
    EDIT_BRAND: 'edit_brand',
    DOWNLOAD_COMPLIANCE_EXPORT_RESULT: 'download_compliance_export_result',
    CREATE_LDAP_SYNC_JOB: 'create_ldap_sync_job',
    READ_LDAP_SYNC_JOB: 'read_ldap_sync_job',
    TEST_LDAP: 'test_ldap', 
    GET_SAML_METADATA_FROM_IDP: 'get_saml_metadata_from_idp', 
    ADD_SAML_PUBLIC_CERT: 'add_saml_public_cert',
    ADD_SAML_PRIVATE_CERT: 'add_saml_private_cert',
    ADD_SAML_IDP_CERT: 'add_saml_idp_cert',

    REMOVE_SAML_PUBLIC_CERT: 'remove_saml_public_cert',
    REMOVE_SAML_PRIVATE_CERT: 'remove_saml_private_cert',
    REMOVE_SAML_IDP_CERT: 'remove_saml_idp_cert',
    GET_SAML_CERT_STATUS: 'get_saml_cert_status',

    ADD_LDAP_PUBLIC_CERT: 'add_ldap_public_cert',
    ADD_LDAP_PRIVATE_CERT: 'add_ldap_private_cert',
    REMOVE_LDAP_PUBLIC_CERT: 'remove_ldap_public_cert',
    REMOVE_LDAP_PRIVATE_CERT: 'remove_ldap_private_cert',

    INVALIDATE_EMAIL_INVITE: 'invalidate_email_invite',
    RELOAD_CONFIG: 'reload_config',
    INVALIDATE_CACHED: 'invalidate_caches', 


    SYSCONSOLE_READ_BILLING: 'sysconsole_read_billing',
    SYSCONSOLE_WRITE_BILLING: 'sysconsole_write_billing',

    SYSCONSOLE_READ_XWORKSPACE_MANAGEMENT_XWORKSPACES: 'sysconsole_read_xworkspace_management_xworkspaces',
    SYSCONSOLE_WRITE_XWORKSPACE_MANAGEMENT_XWORKSPACES: 'sysconsole_write_xworkspace_management_xworkspaces',

    SYSCONSOLE_READ_ORGMANAGEMENT_ORGS: 'sysconsole_read_org_management_orgs',
    SYSCONSOLE_WRITE_ORGMANAGEMENT_ORGS: 'sysconsole_write_org_management_orgs',
    SYSCONSOLE_READ_USERMANAGEMENT_USERS: 'sysconsole_read_user_management_users',
    SYSCONSOLE_WRITE_USERMANAGEMENT_USERS: 'sysconsole_write_user_management_users',
    SYSCONSOLE_READ_USERMANAGEMENT_GROUPS: 'sysconsole_read_user_management_groups',
    SYSCONSOLE_WRITE_USERMANAGEMENT_GROUPS: 'sysconsole_write_user_management_groups',
    SYSCONSOLE_READ_USERMANAGEMENT_WORKSPACES: 'sysconsole_read_user_management_workspaces',
    SYSCONSOLE_WRITE_USERMANAGEMENT_WORKSPACES: 'sysconsole_write_user_management_workspaces',
    SYSCONSOLE_READ_USERMANAGEMENT_TOPICS: 'sysconsole_read_user_management_topics',
    SYSCONSOLE_WRITE_USERMANAGEMENT_TOPICS: 'sysconsole_write_user_management_topics',
    SYSCONSOLE_READ_USERMANAGEMENT_PERMISSIONS: 'sysconsole_read_user_management_permissions',
    SYSCONSOLE_WRITE_USERMANAGEMENT_PERMISSIONS: 'sysconsole_write_user_management_permissions',
    SYSCONSOLE_READ_USERMANAGEMENT_SYSTEM_ROLES: 'sysconsole_read_user_management_system_roles',
    SYSCONSOLE_WRITE_USERMANAGEMENT_SYSTEM_ROLES: 'sysconsole_write_user_management_system_roles',

    SYSCONSOLE_READ_SITE_CUSTOMIZATION: 'sysconsole_read_site_customization',
    SYSCONSOLE_WRITE_SITE_CUSTOMIZATION: 'sysconsole_write_site_customization',
    SYSCONSOLE_READ_SITE_LOCALIZATION: 'sysconsole_read_site_localization',
    SYSCONSOLE_WRITE_SITE_LOCALIZATION: 'sysconsole_write_site_localization',

    SYSCONSOLE_READ_SITE_USERS_AND_WORKSPACES: 'sysconsole_read_site_users_and_workspaces',
    SYSCONSOLE_WRITE_SITE_USERS_AND_WORKSPACES: 'sysconsole_write_site_users_and_workspaces',

    SYSCONSOLE_READ_SITE_NOTIFICATIONS: 'sysconsole_read_site_notifications',
    SYSCONSOLE_WRITE_SITE_NOTIFICATIONS: 'sysconsole_write_site_notifications',

    SYSCONSOLE_READ_SITE_ANNOUNCEMENT_BANNER: 'sysconsole_read_site_announcement_banner',
    SYSCONSOLE_WRITE_SITE_ANNOUNCEMENT_BANNER: 'sysconsole_write_site_announcement_banner',

    SYSCONSOLE_READ_AUTHENTICATION_SIGNUP: 'sysconsole_read_authentication_signup',
    SYSCONSOLE_WRITE_AUTHENTICATION_SIGNUP: 'sysconsole_write_authentication_signup',
    SYSCONSOLE_READ_AUTHENTICATION_EMAIL: 'sysconsole_read_authentication_email',
    SYSCONSOLE_WRITE_AUTHENTICATION_EMAIL: 'sysconsole_write_authentication_email',
    SYSCONSOLE_READ_AUTHENTICATION_PASSWORD: 'sysconsole_read_authentication_password',
    SYSCONSOLE_WRITE_AUTHENTICATION_PASSWORD: 'sysconsole_write_authentication_password',
    SYSCONSOLE_READ_AUTHENTICATION_MFA: 'sysconsole_read_authentication_mfa',
    SYSCONSOLE_WRITE_AUTHENTICATION_MFA: 'sysconsole_write_authentication_mfa',
    SYSCONSOLE_READ_AUTHENTICATION_LDAP: 'sysconsole_read_authentication_ldap',
    SYSCONSOLE_WRITE_AUTHENTICATION_LDAP: 'sysconsole_write_authentication_ldap',
    SYSCONSOLE_READ_AUTHENTICATION_SAML: 'sysconsole_read_authentication_saml',
    SYSCONSOLE_WRITE_AUTHENTICATION_SAML: 'sysconsole_write_authentication_saml',
    SYSCONSOLE_READ_AUTHENTICATION_OPENID: 'sysconsole_read_authentication_openid',
    SYSCONSOLE_WRITE_AUTHENTICATION_OPENID: 'sysconsole_write_authentication_openid',

    SYSCONSOLE_READ_PLUGINS: 'sysconsole_read_plugins',
    SYSCONSOLE_WRITE_PLUGINS: 'sysconsole_write_plugins',


    MANAGE_BOTS: 'manage_bots', 
    MANAGE_OTHERS_BOT: 'manage_others_bot',
    SYSCONSOLE_READ_PERMISSIONS: [] as string[],
    SYSCONSOLE_WRITE_PERMISSIONS: [] as string[],

};

values.SYSCONSOLE_READ_PERMISSIONS = [
    values.SYSCONSOLE_READ_BILLING, 
    values.SYSCONSOLE_READ_USERMANAGEMENT_USERS,
    values.SYSCONSOLE_READ_USERMANAGEMENT_WORKSPACES,
    values.SYSCONSOLE_READ_USERMANAGEMENT_TOPICS,
    values.SYSCONSOLE_READ_AUTHENTICATION_SIGNUP,
    values.SYSCONSOLE_READ_AUTHENTICATION_EMAIL,
    values.SYSCONSOLE_READ_AUTHENTICATION_PASSWORD,
    values.SYSCONSOLE_READ_AUTHENTICATION_LDAP,
    values.SYSCONSOLE_READ_AUTHENTICATION_SAML,
    values.SYSCONSOLE_READ_AUTHENTICATION_OPENID,

    
]

export default values;