import {
    AdminTypes, 
    TopicTypes, 
    UserTypes, 
   // SchemeTypes,
} from 'sigmaflow-redux/action_types';

import { General } from 'sigmaflow-redux/constants';

import { GenericAction } from 'sigmaflow-redux/types/actions';

import { Topic, TopicMessageCount, ServerTopic } from 'sigmaflow-redux/types/topics';
import { RelationOneToOne } from 'sigmaflow-redux/types/utilities';

export default function messageCounts(state: RelationOneToOne<Topic, TopicMessageCount> = {}, action: GenericAction): RelationOneToOne<Topic, TopicMessageCount> {
    switch (action.type) {
        case TopicTypes.RECEIVED_TOPIC: {
            const topic: ServerTopic = action.data;

            return updateMessageCount(state, topic);
        }

        case AdminTypes.RECEIVED_DATA_RETENTION_CUSTOM_POLICY_TOPICS: {
            const topics: ServerTopic[] = action.data.topics;

            return topics.reduce(updateMessageCount, state);
        }

        case AdminTypes.RECEIVED_DATA_RETENTION_CUSTOM_POLICY_TOPICS_SEARCH:
        case TopicTypes.RECEIVED_TOPICS:
        case TopicTypes.RECEIVED_ALL_TOPICS: {
      //  case SchemeTypes.RECEIVED_SCHEME_TOPICS: {
            const topics: ServerTopic[] = action.data;

            return topics.reduce(updateMessageCount, state);
        }

        case TopicTypes.LEAVE_TOPIC: {
            const topic: ServerTopic | undefined = action.data;
            if (!topic || topic.type !== General.OPEN_TOPIC) {
                return state;
            }
            const nextState = {...state};
            Reflect.deleteProperty(nextState, topic.id);

            return nextState;
        }

        case TopicTypes.INCREMENT_TOTAL_MSG_COUNT: {
            const topicId: string = action.data.topicId;
            const amount: number = action.data.amount || 0;
            const amountRoot: number = action.data.amountRoot || 0;

            const existing = state[topicId];

            if (!existing) {
                return state;
            }

            return {
                ...state, 
                [topicId]: {
                    root: existing.root + amountRoot,
                    total_msg: existing.total_msg + amount, 
                },
            };
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

export function updateMessageCount(state: RelationOneToOne<Topic, TopicMessageCount>, topic: ServerTopic) {
    const existing = state[topic.id];

    if (
        existing && 
        existing.root === topic.total_msg_count_root && 
        existing.total_msg === topic.total_msg_count
    ) {
        return state;
    }

    return {
        ...state, 
        [topic.id]: {
            root: topic.total_msg_count_root, 
            total: topic.total_msg_count,
        },
    };
}