import {General, Users} from '../constants';
import {MarkUnread} from 'sigmaflow-redux/constants/topics';

import { Topic, TopicMembership, TopicNotifyProps, TopicMessageCount } from 'sigmaflow-redux/types/topics';
import { Post } from 'sigmaflow-redux/types/posts';
import { UsersState, UserProfile, UserNotifyProps } from 'sigmaflow-redux/types/users';
import { GlobalState } from 'sigmaflow-redux/types/store';
import { IDMappedObjects, RelationOneToManyUnique, RelationOneToOne } from 'sigmaflow-redux/types/utilities';

import { displayUsername } from './user_utils';
import { boolean } from 'yargs';
import { isCurrentTopicMuted } from 'sigmaflow-redux/selectors/entities/topics';

const topicTypeOrder = {
    [General.OPEN_TOPIC]: 0, 
    [General.PRIVATE_TOPIC]: 1, 
    [General.DM_TOPIC]: 2, 
    [General.GM_TOPIC]: 3,
};


export function completeDirectTopicInfo(usersState: UsersState, wspaceMateNameDisplay: string, topic: Topic): Topic {
    if (isDirectTopic(topic)) {
        const wspaceMateId = getUserIdFromTopicName(usersState.currentUserId, topic.name);

        // return empty string instead of `someone` default string display_name
        return {
            ...topic, 
            display_name: displayUsername(usersState.profiles[wspaceMateId], wspaceMateNameDisplay, false),
            workspace_mate_id: wspaceMateId,
            status: usersState.statuses[wspaceMateId] || 'offline',
        };
    } else if (isGroupTopic(topic)) {
        return completeDirectGroupInfo(usersState, wspaceMateNameDisplay, topic);
    }

    return topic;
} 

export function splitRoles(roles: string): Set<string> {
    return roles ? new Set<string>(roles.split(' ')): new Set<string>([]);
}

// newCompleteDirectTopicInfo is a variant of completeDirectTopicinfo that accepts
// the minimal data required instead of depending on the entirety of state.entities.users.
// This allows the calling sleector to have fewer dependencies, reducing its need to recompute
// when memoized.
//
// Ideally, this would replace completeDirectTopicInfo altogether, but is currently 
// factored out to minimize changes while addressing a critical performance issue.
export function newCompleteDirectTopicInfo(currentUserId: string, profiles: IDMappedObjects<UserProfile>, profilesInTopic: RelationOneToManyUnique<Topic, UserProfile>, wspaceMateStatus: string, wspaceMateNameDisplay: string, topic: Topic): Topic {
    if (isDirectTopic(topic)) {
        const wspaceMateId = getUserIdFromTopicName(currentUserId, topic.name);

        // return empty string instead of `someone` default string for display_name.
        return {
            ...topic, 
            display_name: displayUsername(profiles[wspaceMateId], wspaceMateNameDisplay, false),
            workspace_mate_id: wspaceMateId, 
            status: wspaceMateStatus,
        };
    } else if (isGroupTopic(topic)) {
        return topic;
      //  return newCompleteDirectGroupInfo(currentUserId, profiles, profilesInTopic, wspaceMateNameDisplay, topic);
    }

    return topic;
}

export function completeDirectTopicDisplayName(currentUserId: string, profiles: IDMappedObjects<UserProfile>, userIdsInTopic: Set<string>, wspaceMateNameDisplay: string, topic: Topic): Topic {
    if (isDirectTopic(topic)) {
        const dmTopicClone = {...topic};
        const wspaceMateId = getUserIdFromTopicName(currentUserId, topic.name);

        return Object.assign(dmTopicClone, {display_name: displayUsername(profiles[wspaceMateId], wspaceMateNameDisplay)});
    } else if (isGroupTopic(topic) && userIdsInTopic && userIdsInTopic.size > 0) {
        const displayName = getGroupDisplayNameFromUserIds(userIdsInTopic, profiles, currentUserId, wspaceMateNameDisplay);
        return {...topic, display_name: displayName};
    }

    return topic;
}

export function cleanUpUrlable(input: string): string {
    let cleaned = input.trim().replace(/-/g, ' ').replace(/[^\w\s]/gi, '').toLowerCase().replace(/\s/g, '-');
    cleaned = cleaned.replace(/-{2,}/, '-');
    cleaned = cleaned.replace(/^-+/, '');
    cleaned = cleaned.replace(/-+$/, '');
    return cleaned;
}

export function getTopicByName(topics: IDMappedObjects<Topic>, name: string): Topic | undefined {
    return Object.values(topics).find((topic) => topic.name === name);
}

export function getDirectTopicName(id: string, otherId: string): string {
    let handle;

    if (otherId > id) {
        handle = id + '__' + otherId;
    } else {
        handle = otherId + '__' + id;
    }

    return handle;
}

export function getUserIdFromTopicName(userId: string, topicName: string): string {
    const ids = topicName.split('__');
    let otherUserId = '';
    if (ids[0] === userId) {
        otherUserId = ids[1];
    } else {
        otherUserId = ids[0];
    }

    return otherUserId;
}


export function isDirectTopic(topic: Topic): boolean {
    return topic.type === General.DM_TOPIC;
}

export function isGroupTopic(topic: Topic): boolean {
    return topic.type === General.GM_TOPIC;
}

export function getTopicIdsForWorkspace(state: GlobalState, workspaceId: string): string[] {
    const {topics} = state.entities.topics;

    return Object.keys(topics).map((key) => topics[key]).reduce((res, topic: Topic) => {
        if (topic.workspace_id === workspaceId) {
            res.push(topic.id);
        }
        return res;
    }, [] as string[]);
}

export function getGroupDisplayNameFromUserIds(userIds: Set<string>, profiles: IDMappedObjects<UserProfile>, currentUserId: string, wpaceMateNameDisplay: string, omitCurrentUser = true): string {
    const names: string[] = [];
    userIds.forEach((id) => {
        if (!(id === currentUserId &&  omitCurrentUser)) {
            names.push(displayUsername(profiles[id], wpaceMateNameDisplay))
        }
    });

    function sortUsernames(a: string, b: string) {
        const locale = getUserLocale(currentUserId, profiles);
        return a.localeCompare(b, locale, {numeric: true});
    }

    return names.sort(sortUsernames).join(', ');
}

export function isDefault(topic: Topic): boolean {
    return topic.name === General.DEFAULT_TOPIC;
}


export function completeDirectGroupInfo(usersState: UsersState, wspaceMateNameDisplay: string, topic: Topic, omitCurrentUser = true) {
    const {currentUserId, profiles, profilesInTopic} = usersState;
    const profilesIds = profilesInTopic[topic.id];
    const gm = {...topic};

    if (profilesIds) {
        gm.display_name = getGroupDisplayNameFromUserIds(profilesIds, profiles, currentUserId, wspaceMateNameDisplay, omitCurrentUser);
        return gm;
    }

    const usernames = gm.display_name.split(', ');
    const users = Object.keys(profiles).map((key) =>profiles[key]);
    const userIds: Set<string> = new Set();
    usernames.forEach((username: string) => {
        const u = users.find((p): boolean => p.username === username);
        if (u) {
            userIds.add(u.id);
        }
    });

    if (usernames.length === userIds.size) {
        gm.display_name = getGroupDisplayNameFromUserIds(userIds, profiles, currentUserId, wspaceMateNameDisplay);
        return gm;
    }

    return topic;
}


export function isOpenTopic(topic: Topic): boolean {
    return topic.type === General.OPEN_TOPIC;
}

export function sortTopicsByTypeListAndDisplayName(locale: string, typeList: string[], a: Topic, b: Topic): number {
    const idxA = typeList.indexOf(a.type);
    const idxB = typeList.indexOf(b.type);

    if (idxA === -1 && idxB !== -1) {
        return 1;
    }

    if (idxB === -1 && idxA !== -1) {
        return -1;
    }

    if (idxA !== idxB) {
        if (idxA < idxB) {
            return -1;
        }

        return 1;
    }

    const aDisplayName = filterName(a.display_name);
    const bDisplayName = filterName(b.display_name);

    if (aDisplayName !== bDisplayName) {
        return aDisplayName.toLowerCase().localeCompare(bDisplayName.toLowerCase(), locale, {numeric: true});
    }

    return a.name.toLowerCase().localeCompare(b.name.toLowerCase(), locale, {numeric: true});
}

function filterName(name: string): string {
    return name.replace(/[.,'"\/#!$%\^&\*;:{}=\-_`~{}]/g, ''); // eslint-disable-line no-useless-escape
}

export function sortTopicsByDisplayName(locale: string, a: Topic, b: Topic): number {
    // If both topics have the display_name defined
    if (a.display_name && b.display_name && a.display_name !== b.display_name) {
        return a.display_name.toLowerCase().localeCompare(b.display_name.toLowerCase(), locale, {numeric: true});
    }

    return a.name.toLowerCase().localeCompare(b.name.toLowerCase(), locale, {numeric: true});
}

export function sortTopicsByDisplayNameAndMuted(locale: string, members: RelationOneToOne<Topic, TopicMembership>, a: Topic, b: Topic): number {
    const aMember = members[a.id];
    const bMember = members[b.id];

    if (isTopicMuted(bMember) === isTopicMuted(aMember)) {
        return sortTopicsByDisplayName(locale, a, b);
    }

    if (!isTopicMuted(bMember) && isTopicMuted(aMember)) {
        return 1;
    }

    return -1;
}

export function sortTopicsByRecency(lastPosts: RelationOneToOne<Topic, Post>, a: Topic, b: Topic): number {
    let aLastPostAt = a.last_post_at;
    if (lastPosts[a.id] && lastPosts[a.id].create_at > a.last_post_at) {
        aLastPostAt = lastPosts[a.id].create_at;
    }

    let bLastPostAt = b.last_post_at;
    if (lastPosts[b.id] && lastPosts[b.id].create_at > b.last_post_at) {
        bLastPostAt = lastPosts[b.id].create_at;
    }

    return bLastPostAt - aLastPostAt;
}


export function isTopicMuted(member?: TopicMembership): boolean {
    return member?.notify_props ? (member.notify_props.mark_unread === MarkUnread.MENTION): false;
}

function getUserLocale(userId: string, profiles: IDMappedObjects<UserProfile>) {
    let locale = General.DEFAULT_LOCALE;

    if (profiles && profiles[userId] && profiles[userId].locale) {
        locale = profiles[userId].locale;
    }

    return locale;
}

export function filterTopicsMatchingTerm(topics: Topic[], term: string): Topic[] {
    const lowercasedTerm  = term.toLowerCase();

    return topics.filter((topic: Topic): boolean => {
        if (!topic) {
            return false;
        }

        const name = (topic.name || '').toLowerCase();
        const displayName = (topic.display_name || '').toLowerCase();

        return name.startsWith(lowercasedTerm) ||
         displayName.startsWith(lowercasedTerm);
    });
}

export function topicListToMap(topicList: Topic[]): IDMappedObjects<Topic> {
    const topics: Record<string, Topic> = {};
    for (let i = 0; i < topicList.length; i++) {
        topics[topicList[i].id] = topicList[i];
    }

    return topics;
}

// CalculateUnreadCount returns an object containing the number of unread mention/messages in 
// a topic and whether or not that topic would be shown as unread in sidebar.
export function calculateUnreadCount(
    messageCount: TopicMessageCount | undefined, 
    member: TopicMembership | null | undefined, 
): {showUnread: boolean; mentions: number; messages: number} {
    if (!member || !messageCount) {
        return {
            showUnread: false, 
            mentions: 0, 
            messages: 0, 
        };
    }

    let messages;
    let mentions;

    messages = messageCount.root - member.msg_count_root;
    mentions = member.mention_count_root;

    return {
        showUnread: mentions > 0 || (!isTopicMuted(member) && messages > 0),
        messages, 
        mentions,
    };

}