import { combineReducers } from "redux";

import {UserTypes} from 'sigmaflow-redux/action_types';
import type {GenericAction} from 'sigmaflow-redux/types/actions';

import {ActionTypes} from 'utils/constants';

const initialState = {
    blocked: false,
    onNavigationConfirmed: null,
    showNavigationPrompt: false,
};

function navigationBlock(state = initialState, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.SET_NAVIGATION_BLOCKED:
            return {...state, blocked: action.blocked};
        case ActionTypes.DEFER_NAVIGATION:
            const nextState = {
                ...state, 
                onNavigationConfirmed: action.onNavigationConfirmed,
                showNavigationPrompt: true,
            }

            console.log(nextState);
            return {
                ...state, 
                onNavigationConfirmed: action.onNavigationConfirmed,
                showNavigationPrompt: true,
            };

        case ActionTypes.CANCEL_NAVIGATION:
            return {
                ...state,
                onNavigationConfirmed: null,
                showNavigationPrompt: false,
            };
        case ActionTypes.CONFIRM_NAVIGATION:
            return {
                ...state, 
                blocked: false, 
                onNavigationConfirmed: null, 
                showNavigationPrompt: false,
            };
        case UserTypes.LOGOUT_SUCCESS: 
            return initialState;
        default:
            return state;
    }
}

export default combineReducers({
    navigationBlock,
})