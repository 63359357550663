import { combineReducers } from "redux";

import { GenericAction } from "sigmaflow-redux/types/actions";

import { ActionTypes } from "utils/constants";

export function isOpen(state = false, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.ADD_TOPIC_DROPDOWN_TOGGLE:
            return action.open;
        default:
            return state;
    }
}

export default combineReducers({
    isOpen,
});