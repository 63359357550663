import isEqual from 'lodash/isEqual';
import { combineReducers } from 'redux';

import {OrgTypes, TopicTypes, UserTypes} from 'sigmaflow-redux/action_types';
import { GenericAction } from 'sigmaflow-redux/types/actions';
import { OrgInvite } from 'sigmaflow-redux/types/orgs';
import { Topic } from 'sigmaflow-redux/types/topics';
import { UserAccessToken, UserProfile, UserStatus } from 'sigmaflow-redux/types/users';
import { RelationOneToMany, IDMappedObjects, RelationOneToOne, RelationOneToManyUnique  } from 'sigmaflow-redux/types/utilities';
import { Workspace } from 'sigmaflow-redux/types/workspaces';

function profilesToSet(state: RelationOneToMany<Workspace, UserProfile>, action: GenericAction) {
    const id = action.id;
    const users: UserProfile[] = Object.values(action.data);

    return users.reduce((nextState, user) => addProfileToSet(nextState, id, user.id), state);
}

function profileListToSet(state: RelationOneToMany<Workspace, UserProfile>, action: GenericAction, replace = false) {
    const id = action.id;
    const users: UserProfile[] = action.data || [];

    if (replace) {
        return {
            ...state, 
            [id]: new Set(users.map((user) => user.id)),
        };
    }

    //const kk = users.reduce((nextState, user) => addProfileToSet(nextState, id, user.id), state);

    return users.reduce((nextState, user) => addProfileToSet(nextState, id, user.id), state);
}

function removeProfileListFromSet(state: RelationOneToMany<Workspace,UserProfile>, action: GenericAction) {
    const id = action.id;
    const nextSet = new Set(state[id]);
    if (action.data) {
        action.data.forEach((profile: UserProfile) => {
            nextSet.delete(profile.id);
        });

        return {
            ...state,
            [id]: nextSet,
        };
    }

    return state;
}


function addProfileToSet(state: RelationOneToMany<Workspace, UserProfile>, id: string, userId: string) {
    if (state[id]) {
        // the type definitions for this function expect state[id] to be an array, but we
        // seem to use Sets, so handle both of these, just in case.
        if (Array.isArray(state[id]) && state[id].includes(userId)) {
            return state;
        } else if (!Array.isArray(state[id]) && (state[id] as unknown as Set<string>).has(userId)) {
            return state;
        }
    }

    const nextSet = new Set(state[id]);
    nextSet.add(userId);
    return {
        ...state,
        [id]: nextSet,
    } as RelationOneToMany<Workspace, UserProfile>;
}

function removeProfileFromWorkspaces(state: RelationOneToMany<Workspace, UserProfile>, action: GenericAction) {
    const newState = {...state};
    let removed = false;

    Object.keys(state).forEach((key) => {
        if (newState[key][action.data.user_id]) {
            delete newState[key][action.data.user_id];
            removed = true;
        }
    });

    return removed ? newState : state;
}

function removeProfileFromSet(state: RelationOneToMany<Workspace, UserProfile>, action: GenericAction) {
    const {id, user_id: userId} = action.data;

    if (state[id]) {
        // The type definitions for this function expect state[id] to be an array, but we 
        // seem to use set, so handle both of those just in case.
        if (Array.isArray(state[id]) && !state[id].includes(userId)) {
            return state;
        } else if (!Array.isArray(state[id]) && !(state[id] as unknown as Set<string>).has(userId)) {
            return state;
        }
    }

    const nextSet = new Set(state[id]);
    nextSet.delete(userId);

    return {
        ...state, 
        [id]: nextSet,
    };
}

function currentUserId(state = '', action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_ME: {
            const data = action.data || action.payload;

            return data.id;
        }
        case UserTypes.LOGIN: { // Used by the mobile app
           const {user} = action.data;

           return user ? user.id : state;
        }
        case UserTypes.LOGOUT_SUCCESS: 
            return '';
    }

    return state;
}


function mySessions(state: Array<{id: string}> = [], action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_SESSIONS:
            return [...action.data];

        case UserTypes.RECEIVED_REVOKED_SESSION: {
            let index = -1;
            const length = state.length;
            for (let i = 0; i < length; i++) {
                if (state[i].id === action.sessionId) {
                    index = i;
                    break;
                }
            }

            if (index > -1) {
                return state.slice(0, index).concat(state.slice(index+1));
            }

            return state;
        }

        case UserTypes.REVOKE_ALL_USER_SESSIONS_SUCCESS:
            if (action.data.isCurrentUser === true) {
                return [];
            }

            return state;
        case UserTypes.REVOKE_SESSIONS_FOR_ALL_USERS_SUCCESS:
            return [];

        case UserTypes.LOGOUT_SUCCESS:
            return [];

        default:
            return state;
    }
}

function myAudits(state = [], action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_AUDITS:
            return [...action.data];

        case UserTypes.LOGOUT_SUCCESS:
            return [];
        default:
            return state;
    }
}

function profiles(state: IDMappedObjects<UserProfile> = {}, action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_ME:
        case UserTypes.RECEIVED_PROFILE: {
            const data = action.data || action.paylaod;
            const user = {...data};
            const oldUser = state[data.id];
            if (oldUser) {
                user.terms_of_service_id = oldUser.terms_of_service_id;
                user.terms_of_service_create_at = oldUser.terms_of_service_create_at;

                if (isEqual(user, oldUser)) {
                    return state;
                }
            }

            return {
                ...state,
                [data.id]: user,
            };
        }

        case UserTypes.RECEIVED_PROFILES_LIST: {
            const users:  UserProfile[] = action.data;

            return users.reduce((nextState, user) => {
                const oldUser = nextState[user.id];

                if (oldUser && isEqual(user, oldUser)) {
                    return nextState;
                }
                return {
                    ...nextState, 
                    [user.id]: user,
                };
            }, state);
        }

        case UserTypes.RECEIVED_PROFILES: {
            const users: UserProfile[] = Object.values(action.data);

            return users.reduce((nextState, user) => {
                const oldUser = nextState[user.id];

                if (oldUser && isEqual(user, oldUser)) {
                    return nextState;
                }

                return {
                    ...nextState,
                    [user.id]: user,
                };
            }, state);
        }

        case UserTypes.RECEIVED_TERMS_OF_SERVICE_STATUS: {
            const data = action.data || action.paylaod;

            return {
                ...state, 
                [data.user_id]: {
                    ...state[data.user_id],
                    terms_of_service_id: data.terms_of_service_id,
                    terms_of_service_create_at: data.terms_of_service_create_at,
                },
            };
        }

        case UserTypes.PROFILE_NO_LONGER_VISIBLE: {
            if (state[action.data.user_id]) {
                const newState = {...state};
                delete newState[action.data.user_id];
                return newState;
            }

            return state;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

function profilesByEmail(state = {}, action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_PROFILES_BY_EMAILS: {
            const users: UserProfile[] = action.data;

            return users.reduce((nextState, user) => {
                return {
                    ...nextState, 
                    [user.email]: user.id,
                };
            }, state);
        }

        case UserTypes.RECEIVED_PROFILE_BY_EMAIL: {
            const data = action.data || action.payload;
           
            return {
                ...state, 
                [data.email]: data.id, 
            }
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};

        default:
            return state;
    }
}

function profilesInWorkspace(state: RelationOneToMany<Workspace, UserProfile> = {}, action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_PROFILE_IN_WORKSPACE:
            return addProfileToSet(state, action.data.id, action.data.user_id);

        case UserTypes.RECEIVED_PROFILES_LIST_IN_WORKSPACE:
            return profileListToSet(state, action);

        case UserTypes.RECEIVED_PROFILES_IN_WORKSPACE:
            return profilesToSet(state, action);

        case UserTypes.RECEIVED_PROFILE_NOT_IN_WORKSPACE:
            return removeProfileFromSet(state, action);

        case UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_WORKSPACE:
            return removeProfileListFromSet(state, action);

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        case UserTypes.PROFILE_NO_LONGER_VISIBLE:
            return removeProfileFromWorkspaces(state, action);

        default:
            return state;
    }
}

function profilesNotInWorkspace(state: RelationOneToMany<Workspace, UserProfile> = {}, action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_PROFILE_NOT_IN_WORKSPACE:
            return addProfileToSet(state, action.data.id, action.data.user_id);

        case UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_WORKSPACE:
            return profileListToSet(state, action);

        case UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_WORKSPACE_AND_REPLACE:
            return profileListToSet(state, action, true);

        case UserTypes.RECEIVED_PROFILE_IN_WORKSPACE:
            return removeProfileFromSet(state, action);

        case UserTypes.RECEIVED_PROFILES_LIST_IN_WORKSPACE:
            return removeProfileListFromSet(state, action);

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        case UserTypes.PROFILE_NO_LONGER_VISIBLE: 
            return removeProfileFromWorkspaces(state, action);
        default:
            return state;
    }
}

function profilesInTopic(state: RelationOneToMany<Topic, UserProfile> = {}, action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_PROFILE_IN_TOPIC:
            return addProfileToSet(state, action.data.id, action.data.user_id);

        case UserTypes.RECEIVED_PROFILES_LIST_IN_TOPIC: 
              return profileListToSet(state, action);
        
        case UserTypes.RECEIVED_PROFILES_IN_TOPIC:
            return profilesToSet(state, action);

        case UserTypes.RECEIVED_PROFILE_NOT_IN_TOPIC:
            return removeProfileFromSet(state, action);

        case TopicTypes.TOPIC_MEMBER_REMOVED:
            return removeProfileFromSet(state, {
                type: '', 
                data: {
                    id: action.data.topic_id, 
                    user_id: action.data.user_id,
                }});

        case UserTypes.PROFILE_NO_LONGER_VISIBLE:
            return removeProfileFromWorkspaces(state, action);

        case UserTypes.LOGOUT_SUCCESS: 
             return {};
        default:
            return state;
    }
}


function profilesNotInTopic(state: RelationOneToMany<Topic, UserProfile> = {}, action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_PROFILE_NOT_IN_TOPIC: 
            return addProfileToSet(state, action.data.id, action.data.user_id);

        case UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_TOPIC:
            const kt = profileListToSet(state, action);
            return profileListToSet(state, action);

        case UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_TOPIC_AND_REPLACE:
            return profileListToSet(state, action, true);

        case UserTypes.RECEIVED_PROFILES_NOT_IN_TOPIC:
            return profilesToSet(state, action);

        case UserTypes.RECEIVED_PROFILE_IN_TOPIC: 
            return removeProfileFromSet(state, action);
        case TopicTypes.TOPIC_MEMBER_ADDED:
            return removeProfileFromSet(state, {
                type: '',
                data: {
                    id: action.data.topic_id, 
                    user_id: action.data.user_id,
                }});
        case UserTypes.LOGOUT_SUCCESS:
            return {};
        case UserTypes.PROFILE_NO_LONGER_VISIBLE:
            return removeProfileFromWorkspaces(state, action);

        default:
            return state;
    }
}

function addToState<T>(state: Record<string, T>, key: string, value: T): Record<string, T> {
    if (state[key] === value) {
        return state;
    }

    return {
        ...state, 
        [key]: value,
    };
}


function statuses(state: RelationOneToOne<UserProfile, string> = {}, action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_STATUS: {
            const userId = action.data.user_id;
            const status = action.data.status;

            return addToState(state, userId, status);
        }

        case UserTypes.RECEIVED_STATUSES: {
            const userStatuses: UserStatus[] = action.data;

            return userStatuses.reduce((nextState, userStatus) => addToState(nextState, userStatus.user_id, userStatus.status), state);
        }

        case UserTypes.PROFILE_NO_LONGER_VISIBLE: {
            if (state[action.data.user_id]) {
                const newState = {...state};
                delete newState[action.data.user_id];
                return newState;
            }

            return state;
        }

        case UserTypes.LOGOUT_SUCCESS: 
            return {};
        default:
            return state;
    }
}

function stats(state = {}, action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_USER_STATS: {
            const stat = action.data;
            return {
                ...state, 
                ...stat,
            };
        }

        default:
            return state;
    }
}

function filteredStats(state = {}, action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_FILTERED_USER_STATS: {
            const stat = action.data;
            return {
                ...state, 
                stat,
            };
        }

        default: 
          return state;
    }
}

function lastActivity(state: RelationOneToOne<UserProfile, string> = {}, action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_STATUS: {
            const nextState = Object.assign({}, state);
            nextState[action.data.user_id] = action.data.last_activity_at;

            return nextState;
        }

        case UserTypes.RECEIVED_STATUSES: {
            const nextState = Object.assign({}, state);

            for (const s of action.data) {
                nextState[s.user_id] = s.last_activity_at;
            }

            return nextState;
        }
        case UserTypes.LOGOUT_SUCCESS:
            return {};
        case UserTypes.PROFILE_NO_LONGER_VISIBLE: {
            if (state[action.data.user_id]) {
                const newState = {...state}
                delete newState[action.data.user_id];
                return newState;
            }

            return state;
        }

        default:
            return state;
    }
}

////
function isManualStatus(state: RelationOneToOne<UserProfile, boolean> = {}, action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_STATUS: {
            const userId = action.data.user_id;
            const manual = action.data.manual;

            return addToState(state, userId, manual);
        }

        case UserTypes.RECEIVED_STATUSES: {
            const userStatuses: UserStatus[] = action.data;

            return userStatuses.reduce((nextState, userStatus) => addToState(nextState, userStatus.user_id, userStatus.manual || false), state);
        }

        case UserTypes.PROFILE_NO_LONGER_VISIBLE: {
            if (state[action.data.user_id]) {
                const newState = {...state};
                delete newState[action.data.user_id];
                return newState;
            }

            return state;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {}

        default:
            return state;
    }
}

function myUserAccessTokens(state: Record<string, UserAccessToken> = {}, action: GenericAction) {
    switch (action.type) {
        case UserTypes.RECEIVED_MY_USER_ACCESS_TOKEN: {
            const nextState = {...state};
            nextState[action.data.id] = action.data;

            return nextState;
        }
        case UserTypes.RECEIVED_MY_USER_ACCESS_TOKENS: {
            const nextState = {...state};

            for (const uat of action.data) {
                nextState[uat.id] = uat;
            }

            return nextState;
        }

        case UserTypes.REVOKED_USER_ACCESS_TOKEN: {
            const nextState = {...state};
            Reflect.deleteProperty(nextState, action.data);

            return nextState;
        }

        case UserTypes.ENABLED_USER_ACCESS_TOKEN: {
            if (state[action.data]) {
                const nextState = {...state};
                nextState[action.data] = {...nextState[action.data], is_active: true};
                return nextState;
            }

            return state;
        }

        case UserTypes.DISABLED_USER_ACCESS_TOKEN: {
            if (state[action.data]) {
                const nextState = {...state};
                nextState[action.data] = {...nextState[action.data], is_active: false};
                return nextState;
            }

            return state;
        }

        case UserTypes.CLEAR_MY_USER_ACCESS_TOKENS:
        case UserTypes.LOGOUT_SUCCESS:
            return {};

        default:
            return state;
    }
}


export default combineReducers({
    // the current selected User
    currentUserId,

    // array with user's sessions.
    mySessions,

    // array with the user's audits
    myAudits,

    // object where every key is the token id and has a user access token as a value.
    myUserAccessTokens,

    // object where every key is a user id and has an object with user details.
    profiles,

    // object where every key is user email and value is user id (user profile index by email)
    profilesByEmail,

    // Object where every key is a workspace id and has a Set with the users ud that are members of the workspace
    profilesInWorkspace,

    // Object where every key is a workspace id and has a Set with user ids that are not 
    // members of the workspace.
    profilesNotInWorkspace,

    // object where every key is a topic id and has a Set with the users id that are members of the topic.
    profilesInTopic, 

    // object where every key is a topic id and had a Set with the user ids that are not members
    // of the topic.
    profilesNotInTopic,

    // object where every key is the user id and has a value with the current status of each user.
    statuses,

    // object where every key is the user id and has a value with a flag determining if their status
    // was set manually.
    isManualStatus, 


    // Total user stats
    stats,

    // Total user stats after fileters have been applied
    filteredStats, 

    // object where every key is the user id and has a value with the last activity timestamp
    lastActivity,
});