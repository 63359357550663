import { combineReducers } from 'redux';

import { RoleTypes, UserTypes } from 'sigmaflow-redux/action_types';
import { GenericAction } from 'sigmaflow-redux/types/actions';
import {Role} from 'sigmaflow-redux/types/roles';

function  pending(state: Set<string> = new Set(), action: GenericAction) {
    switch (action.type) {
        case RoleTypes.SET_PENDING_ROLES:
            return action.data;
        case UserTypes.LOGOUT_SUCCESS:
            return new Set();
        default:
            return state;
    }
}

function roles(state: Record<string, Role> = {}, action: GenericAction) {
    switch (action.type) {
        case RoleTypes.RECEIVED_ROLES: {
            if (action.data) {
                const nextState = {...state}

                for (const role of action.data) {
                    nextState[role.name] = role;
                }

                return nextState;
            }

            return state;
        }

        case RoleTypes.ROLE_DELETED: {
            if (action.data) {
                const nextState = {...state};
                Reflect.deleteProperty(nextState, action.data.name);
                return nextState;
            }

            return state;
        }

        case RoleTypes.RECEIVED_ROLE: {
            if (action.data) {
                const nextState = {...state};
                nextState[action.data.name] = action.data;
                return nextState;
            }

            return state;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};

        default:
            return state;
    }
}

export default combineReducers({
    roles, 
    pending,
});