import { BooleanLiteral } from '@babel/types';
import { Post } from './posts';
import type {Topic} from './topics';
import { UserProfile } from './users';
import { Org } from './orgs';
import type { IDMappedObjects, RelationOneToMany, RelationOneToOne } from './utilities';

export enum TopicThreadType {
    Synthetic = 'S'
}

export type ThreadType = 'thread_conv_regular' | 'thread_conv_guest_indv' | 'thread_conv_guest_org' | 'thread_conv_guest_grp' | 'thread_conv_host_org';

export type TopicThread = {
    id: string;
    pending_thread_id: string;
    topic_id: string;
    workspace_hostorg_id: string;
    type: ThreadType;
    subject: string;
    reply_count: number;
    create_at: number;
    last_reply_at: number;
    last_viewed_at: number;
    participants?: Array<{id: UserProfile['id']} | UserProfile>;
    unread_replies: number;
    unread_mentions: number;
    is_following?: boolean;
    failed?: boolean;
    
    /*
    post: {
        topic_id: Topic['id'];
        user_id: UserProfile['id'];
        user_hostorg_id: Org['id'];
    };
    */

    
    post: Post;
};

type SyntheticMissingKeys = 'unread_replies' | 'unread_mentions' | 'last_viewed_at';
export type TopicThreadSynthetic = Omit<TopicThread, SyntheticMissingKeys>;

export type TopicThreadWithPost = TopicThread & {post: Post};

export type TopicThreadList = {
    total: number;
    total_unread_threads: number;
    total_unread_mentions: number;
    threads: TopicThreadWithPost[];
};

export type TopicThreadCount = {
    total: number;
    total_unread_threads: number;
    total_unread_mentions: number;
}

export type ThreadsState = {
    threadsInTopic: RelationOneToMany<Topic, TopicThread>;
    unreadThreadsInTopic: RelationOneToMany<Topic, TopicThread>;
    threadsFirstLoadCompleted: RelationOneToOne<Topic, boolean>;
    threads: IDMappedObjects<TopicThread>;
    pendingThreadIds: string[];
    counts: RelationOneToOne<Topic, TopicThreadCount>;
};
