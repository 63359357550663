export default {
    CONFIG_CHANGED: 'config_changed',
    SERVER_VERSION_CHANGED: 'server_version_changed',
    PAGE_SIZE_DEFAULT: 60, 
    PAGE_SIZE_MAXIMUM: 200,
    LOGS_PAGE_SIZE_DEFAULT: 10000,
    AUDITS_CHUNK_SIZE: 100,
    PROFILE_CHUNK_SIZE: 100,
    TOPICS_CHUNK_SIZE: 50,
    WORKSPACES_CHUNK_SIZE: 100,
    ORGS_CHUNK_SIZE: 100,
    ORG_INVITES_CHUNK_SIZE: 40,
    JOBS_CHUNK_SIZE: 100,
    SEARCH_TIMEOUT_MILLISECONDS: 100,
    STATUS_INTERVAL: 60000,
    AUTOCOMPLETE_LIMIT_DEFAULT: 25,
    AUTOCOMPLETE_SPLIT_CHARACTERS: ['.', '-', '_'],
    OUT_OF_OFFICE: 'ooo',
    OFFLINE: 'offline',
    AWAY: 'away',
    ONLINE: 'online',
    DND: 'dnd',
    PERMISSIONS_ALL: 'all',
    PERMISSIONS_TOPIC_ADMIN: 'topic_admin',
    PERMISSIONS_WORKSPACE_ADMIN: 'workspace_admin',
    PERMISSIONS_SYSTEM_ADMIN: 'system_admin',
    WORKSPACE_GUEST_ROLE: 'workspace_guest', 
    WORKSPACE_USER_ROLE: 'workspace_user', 
    WORKSPACE_ADMIN_ROLE: 'workspace_admin', 
    WORKSPACE_ORGMEMBER_INVITE_STATUS_INVITED: 'invited',
    WORKSPACE_ORGMEMBER_INVITE_STATUS_CONFIRMED: 'confirmed',
    WORKSPACE_ORGMEMBER_INVITE_STATUS_REJECTED: 'rejected',
    WORKSPACE_ORGMEMBER_INVITE_STATUS_REQUESTED: 'requested',
    TOPIC_GUEST_ROLE: 'topic_guest', 
    TOPIC_USER_ROLE: 'topic_user', 
    TOPIC_ADMIN_ROLE: 'topic_admin',
    SYSTEM_GUEST_ROLE: 'system_guest', 
    SYSTEM_USER_ROLE: 'system_user', 
    SYSTEM_ADMIN_ROLE: 'system_admin',
    SYSTEM_USER_MANAGER_ROLE: 'system_user_manager', 
    SYSTEM_READ_ONLY_ADMIN_ROLE: 'system_read_only_admin',
    SYSTEM_MANAGER_ROLE: 'system_manager', 
    SYSTEM_USER_ACCESS_TOKEN_ROLE: 'system_user_access_token',
    SYSTEM_POST_ALL_ROLE: 'system_post_all',
    SYSTEM_POST_ALL_PUBLIC_ROLE: 'system_post_all_public',
    STORE_REHYDRATION_COMPLETE: 'store_rehydration_complete',
    ORGMATE_NAME_DISPLAY: {
        SHOW_USERNAME: 'username',
        SHOW_NICKNAME_FULLNAME: 'nickname_full_name',
        SHOW_FULLNAME: 'full_name',
    },
    DEFAULT_TOPIC: 'orgboard',
    ARCHIVED_TOPIC: 'archived',
    DM_TOPIC: 'D',
    GM_TOPIC: 'G',
    DF_TOPIC: 'F', // Default topic type
    OPEN_TOPIC: 'O',
    PRIVATE_TOPIC: 'P',
    SPECIAL_MENTIONS: ['all', 'topic', 'here'],
    DEFAULT_LOCALE: 'en',
    DEFAULT_AUTOLINKED_URL_SCHEMES: ['http', 'https', 'ftp', 'mailto', 'tel', 'sigmaflow'],
    DISABLED: 'disabled',
    DEFAULT_ON: 'default_on',
    DEFAULT_OFF: 'default_off',
    ALWAYS_ON: 'always_on',
    DEFAULT_GROUP: 'board',
}