import {combineReducers} from 'redux';
import { GenericAction } from 'sigmaflow-redux/types/actions';

import { ActionTypes } from 'utils/constants';


export function switcherOpen(state = false, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.SET_PRODUCT_SWITCHER_OPEN:
            return action.open;
        default:
            return state;
    }
}

export default combineReducers({
    switcherOpen,
});