import keyMirror from "sigmaflow-redux/utils/key_mirror";

export default keyMirror({
    GET_WORKSPACES_REQUEST: null, 
    GET_WORKSPACES_SUCCESS: null,
    GET_WORKSPACES_FAILURE: null,

    GET_XWORKSPACES_REQUEST: null, 
    GET_XWORKSPACES_SUCCESS: null,
    GET_XWORKSPACES_FAILURE: null,

    MY_WORKSPACES_REQUEST: null, 
    MY_WORKSPACES_SUCCESS: null,
    MY_WORKSPACES_FAILURE: null,

    CREATE_WORKSPACE_REQUEST: null, 
    CREATE_WORKSPACE_SUCCESS: null,
    CREATE_WORKSPACE_FAILURE: null,

    GET_WORKSPACE_MEMBERS_REQUEST: null, 
    GET_WORKSPACE_MEMBERS_SUCCESS: null,
    GET_WORKSPACE_MEMBERS_FAILURE: null,

    GET_WORKSPACE_ORGMEMBERS_REQUEST: null,
    GET_WORKSPACE_ORGMEMBERS_SUCCESS: null,
    GET_WORKSPACE_ORGMEMBERS_FAILURE: null,

    JOIN_WORKSPACE_REQUEST: null, 
    JOIN_WORKSPACE_SUCCESS: null, 
    JOIN_WORKSPACE_FAILURE: null,

    WORKSPACE_INVITE_INFO_REQUEST: null, 
    WORKSPACE_INVITE_INFO_SUCCESS: null,
    WORKSPACE_INVITE_INFO_FAILURE: null,

    WORKSPACE_ORGINVITE_INFO_REQUEST: null, 
    WORKSPACE_ORGINVITE_INFO_SUCCESS: null,
    WORKSPACE_ORGINVITE_INFO_FAILURE: null,

    ADD_TO_WORKSPACE_FROM_INVITE_REQUEST: null,
    ADD_TO_WORKSPACE_FROM_INVITE_SUCCESS: null,
    ADD_TO_WORKSPACE_FROM_INVITE_FAILURE: null,

    ADD_ORG_TO_WORKSPACE_FROM_INVITE_REQUEST: null,
    ADD_ORG_TO_WORKSPACE_FROM_INVITE_SUCCESS: null,
    ADD_ORG_TO_WORKSPACE_FROM_INVITE_FAILURE: null,

    CREATED_WORKSPACE: null, 
    SELECT_WORKSPACE: null, 
    UPDATED_WORKSPACE: null,
    PATCHED_WORKSPACE: null,
    REGENERATED_WORKSPACE_INVITE_ID: null, 
    RECEIVED_WORKSPACE: null, 
    RECEIVED_WORKSPACES: null,  
    RECEIVED_WORKSPACE_DELETED: null,
    RECEIVED_WORKSPACE_UNARCHIVED: null,
    RECEIVED_WORKSPACES_LIST: null,

    RECEIVED_MY_WORKSPACE_MEMBERS: null, 
    RECEIVED_MY_WORKSPACE_MEMBER: null,

    RECEIVED_MY_WORKSPACE_ORGMEMBERS: null, 
    RECEIVED_MY_WORKSPACE_ORGMEMBER: null,
    
    RECEIVED_WORKSPACE_MEMBERS: null, 
    RECEIVED_MEMBERS_IN_WORKSPACE: null, 
    RECEIVED_MEMBER_IN_WORKSPACE: null, 
    REMOVE_MEMBER_FROM_WORKSPACE: null,

    RECEIVED_MEMBERS_IN_XWORKSPACE: null,

    RECEIVED_WORKSPACE_ORGMEMBERS: null, 
    RECEIVED_ORGMEMBERS_IN_WORKSPACE: null, 
    RECEIVED_ORGMEMBER_IN_WORKSPACE: null, 
    REMOVE_ORGMEMBER_FROM_WORKSPACE: null,

    RECEIVED_XWORKSPACES_LIST_FOR_ORG: null, 
    RECEIVED_XWORKSPACES_LIST: null,
    RECEIVED_XWORKSPACES_STATS: null, 
    RECEIVED_FILTERED_XWORKSPACES_STATS: null,
    RECEIVED_XWORKSPACE_IN_ORG: null,
    RECEIVED_XWORKSPACES_FOR_ORG: null,
    
    XWORKSPACE_NO_LONGER_VISIBLE: null,

    RECEIVED_WORKSPACES_STATS_FOR_USER: null, 
    RECEIVED_FILTERED_WORKSPACES_STATS_FOR_USER: null, 
    RECEIVED_WORKSPACE_MEMBER_FOR_USER: null, 
    RECEIVED_WORKSPACE_MEMBERS_FOR_USER: null, 
    REMOVE_WORKSPACE_MEMBER: null, 
    WORKSPACE_NO_LONGER_VISIBLE: null,
    RECEIVED_WORKSPACE_FOR_USER: null, 
    RECEIVED_WORKSPACES_LIST_FOR_USER: null, 
    RECEIVED_WORKSPACES_FOR_USER: null,  


    RECEIVED_WORKSPACE_STATS: null, 
    RECEIVED_MY_WORKSPACE_UNREADS: null, 
    LEAVE_WORKSPACE: null, 
    UPDATED_WORKSPACE_SCHEME: null, 

    UPDATED_WORKSPACE_MEMBER_SCHEME_ROLES: null,

    RECEIVED_WORKSPACE_MEMBERS_MINUM_GROUP_MEMBERS: null,

    RECEIVED_TOTAL_WORKSPACE_COUNT: null,
});