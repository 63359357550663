/* eslint-disable max-lines */
import { combineReducers } from "redux";
import shallowEquals from 'shallow-equals';
import { markThreadAsUnread } from "sigmaflow-redux/actions/threads";

import { AdminTypes,  TopicTypes, UserTypes, SchemeTypes, PostTypes, ThreadTypes } from "sigmaflow-redux/action_types";

import { General } from "sigmaflow-redux/constants";
import {MarkUnread} from 'sigmaflow-redux/constants/topics';

import { GenericAction } from "sigmaflow-redux/types/actions";
import {
    Topic,
    TopicMembership, 
    TopicStats, 
    ServerTopic
} from 'sigmaflow-redux/types/topics';

import { RelationOneToMany,RelationOneToOne, IDMappedObjects } from "sigmaflow-redux/types/utilities";
import { Workspace } from "sigmaflow-redux/types/workspaces";

import {topicListToMap, splitRoles} from 'sigmaflow-redux/utils/topic_utils';

import messageCounts from './topics/message_counts';
import { UserProfile } from "sigmaflow-redux/types/users";

function removeMemberFromTopics(state: RelationOneToOne<Topic, Record<string, TopicMembership>>, action: GenericAction) {
    const nextState = {...state};
    Object.keys(state).forEach((topic) => {
        nextState[topic] = {...nextState[topic]};
        delete nextState[topic][action.data.user_id];
    });

    return nextState;
}

function topicListToSet(state: any, action: GenericAction) {
    const nextState = {...state};

    action.data.forEach((topic: Topic) => {
        const nextSet = new Set(nextState[topic.workspace_id]);
        nextSet.add(topic.id);
        nextState[topic.workspace_id] = nextSet;
    });

    return nextState;
}

function removeTopicFromSet(state: any, action: GenericAction) {
    const id = action.data.workspace_id;
    const nextSet = new Set(state[id]);
    nextSet.delete(action.data.id);
    return {
        ...state, 
        [id]: nextSet,
    };
}

function currentTopicId(state = '', action: GenericAction) {
    switch (action.type) {
        case TopicTypes.SELECT_TOPIC: 
            return action.data;
        case UserTypes.LOGOUT_SUCCESS:
            return '';
        default:
            return state;
    }
}

function topics(state: IDMappedObjects<Topic> = {}, action: GenericAction) {
    switch (action.type) {
        case TopicTypes.RECEIVED_TOPIC: {
            const topic: Topic = toClientTopic(action.data);

            if (state[topic.id] && topic.type === General.DM_TOPIC) {
                topic.display_name = topic.display_name || state[topic.id].display_name;
            }

            return {
                ...state, 
                [topic.id]: topic,
            };
        }
        case AdminTypes.RECEIVED_DATA_RETENTION_CUSTOM_POLICY_TOPIC: {
            const topics: Topic[] = action.data.topics.map(toClientTopic);

            if (topics.length === 0) {
                return state;
            }

            return {
                ...state, 
                ...topicListToMap(topics),
            };
        }
        case AdminTypes.RECEIVED_DATA_RETENTION_CUSTOM_POLICY_TOPICS_SEARCH:
        case TopicTypes.RECEIVED_TOPICS:
        case TopicTypes.RECEIVED_TOPICS_LIST:
        case TopicTypes.RECEIVED_ALL_TOPICS: {
      //  case SchemeTypes.RECEIVED_SCHEME_TOPICS: {
            const topics: Topic[] = action.data.map(toClientTopic);

            if (topics.length === 0) {
                return state;
            }

            const nextState = {...state};

            for (let topic of topics) {
                if (state[topic.id] && topic.type === General.DM_TOPIC && !topic.display_name) {
                    topic = {...topic, display_name: state[topic.id].display_name};
                }

                nextState[topic.id] = topic;
            }

            return nextState;
        }

        case TopicTypes.RECEIVED_TOPIC_DELETED: {
            const {id, deleteAt} = action.data;

            if (!state[id]) {
                return state;
            }

            return {
                ...state, 
                [id]: {
                    ...state[id], 
                    delete_at: deleteAt,
                },
            };
        }

        case TopicTypes.RECEIVED_TOPIC_UNARCHIVED: {
            const {id} = action.data;

            if (!state[id]) {
                return state;
            }

            return {
                ...state, 
                [id]: {
                    ...state[id],
                    delete_at: 0,
                },
            };
        }

        case TopicTypes.UPDATE_TOPIC_HEADER: {
            const {topicId, header} = action.data;

            if (!state[topicId]) {
                return state;
            }

            return {
                ...state, 
                [topicId]: {
                    ...state[topicId], 
                    header,
                },
            };
        }

        case TopicTypes.LEAVE_TOPIC: {
            if (action.data) {
                const nextState = {...state};
                Reflect.deleteProperty(nextState, action.data.id);
                return nextState;
            }

            return state;
        }

        case ThreadTypes.RECEIVED_NEW_THREAD: {
            const {topic_id, create_at} = action.data.thread; // eslint-disable-line @typescript-eslint/naming-convention
            
            const topic = state[topic_id];

            if (!topic) {
                return state;
            }

            return {
                ...state, 
                [topic_id]: {
                    ...topic, 
                    last_post_at: Math.max(create_at, topic.last_post_at),
                    last_root_post_at: Math.max(create_at, topic.last_post_at),
                },
            };
        }


        case PostTypes.RECEIVED_NEW_POST: {
            const {topic_id, create_at, root_id} = action.data; // eslint-disable-line @typescript-eslint/naming-convention
            const isCrtReply = root_id !== '';

            const topic = state[topic_id];

            if (!topic) {
                return state;
            }

            const lastRootPostAt = isCrtReply ? topic.last_root_post_at : Math.max(create_at, topic.last_root_post_at);

            return {
                ...state, 
                [topic_id]: {
                    ...topic, 
                    last_post_at: Math.max(create_at, topic.last_post_at),
                    last_root_post_at: lastRootPostAt,
                },
            };
        }
/*
        case AdminTypes.REMOVE_DATA_RETENTION_CUSTOM_POLICY_TOPICS_SUCCESS:{
            const {topics} = action.data;
            const nextState = {...state};
            topics.forEach((topicId: string) => {
                if (nextState[topicId]) {
                    nextState[topicId] = {
                        ...nextState[topicId],
                        policy_id: null,
                    };
                }
            });

            return nextState;
    }
    */

    case UserTypes.LOGOUT_SUCCESS:
        return {};
    default: 
       return state;
}
}

function toClientTopic(serverTopic: ServerTopic): Topic {
    const topic = {...serverTopic};

    Reflect.deleteProperty(topic, 'total_msg_count');
    Reflect.deleteProperty(topic, 'total_msg_count_root');

    return topic;
}

function topicsInWorkspace(state: RelationOneToMany<Workspace, Topic> = {}, action: GenericAction) {
    switch (action.type) {
        case TopicTypes.RECEIVED_TOPIC: {
            const nextSet = new Set(state[action.data.workspace_id]);
            nextSet.add(action.data.id);
            return {
                ...state, 
                [action.data.workspace_id]: nextSet,
            }; 
        }

        case TopicTypes.RECEIVED_TOPICS: {
            return topicListToSet(state, action);
        }

        case TopicTypes.LEAVE_TOPIC: {
            if (action.data) {
                return removeTopicFromSet(state, action);
            }

            return state;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

export function myMembers(state: RelationOneToOne<Topic, TopicMembership> = {}, action: GenericAction) {
    switch (action.type) {
        case TopicTypes.RECEIVED_MY_TOPIC_MEMBER: {
            const topicMember: TopicMembership = action.data;

            return receiveTopicMember(state, topicMember);
        }

        case TopicTypes.RECEIVED_MY_TOPIC_MEMBERS: {
            const nextState = {...state};
            const remove = action.remove as string[];
            if (remove) {
                remove.forEach((id: string) => {
                    Reflect.deleteProperty(nextState, id);
                });
            }

            const topicMembers: TopicMembership[] = action.data;

            return topicMembers.reduce(receiveTopicMember, state);
        }

        case TopicTypes.RECEIVED_TOPIC_PROPS: {
            const member = {...state[action.data.topic_id]};
            member.notify_props = action.data.notifyProps;

            return {
                ...state, 
                [action.data.topic_id]: member,
            };
        }

        case TopicTypes.SET_TOPIC_MUTED: {
            const {topicId, muted} = action.data;

            if (!state[topicId]) {
                return state;
            }

            return {
                ...state, 
                [topicId]: {
                    ...state[topicId],
                    notify_props: {
                        ...state[topicId].notify_props, 
                        mark_unread: muted ? MarkUnread.MENTION : MarkUnread.ALL,
                    },
            },
        };
    }

    case TopicTypes.INCREMENT_UNREAD_MSG_COUNT: {
        const {
            topicId, 
            amount, 
            amountRoot, 
            onlyMentions, 
            fetchedTopicMember,
        } = action.data;

        const member = state[topicId];

        if (!member) {
            // Don't keep track of unread posts until we've loaded the actual topic member.
            return state;
        }

        if (!onlyMentions) {
            // Increment the msg_count mark the topic as read, so don't do that
            // if these posts should be unread.
            return state;
        }

        if (fetchedTopicMember) {
            // We've already updated the topic member with the correct msg_count.
            return state;
        }

        return {
            ...state, 
            [topicId]: {
                ...member, 
                msg_count: member.msg_count + amount, 
                msg_count_root: member.msg_count_root + amountRoot, 
            },
        };
    }
    case TopicTypes.DECREMENT_UNREAD_MSG_COUNT: {
        const {topicId, amount, amountRoot} = action.data;

        const member = state[topicId];

        if (!member) {
            // Don't keep track of unread posts until we've loaded the actual topic member.
            return state;
        }

        return {
            ...state, 
            [topicId]: {
                ...member, 
                msg_count: member.msg_count + amount, 
                msg_count_root: member.msg_count_root + amountRoot,
            },
        };
    }

    case TopicTypes.INCREMENT_UNREAD_MENTION_COUNT: {
        const {
            topicId, 
            amount, 
            amountRoot, 
            fetchedTopicMember,
        } = action.data;
        const member = state[topicId];

        if (!member) {
            // Don't keep track of unread posts until we've loaded the actual topic number.
            return state;
        }

        if (fetchedTopicMember) {
            // We've actually updated the topic member with the correct msg_count/mention_count.
            return state;
        }

        return {
            ...state, 
            [topicId]: {
                ...member, 
                mention_count: member.mention_count + amount, 
                mention_count_root: member.mention_count_root + amountRoot,
            },
        };
    }

    case TopicTypes.DECREMENT_UNREAD_MENTION_COUNT: {
        const {topicId, amount, amountRoot} = action.data;
        const member = state[topicId];

        if (!member) {
            // Doon't keep track of unread posts until we've loaded the actual topic member
            return state;
        }

        return {
            ...state, 
            [topicId]: {
                ...member, 
                mention_count: Math.max(member.mention_count - amount, 0),
                mention_count_root: Math.max(member.mention_count_root - amountRoot, 0),
            },
        };
    }

    case TopicTypes.LEAVE_TOPIC: {
        const nextState = {...state};
        if (action.data) {
            Reflect.deleteProperty(nextState, action.data.id);
            return nextState;
        }

        return state;
    }

    case TopicTypes.POST_UNREAD_SUCCESS: {
        const data = action.data;
        const topicState = state[data.topicId];

        if (!topicState) {
            return state;
        }

        return {
            ...state, 
            [data.topicId]: {
                ...topicState, 
                msg_count: data.msgCount, 
                mention_count: data.mention_count, 
                msg_count_root: data.msgCountRoot, 
                mention_count_root: data.mentionCountRoot, 
                last_viewed_at: data.lastViewedAt, 
            },
        };
    }

    case UserTypes.LOGOUT_SUCCESS:
        return {};
    default:
        return state;
}
}

function receiveTopicMember(state: RelationOneToOne<Topic, TopicMembership>, received: TopicMembership) {
    const member = state[received.topic_id];

    let nextMember = received;
    if (member && nextMember.last_viewed_at < member.last_viewed_at) {
        // The last_viewed_at should almost never decrease upon receiving a member, so if it does assume
        // that the unread state of the existing topic member is correct.
        nextMember = {
            ...received, 
            last_viewed_at: Math.max(member.last_viewed_at, received.last_viewed_at),
            msg_count: Math.max(member.msg_count, received.msg_count),
            msg_count_root: Math.max(member.msg_count_root, received.msg_count_root),
            mention_count: Math.min(member.mention_count, received.mention_count),
            mention_count_root: Math.min(member.mention_count_root, received.mention_count_root),
        };
    }

    return {
        ...state, 
        [nextMember.topic_id]: nextMember,
    };
}

function membersInTopic(state: RelationOneToOne<Topic, Record<string, TopicMembership>> = {}, action: GenericAction) {
    switch (action.type) {
        case TopicTypes.RECEIVED_MY_TOPIC_MEMBER:
        case TopicTypes.RECEIVED_TOPIC_MEMBER: {
        const member = action.data;
        const members = {...(state[member.topic_id] || {})};
        members[member.user_id] = member;
        return {
            ...state, 
            [member.topic_id]: members,
        };
    }
    case TopicTypes.RECEIVED_MY_TOPIC_MEMBERS:
    case TopicTypes.RECEIVED_TOPIC_MEMBERS: {
        const nextState = {...state};
        const remove = action.remove as string[];
        const currentUserId = action.currentUserId;
        if (remove && currentUserId) {
            remove.forEach((id) => {
                if (nextState[id]) {
                    Reflect.deleteProperty(nextState[id], currentUserId);
                }
            });
        }

        for (const cm of action.data) {
            if (nextState[cm.topic_id]) {
                nextState[cm.topic_id] = {...nextState[cm.topic_id]};
            } else {
                nextState[cm.topic_id] = {};
            }
            nextState[cm.topic_id][cm.user_id] = cm;
        }

        return nextState;
    }

    case UserTypes.PROFILE_NO_LONGER_VISIBLE:
        return removeMemberFromTopics(state, action);

    case TopicTypes.LEAVE_TOPIC:
    case TopicTypes.REMOVE_MEMBER_FROM_TOPIC:
    case UserTypes.RECEIVED_PROFILE_IN_TOPIC: {
        if (action.data) {
            const data = action.data;
            const members = {...(state[data.id] || {})};
            if (state[data.id]) {
                Reflect.deleteProperty(members, data.user_id);
                return {
                    ...state, 
                    [data.id]: members,
                };
            }
        }

        return state;
    }
    case UserTypes.LOGOUT_SUCCESS:
        return {};
    default:
        return state;
   }
    
}

function stats(state: RelationOneToOne<Topic, TopicStats> = {}, action: GenericAction) {
    switch (action.type) {
        case TopicTypes.RECEIVED_TOPIC_STATS: {
            const nextState = {...state};
            const stat = action.data;
            nextState[stat.topic_id] = stat;

            return nextState;
        }

        case TopicTypes.ADD_TOPIC_MEMBER_SUCCESS: {
            const nextState = {...state};
            const id = action.id;
            const nextStat = nextState[id];
            if (nextStat) {
                const count = nextStat.member_count + 1;

                return {
                    ...nextState, 
                    [id]: {
                        ...nextStat, 
                        member_count: count, 
                    },
                };
            }

            return state;
        }

        case TopicTypes.REMOVE_TOPIC_MEMBER_SUCCESS: {
            const nextState = {...state};
            const id = action.id;
            const nextStat = nextState[id];
            if (nextStat) {
                const count = nextStat.member_count - 1;
                return {
                    ...nextState, 
                    [id]: {
                        ...nextStat, 
                        member_count: count || 1,
                    },
                };
            }

            return state;
        }

        case TopicTypes.INCREMENT_FILE_COUNT: {
            const nextState = {...state};
            const id = action.id;
            const nextStat = nextState[id];
            if (nextStat) {
                const count = nextStat.files_count + action.amount;
                return {
                    ...nextState, 
                    [id]: {
                        ...nextStat, 
                        files_count: count, 
                    },
                };
            }

            return state;
        }

        case UserTypes.LOGOUT_SUCCESS: 
        return {};
        default:
            return state;
    }
}


function totalCount(state = 0, action: GenericAction) {
    switch (action.type) {
        case TopicTypes.RECEIVED_TOTAL_TOPIC_COUNT: {
            return action.data;
        }
        default:
            return state;
    }
}

export function manuallyUnread(state: RelationOneToOne<Topic, boolean> = {}, action: GenericAction) {
    switch (action.type) {
        case TopicTypes.REMOVE_MANUALLY_UNREAD: {
            if (state[action.data.topicId]) {
                const newState = {...state};
                delete newState[action.data.topicId];
                return newState;
            }
            return state;
        }
        case UserTypes.LOGOUT_SUCCESS: {
            return {};
        }

        case TopicTypes.ADD_MANUALLY_UNREAD:
        case TopicTypes.POST_UNREAD_SUCCESS: {
            return {...state, [action.data.topicId]: true};
        }
        default:
            return state;
    }
}


function roles(state: RelationOneToOne<Topic, Set<string>> = {}, action: GenericAction) {
    switch (action.type) {
        case TopicTypes.RECEIVED_MY_TOPIC_MEMBER: {
            const topicMember = action.data;
            const oldRoles = state[topicMember.topic_id];
            const newRoles = splitRoles(topicMember.roles);

            // if roles didn't change no need to update state.
            if (shallowEquals(oldRoles, newRoles)) {
                return state;
            }

            return {
                ...state, 
                [topicMember.topic_id]: newRoles,
            };
        }

        case TopicTypes.RECEIVED_MY_TOPIC_MEMBERS: {
            const nextState = {...state};
            const remove = action.remove as string[];
            let stateChanged = false;
            if (remove && remove.length) {
                remove.forEach((id: string) => {
                    Reflect.deleteProperty(nextState, id);
                });

                stateChanged = true;
            }

            for (const cm of action.data) {
                const oldRoles = nextState[cm.topic_id];
                const newRoles = splitRoles(cm.roles);

                if (!shallowEquals(oldRoles, newRoles)) {
                    nextState[cm.topic_id] = splitRoles(cm.roles);
                    stateChanged = true;
                }
            }

            if (stateChanged) {
                return nextState;
            }

            return state;
        }

        case TopicTypes.LEAVE_TOPIC: {
            const nextState = {...state};
            if (action.data) {
                Reflect.deleteProperty(nextState, action.data.id);
                return nextState;
            }

            return state;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

// sidebar pagination support

function addTopicToSet(state: RelationOneToMany<UserProfile, Topic>, id: string, topicId: string) {
    if (state[id]) {
        if (Array.isArray(state[id]) && state[id].includes(topicId)) {
            return state;
        } else if (!Array.isArray(state[id]) && (state[id] as unknown as Set<string>).has(topicId)) {
            return state;
        }
    }

    const nextSet = new Set(state[id]);
    nextSet.add(topicId);

    return {
        ...state, 
        [id]: nextSet, 
    } as RelationOneToMany<UserProfile, Topic>;
}

function topicListToSetForUser(state: RelationOneToMany<UserProfile, Topic>, action: GenericAction, replace = false) {
    const id = action.id;
    const topics: Topic[] = action.data || [];

    if (replace) {
        return {
            ...state, 
            [id]: new Set(topics.map((topic) => topic.id)),
        };
    }

    return topics.reduce((nextState, topic) => addTopicToSet(nextState, id, topic.id), state);
}

function removeTopicForUsers(state: RelationOneToMany<UserProfile, Topic>, action: GenericAction) {
    const newState = {...state};
    let removed = false;
    Object.keys(state).forEach((key) => {
        if (newState[key][action.data.topic_id]) {
            delete newState[key][action.data.topic_id];
            removed = true;
        }
    });
    return removed ? newState : state;
}



function topicsForUser(state: RelationOneToMany<UserProfile, Topic> = {}, action: GenericAction) {
    switch (action.type) {
        case TopicTypes.RECEIVED_TOPIC_FOR_USER:
            return addTopicToSet(state, action.data.id, action.data.topic_id);
        
        case TopicTypes.RECEIVED_TOPIC_LIST_FOR_USER:
            return topicListToSetForUser(state, action);
        case TopicTypes.RECEIVED_TOPICS_FOR_USER:
            return topicListToSetForUser(state, action);

        case UserTypes.LOGOUT_SUCCESS:
            return {};

        case TopicTypes.TOPIC_NO_LONGER_VISIBLE:
            return removeTopicForUsers(state, action);

        default:
            return state;
    }
}

function topicsStatsForUser(state: any = {}, action: GenericAction) {
    switch (action.type) {
        case TopicTypes.RECEIVED_TOPIC_STATS_FOR_USER: {
            const stat = action.data;
            return {
                ...state, 
                [stat.user_id]: {...stat},
            };
        }
        case TopicTypes.INCREMENT_TOPIC_STATS_FOR_USER: {
            const userStat = state[action.data.user_id];
            return {
                ...state, 
                [action.data.user_id]: {
                    ...userStat, 
                    total_topics_count: userStat.total_topics_count + 1,
                    active_topics_count: userStat.active_topics_count + 1,
                },
            };
        }

        case TopicTypes.DECREMENT_TOPIC_STATS_FOR_USER: {
            const userStat = state[action.data.user_id];
            return {
                ...state, 
                [action.data.user_id]: {
                    ...userStat, 
                    total_topics_count: userStat.total_topics_count - 1,
                    active_topics_count: userStat.active_topics_count - 1,
                },
            };
        }
        

        default:
            return state;
    }
}

function filteredTopicsStatsForUser(state = {}, action: GenericAction) {
    switch (action.type) {
        case TopicTypes.RECEIVED_FILTERED_TOPICS_STATS_FOR_USER: {
            const stat = action.data;
            return {
                ...state, 
                [stat.user_id]: {...stat},
            };
        }

        default:
            return state;
    }
}

function topicMembersForUser(state: RelationOneToOne<UserProfile, RelationOneToOne<Topic, TopicMembership>> = {}, action: GenericAction) {
    switch (action.type) {
        case TopicTypes.RECEIVED_TOPIC_MEMBER_FOR_USER: {
            const data = action.data;
            const members = {...(state[data.user_id] || {})};
            members[data.topic_id] = data;

            return {
                ...state, 
                [data.user_id]: members,
            };
        }

        case TopicTypes.RECEIVED_TOPIC_MEMBERS_FOR_USER: {
            const data = action.data;
            if (data && data.length) {
                const nextState = {...state};
                for (const member of data) {
                    if (nextState[member.user_id]) {
                        nextState[member.user_id] = {...nextState[member.user_id]};
                    } else {
                        nextState[member.user_id] = {};
                    }

                    nextState[member.user_id][member.topic_id] = member;
                }

                return nextState;
            }

            return state;
        }

        case TopicTypes.REMOVE_TOPIC_MEMBER:
        case TopicTypes.REMOVE_MEMBER_FROM_TOPIC:
        case TopicTypes.TOPIC_NO_LONGER_VISIBLE:
        case TopicTypes.RECEIVED_TOPIC_DELETED: {
            const data = action.data;
            const members = state[data.user_id];
            if (members) {
                const nextState = {...members};
                Reflect.deleteProperty(nextState, data.topic_id);
                return {
                    ...state, 
                    [data.user_id]: nextState,
                };
            }

            return state;
        }

        // for sidebar - this does not apply.. really
        case UserTypes.RECEIVED_USER_DELETED: {
            const nextState = {...state};
            const userId = action.data.id;
            if (nextState.hasOwnProperty(userId)) {
                Reflect.deleteProperty(nextState, userId);
                return nextState;
            }
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}


export default combineReducers({
    currentTopicId, 

    // object where every key is the topic id and has an object with topic detail.
    topics,
    // object where every key is a workspace id and has set of topic ids that are in the workspace.
    topicsInWorkspace, 

    // object where every key is topic id and has an object with the topic member details.
    myMembers, 

    // object where every key is the topic id  and has object with the topic roles.
    roles, 

    // object where every key is the topic id and has object wherekey is a user id and has an object with topic member details.
    membersInTopic,

     // object where every key is the topic id and  has an object with the topic stats.
     stats, 

    totalCount,  

    // object where every key is the topic id, if present means a user requested to mark that topic as 
    // unread.
    manuallyUnread, 


    // object where every key is the topic_id mappingto an object containing the number of messages in the topic.
    messageCounts,
    
    // sidebar pagination support
    topicsForUser,

    topicMembersForUser,

    topicsStatsForUser,

    filteredTopicsStatsForUser,

});
