import { combineReducers } from "redux";

import {RoleTypes} from 'sigmaflow-redux/action_types';


import { GenericAction } from "sigmaflow-redux/types/actions";
import { RolesRequestsStatuses, RequestStatusType } from "sigmaflow-redux/types/requests";

import { handleRequest, initialRequestState } from "./helpers";

function getRolesByNames(state: RequestStatusType = initialRequestState(), action: GenericAction): RequestStatusType {
    return handleRequest(
        RoleTypes.ROLES_BY_NAMES_REQUEST,
        RoleTypes.ROLES_BY_NAMES_SUCCESS,
        RoleTypes.ROLES_BY_NAMES_FAILURE,
        state,
        action,
    );
}

function getRoleByName(state: RequestStatusType = initialRequestState(), action: GenericAction): RequestStatusType {
    return handleRequest(
        RoleTypes.ROLE_BY_NAME_REQUEST,
        RoleTypes.ROLE_BY_NAME_SUCCESS,
        RoleTypes.ROLE_BY_NAME_FAILURE,
        state,
        action,
    );
}


function getRole(state: RequestStatusType = initialRequestState(), action: GenericAction): RequestStatusType {
    return handleRequest(
        RoleTypes.ROLE_BY_ID_REQUEST,
        RoleTypes.ROLE_BY_ID_SUCCESS,
        RoleTypes.ROLE_BY_ID_FAILURE,
        state,
        action,
    );
}

function editRole(state: RequestStatusType = initialRequestState(), action: GenericAction): RequestStatusType {
    return handleRequest(
        RoleTypes.EDIT_ROLE_REQUEST,
        RoleTypes.EDIT_ROLE_SUCCESS,
        RoleTypes.EDIT_ROLE_FAILURE,
        state,
        action,
    );
}

export default (combineReducers({
    getRolesByNames,
    getRoleByName,
    getRole,
    editRole,
}) as (b: RolesRequestsStatuses, a: GenericAction) => RolesRequestsStatuses);