import { createSelector } from "reselect";

import { getCurrentTopicId } from "./common";
import { GlobalState } from "sigmaflow-redux/types/store";
import { Topic } from "sigmaflow-redux/types/topics";
import { TopicThread, ThreadsState, TopicThreadType, TopicThreadSynthetic } from "sigmaflow-redux/types/threads";
import { Post } from "sigmaflow-redux/types/posts";
import { IDMappedObjects, RelationOneToMany, RelationOneToOne } from "sigmaflow-redux/types/utilities";
import { selectedThreadIdInTopic } from "reducers/views/threads";
import { createIdsSelector } from "sigmaflow-redux/utils/helpers";



export function getThreadsInTopic(state: GlobalState): RelationOneToMany<Topic, TopicThread> {
    return state.entities.threads.threadsInTopic;
}

export function getUnreadThreadsInTopic(state: GlobalState): RelationOneToMany<Topic, TopicThread> {
    return state.entities.threads.unreadThreadsInTopic;
}

export function getThreadIdsInTopic(state: GlobalState, topicId: Topic['id']): string[]  {
    return state.entities.threads.threadsInTopic?.[topicId] || [];
}

export function getThreadsFirstLoadedInTopic(state: GlobalState, topicId: Topic['id']): boolean {
    return state.entities.threads.threadsFirstLoadCompleted?.[topicId] || false;
}

export function getThreadsFirstLoadCompleted(state: GlobalState): RelationOneToOne<Topic, boolean> {
    return state.entities.threads.threadsFirstLoadCompleted;
}

export function getUnreadThreadIdsInTopic(state: GlobalState, topicId: Topic['id']): string[]  {
    return state.entities.threads.unreadThreadsInTopic?.[topicId] || [];
}
export const getThreadsFirstLoadedInCurrentTopic: (state: GlobalState) => boolean = createSelector(
    'getThreadsFirstLoadedInCurrentTopic',
    getCurrentTopicId,
    getThreadsFirstLoadCompleted,
    (
        currentTopicId, 
        threadsFirstLoadCompleted,
    ) => {
        return threadsFirstLoadCompleted?.[currentTopicId] ?? false;
    },
);

export const getThreadsInCurrentTopic: (state: GlobalState) => Array<TopicThread['id']> = createSelector(
    'getThreadsInCurrentTopic', 
    getCurrentTopicId, 
    getThreadsInTopic, 
    (
        currentTopicId, 
        threadsInTopic,
    ) => {
        return threadsInTopic?.[currentTopicId] ?? [];
    },
);

export const getUnreadThreadsInCurrentTopic: (state: GlobalState) => Array<TopicThread['id']> = createSelector(
    'getUnreadThreadsInCurrentTopic',
    getCurrentTopicId, 
    getUnreadThreadsInTopic,
    (
        currentTopicId, 
        unreadThreadsInTopic,
    ) => {
        return unreadThreadsInTopic?.[currentTopicId] ?? [];
    },
);

export function getThreadCounts(state: GlobalState): ThreadsState['counts'] {
    return state.entities.threads.counts;
}


/*
export function getThreadCountsIncludingDirect(state: GlobalState): ThreadsState['counts'] {
    return state.entities.threads.countsIncludingDirect
}
*/

export const getThreadCountsInCurrentTopic: (state: GlobalState) => ThreadsState['counts'][Topic['id']] = createSelector(
    'getThreadCountsInCurrentTopic',
    getCurrentTopicId, 
    getThreadCounts, 
    (
        currentTopicId, 
        counts,
    ) => {
        return counts?.[currentTopicId];
    },
);

export const isThreadIdSending = (state: GlobalState, threadId: TopicThread['id']): boolean => {
    return state.entities.threads.pendingThreadIds.some((sendingThreadId) => sendingThreadId === threadId);
}

export function getThreads(state: GlobalState): IDMappedObjects<TopicThread> {
    return state.entities.threads.threads;
}

export function getThread(state: GlobalState, threadId?: TopicThread['id']) {
    if (!threadId) {
        return null;
    }

    const threads = getThreads(state);
    return threads[threadId];
}

export function getThreadOrSynthentic(state: GlobalState, rootPost: Post): TopicThread |  TopicThreadSynthetic {
    const thread = getThreads(state)[rootPost.id];

    if (thread?.id) {
        return thread;
    }

    return {
        id: rootPost.id, 
        type: TopicThreadType.Synthetic, 
        reply_count: rootPost.reply_count,
        participants: rootPost.participants, 
        last_reply_at: rootPost.last_reply_at ?? 0,
        is_following: thread?.is_following ?? rootPost.is_following ?? null,
        post: {
            user_id: rootPost.user_id, 
            topic_id: rootPost.topic_id,
        },
    };
}

export const getThreadOrderInTopic: (state: GlobalState, topicId: Topic['id'], selectedThreadIdInTopic?: TopicThread['id']) => Array<TopicThread['id']> = createSelector(
    'getThreadOrderInTopic',
  // getThreadsInCurrentTopic, 
    (state: GlobalState, topicId: Topic['id']) => getThreadIdsInTopic(state, topicId),
    getThreads, 
    (state: GlobalState, topicId: Topic['id'], selectedThreadIdInTopic?: TopicThread['id']) => selectedThreadIdInTopic, 
    (
        threadsInTopic, 
        threads,
        selectedThreadIdInTopic,
    ) => {
        /*
        const ids = threadsInTopic.filter((id) => {
            const thread = threads[id];
            // return thread.is_following && (thread.unread_replies || thread.unread_mentions)
            return thread.unread_replies || thread.unread_mentions;
        });
        */

        const ids = [...threadsInTopic.filter((id) => threads[id].is_following)];
        

        if (selectedThreadIdInTopic && !ids.includes(selectedThreadIdInTopic)) {
            ids.push(selectedThreadIdInTopic);
        }

        return sortByLastReply(ids, threads);
    },
);


export const getSearchThreadsInCurrentTopic: (state: GlobalState,  selectedThreadIdInTopic?: TopicThread['id']) => Array<TopicThread['id']> = createSelector(
    'getSearchThreadsInCurrentTopic',
     (state: GlobalState) => state.entities.search.results,
    getThreads, 
    (state: GlobalState, selectedThreadIdInTopic?: TopicThread['id']) => selectedThreadIdInTopic, 
    (
        threadIds, 
        threads,
        selectedThreadIdInTopic,
    ) => {
        /*
        const ids = threadsInTopic.filter((id) => {
            const thread = threads[id];
            // return thread.is_following && (thread.unread_replies || thread.unread_mentions)
            return thread.unread_replies || thread.unread_mentions;
        });
        */

        const ids = [...threadIds.filter((id) => threads[id].is_following)];
        

        if (selectedThreadIdInTopic && !ids.includes(selectedThreadIdInTopic)) {
            ids.push(selectedThreadIdInTopic);
        }

        return sortByLastReply(ids, threads);
    },
);

// Returns the matches text from search results, if the server has provided them.
// If these matches are not present, null will be returned
export function getSearchMatches(state: GlobalState): {
    [x: string]: string[];
} {
    return state.entities.search.matches;
}

export const getThreadOrderInCurrentTopic: (state: GlobalState, selectedThreadIdInTopic?: TopicThread['id']) => Array<TopicThread['id']> = createSelector(
    'getThreadOrderInCurrentTopic',
     getThreadsInCurrentTopic, 
    getThreads, 
    (state: GlobalState, selectedThreadIdInTopic?: TopicThread['id']) => selectedThreadIdInTopic, 
    (
        threadsInTopic, 
        threads,
        selectedThreadIdInTopic,
    ) => {
        /*
        const ids = threadsInTopic.filter((id) => {
            const thread = threads[id];
            // return thread.is_following && (thread.unread_replies || thread.unread_mentions)
            return thread.unread_replies || thread.unread_mentions;
        });
        */

        const ids = [...threadsInTopic.filter((id) => threads[id].is_following)];
        

        if (selectedThreadIdInTopic && !ids.includes(selectedThreadIdInTopic)) {
            ids.push(selectedThreadIdInTopic);
        }

        return sortByLastReply(ids, threads);
    },
);

export const getNewestThreadInTopic: (state: GlobalState, topicID: string) => (TopicThread | null) = createSelector(
    'getNewestThreadInTopic',
    getThreadsInTopic, 
    getThreads, 
    (state: GlobalState, topicID: string) => topicID, 
     (
        threadsInTopic, 
        threads, 
        topicId: string,
     ) => {
        const threadsInGivenTopic = threadsInTopic?.[topicId] ?? [];
        if (!threadsInGivenTopic) {
            return null;
        }
        
        return threads[sortByLastReply([...threadsInGivenTopic], threads)[0]];
     },
);

export const getUnreadThreadOrderInTopic: (
    state: GlobalState,
    topicId: Topic['id'],
    selectedThreadIdInTopic?: TopicThread['id'],
) => Array<TopicThread['id']> = createSelector(
    'getUnreadThreadOrderInCurrentTopic',
    (state: GlobalState, topicId: Topic['id']) => getUnreadThreadIdsInTopic(state, topicId),
    getThreads,
    (state: GlobalState, topicId: Topic['id'], selectedThreadIdInTopic?: TopicThread['id']) => selectedThreadIdInTopic,
    (
        threadsInTopic, 
        threads, 
        selectedThreadIdInTopic,
    ) => {
        const ids = threadsInTopic.filter((id) => {
            const thread = threads[id];
            return thread.unread_replies || thread.unread_mentions;
        });

        if (selectedThreadIdInTopic && !ids.includes(selectedThreadIdInTopic)) {
            ids.push(selectedThreadIdInTopic);
        }

        return sortByLastReply(ids, threads);
    }
);

export const getUnreadThreadOrderInCurrentTopic: (
    state: GlobalState,
    selectedThreadIdInTopic?: TopicThread['id'],
) => Array<TopicThread['id']> = createSelector(
    'getUnreadThreadOrderInCurrentTopic',
    getUnreadThreadsInCurrentTopic,
    getThreads,
    (state: GlobalState, selectedThreadIdInTopic?: TopicThread['id']) => selectedThreadIdInTopic,
    (
        threadsInTopic, 
        threads, 
        selectedThreadIdInTopic,
    ) => {
        const ids = threadsInTopic.filter((id) => {
            const thread = threads[id];
            return thread.unread_replies || thread.unread_mentions;
        });

        if (selectedThreadIdInTopic && !ids.includes(selectedThreadIdInTopic)) {
            ids.push(selectedThreadIdInTopic);
        }

        return sortByLastReply(ids, threads);
    }
);


function sortByLastReply(ids: Array<TopicThread['id']>, threads: ReturnType<typeof getThreads>) {
    return ids.sort((a, b) => threads[b].last_reply_at - threads[a].last_reply_at);
}

