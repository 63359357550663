import ClientSfClass, {DEFAULT_LIMIT_AFTER, DEFAULT_LIMIT_BEFORE, HEADER_X_VERSION_ID} from './client_sf';

const ClientSf = new ClientSfClass();

export {
    ClientSf,
    DEFAULT_LIMIT_AFTER,
    DEFAULT_LIMIT_BEFORE,
    HEADER_X_VERSION_ID,
};

export type {TelemetryHandler} from './telemetry';
//export {RudderTelemetryHandler} from './rudder';