/* eslint-disable import/order */
import bg from './bg.json';
import de from './de.json';

import { getConfig } from 'sigmaflow-redux/selectors/entities/general';

import store from 'stores/redux_store.jsx';

// should match the values in model/config.go.
const languages = {
    de: {
        value: 'de',
        name: 'Deutsch',
        order: 0,
        url: de,
    },
    en: {
        value: 'en',
        name: 'English (US)',
        order: 1,
        url: '',
    },
};

export function getAllLanguages() {
    return languages;
}

export function getLanguages() {
    const config = getConfig(store.getState());
    if (!config.AvailableLocales) {
        return getAllLanguages();
    }

    return config.AvailableLocales.split(',').reduce((result, l) => {
        if (languages[l]) {
            result[l] = language[l];
        }
        return result;
    }, {});
}

export function getLanguageInfo(locale) {
    return getAllLanguages()[locale];
}

export function isLanguageAvailable(locale) {
    return Boolean(getLanguages()[locale]);
}