import { combineReducers } from 'redux';

import { ThreadTypes } from 'sigmaflow-redux/action_types';

import { GenericAction } from 'sigmaflow-redux/types/actions';
import { ThreadsRequestsStatuses, RequestStatusType } from 'sigmaflow-redux/types/requests';

import {handleRequest, initialRequestState} from './helpers';

function createThread(state: RequestStatusType = initialRequestState(), action: GenericAction): RequestStatusType {
    if (action.type === ThreadTypes.CREATE_THREAD_RESET_REQUEST) {
        return initialRequestState();
    }

    return handleRequest(
        ThreadTypes.CREATE_THREAD_REQUEST,
        ThreadTypes.CREATE_THREAD_SUCCESS, 
        ThreadTypes.CREATE_THREAD_FAILURE, 
        state, 
        action,
    );
}

function getThreads(state: RequestStatusType = initialRequestState(), action: GenericAction): RequestStatusType {
   

    return handleRequest(
        ThreadTypes.GET_THREADS_REQUEST,
        ThreadTypes.GET_THREADS_SUCCESS, 
        ThreadTypes.GET_THREADS_FAILURE, 
        state, 
        action,
    );
}

function getThreadPosts(state: RequestStatusType = initialRequestState(), action: GenericAction): RequestStatusType {
   

    return handleRequest(
        ThreadTypes.GET_THREAD_POSTS_REQUEST,
        ThreadTypes.GET_THREAD_POSTS_SUCCESS, 
        ThreadTypes.GET_THREAD_POSTS_FAILURE, 
        state, 
        action,
    );
}

export default (combineReducers({
    createThread, 
    getThreads,
    getThreadPosts,
}) as (b: ThreadsRequestsStatuses, a: GenericAction) => ThreadsRequestsStatuses);