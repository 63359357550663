import {
    applyMiddleware,
    createStore,
    Reducer,
    Store,
} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import {GlobalState} from 'sigmaflow-redux/types/store';
import serviceReducers from '../reducers';

import reducerRegistry from './reducer_registry';

import {createReducer} from './helpers';
import initialState from './initial_state';

/**
 * Configures and constructs the redux store. Accepts the following parameters:
 * preloadedState - any preloaded state to be applied to the store after it is initially configured.
 * appReducer - An object containing any app-specific reducer functionality that the client needs.
 * getAppReducers - a function that returns the appReducer defined above.
 */
export default function configureStore<S extends GlobalState>({
    appReducers,
    getAppReducers,
    preloadedState,
} : {
    appReducers: Record<string, Reducer>;
    getAppReducers: () => Record<string,Reducer>;
    preloadedState: Partial<S>;
}): Store {
    const baseState = {
        ...initialState,
        ...preloadedState,
    };

    let middleware = applyMiddleware(thunk);
    middleware = composeWithDevTools({
        // set this to false to stop action from being dipatched again when reducers are replaced.
        shouldHotReload: false,
    })(middleware);

    const baseReducer = createReducer(serviceReducers, appReducers);

    const store = createStore(
        baseReducer, 
        baseState, 
        middleware,
    );

    reducerRegistry.setChangeListener((reducers: Record<string, Reducer>) => {
        store.replaceReducer(createReducer(reducers, serviceReducers, appReducers));
    });

    if (module.hot) {
        // Enable webpack hot module replacement for reducers
        module.hot.accept(() => {
            const registryReducers = reducerRegistry.getReducers();
            const nextServiceReducers = require('../reducers').default; // eslint-disable-line global-require
            const nextAppReducers = getAppReducers();

            // Ensure registryReducers come first so that 
            // stored service/ app reducers are replaced by the new ones
            store.replaceReducer(createReducer(registryReducers, nextServiceReducers, nextAppReducers));
        });
    }

    return store;
}