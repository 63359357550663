/* eslint-disable @typescript-eslint/naming-convention */
const latinMap: {[key: string]: string} = {
    A: 'A',
    Å: 'A',
};

/* eslint-enable */
export function map(x: string) {
    return latinMap[x] || x;
}

export function latinise(input: string) {
    return input.replace(/[^A-Za-z0-9]/g, map);
}