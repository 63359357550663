import {createSelector} from 'reselect';

import {General, Preferences} from 'sigmaflow-redux/constants';

import { getConfig, getLicense } from 'sigmaflow-redux/selectors/entities/general';

import { AddMembersToTopictreatments } from 'sigmaflow-redux/constants/config';
import { PreferencesType, PreferenceType } from 'sigmaflow-redux/types/preferences';
import { GlobalState } from 'sigmaflow-redux/types/store';
import {Theme} from 'sigmaflow-redux/types/themes';

import {createShallowSelector} from 'sigmaflow-redux/utils/helpers';
import {getPreferenceKey} from 'sigmaflow-redux/utils/preference_utils';
import { setThemeDefaults } from 'sigmaflow-redux/utils/theme_utils';


export function getMyPreferences(state: GlobalState): {[x: string]: PreferenceType} {
    return state.entities.preferences.myPreferences;
}


export function get(state: GlobalState, category: string, name: string, defaultValue: any = '') {
    const key = getPreferenceKey(category, name);
    const prefs = getMyPreferences(state);
    
    if (!(key in prefs)) {
        return defaultValue;
    }

    return prefs[key].value;
}

export function getBool(state: GlobalState, category: string, name: string, defaultValue = false): boolean {
    const value = get(state, category, name, String(defaultValue));
    return value !== 'false';
}

export function getInt(state: GlobalState, category: string, name: string, defaultValue = 0): number {
    const value = get(state, category, name, defaultValue);
    return parseInt(value, 10);
}


export function makeGetCategory(): (state: GlobalState, category: string) => PreferenceType[] {
    return createSelector(
        'makeGetCategory',
        getMyPreferences, 
        (state: GlobalState, category: string) => category,
        (preferences, category) => {
            const prefix = category + '--';
            const prefsInCategory: PreferenceType[] = [];

            for (const key in preferences) {
                if (key.startsWith(prefix)) {
                    prefsInCategory.push(preferences[key]);
                }
            }

            return prefsInCategory;
        },
    );
}

const getDirectShowCategory = makeGetCategory();

export function getDirectShowPreferences(state: GlobalState) {
    return getDirectShowCategory(state, Preferences.CATEGORY_DIRECT_TOPIC_SHOW);
}

const getGroupShowCategory = makeGetCategory();

export function getGroupShowPreferences(state: GlobalState) {
    return getGroupShowCategory(state, Preferences.CATEGORY_GROUP_TOPIC_SHOW);
}



export const getWspaceMateNameDisplaySetting: (state: GlobalState) => string = createSelector(
    'getWspaceMateNameDisplaysetting',
    getConfig, 
    getMyPreferences,
    getLicense,
    (config, preferences, license) => {
       // const useAdminOrgmateNameDisplaySetting = (license && license.lockOrgmateNameDisplay === 'true') && config.lockOrgmateNameDisplay === 'true';
       // const key = getPreferenceKey(Preferences.CATEGORY_DISPLAY_SETTINGS, Preferences.NAME_NAME_FORMAT);
       /*
        if (preferences[key] && !useAdminOrgmateNameDisplaySetting) {
            return preferences[key].value || '';
        } else if (config.OrgmateNameDisplay) {
            return config.OrgmateNameDisplay;
        }
        */

        return General.ORGMATE_NAME_DISPLAY.SHOW_USERNAME;
    },
);

const getThemePreference = createSelector(
    'getThemePreference',
    getMyPreferences,
   // (state) => state.entities.workspaces.currentWorkspaceId,
    (myPreferences) => {
        // Prefer the user's current workspace-specific theme over the user's current global theme.
        let themePreference;

       
        themePreference = myPreferences[getPreferenceKey(Preferences.CATEGORY_THEME, 'home')];
        

        if (!themePreference) {
            themePreference = myPreferences[getPreferenceKey(Preferences.CATEGORY_THEME, '')];
        }

        return themePreference;
    },
);

/*
export type ThemeKey = 'denim' | 'sapphire' | 'quartz' | 'indigo' | 'onyx';

export type ThemeType = 'Denim' | 'Sapphire' | 'Quartz' | 'Indigo' | 'Onyx';

export type Theme = {
    [key: string]: string | undefined;
    type?: ThemeType | 'custom';
    sidebarBg: string;
    sidebarText: string;
    sidebarUnreadText: string;
    sidebarTextHoverBg: string;
    sidebarTextActiveBorder: string;
    sidebarTextActiveColor: string;
    sidebarHeaderBg: string;
    sidebarOrgBarBg: string;
    sidebarHeaderTextColor: string;
    onlineIndicator: string;
    awayIndicator: string;
    dndIndicator: string;
    mentionBg: string;
    mentionBj: string;
    mentionColor: string;
    centerTopicBg: string;
    centerTopicColor: string;
    newMessageeparator: string;
    linkColor: string;
    buttonBg: string;
    buttonColor: string;
    errorTextColor: string;
    mentionHighlightBg: string;
    mentionHighlightLink: string;
    codeTheme: string;
};

*/



const getDefaultTheme = createSelector('getDefaultTheme', getConfig, (config): Theme => {
    if (config.DefaultTheme && config.DefaultTheme in Preferences.THEMES) {
        const theme: Theme = Preferences.THEMES[config.DefaultTheme];
        if (theme) {
            return theme;
        }
    }

    // If no config.DefaultTheme or value doesn't refer to a valid theme name..
    return Preferences.THEMES.denim;
});

export const getTheme: (state: GlobalState) => Theme = createShallowSelector(
    'getTheme',
    getThemePreference,
    getDefaultTheme,
    (themePreference, defaultTheme): Theme => {
        const themeValue: Theme | string = themePreference?.value ?? defaultTheme;

        // A custom theme will be JSON serialized object stored in a preference at this point.
        // At this point, the theme should be a plain object.
        const theme: Theme = typeof themeValue === 'string' ? JSON.parse(themeValue) : themeValue;

        return setThemeDefaults(theme);

    },
);


export function makeGetStyleFromTheme<Style>(): (state: GlobalState, getStyleFromTheme: (theme: Theme) => Style) => Style {
    return createSelector(
        'makeGetStyleFromTheme',
        getTheme, 
        (state: GlobalState, getStyleFromTheme: (theme: Theme) => Style) => getStyleFromTheme,
        (theme, getStyleFromTheme) => {
            return getStyleFromTheme(theme);
        }
    );
}

// shouldShowUnreadsCategory returns true if the user has unreads grouped separately
// with the new sidebar enabled.
export const shouldShowUnreadsCategory: (state: GlobalState) => boolean = createSelector(
    'shouldShowUnreadsCategory', 
    (state: GlobalState) => get(state, Preferences.CATEGORY_SIDEBAR_SETTINGS, Preferences.SHOW_UNREAD_SECTION),
    (state: GlobalState) => get(state, Preferences.CATEGORY_SIDEBAR_SETTINGS, ''),
    (userPreference, oldUserPreference) => {
        if (userPreference) {
            return userPreference === 'true';
        }

        if (oldUserPreference) {
            return JSON.parse(oldUserPreference).unreads_at_top === 'true';
        }

        return true;
    },
);

// TODO:
export function getUnreadScrollPositionPreference(state: GlobalState): string {
    return get(state, Preferences.CATEGORY_ADVANCED_SETTINGS, Preferences.SHOW_UNREAD_SECTION);
}

export function getUseCaseOnboarding(state: GlobalState): boolean {
   // return getFeatureFlagValue(state, 'UserCaseOnboarding') === 'true';
   return false;
}

export function insightsAreEnabled(state: GlobalState): boolean {
    //const isConfiguredForFeature = getConfig(state).InsightsEnabled === 'true';

    return false;

}

export function syncedDraftsAreAllowedAndEnabled(state: GlobalState): boolean {
    return false;
}