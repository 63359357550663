import { max } from "lodash";

import {General, Permissions, Preferences} from 'sigmaflow-redux/constants';
import { CategoryTypes } from "sigmaflow-redux/constants/topic_categories";

import { getCategoryByType } from "./topic_categories";

import { 
    getCurrentTopicId,
    getCurrentUser,
    getCurrentUserId,
    getMyTopicMemberships,
    getMyCurrentTopicMembership,
    getUsers,
 } from "./common";

 /*
 import {
     haveITopicPermission, 
     haveICurrentTopicPermission,
 } from 'sigmaflow-redux/selectors/entities/roles';
 */
 import {getMyWorkspaces} from 'sigmaflow-redux/selectors/entities/workspaces';

 import {
     getStatusForUserId,
     getUser,
     getUserIdsInTopics,
     isCurrentUserSystemAdmin,
 } from 'sigmaflow-redux/selectors/entities/users';

 import {
     Topic, 
    // TopicMemberCountsByGroup, 
     TopicMembership,
     TopicMessageCount, 
     //TopicModeration, 
     TopicSearchOpts, 
     TopicStats,
 } from 'sigmaflow-redux/types/topics';
 import { GlobalState } from "sigmaflow-redux/types/store";
 import { Workspace } from "sigmaflow-redux/types/workspaces";
 import { UserProfile, UsersState } from "sigmaflow-redux/types/users";
 import { 
     IDMappedObjects,
     RelationOneToMany, 
     RelationOneToManyUnique,
     RelationOneToOne,
    } from "sigmaflow-redux/types/utilities";

    import {
        calculateUnreadCount, 
        completeDirectTopicDisplayName, 
        completeDirectTopicInfo,
        completeDirectGroupInfo,
        filterTopicsMatchingTerm,
        getTopicByName as getTopicByNameHelper, 
        getUserIdFromTopicName, 
        isTopicMuted, 
        isDefault, 
        isDirectTopic, 
        newCompleteDirectTopicInfo, 
        sortTopicsByDisplayName, 
    } from 'sigmaflow-redux/utils/topic_utils';

    import {createIdsSelector} from 'sigmaflow-redux/utils/helpers';
    import { createSelector } from "reselect";

    import { getThreadCounts } from "./threads";
import { TopicThreadCount } from "sigmaflow-redux/types/threads";



export type TopicFilters = {
    inactive?: boolean;
    active?: boolean;
    workspace_types?: string[];
}


    export {getCurrentTopicId, getMyTopicMemberships, getMyCurrentTopicMembership};

    export function getAllTopics(state: GlobalState): IDMappedObjects<Topic> {
        return state.entities.topics.topics;
    }

    export const getAllDmTopics = createSelector(
        'getAllDmTopics', 
        getAllTopics, 
        (allTopics) => {
            let allDmTopics: Record<string, Topic> = {};

            Object.values(allTopics).forEach((topic: Topic) => {
                if (topic.type === General.DM_TOPIC) {
                    allDmTopics = {...allDmTopics, [topic.name]: topic};
                }
            });

            return allDmTopics;
        },
    );


    export function getAllTopicStats(state: GlobalState): RelationOneToOne<Topic, TopicStats> {
        return state.entities.topics.stats;
    }

    export function getTopicsInWorkspace(state: GlobalState): RelationOneToMany<Workspace, Topic> {
        return state.entities.topics.topicsInWorkspace;
    }

    export const getDirectTopicsSetInWorkspace: (state: GlobalState) => Set<string> = createSelector(
        'getDirectTopicsSetInWorkspace',
        getTopicsInWorkspace, 
        (topicsInWorkspace: RelationOneToMany<Workspace, Topic>): Set<string> => {
            if (!topicsInWorkspace) {
                return new Set();
            }

            return new Set(topicsInWorkspace['']);
        },
    );

    export const getDirectTopicsSet: (state: GlobalState) => Set<string> = createSelector(
        'getDirectTopicsSet',
        getAllTopics, 
        (topics: IDMappedObjects<Topic>): Set<string> => {
            if (!topics) {
                return new Set();
            }

            // TODO:
            return new Set();
        },
    );

    export function getTopicMembersInTopics(state: GlobalState): RelationOneToOne<Topic, Record<string, TopicMembership>> {
        return state.entities.topics.membersInTopic;
    }

    // makeGetTopic returns a selector that returns a topic from the store with the following
    // filled in for DM/GM topics:
    // - the display_name set to the otehr user(s) names, following the OrgMate Name display setting.
    // - the wspace_mate id for DM topic.
    // - the status of the other user in a DM topic.
    export function makeGetTopic(): (state: GlobalState, props: {id: string}) => Topic {
        return createSelector(
            'makeGetTopic',
            getCurrentUserId,
            (state: GlobalState) => state.entities.users.profiles, 
            (state: GlobalState) => state.entities.users.profilesInTopic, 
            (state: GlobalState, props: {id: string}) => {
                const topic = getTopic(state, props.id);
                if (!topic || !isDirectTopic(topic)) {
                    return '';
                }

                const currentUserId = getCurrentUserId(state);
                const wspaceMateId = getUserIdFromTopicName(currentUserId, topic.name);
                const wspaceMateStatus = getStatusForUserId(state, wspaceMateId);

                return wspaceMateStatus || 'offline';

            },
            (state: GlobalState, props: {id: string}) => getTopic(state, props.id),
            (currentUserId, profiles, profilesInTopic, wspaceMateStatus, topic) => {
                if (topic) {
                    return newCompleteDirectTopicInfo(currentUserId, profiles, profilesInTopic, wspaceMateStatus, Preferences.DISPLAY_PREFER_FULL_NAME, topic);
                }

                return topic;
            },
        );
    }

    // getTopic returns a topic as it exists in the store without filling in any additional details
    // such as the display_name for DM/GM topics.
    
    export function getTopic(state: GlobalState, id: string) {
        return getAllTopics(state)[id];
    }
    

    export function getMyTopicMembership(state: GlobalState, topicId: string): TopicMembership {
        return getMyTopicMemberships(state)[topicId];
    }

    // makeGetTopicsForIds returns a selector that, given an array of topic IDs, returns a list of 
    // the corresponding topics. Topics are returned in the same order as the given IDs with
    // undefined entries replacing any invalid IDs.
    // Note that memoization will fail if an array literal is passed in.
    export function makeGetTopicsForIds(): (state: GlobalState, ids: string[]) => Topic[] {
        return createSelector(
            'makeGetTopicsForIds', 
            getAllTopics, 
            (state: GlobalState, ids: string[]) => ids, 
            (allTopics, ids) => {
                return ids.map((id) => allTopics[id]);
            },
        );
    }

    export const getCurrentTopic: (state: GlobalState) => Topic = createSelector(
        'getCurrentTopic',
        getAllTopics, 
        getCurrentTopicId, 
        (state: GlobalState) => state.entities.users,
        (allTopics, currentTopicId, users): Topic => {
            const topic = allTopics[currentTopicId];

            if (topic) {
                return completeDirectTopicInfo(users, Preferences.DISPLAY_PREFER_FULL_NAME, topic);
            }

            return topic;
        },
    );

    export const getCurrentTopicNameForSearchShortcut: (state: GlobalState) => string | undefined = createSelector(
        'getCurrentTopicNameForSearchShortcut', 
        getAllTopics, 
        getCurrentTopicId, 
        (state: GlobalState): UsersState => state.entities.users, 
        (allTopics: IDMappedObjects<Topic>, currentTopicId: string, users: UsersState): string | undefined => {
            const topic = allTopics[currentTopicId];

            // Only get the extra infor from users if we need it.
            if (topic?.type === General.DM_TOPIC) {
                const dmTopicWithInfo = completeDirectTopicInfo(users, Preferences.DISPLAY_PREFER_USERNAME, topic);
                return `@${dmTopicWithInfo.display_name}`;
            }

            // Replace spaces in GM topic names
            if (topic?.type === General.GM_TOPIC) {
                const gmTopicWithInfo = completeDirectTopicInfo(users, Preferences.DISPLAY_PREFER_USERNAME, topic);
                return `@${gmTopicWithInfo.display_name.replace(/\s/g, '')}`
            }

            return topic?.name;
        },
    );

    export const getMyTopicMember: (state: GlobalState, topicId: string) => TopicMembership | undefined | null = createSelector(
        'getMyTopicMember',
        getMyTopicMemberships, 
        (state: GlobalState, topicId: string): string => topicId, 
        (topicMemberships: RelationOneToOne<Topic, TopicMembership>, topicId: string):  TopicMembership | undefined | null => {
            return topicMemberships[topicId] || null;
        },
    );


    export const getCurrentTopicStats: (state: GlobalState) => TopicStats = createSelector(
        'getCurrentTopicStats',
        getAllTopicStats, 
        getCurrentTopicId, 
        (allTopicStats: RelationOneToOne<Topic, TopicStats>, currentTopicId: string): TopicStats => {
            return allTopicStats[currentTopicId];
        },
    );

    export function isCurrentTopicFavorite(state: GlobalState): boolean {
        const currentTopicId = getCurrentTopicId(state);
        return isFavoriteTopic(state, currentTopicId);
    }

    export const isCurrentTopicMuted: (state: GlobalState) => boolean = createSelector(
        'isCurrentTopicMuted', 
        getMyCurrentTopicMembership, 
        (membership?: TopicMembership): boolean => {
            if (!membership) {
                return false;
            }

            return isTopicMuted(membership);
        },
    );

    export const isMutedTopic: (state: GlobalState, topicId: string)=> boolean = createSelector(
        'isMutedTopic', 
        (state: GlobalState, topicId: string) =>  getMyTopicMembership(state, topicId),
        (membership?: TopicMembership): boolean => {
            if (!membership) {
                return false;
            }

            return isTopicMuted(membership);
        },
    );

    export const isCurrentTopicArchived: (state: GlobalState) => boolean = createSelector(
        'isCurrentTopicArchived',
        getCurrentTopic,
        (topic) => topic.delete_at !== 0,
    );

    export const isCurrentTopicDefault: (state: GlobalState) => boolean = createSelector(
        'isCurrentTopicDefault',
        getCurrentTopic, 
        (topic) => isDefault(topic),
    );


    export function isCurrentTopicReadOnly(state: GlobalState): boolean {
        return isTopicReadOnly(state, getCurrentTopic(state));
    }

    export function isTopicReadOnlyById(state: GlobalState, topicId: string): boolean {
        return isTopicReadOnly(state, getTopic(state, topicId));
    }

    export function isTopicReadOnly(state: GlobalState, topic: Topic): boolean {
        return topic  && topic.name === General.DEFAULT_TOPIC && !isCurrentUserSystemAdmin(state);
    }


    export function getTopicMessageCounts(state: GlobalState): RelationOneToOne<Topic, TopicMessageCount> {
        return state.entities.topics.messageCounts;
    }

    export function getTopicMessageCount(state: GlobalState, topicId: string): TopicMessageCount {
        return getTopicMessageCounts(state)[topicId];
    }

    export function getTopicThreadCounts(state: GlobalState): RelationOneToOne<Topic, TopicThreadCount> {
        return state.entities.threads.counts;
    }

    export function getTopicThreadCount(state: GlobalState, topicId: string): TopicThreadCount {
        return getTopicThreadCounts(state)[topicId];
    }



    function getCurrentTopicMessageCount(state: GlobalState) {
        return getTopicMessageCount(state, getCurrentTopicId(state));
    }

    export const countCurrentTopicUnreadMessages: (state: GlobalState) => number = createSelector(
        'countCurrentTopicUnreadMessages', 
        getCurrentTopicMessageCount, 
        getMyCurrentTopicMembership, 
        (messageCount: TopicMessageCount, membership?: TopicMembership): number => {
            if (!membership) {
                return 0;
            }

            return messageCount.root - membership.msg_count_root;

           // return messageCount.total_msg - membership.msg_count
        },
    );


    export function makeGetTopicUnreadCount(): (state: GlobalState, topicId: string) => ReturnType<typeof calculateUnreadCount> {
        return createSelector(
            'makeGetTopicUnreadCount', 
            (state: GlobalState, topicId: string) => getTopicMessageCount(state, topicId),
            (state: GlobalState, topicId: string) => getMyTopicMembership(state, topicId),
            (messageCount: TopicMessageCount, member: TopicMembership) => 
                 calculateUnreadCount(messageCount, member),
        );
    }

    export function makeGetTopicThreadUnreadCount(): (state: GlobalState, topicId: string) => TopicThreadCount | null | undefined {
        return createSelector(
            'makeGetTopicThreadUnreadCount',
            (state: GlobalState, topicId: string) => getTopicThreadCount(state, topicId), 
            (threadCount: TopicThreadCount) => {
                return threadCount;
            }
        );
    }


    export function getTopicByName(state: GlobalState, topicName: string): Topic | undefined | null {
        return getTopicByNameHelper(getAllTopics(state), topicName);
    }

    export const getTopicSetInWorkspace: (state: GlobalState, wspaceId: string) => string[] = createSelector(
        'getTopicSetInWorkspace',
        (state: GlobalState, wspaceId: string) => wspaceId,
        getTopicsInWorkspace,
        (wspaceId: string, topicsInWorkspace: RelationOneToMany<Workspace, Topic>): string[] => {
            return (topicsInWorkspace && topicsInWorkspace[wspaceId]) || [];
        },
    );

    export const getTopicSetForAllWorkspaces: (state: GlobalState) => string[] = createSelector(
        'getTopicSetForAllWorkspaces',
        getAllTopics, 
        (allTopics): string[] => {
            const topicSet: string[] = [];
            Object.values(allTopics).forEach((topic: Topic) => {
                if (topic.type !== General.GM_TOPIC && topic.type !== General.DM_TOPIC) {
                    topicSet.push(topic.id);
                }
            });
            return topicSet;
        },
    );

    function sortAndInjectTopics(topics: IDMappedObjects<Topic>, topicSet: string[], locale: string): Topic[] {
        const currentTopics: Topic[] = [];

        if (typeof topicSet === 'undefined') {
            return currentTopics;
        }

        topicSet.forEach((c) => {
            currentTopics.push(topics[c]);
        });

        return currentTopics.sort(sortTopicsByDisplayName.bind(null, locale));
    }

    
    export const getTopicsInAllWorkspaces: (state: GlobalState) => Topic[] = createSelector(
        'getTopicsInAllWorkspaces',
        getAllTopics, 
        getTopicSetForAllWorkspaces, 
        getCurrentUser, 
        (topics: IDMappedObjects<Topic>, getTopicSetForAllWorkspaces: string[], currentUser: UserProfile): Topic[] => {
            const locale = currentUser?.locale || General.DEFAULT_LOCALE;
            return sortAndInjectTopics(topics, getTopicSetForAllWorkspaces, locale);
        },
    );

    export const getTopicListInWorkspace: (state: GlobalState, wspaceId: string) => Topic[] = createSelector(
        'getTopicsListInWorkspace',
        getAllTopics, 
        getTopicsInWorkspace, 
        (state: GlobalState, wspaceId: string): string => wspaceId, 
        (topics: IDMappedObjects<Topic>, topicsInWorkspaces: RelationOneToMany<Workspace, Topic>, wspaceId: string): Topic[] => {
            const topicsInWorkspace = topicsInWorkspaces[wspaceId] || [];
            const topicList:  Topic[] = [];
            topicsInWorkspace.forEach((id) => {
               topicList.push(topics[id]);
            });

            return topicList;
        },
    );

    export const getTopicsNameMapInWorkspace: (state: GlobalState, wspaceId: string) => Record<string, Topic> = createSelector(
        'getTopicsNameMapInWorkspace',
        getAllTopics, 
        getTopicsInWorkspace, 
        (state: GlobalState, wspaceId: string): string => wspaceId, 
        (topics: IDMappedObjects<Topic>, topicsInWorkspaces: RelationOneToMany<Workspace, Topic>, wspaceId: string): Record<string, Topic> => {
            const topicsInWorkspace = topicsInWorkspaces[wspaceId] || [];
            const topicMap: Record<string, Topic> = {};
            topicsInWorkspace.forEach((id) => {
                const topic = topics[id];
                topicMap[topic.name] = topic;
            });

            return topicMap;
        },
    );

    export const getTopicsNameMap: (state: GlobalState) => Record<string, Topic> = createSelector(
        'getTopicsNameMapForUser',
        getAllTopics, 
        (topics: IDMappedObjects<Topic>): Record<string, Topic> => {
            const topicMap: Record<string, Topic> = {};

            Object.values(topics).forEach((topic) => {
                topicMap[topic.name] = topic;
            });

            return topicMap;
            
        },
    );

    export const getTopicNameToDisplayNameMap: (state: GlobalState) => Record<string,string> = createSelector(
        'getTopicNameToDisplayNameMap',
        getAllTopics, 
        (topics: IDMappedObjects<Topic>) => {
            const topicMap: Record<string, string> = {};

            Object.values(topics).forEach((topic) => {
                topicMap[topic.name] = topic.display_name;
            });

            return topicMap;
        },
    );

    
    // Returns both DMs and GMs.
    export const getAllDirectTopics: (state: GlobalState) => Topic[] = createSelector(
        'getAllDirectTopics',
        getAllTopics, 
        getDirectTopicsSet, 
        (state: GlobalState): UsersState => state.entities.users,
        (topics: IDMappedObjects<Topic>, topicSet: Set<string>, users: UsersState ): Topic[] => {
            const dmTopics: Topic[] = [];
            topicSet.forEach((c) => {
                dmTopics.push(completeDirectGroupInfo(users, Preferences.DISPLAY_PREFER_FULL_NAME, topics[c]));
            });

            return dmTopics;
        },
    );

    // Returns only GMs.
    export const getGroupTopics: (state: GlobalState) => Topic[] = createSelector(
        'getGroupTopics',
        getAllTopics, 
        getDirectTopicsSet, 
        (state: GlobalState): UsersState => state.entities.users, 
        (topics: IDMappedObjects<Topic>, topicSet: Set<string>, users: UsersState) : Topic[] => {
            const gmTopics: Topic[] = [];
            topicSet.forEach((id) => {
                const topic = topics[id];

                if (topic.type === General.GM_TOPIC) {
                    gmTopics.push(completeDirectGroupInfo(users, Preferences.DISPLAY_PREFER_FULL_NAME, topic));
                }
            });
            return gmTopics;
        },
    );


    // TODO: review for selector performance
    export const getMyTopics: (state: GlobalState) => Topic[] = createSelector(
        'getMyTopics', 
        getAllTopics, 
        getAllDirectTopics, 
        getMyTopicMemberships,
        (topics: IDMappedObjects<Topic>, directTopics: Topic[], myMembers: RelationOneToOne<Topic, TopicMembership>): Topic[] => {
            
            return [...Object.values(topics), ...directTopics].filter((c) => myMembers.hasOwnProperty(c.id));
        }
    );

    export const getOtherTopics: (state: GlobalState, archived?: boolean | null) => Topic[] = createSelector(
        'getOtherTopics',
        getAllTopics,
        getMyTopicMemberships,
        (state: GlobalState, archived: boolean | undefined | null = true) => archived,
        (topics: IDMappedObjects<Topic>, myMembers: RelationOneToOne<Topic, TopicMembership>, archived?: boolean | null): Topic[] => {
            return Object.values(topics).filter((c) => !myMembers.hasOwnProperty(c.id) && c.type === General.OPEN_TOPIC && (archived ? true : c.delete_at === 0));
        },
    );


    export const getMembersInCurrentTopic: (state: GlobalState) => Record<string, TopicMembership> = createSelector(
        'getMembersInCurrentTopic', 
        getCurrentTopicId, 
        getTopicMembersInTopics, 
        (currentTopicId: string, members: RelationOneToOne<Topic, Record<string, TopicMembership>>): Record<string, TopicMembership> => {
            return members[currentTopicId];
        },
    );

    /**
     * A scalar encoding or primitive-value representation of 
     */
    export type BasicUnreadStatus = boolean | number;
    export type BasicUnreadMeta = {isUnread: boolean; unreadMentionCount: number}
    export function basicUnreadMeta(unreadStatus: BasicUnreadStatus): BasicUnreadMeta {
        return {
            isUnread: Boolean(unreadStatus), 
            unreadMentionCount: (typeof unreadStatus === 'number' && unreadStatus) || 0,
        };
    }

    export const getUnreadStatus: (state: GlobalState) => BasicUnreadStatus = createSelector(
        'getUnreadStatus', 
        getAllTopics,
        getMyTopicMemberships, 
        getTopicMessageCounts, 
        getUsers, 
        getCurrentUserId, 
        getThreadCounts, 
        (topics,
            myMembers, 
            messageCounts, 
            users, 
            currentUserId,
            threadCounts,
            ) => {
                const {
                    messages: unreadMesages,
                    mentions: unreadMentions, 
                } = Object.entries(myMembers).reduce((counts, [topicId, membership]) => {
                    const topic = topics[topicId];

                    if (!topic || !membership) {
                        return counts;
                    }

                    if (
                        // other non-DM/non-GM topics
                        topic.type !== General.DM_TOPIC && 
                        topic.type !== General.GM_TOPIC
                    ) {
                        return counts;
                    }

                    const topicExists = topic.type === General.DM_TOPIC ? users[getUserIdFromTopicName(currentUserId, topic.name)]?.delete_at === 0: topic.delete_at === 0;
                    if (topicExists) {
                        return counts;
                    }

                    counts.mentions += membership.mention_count_root;

                    const unreadCount = calculateUnreadCount(messageCounts[topicId], myMembers[topicId]);
                    if (unreadCount.showUnread) {
                        counts.messages += unreadCount.messages;
                    }

                    return counts;
                }, {
                    messages: 0,
                    mentions: 0,
                });

                // Includes mention count and message

                const totalUnreadMessages = unreadMesages;
                let totalUnreadMentions = unreadMentions;
                let anyUnreadThreads = false;


                Object.keys(threadCounts).forEach((topicId) => {
                    const c = threadCounts[topicId];

                    anyUnreadThreads = anyUnreadThreads || Boolean(c.total_unread_threads);
                    totalUnreadMentions += c.total_unread_mentions;
                    
                });

                return totalUnreadMentions || anyUnreadThreads || Boolean(totalUnreadMessages);
            }
    );

    export const getAllDirectTopicIds: (state: GlobalState) => string[] = createIdsSelector(
        'getAllDirectTopicIds', 
        getDirectTopicsSet, 
        (directIds: Set<string>): string[] => {
            return Array.from(directIds);
        },
    );


    export const getTopicIdsInAllWorkspaces: (state: GlobalState) => string[] = createIdsSelector(
        'getTopucIdsInAllWorkspaces',
        getTopicSetForAllWorkspaces, 
        (topics): string[] => {
            return Array.from(topics || []);
        },
    );


    export const getUnreadTopicIds: (state: GlobalState, lastUnreadTopic?: Topic | null) => string[] = createSelector(
        'getUnreadTopicIds', 
       getMyTopicMemberships, 
       getTopicMessageCounts, 
       getTopicIdsInAllWorkspaces,
       (state: GlobalState, lastUnreadTopic: Topic | undefined | null = null): Topic | undefined | null => lastUnreadTopic,
       (
           members: RelationOneToOne<Topic, TopicMembership>,
           messageCounts: RelationOneToOne<Topic, TopicMessageCount>,
           topicIds: string[],
           lastUnreadTopic?: Topic | null, 
        ) : string[] => {
            const unreadIds = topicIds.filter((id) => {
                return calculateUnreadCount(messageCounts[id], members[id]).showUnread;
            });

            if (lastUnreadTopic && members[lastUnreadTopic.id] && !unreadIds.includes(lastUnreadTopic.id)) {
                unreadIds.push(lastUnreadTopic.id);
            }

            return unreadIds;
        },
    );

    export const getUnreadTopics: (state: GlobalState, lastUnreadTopic?: Topic | null) => Topic[] = createIdsSelector(
        'getUnreadTopics',
        getCurrentUser, 
        getUsers, 
        getUserIdsInTopics, 
        getAllTopics, 
        getUnreadTopicIds, 
        (currentUser, profiles, userIdsInTopics: any, topics, unreadIds) => {
            // If we receive an unread for a topic and then a mention the topic
            // won't be sorted correctly until we receive a message in another topic.
            if (!currentUser) {
                return [];
            }

            const allUnreadTopics = unreadIds.filter((id) => topics[id] && topics[id].delete_at === 0).map((id) => {
                const t = topics[id];

                if (t.type === General.DM_TOPIC || t.type === General.GM_TOPIC) {
                    return completeDirectTopicDisplayName(currentUser.id, profiles, userIdsInTopics[id], Preferences.DISPLAY_PREFER_FULL_NAME, t);
                }

                return t;
            });

            return allUnreadTopics;
        },
    );

    export const sortUnreadTopics = (
        topics: Topic[], 
        myMembers: RelationOneToOne<Topic, TopicMembership>,
        lastUnreadTopic: (Topic & {hadMentions: boolean}) | null,
    ) => {
        function isMuted(topic: Topic) {
            return isTopicMuted(myMembers[topic.id]);
        }

        function hasMentions(topic: Topic) {
            if (lastUnreadTopic && topic.id === lastUnreadTopic.id && lastUnreadTopic.hadMentions) {
                return true;
            }

            const member = myMembers[topic.id];
            return member?.mention_count !== 0;
        }

        // Sort topics with mentions first and then sort by recency.
        return [...topics].sort((a, b) => {
            // Sort muted topics last
            if (isMuted(a) && !isMuted(b)) {
                return 1;
            } else if (!isMuted(a) && isMuted(b)) {
                return -1;
            }

            // Sort non-muted mentions first
            if (hasMentions(a) && !hasMentions(b)) {
                return -1;
            } else if (!hasMentions(a) && hasMentions(b)) {
                return 1;
            }

            const aLastPostAt = max([a.last_root_post_at, a.create_at]) || 0;
            const bLastPostAt = max([b.last_root_post_at, b.create_at]) || 0;

            return bLastPostAt - aLastPostAt;
        });
    };

    export const getSortedAllWorkspacesUnreadTopics: (state: GlobalState) => Topic[] = createSelector(
        'getSortedAllWorkspacesUnreadTopics',
        getUnreadTopics, 
        getMyTopicMemberships, 
        (topics, myMembers) => {
            return sortUnreadTopics(topics, myMembers, null);
        },
    );

    const getProfiles = (currentUserId: string, usersIdsInTopic: Set<string>, users: IDMappedObjects<UserProfile>): UserProfile[] => {
        const profiles: UserProfile[] = [];
        usersIdsInTopic.forEach((userId) => {
            if (userId !== currentUserId) {
                profiles.push(users[userId]);
            }
        });

        return profiles;
    }

    /**
     * Returns an array of unsorted group topics, each with an array of the user profiles in the 
     * topic attached to them.
     */
    export const getTopicsWithUserProfiles: (state: GlobalState) => Array<{
        profiles: UserProfile[];
    }  & Topic> = createSelector(
        'getTopicsWithUserProfiles', 
        getUserIdsInTopics,
        getUsers, 
        getGroupTopics, 
        getCurrentUserId, 
        (topicUserMap: RelationOneToManyUnique<Topic, UserProfile>, users: IDMappedObjects<UserProfile>, topics: Topic[], currentUserId: string) => {
            return topics.map((topic: Topic): {
                profiles: UserProfile[];
            } & Topic => {
                const profiles = getProfiles(currentUserId, topicUserMap[topic.id] || new Set(), users);
                return {
                    ...topic, 
                    profiles,
                };
            });
        },
    );

    export const getMyFirstTopic: (state: GlobalState) => Topic | null = createSelector(
        'getMyFirstTopic',
        getAllTopics, 
        getMyTopicMemberships, 
        getCurrentUser,
        (allTopics: IDMappedObjects<Topic>, myTopicMemberships: RelationOneToOne<Topic, TopicMembership>, currentUser: UserProfile): Topic | null => {
            const locale = currentUser.locale || General.DEFAULT_LOCALE;
           

            const userTopics = Object.values(allTopics).filter((topic: Topic) => topic &&  Boolean(myTopicMemberships[topic.id])).sort(sortTopicsByDisplayName.bind(null, locale));

            if (userTopics.length === 0) {
                return null;
            }

            return userTopics[0];
        },
    );

    export const getRedirectTopicNameForUser = (state: GlobalState): string => {

        const myFirstTopic = getMyFirstTopic(state);

        return (myFirstTopic && myFirstTopic.name) || General.DEFAULT_TOPIC;
    }

    export const getRedirectTopicIdForUser = (state: GlobalState): string => {

        const myFirstTopic = getMyFirstTopic(state);

        return (myFirstTopic && myFirstTopic.id) || General.DEFAULT_TOPIC;
    }

    export const getRedirectTopicIdForUserU = (state: GlobalState): string => {
        const currentUserId = getCurrentUserId(state);
        const topics = getTopicsForUser(state, currentUserId);

        let result: string = '';
        if (topics && topics.length > 0) {
            result = topics[0].id;
        }
        return General.DEFAULT_TOPIC;
    }



    // isManuallyUnread looks into state if the provided topicId is marked as unread
    // by the user.
    export function isManuallyUnread(state: GlobalState, topicId?: string): boolean {
        if (!topicId) {
            return false;
        }

        return Boolean(state.entities.topics.manuallyUnread[topicId]);
    }

    export function isFavoriteTopic(state: GlobalState, topicId: string): boolean {
        const topic = getTopic(state, topicId);
        if (!topic) {
            return false;
        }

        const category = getCategoryByType(state, CategoryTypes.FAVORITES);

        if (!category) {
            return false;
        }

        return category.topic_ids.includes(topic.id);
    }

    export function filterTopicList(topicList: Topic[], filters: TopicSearchOpts): Topic[] {
        if (!filters || (!filters.private && !filters.public && !filters.deleted && !filters.workspace_ids)) {
            return topicList;
        }

        let result: Topic[] = [];
        const topicType: string[] = [];
        const topics = topicList;
        if (filters.public) {
            topicType.push(General.OPEN_TOPIC);
        }

        if (filters.private) {
            topicType.push(General.PRIVATE_TOPIC);
        }

        if (filters.deleted) {
            topicType.push(General.ARCHIVED_TOPIC);
        }

        topicType.forEach((type) => {
            result = result.concat(topics.filter((topic) => topic.type === type));
        });

        if (filters.workspace_ids && filters.workspace_ids.length > 0) {
            let wspaceResult: Topic[] = [];
            filters.workspace_ids.forEach((id) => {
                if (topicType.length > 0) {
                    const filterResult = result.filter((topic) => topic.workspace_id === id);
                    wspaceResult = wspaceResult.concat(filterResult);
                } else {
                    wspaceResult = wspaceResult.concat(topics.filter((topic) => topic.workspace_id === id));
                }
            });

            result = wspaceResult;
        }

        return result;
    }

    export function getDirectWorkspaceMate(state: GlobalState, topicId: string): UserProfile | undefined {
        const topic = getTopic(state, topicId);
        if (!topic) {
            return undefined;
        }

        const userIds = topic.name.split('__');
        const currentUserId = getCurrentUserId(state);

        if (userIds.length !== 2 || userIds.indexOf(currentUserId) === -1) {
            return undefined;
        }

        if (userIds[0] === userIds[1]) {
            return getUser(state, userIds[0])
        }

        for (const id of userIds) {
            if (id !== currentUserId) {
                return getUser(state, id);
            }
        }
        return undefined;
    }

export const getMyActiveTopicIds = createSelector(
        'getMyActiveTopics',
        getMyTopics,
        (topics) => topics.flatMap((topic) => {
            if (topic.delete_at > 0) {
                return [];
            }
            return topic.id;
        }),
    );

export function getTopicIdsForUser(state: GlobalState): RelationOneToMany<UserProfile, Topic> {
    return state.entities.topics.topicsForUser;
}

export function getTopicMembersForUser(state: GlobalState, userId: string): RelationOneToOne<Topic, TopicMembership> {
    return state.entities.topics.topicMembersForUser?.[userId] || {};
}



export function getFilteredTopicsStatsForUser(state: GlobalState): any {
    return state.entities.topics.filteredTopicsStatsForUser;
}

export function getTopicsStatsForUser(state: GlobalState): any {
    return state.entities.topics.topicsStatsForUser;
}

export const getNewestTopicForUser: (state: GlobalState, userId: UserProfile['id']) => (Topic | null) = createSelector(
    'getNewestTopicForUser', 
    getAllTopics, 
    getTopicIdsForUser,
    (state: GlobalState, userId: string) => userId, 
    (
        topics, 
        topicIdsForUser, 
        userId: string,
    ) => {
        const topicIdsForThisUser = topicIdsForUser?.[userId] ?? [];
        if (!topicIdsForThisUser) {
            return null;
        } 

        return topics[sortByLastPostAt(topicIdsForThisUser, topics)[0]]
    },
);



function sortByLastPostAt(ids: Array<Topic['id']>, topics: ReturnType<typeof getAllTopics>) {
    return ids.filter((id) => topics[id].last_post_at !== 0).sort((a, b) => topics[b].last_post_at - topics[a].last_post_at);
}

export const getTopicsForUser: (state: GlobalState, userId: UserProfile['id'], filters?: TopicFilters) => Topic[] = createSelector(
    'getTopicsForUser', 
    getAllTopics, 
    getTopicIdsForUser, 
    getTopicMembersForUser, 
    (state: GlobalState, userId: string) => userId, 
    (state: GlobalState, userId: string, filters: TopicFilters) => filters, 
    (topics, topicsForUser, memberships, userId, filters) => {
       return sortAndInjectTopicsForUser(filterTopics(topics, filters, memberships), topicsForUser[userId] || new Set())
    }
);

function sortAndInjectTopicsForUser(topics: IDMappedObjects<Topic>, topicSet?: 'all' | Array<Topic['id']> | Set<Topic['id']>): Topic[] {
    const currentTopics = injectTopics(topics, topicSet);
    
    return currentTopics.sort((a: Topic, b: Topic) => {
        const b_changed_at = Boolean(b.last_post_at) ? b.last_post_at : b.update_at;
        const a_changed_at = Boolean(a.last_post_at) ? a.last_post_at : a.update_at;
        //return b.last_post_at - a.last_post_at;
        return b_changed_at - a_changed_at;
    });
}

function injectTopics(topics: IDMappedObjects<Topic>, topicSet?: 'all' |  Array<Topic['id']> | Set<Topic['id']>): Topic[] {
    let currentTopics: Topic[] = [];

    if (typeof topicSet === 'undefined') {
        return currentTopics;
    } else if (topicSet === 'all') {
        currentTopics = Object.keys(topics).map((key) => topics[key]);
    } else {
        currentTopics = Array.from(topicSet).map((t) => topics[t]);
    }

    return currentTopics.filter((topic) => Boolean(topic));
}


export function filterTopics(topics: IDMappedObjects<Topic>, filters?: TopicFilters, memberships?: RelationOneToOne<Topic, TopicMembership>): IDMappedObjects<Topic> {
    if (!filters) {
        return topics;
    }

    let currentTopics = Object.keys(topics).map((key) => topics[key]);

    if (filters.workspace_types && filters.workspace_types.length > 0) {
        currentTopics = currentTopics.filter((topic) => filters.workspace_types?.some((ws_type) => topic.workspace_type === ws_type));
    }

    if (filters.inactive) {
        currentTopics = currentTopics.filter((topic) => topic.delete_at !== 0);
    } else if (filters.active) {
        currentTopics = currentTopics.filter((topic) => topic.delete_at === 0)
    }

    return currentTopics.reduce((acc, topic) => {
        acc[topic.id] = topic;
        return acc;
    }, {} as IDMappedObjects<Topic>);
}



export function searchTopicsForUser(state: GlobalState, userId: UserProfile['id'], term: string, filters?: TopicFilters): Topic[] {
    const topics = filterTopicsMatchingTerm(getTopicsForUser(state, userId, filters), term);
    return topics;
}
