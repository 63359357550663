import FormData from 'form-data';

import {Options, StatusOK, ClientResponse, LogLevel, FetchPaginatedThreadOptions} from 'sigmaflow-redux/types/client_sf';

import { SystemSetting } from 'sigmaflow-redux/types/general';

// import type {AppBinding} from 'sigmaflow-redux/types/apps';
import { Audit } from 'sigmaflow-redux/types/audits';
import { UserAutocomplete, AutocompleteSuggestion } from 'sigmaflow-redux/types/autocomplete';
// import {Bot, BotPatch}


import {
    Org,
    OrgStats,
    OrgsWithCount,
    OrgSearchOpts,
    OrgInviteWithError,
    GetFilteredOrgsStatsOpts,
    OrgsStats,
    OrgInvite,
    OrgInvitesWithCount,
} from 'sigmaflow-redux/types/orgs';

import {
    Topic,
    TopicMembership,
    TopicStats,
    TopicsWithTotalCount,
    TopicWithWorkspaceData,
    TopicSearchOpts,
    ServerTopic,
    UserOrgForDM,
    TopicUnread,
    TopicsStatsForUser,
    GetFilteredTopicsStatsForUserOpts,
    TopicsWithCount,
} from 'sigmaflow-redux/types/topics';

import {
    ClientConfig,
    ClientLicense, 
    DataRetentionPolicy, 
    License,
    //AdminConfig,
    //EnvironmentConfig,
} from 'sigmaflow-redux/types/config';

import { ServerError } from 'sigmaflow-redux/types/errors';

import { PreferenceType } from 'sigmaflow-redux/types/preferences';
import { Role } from 'sigmaflow-redux/types/roles';
import { SamlCertificateStatus, SamlMetadataResponse } from 'sigmaflow-redux/types/saml';
import { Session } from 'sigmaflow-redux/types/sessions';

import { 
    GetFilteredWorkspacesStatsOpts,
    GetWorkspaceMembersOpts,
    GetWorkspaceOrgMembersOpts,
    Workspace,
   WorkspaceInviteWithError,
   WorkspaceMembership,
   // GetWorkspaceMemberOpts,
    WorkspaceMemberWithError,
    WorkspaceOrgMembership,
    WorkspaceOrgMemberWithError,
    WorkspacesStatsForUser,
    WorkspaceStats,
    WorkspacesWithCount,
    XWorkspacesStats,
     } from 'sigmaflow-redux/types/workspaces';

import {TermsOfService} from 'sigmaflow-redux/types/terms_of_service';
import { MfaSecret } from 'sigmaflow-redux/types/mfa';

import {
         AuthChangeResponse,
         UserAccessToken,
         UserProfile,
         UsersStats,
         UserStatus,
         GetFilteredUsersStatsOpts,
         UserCustomStatus,
     } from 'sigmaflow-redux/types/users';

     import { RelationOneToOne } from 'sigmaflow-redux/types/utilities';

     import { CompleteOnboardingRequest } from 'sigmaflow-redux/types/setup';

    import {buildQueryString} from 'sigmaflow-redux/utils/helpers_client';
    import {TelemetryHandler} from './telemetry';


     import {isSystemAdmin} from 'sigmaflow-redux/utils/user_utils';
     import { General } from 'sigmaflow-redux/constants';
import { boolean, string } from 'yargs';
import { logClientError } from 'sigmaflow-redux/actions/general';
import session from 'redux-persist/lib/storage/session';
import { OrderedTopicCategories, TopicCategory } from 'sigmaflow-redux/types/topic_categories';
import { PaginatedPostList, Post, PostList, PostSearchResults } from 'sigmaflow-redux/types/posts';
import { Reaction } from 'sigmaflow-redux/types/reactions';
import { FileInfo, FileUploadResponse, FileSearchResults } from 'sigmaflow-redux/types/files';
import { TopicThread, TopicThreadList, TopicThreadWithPost } from 'sigmaflow-redux/types/threads';
import workspace from 'components/admin_console/workspace_topic_settings/workspace';
import { Address, CloudCustomer, CloudCustomerPatch, Invoice, Limits, NotifyAdminRequest, Product, ValidBusinessEmail } from 'sigmaflow-redux/types/cloud';




     const HEADER_AUTH = 'Authorization';
     const HEADER_BEARER = 'BEARER';
     const HEADER_CONTENT_TYPE = 'Content-Type';
     const HEADER_REQUESTED_WITH = 'X-Requested-With';
     const HEADER_USER_AGENT = 'User-Agent';
     const HEADER_X_CSRF_TOKEN = 'X-CSRF-Token';
     export const HEADER_X_VERSION_ID = 'X-Version-Id';
     const PER_PAGE_DEFAULT = 60;
     const LOGS_PER_PAGE_DEFAULT = 10000;
     export const DEFAULT_LIMIT_BEFORE = 30;
     export const DEFAULT_LIMIT_AFTER = 30;
     const GRAPHQL_ENDPOINT = '/api/v5/graphql';

     export default class ClientSf {
         logToConsole = false;
         serverVersion = '';
         token = '';
         csrf = '';
         url = '';
         urlVersion = '/api';
         userAgent: string | null = null;
         enableLogging = false;
         defaultHeaders: {[x: string]: string} = {};
         userId = '';
         diagnosticId = '';
         includeCookies = true;
         setAuthHeader = true;

         translations = {
             connectionError: 'There appears to be a problem with your internet connection.',
             unknownError: 'We received an unexpected status code from the server.',
         };
         userRoles?: string;
         telemetryHandler?: TelemetryHandler;

        getUrl() {
            return this.url;
        }

        getAbsoluteUrl(baseUrl: string) {
            if (typeof baseUrl !== 'string' || !baseUrl.startsWith('/')) {
                return baseUrl;
            }

            return this.getUrl() + baseUrl;
        }

        getGraphQLUrl() {
            return `${this.url}${GRAPHQL_ENDPOINT}`;
        }

        setUrl(url: string) {
            this.url = url;
        }

        setUserAgent(userAgent: string) {
            this.userAgent = userAgent;
        }

        getToken() {
            return this.token;
        }

        setToken(token: string) {
            this.token = token;
        }

        setCSRF(csrfToken: string) {
            this.csrf = csrfToken;
        }

        setAcceptLanguage(locale: string) {
            this.defaultHeaders['Accept-Language'] = locale;
        }

        setEnableLogging(enable: boolean) {
            this.enableLogging = enable;
        }

        setIncludeCookies(include: boolean) {
            this.includeCookies = include;
        }

        setUserId(userId: string) {
            this.userId = userId;
        }

        setUserRoles(roles: string) {
            this.userRoles = roles;
        }

        setDiagnosticId(diagnosticId: string) {
            this.diagnosticId = diagnosticId;
        }

        getServerVersion() {
            return this.serverVersion;
        }

        getUrlVersion() {
            return this.urlVersion;
        }

        getBaseRoute() {
            return `${this.url}${this.urlVersion}`; 
        }

        // This function belongs to the APPs Framework feature.
        // Apps framework feature is experimental, and this function is succeptible
        // to breaking changes without pushing the major version of this package.
        getAppsProxyRoute() {
            return `${this.url}/plugins/com.sigmaflow.apps`;
        }



        getUsersRoute() {
            return `${this.getBaseRoute()}/users`;
        }

        getUserRoute(userId: string) {
            return `${this.getUsersRoute()}/${userId}`;
        }

        getOrgsRoute() {
            return `${this.getBaseRoute()}/orgs`
        }

        getOrgRoute(orgId: string) {
            return `${this.getOrgsRoute()}/${orgId}`;
        }

        getOrgDomainNameRoute(orgDomain: string) {
            return `${this.getOrgsRoute()}/domain/${orgDomain}`;
        }

        getWorkspacesRoute() {
            return `${this.getBaseRoute()}/workspaces`;
        }

        getXWorkspacesRoute() {
            return `${this.getBaseRoute()}/xworkspaces`;
        }

        getWorkspacesRouteWithHostOrg(wspaceTypeOrHostOrgId: string) {
            return `${this.getBaseRoute()}/workspaces/${wspaceTypeOrHostOrgId}`;
        }

        getWorkspaceRoute(wspaceTypeOrHostOrgId: string, wspaceId: string) {
            return `${this.getWorkspacesRouteWithHostOrg(wspaceTypeOrHostOrgId)}/ids/${wspaceId}`;
        }

        getWorkspaceSchemeRoute( wspaceTypeOrHostOrgId: string, workspaceId: string) {
            return `${this.getWorkspaceRoute(wspaceTypeOrHostOrgId, workspaceId)}/scheme`;
        }

        getWorkspaceNameRoute(wspaceTypeOrHostOrgId: string, wspaceName: string) {
            return `${this.getWorkspacesRouteWithHostOrg(wspaceTypeOrHostOrgId)}/name/${wspaceName}`;
        }

        getWorkspaceMembersRoute(wspaceTypeOrHostOrgId: string, wspaceId: string) {
            return `${this.getWorkspaceRoute(wspaceTypeOrHostOrgId, wspaceId)}/members`;
        }

        getWorkspaceOrgMembersRoute(wspaceTypeOrHostOrgId: string, wspaceId: string) {
            return `${this.getWorkspaceRoute(wspaceTypeOrHostOrgId, wspaceId)}/orgmembers`;
        }

        getWorkspaceMemberRoute(wspaceTypeOrHostOrgId: string, wspaceId: string, userId: string) {
            return `${this.getWorkspaceMembersRoute(wspaceTypeOrHostOrgId, wspaceId)}/${userId}`
        }

        getWorkspaceOrgMemberRoute(wspaceTypeOrHostOrgId: string, wspaceId: string, memberOrgId: string) {
            return `${this.getWorkspaceOrgMembersRoute(wspaceTypeOrHostOrgId, wspaceId)}/${memberOrgId}`
        }

        getWorkspaceOrgMembersForOrgRoute(orgId: string) {
            return `${this.getOrgRoute(orgId)}/xworkspaces/org_members`;
        }

        // xprofiles - xorg profile and images
        getXProfilesRoute(userHostOrgId: string) {
            return `${this.getBaseRoute()}/xprofiles/${userHostOrgId}/ids`;
        }

        getXProfileRoute(userHostOrgId: string, userId: string) {
            return `${this.getXProfilesRoute(userHostOrgId)}/${userId}`;
        }


        getTopicsRoute(wspaceTypeOrHostOrgId: string) {
            return `${this.getBaseRoute()}/topics/${wspaceTypeOrHostOrgId}/ids`;
        }

        getTopicRoute(wspaceTypeOrHostOrgId: string, topicId: string) {
            return `${this.getTopicsRoute(wspaceTypeOrHostOrgId)}/${topicId}`;
        }

        getTopicMembersRoute(wspaceTypeOrHostOrgId: string, topicId: string) {
            return `${this.getTopicRoute(wspaceTypeOrHostOrgId, topicId)}/members`;
        }

        getTopicMemberRoute(wspaceTypeOrHostOrgId: string, topicId: string, userId: string) {
            return `${this.getTopicMembersRoute(wspaceTypeOrHostOrgId, topicId)}/${userId}`;
        }

        getTopicSchemeRoute(wspaceTypeOrHostOrgId: string, topicId: string) {
            return `${this.getTopicRoute(wspaceTypeOrHostOrgId, topicId)}/scheme`;
        }

        getTopicCategoriesRoute(userId: string) {
            return `${this.getUserRoute(userId)}/topics/categories`;
        }

        getTopicShardRoute(topicId: string) {
            return `${this.getBaseRoute()}/topics/${topicId}`;
        }

        getPostsRouteOld(topicId: string) {
            return `${this.getBaseRoute()}/topics/${topicId}/posts`;
        }

        getPostRouteOld(topicId: string, postId: string) {
            return `${this.getPostsRouteOld(topicId)}/${postId}`;
        }

        getPostsRoute(topicId: string, hostOrgId: string) {
            return `${this.getBaseRoute()}/topics/${hostOrgId}/ids/${topicId}/posts`;
        }

        getPostRoute(topicId: string, hostOrgId: string, postId: string) {
            return `${this.getPostsRoute(topicId, hostOrgId)}/${postId}`;
        }

        getTopicThreadsRoute(topicId: string, wspaceHostOrgId: string): string {
            return `${this.getBaseRoute()}/topics/${wspaceHostOrgId}/ids/${topicId}/threads`;
        }

        getTopicThreadsRouteForUser(topicId: string, wspaceHostOrgId: string, userId: string): string {
            return `${this.getUserRoute(userId)}/topics/${wspaceHostOrgId}/ids/${topicId}/threads`;
        }

        getTopicThreadsSearchRouteForUser(topicId: string, wspaceHostOrgId: string, userId: string): string {
            return `${this.getUserRoute(userId)}/topics/${wspaceHostOrgId}/ids/${topicId}/threads/search`;
        }

        getTopicThreadRoute(topicId: string, wspaceHostOrgId: string, threadId: string): string {
            return `${this.getTopicThreadsRoute(topicId, wspaceHostOrgId)}/${threadId}`;
        }

        getTopicThreadRouteForUser(topicId: string, wspaceHostOrgId: string, userId: string, threadId: string): string {
            return `${this.getTopicThreadsRouteForUser(topicId, wspaceHostOrgId, userId)}/${threadId}`;
        }

        getThreadPostsRouteForUser(topicId: string, wspaceHostOrgId: string, userId: string, threadId: string): string {
            return `${this.getTopicThreadRouteForUser(topicId, wspaceHostOrgId, userId, threadId)}/posts`;
        }

        getThreadPostsRoute(topicId: string, wspaceHostOrgId: string, threadId: string): string {
            return `${this.getTopicThreadRoute(topicId, wspaceHostOrgId, threadId)}/posts`;
        }

        getThreadPostRoute(topicId: string, wspaceHostOrgId: string, threadId: string, postId: string): string {
            return `${this.getThreadPostsRoute(topicId, wspaceHostOrgId, threadId)}/${postId}`;
        }

        getThreadMembersRoute(topicId: string, wspaceHostOrgId: string, threadId: string) {
            return `${this.getTopicThreadRoute(topicId, wspaceHostOrgId, threadId)}/members`;
        }

        getThreadMemberRoute(topicId: string, wspaceHostOrgId: string, threadId: string,  userId: string) {
            return `${this.getThreadMembersRoute(topicId, wspaceHostOrgId, threadId)}/${userId}`;
        }


        getReactionsRoute(topicId: string, hostOrgId: string) {
            return `${this.getBaseRoute()}/topics/${hostOrgId}/ids/${topicId}/reactions/`;
        }

        getFilesRoute(topicId: string, hostOrgId: string) {
            return `${this.getBaseRoute()}/topics/${hostOrgId}/ids/${topicId}/files`;
        }

        getFileRoute(topicId: string, hostOrgId: string, fileId: string) {
            return `${this.getFilesRoute(topicId, hostOrgId)}/${fileId}`;
        }
        
        
        getDataRetentionRoute() {
            return `${this.getBaseRoute()}/data_retention`;
        }


        getPreferencesRoute(userId: string) {
            return `${this.getUserRoute(userId)}/preferences`;
        }

        getOAuthRoute() {
            return `${this.url}/oauth`;
        }

        getOAuthAppsRoute() {
            return `${this.getBaseRoute()}/oauth/apps`;
        }

        getEmojisRoute() {
            return `${this.getBaseRoute()}/emoji`;
        }

        getEmojiRoute(emojiId: string) {
            return `${this.getEmojisRoute()}/${emojiId}`;
        }

        getBrandRoute() {
            return `${this.getBaseRoute()}/brand`;
        }

        getBrandImageUrl(timestamp: string) {
            return `${this.getBrandRoute()}/image?t=${timestamp}`;
        }

        getPluginsRoute() {
            return `${this.getBaseRoute()}/plugins`;
        }


        getPluginRoute(pluginId: string) {
            return `${this.getPluginsRoute()}/${pluginId}`;
        }

        getPluginsMarketplaceRoute() {
            return `${this.getPluginsRoute()}/marketplace`;
        }

        getRolesRoute() {
            return `${this.getBaseRoute()}/roles`;
        }


        getRedirectLocationRoute() {
            return `${this.getBaseRoute()}/redirect_location`;
        }

        getCloudRoute() {
            return `${this.getBaseRoute()}/cloud`;
        }

        getUsageRoute() {
            return `${this.getBaseRoute()}/usage`;
        }

        getPermissionsRoute() {
            return `${this.getBaseRoute()}/permissions`;
        }


        getSystemRoute() {
            return `${this.getBaseRoute()}/system`;
        }

        getCSRFFromCookie() {
            if (typeof document !== 'undefined' && typeof document.cookie !== 'undefined') {
                const cookies = document.cookie.split(';');
                for (let i = 0; i < cookies.length; i++) {
                    const cookie = cookies[i].trim();
                    if (cookie.startsWith('SFCSRF=')) {
                        return cookie.replace('SFCSRF=', '');
                    }
                }
            }
            return '';
        }

        getOptions(options: Options) {
            const newOptions: Options = {...options};

            const headers: {[x: string]: string} = {
                [HEADER_REQUESTED_WITH]: 'XMLHttpRequest',
                ...this.defaultHeaders,
            };

            if (this.setAuthHeader && this.token) {
                headers[HEADER_AUTH] = `${HEADER_BEARER} ${this.token}`;
            }

            const csrfToken = this.csrf || this.getCSRFFromCookie();
            if (options.method && options.method.toLowerCase() !== 'get' && csrfToken) {
                headers[HEADER_X_CSRF_TOKEN] = csrfToken;
            }

            if (this.includeCookies) {
                newOptions.credentials = 'include';
            }

            if (this.userAgent) {
                headers[HEADER_USER_AGENT] = this.userAgent;
            }

            if (options.body) {
                // When the body is an instance of FormData, we let fetch to set the
                // Content-Type header so it defines a correct boundary.
                if (!(options.body instanceof FormData)) {
                    headers[HEADER_CONTENT_TYPE] = 'application/json';
                }
            }

            if (newOptions.headers) {
                Object.assign(headers, newOptions.headers);
            }

            return {
                ...newOptions,
                headers,
            };
        }

        // User routes

        createUser = (user: UserProfile, token: string, inviteId: string, redirect: string) => {
           //  this.trackEvent('api', 'api_users_create');

           const queryParams: any = {};

           if (token) {
               queryParams.t = token;
           }

           if (inviteId) {
               queryParams.iid = inviteId;
           }

           if (redirect) {
               queryParams.r = redirect;
           }

           return this.doFetch<UserProfile>(
               `${this.getUsersRoute()}${buildQueryString(queryParams)}`,
               {method: 'post', body: JSON.stringify(user)},
           );
        }

        patchMe = (userPatch: Partial<UserProfile>) => {
            return this.doFetch<UserProfile>(
                `${this.getUserRoute('me')}/patch`,
                {method: 'put', body: JSON.stringify(userPatch)},
            );
        }

        patchUser = (userPatch: Partial<UserProfile> & {id: string}) => {
            // this.trackEvent('api', 'api_users_patch');
            return this.doFetch<UserProfile>(
                `${this.getUserRoute(userPatch.id)}/patch`,
                {method: 'put', body: JSON.stringify(userPatch)},
            );
        }

        updateUser = (user: UserProfile) => {
            // this.trackEvent('api', 'api_users_update');

            return this.doFetch<UserProfile>(
                `${this.getUserRoute(user.id)}`,
                {method: 'put', body: JSON.stringify(user)},
            );
        };

        updateUserRoles = (userId: string, roles: string) => {
            return this.doFetch<StatusOK>(
                `${this.getUserRoute(userId)}/roles`,
                {method: 'put', body: JSON.stringify({roles})},
            );
        };

        updateUserMfa = (userId: string, activate: boolean, code: string) => {
            const body: any = {
                activate,
            }

            if (activate) {
                body.code = code;
            }

            return this.doFetch<StatusOK>(
                `${this.getUserRoute(userId)}/mfa`,
                {method: 'put', body: JSON.stringify(body)},
            );
        };

    updateUserPassword = (userId: string, currentPassword: string, newPassword: string) => {
        return this.doFetch<StatusOK>(
            `${this.getUserRoute(userId)}/password`,
            {method: 'put', body: JSON.stringify({current_password: currentPassword, new_password: newPassword})},
        );
    };

    resetUserPassword = (token: string, newPassword: string) => {

        return this.doFetch<StatusOK>(
            `${this.getUsersRoute()}/password/reset`,
            {method: 'post', body: JSON.stringify({token, new_password: newPassword})},
        );
    }

    sendPasswordResetEmail = (email: string) => {
        return this.doFetch<StatusOK>(
            `${this.getUsersRoute()}/password/reset/send`,
            {method: 'post', body: JSON.stringify({email})},
        );
    }

    updateUserActive = (userId: string, active: boolean) => {
        return this.doFetch<StatusOK>(
            `${this.getUserRoute(userId)}/active`,
            {method: 'put', body: JSON.stringify({active})},
        );
    }

   uploadProfileImage = (userId: string, imageData: File) => {
    // this.trackEvent('api', 'api_users_update_profile_picture');

    const formData = new FormData();
    formData.append('image', imageData);
    const request: any = {
        method: 'post', 
        body: formData,
    };

    return this.doFetch<StatusOK>(
        `${this.getUserRoute(userId)}/image`, 
        request, 
    );

   }

   setDefaultProfileImage = (userId: string) => {
       return this.doFetch<StatusOK>(
           `${this.getUserRoute(userId)}/image`,
           {method: 'delete'},
       )
   };

   verifyUserEmail = (token: string) => {
       return this.doFetch<StatusOK>(
           `${this.getUsersRoute()}/email/verify`,
           {method: 'post', body: JSON.stringify({token})},
       );
   }

   updateMyTermsOfServiceStatus = (termsOfServiceId: string, accepted: boolean) => {
       return this.doFetch<StatusOK>(
           `${this.getUserRoute('me')}/terms_of_service`,
           {method: 'post', body: JSON.stringify({termsOfServiceId, accepted})},
       );
   }

   getTermsOfService = () => {
       return this.doFetch<TermsOfService>(
           `${this.getBaseRoute()}/terms_of_service`,
           {method: 'get'},
       );
   }

   createTermsOfService = (text: string) => {
       return this.doFetch<TermsOfService>(
           `${this.getBaseRoute()}/terms_of_service`,
           {method: 'post', body: JSON.stringify({text})},
       );
   }

   sendVerificationEmail = (email: string) => {
       return this.doFetch<StatusOK>(
           `${this.getUsersRoute()}/email/verify/send`,
           {method: 'post', body: JSON.stringify({email})},
       );
   }

   login = async (loginId: string, password: string, token = '', deviceId = '', ldapOnly = false) => {
       if (ldapOnly) {
           // this.trackEvent()
       }

       const body: any = {
           device_id: deviceId,
           login_id: loginId,
           password,
           token,
       };

       if (ldapOnly) {
           body.ldap_only = 'true';
       }

       const {
           data: profile,
           headers,
       } = await this.doFetchWithResponse<UserProfile>(
           `${this.getUsersRoute()}/login`,
           {method: 'post', body: JSON.stringify(body)},
       );

       if (headers.has('Token')) {
           this.setToken(headers.get('Token')!);
       }

       return profile;
   }

   loginById = (id: string, password: string, token = '', deviceId = '') => {
       const body: any = {
           device_id: deviceId,
           id,
           password,
           token,
       };

       return this.doFetch<UserProfile>(
           `${this.getUsersRoute()}/login`,
           {method: 'post', body: JSON.stringify(body)},
       );
   }

   logout = async () => {

    // this.trackEvent()

    const {response} = await this.doFetchWithResponse(
        `${this.getUsersRoute()}/logout`,
        {method: 'post'},
    );

    if (response.ok) {
        this.token = '';
    }

    this.serverVersion = '';
   }

   // org user invite
   sendEmailInvitesGracefully = (emails: string[]) => {

    return this.doFetch<OrgInviteWithError>(
        `${this.getUsersRoute()}/invite/email?graceful=true`,
        {method: 'post', body: JSON.stringify(emails)},
    );
   };


   getProfiles = (page = 0, perPage = PER_PAGE_DEFAULT, options = {}) => {
       return this.doFetch<UserProfile[]>(
           `${this.getUsersRoute()}${buildQueryString({page, per_page: perPage, ...options})}`,
           {method: 'get'},
       );
   }
   

   // Same Org (under the org domain)
   getProfilesByIds = (userIds: string[], options = {}) => {
    return this.doFetch<UserProfile[]>(
        `${this.getUsersRoute()}/ids${buildQueryString(options)}`, 
        {method: 'post', body: JSON.stringify(userIds)}
    );
   };

   getProfilesByIdsXOrg = (keyValuesMap: Record<string, Set<string>>, options = {}) => {
    return this.doFetch<UserProfile[]>(
        `${this.getUsersRoute()}/xorg_ids${buildQueryString(options)}`,
        {method: 'post', body: JSON.stringify(keyValuesMap, 
            (_key, value) => (value instanceof Set ? [...value]: value))},
    );
   }



   getProfilesByUsernamesXOrg = (keyValuesMap: Record<string, Set<string>>) => {
    return this.doFetch<UserProfile[]>(
        `${this.getUsersRoute()}/usernames_xorg`, 
        {method: 'post', body: JSON.stringify(keyValuesMap, 
            (_key, value) => (value instanceof Set ? [...value]: value))},
    );
   }



   getProfilesByUsernames = (usernames: string[]) => {
    return this.doFetch<UserProfile[]>(
        `${this.getUsersRoute()}/usernames`, 
        {method: 'post', body: JSON.stringify(usernames)},
    );
   }




   getProfilesInWorkspace = (workspaceId: string, wspaceHostOrgId: string, workspaceType: string, page = 0, perPage = PER_PAGE_DEFAULT, sort = '', options = {}) => {
       return this.doFetch<UserProfile[]>(
           `${this.getUsersRoute()}${buildQueryString({...options, in_workspace: workspaceId, workspace_hostorg: wspaceHostOrgId, workspace_type: workspaceType,  page, per_page: perPage, sort})}`,
           {method: 'get'},
       );
   };

   getProfilesNotInWorkspace = (workspaceId: string, workspaceHostOrgId: string, workspaceType: string, groupConstrained: boolean, page = 0, perPage = PER_PAGE_DEFAULT) => {
    const queryStringObj: any = {not_in_workspace: workspaceId, workspace_hostorg: workspaceHostOrgId, workspace_type: workspaceType, page, per_page: perPage};
    if (groupConstrained) {
        queryStringObj.group_constrained = true;
    }

    return this.doFetch<UserProfile[]>(
        `${this.getUsersRoute()}${buildQueryString(queryStringObj)}`,
        {method: 'get'},
    );
   };

   getProfilesInTopic = (topicId: string, wspaceHostOrgId: string, workspaceType: string, page = 0, perPage = PER_PAGE_DEFAULT, sort = '', options: {active?: boolean, member_org?: string} = {}) => {
    const queryStringObj = {in_topic: topicId, workspace_hostorg: wspaceHostOrgId, workspace_type: workspaceType, page, per_page: perPage, sort};

    return this.doFetch<UserProfile[]>(
        `${this.getUsersRoute()}${buildQueryString({...queryStringObj, ...options})}`,
        {method: 'get'},
    );
   };

   getProfilesNotInTopic = (wspaceId: string, topicId: string, wspaceHostOrgId: string, workspaceType: string, groupConstrained: boolean, page = 0, perPage = PER_PAGE_DEFAULT) => {
    const queryStringObj: any = {in_workspace: wspaceId, not_in_topic: topicId, workspace_hostorg: wspaceHostOrgId, workspace_type: workspaceType, page, per_page: perPage};
    if (groupConstrained) {
        queryStringObj.group_constrained = true;
    }
    return this.doFetch<UserProfile[]>(
        `${this.getUsersRoute()}${buildQueryString(queryStringObj)}`, 
        {method: 'get'},
    );
   };



   getMe = () => {
       return this.doFetch<UserProfile>(
           `${this.getUserRoute('me')}`,
           {method: 'get'},
       );
   }

   getMyOrg = () => {
    return this.doFetch<Org>(
        `${this.getOrgRoute('myorg')}`,
        {method: 'get'},
    );
}


   getUser = (userId: string) => {
       return this.doFetch<UserProfile>(
           `${this.getUserRoute(userId)}`,
           {method: 'get'},
       );
   }

   getUserByUsername = (username: string) => {
       return this.doFetch<UserProfile>(
           `${this.getUsersRoute()}/username/${username}`,
           {method: 'get'},
       );
   }

   getUsersByEmails = (emails: string[]) => {
    return this.doFetch<UserProfile[]>(
        `${this.getUsersRoute()}/emails`,
        {method: 'post', body: JSON.stringify(emails)},
    )
   }

   getUserByEmail = (email: string) => {
       return this.doFetch<UserProfile>(
           `${this.getUsersRoute()}/email/${email}`,
           {method: 'get'},
       );
   }

   getProfilePictureUrl = (userId: string, orgId: string, lastPictureUpdate: number) => {
       const params: any = {};

       if (lastPictureUpdate) {
           params._ = lastPictureUpdate;
       }

       if (orgId) {
        params.org_id = orgId
       }

       return `${this.getUserRoute(userId)}/image${buildQueryString(params)}`;
   }

   getOrgProfilePictureUrl = (orgId: string, lastPictureUpdate: number) => {
    const params: any = {};

    if (lastPictureUpdate) {
        params._ = lastPictureUpdate;
    }


    return `${this.getOrgRoute(orgId)}/image${buildQueryString(params)}`;
}



   getDefaultProfilePictureUrl = (userId: string, orgId = '') => {
       return `${this.getUserRoute(userId)}/image/default`;
   };

   autocompleteUsers = (name: string, workspaceId: string, topicId: string, wspaceHostOrgId: string, options = {
       limit: General.AUTOCOMPLETE_LIMIT_DEFAULT,
   }) => {
       return this.doFetch<UserAutocomplete>(`${this.getUsersRoute()}/autocomplete${buildQueryString({
           in_workspace: workspaceId,
           in_topic: topicId,
           in_workspace_hostorg: wspaceHostOrgId,
           name,
           limit: options.limit,
       })}`, {
           method: 'get',
       });
   }


   getSessions = (userId: string) => {
       return this.doFetch<Session[]>(
           `${this.getUserRoute(userId)}/sessions`,
           {method: 'get'}
       );
   }

   revokeSession = (userId: string, sessionId: string) => {
       return this.doFetch<StatusOK>(
           `${this.getUserRoute(userId)}/sessions/revoke`,
           {method: 'post', body: JSON.stringify({session_id: sessionId})},
       );
   };

   revokeAllSessionsForUser = (userId: string) => {
       return this.doFetch<StatusOK>(
           `${this.getUserRoute(userId)}/sessions/revoke/all`,
           {method: 'post'},
       );
   }


   getUserAudits = (userId: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
       return this.doFetch<Audit[]>(
           `${this.getUserRoute(userId)}/audits${buildQueryString({page, per_page: perPage})}`,
           {method: 'get'},
       );
   }


   checkUserMfa = (loginId: string) => {
       return this.doFetch<{mfa_required: boolean}>(
           `${this.getUsersRoute()}/mfa`,
           {method: 'post', body: JSON.stringify({login_id: loginId})},
       );
   }


   generateMfaSecret = (userId: string) => {
       return this.doFetch<MfaSecret>(
           `${this.getUserRoute(userId)}/mfa/generate`,
           {method: 'post'},
       );
   }

   attachDevice = (deviceId: string) => {
       return this.doFetch<StatusOK>(
           `${this.getUsersRoute()}/sessions/device`,
           {method: 'put', body: JSON.stringify({device_id: deviceId})},
       );
   }

   searchUsers = (term: string, options: any) => {
    // this.trackEvent('api', 'api_search_users');

    return this.doFetch<UserProfile[]>(
        `${this.getUsersRoute()}/search`, 
        {method: 'post', body: JSON.stringify({term, ...options})},
    );
   };

 searchUsersInOrg = (orgId: string, term: string, options: any) => {
    // this.trackEvent('api', 'api_search_users');

    return this.doFetch<UserProfile[]>(
        `${this.getOrgRoute(orgId)}/users/search`, 
        {method: 'post', body: JSON.stringify({term, ...options})},
    );
   };



   getStatusesByIds = (userIds: string[]) => {
       return this.doFetch<UserStatus[]>(
           `${this.getUsersRoute()}/status/ids`,
           {method: 'post', body: JSON.stringify(userIds)},
       );
   }

   getStatusesByIdsXOrg = (keyValuesMap: Record<string, Set<string>>) => {
    return this.doFetch<UserStatus[]>(
        `${this.getUsersRoute()}/status/ids_xorg`,
        {method: 'post', body: JSON.stringify(keyValuesMap, 
            (_key, value) => (value instanceof Set ? [...value]: value))},
    );
}

   getStatus = (userId: string) => {
       return this.doFetch<UserStatus>(
           `${this.getUserRoute(userId)}/status`,
           {method: 'get'},
       );
   }

   updateStatus = (status: UserStatus) => {
       return this.doFetch<UserStatus>(
           `${this.getUserRoute(status.user_id)}/status`,
           {method: 'put', body: JSON.stringify(status)},
       );
   };

   updateCustomStatus = (customStatus: UserCustomStatus) => {
    return this.doFetch(
        `${this.getUserRoute('me')}/status/custom`,
        {method: 'put', body: JSON.stringify(customStatus)},
    );
   };

   unsetCustomStatus = () => {
    return this.doFetch(
        `${this.getUserRoute('me')}/status/custom`,
        {method: 'delete'}
    );
   }

   removeRecentCustomStatus = (customStatus: UserCustomStatus) => {
    return this.doFetch(
        `${this.getUserRoute('me')}/status/custom/recent/delete`,
        {method: 'post', body: JSON.stringify(customStatus)},
    );
   }

   switchEmailToOAuth = (service: string, email: string, password: string, mfaCode = '') => {
    this.trackEvent('api', 'api_users_email-to_oauth');

    return this.doFetch<AuthChangeResponse>(
        `${this.getUsersRoute()}/login/switch`,
        {method: 'post', body: JSON.stringify({current_service: 'email', new_service: service, email, password, mfaCode: mfaCode})},
    );
   };

   switchOAuthToEmail = (currentService: string, email: string, password: string) => {
    this.trackEvent('api', 'api_users_oauth_to_email');

    return this.doFetch<AuthChangeResponse>(
        `${this.getUsersRoute()}/login/switch`,
        {method: 'post', body: JSON.stringify({current_service: currentService, new_service: 'email', email, new_password: password})},
    );
   }

   switchEmailToLdap = (email: string, emailPassword: string, ldapId: string, ldapPassword: string, mfaCode = '') => {

    return this.doFetch<AuthChangeResponse>(
        `${this.getUsersRoute()}/login/switch`,
        {method: 'post', body: JSON.stringify({current_service: 'email', new_service: 'ldap', password: emailPassword, ldap_id: ldapId, new_password: ldapPassword, mfa_code: mfaCode})},
    );
   };

   switchLdapToEmail = (ldapPassword: string, email: string,  emailPassword: string, mfaCode = '') => {
    this.trackEvent('api', 'api_users_ldap_to_email');

    return this.doFetch<AuthChangeResponse>(
        `${this.getUsersRoute()}/login/switch`,
        {method: 'post', body: JSON.stringify({current_service: 'ldap', new_service: 'email', password: ldapPassword, new_password: emailPassword, mfa_code: mfaCode})},
    );
   }

   // Org Routes

   createOrg = (org: Org) => {
       // this.trackEvent('api', 'api_orgs_create');

       return this.doFetch<Org>(
           `${this.getOrgsRoute()}`,
           {method: 'post', body: JSON.stringify(org)},
       );
   };

   deleteOrg = (orgId: string) => {
       return this.doFetch<StatusOK>(
           `${this.getOrgRoute(orgId)}`,
           {method: 'delete'},
       );
   };

   updateOrg = (org: Org) => {
      // this.trackEvent('api', 'api_orgs_update_name', {org_id: org.id});

      return this.doFetch<Org>(
          `${this.getOrgRoute(org.id)}`,
          {method: 'put', body: JSON.stringify(org)},
      );
   };

   patchOrg = (org: Partial<Org> & {id: string}) => {
       return this.doFetch<Org>(
           `${this.getOrgRoute(org.id)}/patch`,
           {method: 'put', body: JSON.stringify(org)},
       );
   };

   regenerateOrgInviteId = (orgId: string) => {

    return this.doFetch<Org>(
        `${this.getOrgRoute(orgId)}/regenerate_invite_id`,
        {method: 'post'},
    );
   };

   checkIfOrgExists = (orgDomain: string) => {
       return this.doFetch<{exists: boolean}>(
           `${this.getOrgDomainNameRoute(orgDomain)}/exists`,
           {method: 'get'},
       );
   };

   getOrgs = (page = 0, perPage = PER_PAGE_DEFAULT, includeTotalCount = false )  => {
       return this.doFetch<Org[] | OrgsWithCount>(
           `${this.getOrgsRoute()}${buildQueryString({page, per_page: perPage, include_total_count: includeTotalCount})}`,
           {method: 'get'},
       );
   }

   
    getOrgsByIds = (orgIds: string[], options = {}) => {
        return this.doFetch<Org[]>(
            `${this.getOrgsRoute()}/ids${buildQueryString(options)}`, 
            {method: 'post', body: JSON.stringify(orgIds)}
        );
       };
    

   searchOrgs = (term: string, opts: OrgSearchOpts) => {

    return this.doFetch<Org[] | OrgsWithCount>(
        `${this.getOrgsRoute()}/search`,
        {method: 'post', body: JSON.stringify({term, ...opts})},
    );
   };

   getOrg = (orgId: string) => {
       return this.doFetch<Org>(
           this.getOrgRoute(orgId),
           {method: 'get'},
       );
   };

   getOrgByDomain = (orgDomain: string) => {
       return this.doFetch<Org>(
           this.getOrgDomainNameRoute(orgDomain),
           {method: 'get'},
       );
   };

   addToOrgFromInvite = (token = '', inviteId = '') => {
       const query = buildQueryString({token, invite_id: inviteId});
       return this.doFetch<UserProfile>(
           `${this.getOrgsRoute()}/invite${query}`,
           {method: 'post'},
       );
   };


   getOrgStats = (orgId: string) => {
       return this.doFetch<OrgStats>(
           `${this.getOrgRoute(orgId)}/stats`,
           {method: 'get'},
       );
   };

   // orgInvites
   getOrgInvite = (orgId: string, inviteId: string) => {
    return this.doFetch<OrgInvite>(
        `${this.getOrgRoute(orgId)}/xworkspace/invites/${inviteId}`,
        {method: 'get'},
    );
};

getOrgInvites = (page = 0, perPage = PER_PAGE_DEFAULT, includeTotalCount = false )  => {
    return this.doFetch<OrgInvite[] | OrgInvitesWithCount>(
        `${this.getOrgsRoute()}/xworkspace/invites${buildQueryString({page, per_page: perPage, include_total_count: includeTotalCount})}`,
        {method: 'get'},
    );
}

getOrgInvitesForUser = (userId: string, page = 0, perPage = PER_PAGE_DEFAULT, includeTotalCount = false )  => {
    return this.doFetch<OrgInvite[] | OrgInvitesWithCount>(
        `${this.getUserRoute(userId)}/xworkspace/org_invites${buildQueryString({page, per_page: perPage, include_total_count: includeTotalCount})}`,
        {method: 'get'},
    );
}

updateOrgInviteStatus = (orgId: string, inviteId: string, status: string) => {
    return this.doFetch<OrgInvite>(
        `${this.getOrgRoute(orgId)}/xworkspace/invites/${inviteId}`,
        {method: 'post', body: JSON.stringify({status})},
    );
}

   invalidateAllEmailInvites = () => {
       return this.doFetch<StatusOK>(
           `${this.getOrgsRoute()}/invites/email`,
           {method: 'delete'},
       );
   };

   getOrgInviteInfo = (inviteId: string) => {
       return this.doFetch<{
           display_name: string;
           description: string;
           name: string;
           id: string;
       }>(
           `${this.getOrgsRoute()}/invite/${inviteId}`,
           {method: 'get'},
       );
   };


    // org admin invite
    sendEmailInvitesToOrgAdminsGracefully = (orgId: string, emails: string[]) => {

        return this.doFetch<OrgInviteWithError>(
            `${this.getOrgRoute(orgId)}/invite-admins/email?graceful=true`,
            {method: 'post', body: JSON.stringify(emails)},
        );
       };




   sendEmailInvitesToOrg = (orgId: string, emails: string[]) => {

    return this.doFetch<StatusOK>(
        `${this.getOrgRoute(orgId)}/invite/email`,
        {method: 'post', body: JSON.stringify(emails)},
    );
   };

   /*
   sendEmailInvitesToOrgGracefully = (orgId: string, emails: string[]) => {

    return this.doFetch<OrgInviteWithError>(
        `${this.getOrgRoute(orgId)}/invite/email?graceful=true`,
        {method: 'post', body: JSON.stringify(emails)},
    );
   };
   */

   getOrgIconUrl = (orgId: string, lastOrgIconUpdate: number) => {
       const params: any = {};
       if (lastOrgIconUpdate) {
           params._ = lastOrgIconUpdate;
       }

       return `${this.getOrgRoute(orgId)}/image${buildQueryString(params)}`;
   };

   setOrgIcon = (orgId: string, imageData: File) => {
       const formData = new FormData();
       formData.append('image', imageData);

       const request: any = {
           method: 'post',
           body: formData,
       }

       if (formData.getBoundary) {
           request.headers = {
               'Content-Type': `multipart/form-data; boundary=${formData.getBoundary()}`,
           };
       }

       return this.doFetch<StatusOK>(
           `${this.getOrgRoute(orgId)}/image`,
           request,
       );
   };

   removeOrgIcon = (orgId: string) => {

    return this.doFetch<StatusOK>(
        `${this.getOrgRoute(orgId)}/image`,
        {method: 'delete'},
    );
   }



   // Preference Routes

   savePreferences = (userId: string, preferences: PreferenceType[]) => {
       return this.doFetch<StatusOK>(
           `${this.getPreferencesRoute(userId)}`,
           {method: 'post', body: JSON.stringify(preferences)},
       );
   }

   getMyPreferences = () => {
       return this.doFetch<PreferenceType>(
           `${this.getPreferencesRoute('me')}`,
           {method: 'get'},
       );
   }

   deletePreferences = (userId: string, preferences: PreferenceType[]) => {
       return this.doFetch<StatusOK>(
           `${this.getPreferencesRoute(userId)}/delete`,
           {method: 'post', body: JSON.stringify(preferences)},
       );
   }

  

   getTotalUserStats = () => {
       return this.doFetch<UsersStats>(
           `${this.getUsersRoute()}/stats`,
           {method: 'get'},
       );
   }


   // General routes
   ping = () => {
       return this.doFetch<{
           status: string;
       }>(
           `${this.getBaseRoute()}/system/ping?time=${Date.now()}`,
           {method: 'get'},
       );
   }

   logClientError = (message: string, level = LogLevel.Error) => {
       const url = `${this.getBaseRoute()}/logs`;

       if (!this.enableLogging) {
           throw new ClientError(this.getUrl(), {
               message: 'LoggingDisabled.',
               url,
           });
       }

       return this.doFetch<{
           message: string;
       }>(
           url,
           {method: 'post', body: JSON.stringify({message, level})},
       );
   }

   getClientConfigOld = () => {
       return this.doFetch<ClientConfig>(
           `${this.getBaseRoute()}/config/client?format=old`,
           {method: 'get'},
       );
   };

   getClientLicenseOld = () => {
       return this.doFetch<ClientLicense>(
           `${this.getBaseRoute()}/license/client?format=old`,
           {method: 'get'},
       );
   }

   getWarnMetricsStatus = async () => {
       return this.doFetch(
           `${this.getBaseRoute()}/warn_metrics/status`,
           {method: 'get'},
       );
   };


   sendWarnMetricAck = async (warnMetricsId: string, forceAckVal: boolean) => {
       return this.doFetch(
           `${this.getBaseRoute()}/warn_metrics/ack/${encodeURI(warnMetricsId)}`,
           {method: 'post', body: JSON.stringify({forceAck: forceAckVal})},
       );
   }


   setFirstAdminVisitMarketplaceStatus = async () => {
       return this.doFetch<StatusOK>(
           `${this.getPluginsRoute()}/marketplace/first_admin_visit`,
           {method: 'post', body: JSON.stringify({first_admin_visit_marketplace_status: true})},
       );
   }

   getFirstAdminVisitMarketplaceStatus = async () => {
       return this.doFetch<SystemSetting>(
           `${this.getPluginsRoute()}/marketplace/first_admin_visit`,
           {method: 'get'}
       )
   }

   getFirstAdminSetupComplete = async () => {
       return this.doFetch<SystemSetting>(
           `${this.getSystemRoute()}/onboarding/complete`,
           {method: 'get'}
       );
   }

   getTranslations = (url: string) => {
       return this.doFetch<Record<string, string>>(
           url,
           {method: 'get'},
       );
   }

   getWebSocketUrl = () => {
       return `${this.getBaseRoute()}/websocket`;
   }

   // Data retention

   getDataRetentionPolicy = () => {
       return this.doFetch<DataRetentionPolicy>(
           `${this.getDataRetentionRoute()}/policy`,
           {method: 'get'},
       );
   }


   // Role routes

   getRole = (roleId: string) => {
       return this.doFetch<Role>(
           `${this.getRolesRoute()}/${roleId}`,
           {method: 'get'},
       );
   }

   getRoleByName = (roleName: string) => {
       return this.doFetch<Role>(
           `${this.getRolesRoute()}/name/${roleName}`,
           {method: 'get'},
       );
   }

   getRolesByNames = (rolesNames: string[]) => {
       return this.doFetch<Role[]>(
           `${this.getRolesRoute()}/names`,
           {method: 'post', body: JSON.stringify(rolesNames)},
       );
   }

   patchRole = (roleId: string, rolePatch: Partial<Role>) => {
       return this.doFetch<Role>(
           `${this.getRolesRoute()}/${roleId}/patch`,
           {method: 'put', body: JSON.stringify(rolePatch)},
       );
   }


   // Redirect Location.
   getRedirectLocation = (urlParam: string) => {
       if (!urlParam.length) {
           return Promise.resolve();
       }

       const url = `${this.getRedirectLocationRoute()}${buildQueryString({url: urlParam})}`;
       return this.doFetch<{
           location: string;
       }>(url, {method: 'get'});
   }

   setSamlCertificateFromMetadata = (certData: string) => {
       const request: any = {
           menthod: 'post',
           body: certData,
       }

       request.headers = {
           'Content-Type': 'application/x-pem-file',
       };

       return this.doFetch<StatusOK>(
           `${this.getBaseRoute()}/saml/certificate/idp`,
           request,
       );
   }

   

        getSamlMetadataFromIdp = (samlMetadataURL: string) => {
            return this.doFetch<SamlMetadataResponse>(
                `${this.getBaseRoute()}/saml/metadatafromidp`, {method: 'post', body: JSON.stringify({saml_metadata_url: samlMetadataURL})},
            );
        };

        setSamlIdpCertificateFromMetadata = (certData: string) => {
            const request: any = {
                method: 'post',
                body: certData,
            };

            request.headers = {
                'Content-Type': 'application/x-pem-file',
            };

            return this.doFetch<StatusOK>(
                `${this.getBaseRoute()}/saml/certificate/idp`,
                request,
            );
        }


        // Workspace Routes

        getWorkspaceIconUrl = (workspaceId: string, hostOrgId: string, lastWorkspaceIconUpdate: number) => {
            const params: any = {};
            if (lastWorkspaceIconUpdate) {
                params._ = lastWorkspaceIconUpdate;
            }

            return `${this.getWorkspaceRoute(hostOrgId, workspaceId)}/image${buildQueryString(params)}`;
        }

        createWorkspace = (workspace: Workspace) => {
           // this.trackEvent('api', 'api_workspaces_create');

           return this.doFetch<Workspace>(
               `${this.getWorkspacesRoute()}`,
               {method: 'post', body: JSON.stringify(workspace)}
           );
        };

        deleteWorkspace = (wspaceTypeOrHostOrgId: string, wspaceId: string) => {
            // this.trackEvent('api', 'api_workspaces_delete');

            return this.doFetch<StatusOK>(
                `${this.getWorkspaceRoute(wspaceTypeOrHostOrgId, wspaceId)}`,
                {method: 'delete'},
            );
        };


        unarchiveWorkspace = (wspaceTypeOrHostOrgId: string, wspaceId: string) => {
            return this.doFetch<Workspace>(
                `${this.getWorkspaceRoute(wspaceTypeOrHostOrgId, wspaceId)}/restore`,
                {method: 'post'},
            );
        }

        getWorkspace = (hostOrgId: string, workspaceId: string) => {
            return this.doFetch<Workspace>(
                `${this.getWorkspaceRoute(hostOrgId, workspaceId)}`,
                {method: 'get'},
            );
        };

        updateWorkspace = (wspaceTypeOrHostOrgId: string, workspace: Workspace) => {
           // this.trackEvent('api', 'api_workspaces_update_name', {workspace_id: workspace.id, workspace_hostorg: wspaceHostOrgId, workspace_type: wspaceType});
           return this.doFetch<Workspace>(
               `${this.getWorkspaceRoute(wspaceTypeOrHostOrgId, workspace.id)}`,
               {method: 'put', body: JSON.stringify(workspace)},
           );
        };

        patchWorkspace = (wspaceTypeOrHostOrgId: string, workspace: Partial<Workspace> & {id: string}) => {

            return this.doFetch<Workspace>(
                `${this.getWorkspaceRoute(wspaceTypeOrHostOrgId, workspace.id)}/patch`,
                {method: 'put', body: JSON.stringify(workspace)},
            );
        };

        regenerateWorkspaceInviteId = (workspaceId: string, wspaceTypeOrHostOrgId: string) => {

            return this.doFetch<Workspace>(
                `${this.getWorkspaceRoute(wspaceTypeOrHostOrgId, workspaceId)}/regenerate_invite_id`, 
                {method: 'post'},
            );
        };


        checkIfWorkspaceExists = (wspaceTypeOrHostOrgId: string, wspaceName: string) => {
            return this.doFetch<{exists: boolean}>(
                `${this.getWorkspaceNameRoute(wspaceTypeOrHostOrgId, wspaceName)}/exists`,
                {method: 'get'}
            );
        };


    getOwnedWorkspaces = (page = 0, perPage = PER_PAGE_DEFAULT, includeTotalCount = false) => {
            return this.doFetch<Workspace[] | WorkspacesWithCount>(
                `${this.getWorkspacesRoute()}/owned${buildQueryString({page, per_page: perPage, include_total_count: includeTotalCount})}`,
                {method: 'get'},
            );
        }

    getXOrgWorkspaces = (page = 0, perPage = PER_PAGE_DEFAULT, includeTotalCount = false) => {
            return this.doFetch<Workspace[] | WorkspacesWithCount>(
                `${this.getWorkspacesRoute()}/xorg${buildQueryString({page, per_page: perPage, include_total_count: includeTotalCount})}`,
                {method: 'get'},
            );
        }

    



        getMyWorkspaces = () => {
            return this.doFetch<Workspace[]>(
                `${this.getUserRoute('me')}/workspaces`,
                {method: 'get'},
            );
        };

        getMyWorkspaceMembers = () => {
            return this.doFetch<WorkspaceMembership[]>(
                `${this.getUserRoute('me')}/workspaces/members`,
                {method: 'get'},
            );
        };


        getWorkspacesForUser = (userId: string) => {
            return this.doFetch<Workspace[]>(
                `${this.getUserRoute(userId)}/workspaces/all`,
                {method: 'get'},
            );
        };

        getMyWorkspaceMembersForUser = (userId: string) => {
            return this.doFetch<WorkspaceMembership[]>(
                `${this.getUserRoute(userId)}/workspaces/members`,
                {method: 'get'},
            );
        };


        
       

        getWorkspaceMembers = (wspaceTypeOrHostOrgId: string, workspaceId: string, page = 0, perPage = PER_PAGE_DEFAULT, options: GetWorkspaceMembersOpts) => {
            return this.doFetch<WorkspaceMembership[]>(
                `${this.getWorkspaceMembersRoute( wspaceTypeOrHostOrgId, workspaceId)}${buildQueryString({page, per_page: perPage, ...options})}`,
                {method: 'get'},
            );
        };

        getWorkspaceMembersByIds = (workspaceId: string, wspaceTypeOrHostOrgId: string, userIds: string[]) => {
            return this.doFetch<WorkspaceMembership[]>(
                `${this.getWorkspaceMembersRoute(wspaceTypeOrHostOrgId, workspaceId)}/ids`,
                {method: 'post', body: JSON.stringify(userIds)},
            );
        };

        getWorkspaceMembersForUser = (userId: string) => {
            return this.doFetch<WorkspaceMembership[]>(
                `${this.getUserRoute(userId)}/workspaces/members`,
                {method: 'get'},
            );
        };

        getWorkspaceMember = (wspaceTypeOrHostOrgId: string, wspaceId: string, userId: string) => {
            return this.doFetch<WorkspaceMembership>(
                `${this.getWorkspaceMemberRoute(wspaceTypeOrHostOrgId, wspaceId, userId)}`,
                {method: 'get'},
            );
        };
   

        addToWorkspace = ( wspaceTypeOrHostOrgId: string, wspaceId: string, userId: string) => {

            const member = {user_id: userId,  workspace_id: wspaceId, workspace_hostorg_id: wspaceTypeOrHostOrgId}

            return this.doFetch<WorkspaceMembership>(
                `${this.getWorkspaceMembersRoute(wspaceTypeOrHostOrgId, wspaceId)}`,
                {method: 'post', body: JSON.stringify(member)},
            );
        };

        addToWorkspaceFromInvite = (wspaceTypeOrHostOrgId: string, token = '', inviteId='') => {

            const query = buildQueryString({token, invite_id: inviteId});
            return this.doFetch<WorkspaceMembership>(
                `${this.getWorkspacesRouteWithHostOrg(wspaceTypeOrHostOrgId)}/members/invite${query}`,
                {method: 'post'},
            );
        };

        addUsersToWorkspace = (wspaceTypeOrHostOrgId: string, workspaceId: string, userIds: string[], usersHostOrgId: string) => {

            const members: any = [];
            userIds.forEach((id) => members.push({user_id: id,  workspace_id: workspaceId, workspace_host_org_id: wspaceTypeOrHostOrgId,  user_host_org_id: usersHostOrgId}));

            this.doFetch<WorkspaceMembership[]>(
                `${this.getWorkspaceMembersRoute( wspaceTypeOrHostOrgId, workspaceId)}/batch`,
                {method: 'post', body: JSON.stringify(members)},
            );
        };

        addUsersToWorkspaceGracefully = (wspaceTypeOrHostOrgId: string, workspaceId: string, userIds: string[], usersHostOrgId: string) => {
            const members: any = [];
            userIds.forEach((id) => members.push({user_id: id,  workspace_id: workspaceId, workspace_host_org_id: wspaceTypeOrHostOrgId,  user_host_org_id: usersHostOrgId}));
            return this.doFetch<WorkspaceMemberWithError>(
                `${this.getWorkspaceMembersRoute(wspaceTypeOrHostOrgId, workspaceId)}/batch?graceful=true`,
                {method: 'post', body: JSON.stringify(members)},
            );
        };


        joinWorkspace = (wspaceTypeOrHostOrgId: string, inviteId: string) => {
            const query = buildQueryString({invite_id: inviteId});
            return this.doFetch<WorkspaceMembership>(
                `${this.getWorkspacesRouteWithHostOrg(wspaceTypeOrHostOrgId)}/members/invite${query}`,
                {method: 'post'},
            );
        };

        removeFromWorkspace = (workspaceId: string, hostOrgId: string, userId: string) => {
            //this.trackEvent('api', 'api_teams_remove_members', {workspace_id: workspaceId, hostorg_id: hostOrgId});
    
            return this.doFetch<StatusOK>(
                `${this.getWorkspaceMemberRoute(hostOrgId, workspaceId, userId)}`,
                {method: 'delete'},
            );
        };
    
        getWorkspaceStats = (workspaceId: string, hostOrgId: string) => {
            return this.doFetch<WorkspaceStats>(
                `${this.getWorkspaceRoute(hostOrgId, workspaceId)}/stats`,
                {method: 'get'},
            );
        };


        getWorkspaceInviteInfo = (wspaceTypeOrHostOrgId: string, inviteId: string) => {
            return this.doFetch<{
                display_name: string; 
                description: string;
                name: string;
                id: string;
                host_org_id: string;
                wrokspace_type: string;
            }>(
                `${this.getWorkspacesRouteWithHostOrg(wspaceTypeOrHostOrgId)}/invite/${inviteId}`,
                {method: 'get'},
            );
        };

        sendEmailInvitesToWorkspace = (wspaceTypeOrHostOrgId: string, wspaceId: string, emails: string[]) => {

            return this.doFetch<StatusOK>(
                `${this.getWorkspaceRoute(wspaceTypeOrHostOrgId, wspaceId)}/invite/email`,
                {method: 'post', body: JSON.stringify(emails)},
            );
        };

        sendEmailInvitesToWorkspaceGracefully = (wspaceTypeOrHostOrgId: string, wspaceId: string, emails: string[]) => {

            return this.doFetch<WorkspaceInviteWithError>(
                `${this.getWorkspaceRoute(wspaceTypeOrHostOrgId, wspaceId)}/invite/email?graceful=true`,
                {method: 'post', body: JSON.stringify(emails)},
            );
        };

        sendEmailInvitesToWorkspaceAndTopicsGracefully = (
            wspaceTypeOrHostOrgId: string, 
            wspaceId: string, 
            topicIds: string[],
             emails: string[],
             message: string) => {
            return this.doFetch<WorkspaceInviteWithError>(
                `${this.getWorkspaceRoute(wspaceTypeOrHostOrgId, wspaceId)}/invite/email?graceful=true`,
                {method: 'post', body: JSON.stringify({emails, topicIds, message})},
            );
        };

    // xorg workspace routes for both org and workspaces

    inviteOrgToXWorkspaceGracefully = (workspaceId: string, hostOrgId: string, orgId: string, userIds: string[], message: string) => {
        return this.doFetch<OrgInvite>(
            `${this.getWorkspaceRoute(hostOrgId, workspaceId)}/invite-org?graceful=true`,
            {method: 'post', body: JSON.stringify({orgId, userIds, message})},
        );
    }

    sendXWorkspaceEmailInviteToOrgGracefully = (workspaceId: string, hostOrgId: string, emails: string[], message: string) => {
        return this.doFetch<WorkspaceInviteWithError>(
            `${this.getWorkspaceRoute(hostOrgId, workspaceId)}/invite-org/email?graceful=true`,
            {method: 'post', body: JSON.stringify({emails, message})},
        );
    }




         // xworkspaces/ xorg workspaces.
        getWorkspaceOrgMembersForOrg = (orgId: string, page = 0, perPage = PER_PAGE_DEFAULT, options: GetWorkspaceOrgMembersOpts) => {
        return this.doFetch<WorkspaceOrgMembership[]>(
            `${this.getOrgRoute(orgId)}/xworkspaces/org_members${buildQueryString({page, per_page: perPage, ...options})}`,
            {method: 'get'},
        );
     };


     getXWorkspacesStatsForOrg = (orgId: string) => {
        return this.doFetch<XWorkspacesStats>(
            `${this.getOrgRoute(orgId)}/xworkspaces/stats`,
            {method: 'get'},
        );
     }

     getFilteredXWorkspacesStats = (options: GetFilteredWorkspacesStatsOpts) => {
        return this.doFetch<XWorkspacesStats>(
            `${this.getOrgRoute(options.org_id)}/xworkspaces/stats/filtered${buildQueryString(options)}`,
            {method: 'get'},
        );
     }


     getXWorkspacesForOrg = (orgId: string, page = 0, perPage = PER_PAGE_DEFAULT, sort = '', options = {}) => {
        return this.doFetch<Workspace[]>(
            `${this.getOrgRoute(orgId)}/xworkspaces${buildQueryString({...options, type: 'xorg', for_org: orgId, page, per_page: perPage, sort})}`,
            {method: 'get'},
        );
     }

     getWorkspaceOrgMembersByIds = (wspaceId: string, hostOrgId: string, orgIds: string[]) => {
        return this.doFetch<WorkspaceOrgMembership[]>(
            `${this.getWorkspaceOrgMembersRoute(hostOrgId, wspaceId)}/ids`,
            {method: 'post', body: JSON.stringify(orgIds)},
        );
     }

     getWorkspaceOrgMembers = (workspaceId: string, hostOrgId: string, page = 0, perPage = PER_PAGE_DEFAULT, options: GetWorkspaceOrgMembersOpts) =>  {
        return this.doFetch<WorkspaceOrgMembership[]>(
            `${this.getWorkspaceOrgMembersRoute(hostOrgId, workspaceId)}${buildQueryString({page, per_page: perPage, ...options})}`,
            {method: 'get'},
        );
     };

     getWorkspaceOrgMember = (wspaceId: string, hostOrgId: string, memberOrgId: string) => {
        return this.doFetch<WorkspaceOrgMembership>(
            `${this.getWorkspaceOrgMemberRoute(hostOrgId, wspaceId, memberOrgId)}`,
            {method: 'get'}
        );
     }

     // org - workspaceOrg members
     getFilteredOrgsStats =(options: GetFilteredOrgsStatsOpts) => {
        return this.doFetch<OrgsStats>(
            `${this.getOrgsRoute()}/stats/filtered${buildQueryString(options)}`,
            {method: 'get'}
        )
     }

     getTotalOrgsStats = () => {
        return this.doFetch<OrgsStats>(
            `${this.getOrgsRoute()}/stats`,
            {method: 'get'},
        );
     };

     getOrgsInWorkspace = (workspaceId: string, hostOrgId: string, page = 0, perPage = PER_PAGE_DEFAULT, sort = '', options = {}) => {
        return this.doFetch<Org[]>(
            //`${this.getOrgsRoute()}${buildQueryString({...options, in_workspace: workspaceId, workspace_hostorg_id: hostOrgId, page, per_page: perPage, sort})}`,
            `${this.getWorkspaceRoute(hostOrgId, workspaceId)}/orgs${buildQueryString({...options, in_workspace: workspaceId, workspace_hostorg_id: hostOrgId, page, per_page: perPage, sort})}`,
            {method: 'get'},
        );
     }

     getWorkspaceOrgMembersByWorkspaceIds = (orgId: string, workspaceIds: string[]) => {
        return this.doFetch<WorkspaceOrgMembership[]>(
            `${this.getWorkspaceOrgMembersForOrgRoute(orgId)}/ids`,
            {method: 'post', body: JSON.stringify(workspaceIds)},
        );
     };

     searchWorkspaces = (term: string, options: any) => {
        // this trackEvent('api', 'api_search_users');

        return this.doFetch<Workspace[]>(
            `${this.getWorkspacesRoute()}/search`,
            {method: 'post', body: JSON.stringify({term, ...options})},
        );
     };

     searchXWorkspaces = (term: string, options: any) => {
        // this trackEvent('api', 'api_search_users');

        return this.doFetch<Workspace[]>(
            `${this.getXWorkspacesRoute()}/search`,
            {method: 'post', body: JSON.stringify({term, ...options})},
        );
     };


     // workspaces and workspacemembers for user.
     getWorkspacesStatsForUser = (userId: string) => {
        return this.doFetch<WorkspacesStatsForUser>(
            `${this.getUserRoute(userId)}/workspaces/stats`,
            {method: 'get'},
        );
     }

     getFilteredWorkspacesStatsForUser = (options: GetFilteredWorkspacesStatsOpts) => {
        return this.doFetch<WorkspacesStatsForUser>(
            `${this.getUserRoute(options.user_id!)}/workspaces/stats/filtered${buildQueryString(options)}`,
            {method: 'get'},
        );
     }


     getWorkspacesPageForUser = (userId: string, page = 0, perPage = PER_PAGE_DEFAULT, sort = '', options = {}) => {
        return this.doFetch<Workspace[]>(
            `${this.getUserRoute(userId)}/workspaces${buildQueryString({...options, for_user: userId, page, per_page: perPage, sort})}`,
            {method: 'get'},
        );
     }

     getWorkspaceMembersByWorkspaceIds = (userId: string, workspaceIds: string[]) => {
        return this.doFetch<WorkspaceMembership[]>(
            `${this.getUserRoute(userId)}/workspaces/members/ids`,
            {method: 'post', body: JSON.stringify(workspaceIds)},
        );
     };



    

        // Topic Routes

        getAllTopics = (wspaceTypeOrHostOrgId: string, page = 0, perPage = PER_PAGE_DEFAULT, notAssociatedToGroup = '', excludeDefaultTopics = false, includeTotalcount = false, includeDeleted = false, excludePolicyConstrained = false) => {
            const queryData = {
                page, 
                per_page: perPage, 
                not_associated_to_group: notAssociatedToGroup, 
                exclude_default_topics: excludeDefaultTopics, 
                include_total_count: includeTotalcount, 
                include_deleted: includeDeleted, 
                exclude_policy_constained: excludePolicyConstrained, 
            };

            return this.doFetch<TopicWithWorkspaceData[] | TopicsWithTotalCount>(
                `${this.getTopicsRoute(wspaceTypeOrHostOrgId)}${buildQueryString(queryData)}`,
                {method: 'get'},
            );
        };

        createTopic = (wspaceHostOrgId: string, topic: Topic) => {
            //this.trackEvent('api', 'api_topics_create', {workspace_id: topic.workspace_id, wspace_host_org_id: topic.workspace_host_orgid});

            return this.doFetch<ServerTopic>(
                `${this.getTopicsRoute(wspaceHostOrgId)}`,
                {method: 'post', body: JSON.stringify(topic)},
            );
        };

        createDirectTopic = (wspaceTypeOrHostOrgId: string, userIdsOrgIds: UserOrgForDM[]) => {
            return this.doFetch<ServerTopic>(
                `${this.getTopicsRoute(wspaceTypeOrHostOrgId)}/direct`,
                {method: 'post', body: JSON.stringify(userIdsOrgIds)},
            );
        };

        createGroupTopic = (wspaceTypeOrHostOrgId: string, userIdsOrgIds: UserOrgForDM[]) => {

            return this.doFetch<ServerTopic>(
                `${this.getTopicsRoute(wspaceTypeOrHostOrgId)}/group`,
                {method: 'post', body: JSON.stringify(userIdsOrgIds)},
            );
        };

        deleteTopic = (wspaceTypeOrHostOrgId: string, topicId: string) => {
            return this.doFetch<StatusOK>(
                `${this.getTopicRoute(wspaceTypeOrHostOrgId, topicId)}`,
                {method: 'delete'},
            );
        }

        unarchiveTopic = (wspaceTypeOrHostOrgId: string, topicId: string) => {

            return this.doFetch<ServerTopic>(
                `${this.getTopicRoute(wspaceTypeOrHostOrgId, topicId)}/restore`,
                {method: 'post'},
            );
        };
        updateTopic = (wspaceTypeOrHostOrgId: string, topic: Topic) => {

            return this.doFetch<ServerTopic>(
                `${this.getTopicRoute(wspaceTypeOrHostOrgId, topic.id)}`,
                {method: 'put', body: JSON.stringify(topic)},
            );
        };

        updateTopicPrivacy = (wspaceTypeOrHostOrgId: string, topicId: string, privacy: any) => {

            return this.doFetch<ServerTopic>(
                `${this.getTopicRoute(wspaceTypeOrHostOrgId, topicId)}/privacy`,
                {method: 'put', body: JSON.stringify({privacy})},
            );
        };

        patchTopic = (wspaceTypeOrHostOrgId: string, topicId: string, topicPatch: Partial<Topic>) => {

            return this.doFetch<ServerTopic>(
                `${this.getTopicRoute(wspaceTypeOrHostOrgId, topicId)}/patch`,
                {method: 'put', body: JSON.stringify(topicPatch)},
            );
        };

        updateTopicNotifyProps = (props: any) => {

            return this.doFetch<StatusOK>(
                `${this.getTopicMemberRoute(props.workspace_hostorg_id, props.topic_id, props.user_id)}/notify_props`,
                {method: 'put', body: JSON.stringify(props)},
            );
        };

        updateTopicScheme = (wspaceTypeOrHostOrgId: string, topicId: string, schemeId: string) => {
            const patch = {scheme_id: schemeId};

            return this.doFetch<StatusOK>(
                `${this.getTopicSchemeRoute(wspaceTypeOrHostOrgId, topicId)}`,
                {method: 'put', body: JSON.stringify(patch)},
            );
        };


        getTopic = (wspaceTypeOrHostOrgId: string, topicId: string) => {
            return this.doFetch<ServerTopic>(
                `${this.getTopicRoute(wspaceTypeOrHostOrgId, topicId)}`,
                {method: 'get'},
            );
        };

        getTopicByName = (wspaceTypeOrHostOrgId: string, topicName: string, includeDeleted = false) => {
            return this.doFetch<ServerTopic>(
                `${this.getTopicsRoute(wspaceTypeOrHostOrgId)}/name/${topicName}?include_deleted=${includeDeleted}`,
                {method: 'get'},
            );
        };

        getTopics = (wspaceTypeOrHostOrgId: string, wspaceId: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
            return this.doFetch<ServerTopic[]>(
               `${this.getWorkspaceRoute(wspaceTypeOrHostOrgId, wspaceId)}/topics${buildQueryString({page, per_page: perPage})}`,
               {method: 'get'}, 
            );
        }

        getAllWorkspacesTopics = () => {
            return this.doFetch<ServerTopic[]>(
                `${this.getUsersRoute()}/me/topics`,
                {method: 'get'},
            );
        };

        getArchivedTopics = (wspaceTypeOrHostOrgId: string, wspaceId: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
            return this.doFetch<ServerTopic[]>(
                `${this.getWorkspaceRoute(wspaceTypeOrHostOrgId, wspaceId)}/topics/deleted${buildQueryString({page, per_page: perPage})}`,
                {method: 'get'},
            );
        };

        getMyTopics = (includeDeleted = false) => {
            return this.doFetch<ServerTopic[]>(
                `${this.getUserRoute('me')}/topics${buildQueryString({include_deleted: includeDeleted})}`,
                {method: 'get'},
            );
        };



        getAllTopicsMembers = (userId: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
            return this.doFetch<TopicMembership[]>(
                `${this.getUserRoute(userId)}/topic_members${buildQueryString({page, per_page: perPage})}`,
                {method: 'get'},
            );
        };

        getMyTopicMember = (wspaceTypeOrHostOrgId: string, topicId: string) => {
            return this.doFetch<TopicMembership>(
                `${this.getTopicMemberRoute(wspaceTypeOrHostOrgId, topicId, 'me')}`,
                {method: 'get'},
            );
        };

        getMyTopicMembers = () => {
            return this.doFetch<TopicMembership[]>(
                `${this.getUserRoute('me')}/topics/members`,
                {method: 'get'},
            );
        };

        getTopicTimezones = (wspaceTypeOrHostOrgId: string, topicId: string) => {
            return this.doFetch<string[]>(
                `${this.getTopicRoute(wspaceTypeOrHostOrgId, topicId)}/timezones`,
                {method: 'get'},
            );
        };

        getTopicMembers = (wspaceTypeOrHostOrgId: string, topicId: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
            return this.doFetch<TopicMembership[]>(
                `${this.getTopicMembersRoute(wspaceTypeOrHostOrgId, topicId)}${buildQueryString({page, per_page: perPage})}`,
                {method: 'get'}
            );
        };

        getTopicMember = (topicId: string, wspaceTypeOrHostOrgId: string, userId: string) => {
            return this.doFetch<TopicMembership>(
                `${this.getTopicMemberRoute(wspaceTypeOrHostOrgId, topicId, userId)}`,
                {method: 'get'}
            );
        };

        getTopicMembersByIds = (topicId: string, wspaceTypeOrHostOrgId: string, userIds: string[]) => {
            return this.doFetch<TopicMembership[]>(
                `${this.getTopicMembersRoute(wspaceTypeOrHostOrgId, topicId)}/ids`,
                {method: 'post', body: JSON.stringify(userIds)},
            );
        };

        addToTopic = (hostOrgId: string, topicId: string, userId: string, userHostOrgId: string, workspaceType: string, threadId = '') => {
         
            const member = {user_id: userId, user_hostorg_id: userHostOrgId, topic_id: topicId, workspace_hostorg_id: hostOrgId, workspace_type: workspaceType, thread_id: threadId}

            return this.doFetch<TopicMembership>(
                `${this.getTopicMembersRoute(hostOrgId, topicId)}`,
                {method: 'post', body: JSON.stringify(member)},
            );
        };

        removeFromTopic = (hostOrgId: string, topicId: string, userId: string) => {
            return this.doFetch<StatusOK>(
                `${this.getTopicMemberRoute(hostOrgId, topicId, userId)}`,
                {method: 'delete'},
            );
        };

        updateTopicMemberRoles = (hostOrgId: string, topicId: string, userId: string, roles: string) => {
            return this.doFetch<StatusOK>(
                `${this.getTopicMemberRoute(hostOrgId, topicId, userId)}/roles`,
                {method: 'put', body: JSON.stringify({roles})},
            );
        };

        getTopicStats = (topicId: string, wspaceTypeOrHostOrgId: string, workspaceType: string) => {
            return this.doFetch<TopicStats>(
                `${this.getTopicRoute(wspaceTypeOrHostOrgId, topicId)}/stats${buildQueryString({workspace_type: workspaceType, exclude_file_count: "true"})}`,
                {method: 'get'},
            );
        };

        getTopicModerations = (wspaceTypeOrHostOrgId: string, topicId: string) => {
            return this.doFetch<TopicModeration[]>(
                `${this.getTopicRoute(wspaceTypeOrHostOrgId, topicId)}/moderations`,
                {method: 'get'},
            );
        };

       // viewMyTopic = (wspaceType: string, wspaceHostOrgId: string, topicId: string)

       autocompleteTopics = (wspaceTypeOrHostOrgId: string, workspaceId: string, name: string) => {
           return this.doFetch<Topic[]>(
               `${this.getWorkspaceRoute(wspaceTypeOrHostOrgId, workspaceId)}/topics/autocomplete${buildQueryString({name})}`,
               {method: 'get'},
           );
       };

       searchTopics = (wspaceHostOrgId: string, workspaceId: string, term: string) => {
           return this.doFetch<Topic[]>(
               `${this.getWorkspaceRoute(wspaceHostOrgId, workspaceId)}/topics/search`,
               {method: 'post', body: JSON.stringify({term})},
           );
       }

       searchArchivedTopics = (wspaceTypeOrHostOrgId: string, workspaceId: string, term: string) => {
           return this.doFetch<Topic[]>(
               `${this.getWorkspaceRoute(wspaceTypeOrHostOrgId, workspaceId)}/topics/search_archived`,
               {method: 'post', body: JSON.stringify({term})},
           );
       };



       searchTopicsForUser = (term: string, options: any) => {
        // this trackEvent('api', 'api_search_users');

        return this.doFetch<Topic[]>(
            `${this.getUserRoute(options.user_id)}/topics/search`,
            {method: 'post', body: JSON.stringify({term, ...options})},
        );
     };


     // workspaces and workspacemembers for user.
     getTopicsStatsForUser = (userId: string) => {
        return this.doFetch<TopicsStatsForUser>(
            `${this.getUserRoute(userId)}/topics/stats`,
            {method: 'get'},
        );
     }

     getFilteredTopicsStatsForUser = (options: GetFilteredTopicsStatsForUserOpts) => {
        return this.doFetch<TopicsStatsForUser>(
            `${this.getUserRoute(options.user_id!)}/topics/stats/filtered${buildQueryString(options)}`,
            {method: 'get'},
        );
     }


     getTopicsPageForUser = (userId: string, page = 0, perPage = PER_PAGE_DEFAULT, sort = '', options = {}) => {
        return this.doFetch<Topic[] | TopicsWithCount>(
            `${this.getUserRoute(userId)}/topics${buildQueryString({...options, for_user: userId, page, per_page: perPage, sort})}`,
            {method: 'get'},
        );
     }

     getTopicMembersByTopicIds = (userId: string, topicIds: string[]) => {
        return this.doFetch<TopicMembership[]>(
            `${this.getUserRoute(userId)}/topics/members/ids`,
            {method: 'post', body: JSON.stringify(topicIds)},
        );
     };

     getUnreadThreadsCountForUserByTopicIds = (userId: string, topicIds: string[]) => {
        return this.doFetch<RelationOneToOne<Topic, number>>(
            `${this.getUserRoute(userId)}/topics/unread_thread_count/ids`,
            {method: 'post', body: JSON.stringify(topicIds)},
        );
     };





       // Topic Category routes

       getTopicCategories = (userId: string) => {
           return this.doFetch<OrderedTopicCategories>(
               `${this.getTopicCategoriesRoute(userId)}`,
               {method: 'get'},
           );
       };

       createTopicCategory = (userId: string, category: Partial<TopicCategory>) => {
           return this.doFetch<TopicCategory>(
               `${this.getTopicCategoriesRoute(userId)}`,
               {method: 'post', body: JSON.stringify(category)},
           );
       };

       updateTopicCategories = (userId: string, categories: TopicCategory[]) => {
           return this.doFetch<TopicCategory[]>(
               `${this.getTopicCategoriesRoute(userId)}`,
               {method: 'put', body: JSON.stringify(categories)},
           );
       };

       getTopicCategoryOrder = (userId: string) => {
           return this.doFetch<string[]>(
               `${this.getTopicCategoriesRoute(userId)}/order`,
               {method: 'get'},
           );
       };

       updateTopicCategoryOrder = (userId: string, categoryOrder: string[]) => {
           return this.doFetch<string[]>(
               `${this.getTopicCategoriesRoute(userId)}/order`,
               {method: 'put', body: JSON.stringify(categoryOrder)},
           );
       };

       getTopicCategory = (userId: string, categoryId: string) => {
           return this.doFetch<TopicCategory>(
               `${this.getTopicCategoriesRoute(userId)}/${categoryId}`,
               {method: 'get'},
           );
       };

       updateTopicCategory = (userId: string, category: TopicCategory) => {
           return this.doFetch<TopicCategory>(
               `${this.getTopicCategoriesRoute(userId)}/${category.id}`,
               {method: 'put', body: JSON.stringify(category)},
           );
       };

       deleteTopicCategory = (userId: string, categoryId: string) => {
           return this.doFetch<TopicCategory>(
               `${this.getTopicCategoriesRoute(userId)}/${categoryId}`,
               {method: 'delete'},
           );
       }

       // Post Routes

       createPost = async (topicId: string, hostOrgId: string,  post: Post) => {
           const result = await this.doFetch<Post>(
               `${this.getPostsRoute(topicId, hostOrgId)}`,
               {method: 'post', body: JSON.stringify(post)},
           );

           //const analyticsData = {topic_id: result.topic_id, post_id: result.id, user_actual_id: result.user_id, user_hostorg_id: result.hostorg_id};
           //this.trackEvent('api', 'api_posts_create', analyticsData);

           return result;
       };

       createThreadReplyPost = async (topicId: string, hostOrgId: string, userId: string, threadId: string,  post: Post) => {
        const result = await this.doFetch<Post>(
            `${this.getThreadPostsRouteForUser(topicId, hostOrgId, userId, threadId)}`,
            {method: 'post', body: JSON.stringify(post)},
        );

        //const analyticsData = {topic_id: result.topic_id, post_id: result.id, user_actual_id: result.user_id, user_hostorg_id: result.hostorg_id};
        //this.trackEvent('api', 'api_posts_create', analyticsData);

        return result;
    };

    updatePost = (topicId: string, hostOrgId: string, post: Post) => {

        return this.doFetch<Post>(
            `${this.getPostRoute(topicId, hostOrgId, post.id)}`,
            {method: 'put', body: JSON.stringify(post)},
        );
       };

       getPost = (topicId: string, hostOrgId: string, postId: string) => {
           return this.doFetch<Post>(
               `${this.getPostRoute(topicId, hostOrgId, postId)}`,
               {method: 'get'},
           );
       };

       patchPost = (topicId: string, hostOrgId: string, postPatch: Partial<Post> & {id: string}) => {

        return this.doFetch<Post>(
            `${this.getPostRoute(topicId, hostOrgId, postPatch.id)}/patch`,
            {method: 'put', body: JSON.stringify(postPatch)},
        );
       };

       deletePost = (topicId: string, HostOrgId: string, postId: string) => {

        return this.doFetch<StatusOK>(
            `${this.getPostRoute(topicId, HostOrgId, postId)}`,
            {method: 'delete'},
        );
       };

    getPostThread = (topicId: string, hostOrgId: string, postId: string, fetchThreads = true, collapsedThreads = false, collapsedThreadsExtended = false) => {
           return this.getPaginatedPostThread(topicId, hostOrgId, postId, {fetchThreads, collapsedThreads, collapsedThreadsExtended});
       };


    getPaginatedPostThread = async (topicId: string, hostOrgId: string, postId: string, options: FetchPaginatedThreadOptions): Promise<PaginatgedPostList> => {
        // getting all option parameters with defaults from options object and spread the rest.
        const {
            fetchThreads = true,
            collapsedThreads = false, 
            collapsedThreadsExtended = false, 
            direction = 'down',
            fetchAll = false, 
            perPage = fetchAll ? undefined : PER_PAGE_DEFAULT, 
            ...rest
        } = options;

        return this.doFetch<PaginatedPostList>(
            `${this.getPostRoute(topicId, hostOrgId, postId)}/thread${buildQueryString({skipFetchThreads: !fetchThreads, collapsedThreads, collapsedThreadsExtended, direction, perPage, ...rest})}`,
            {method: 'get'},
        );
    };



    getPosts = (topicId: string, hostOrgId: string, page = 0, perPage = PER_PAGE_DEFAULT, fetchThreads = true, collapsedThreads = false, collapsedThreadsExtended = false) => {
        return this.doFetch<PostList>(
            `${this.getPostsRoute(topicId, hostOrgId)}${buildQueryString({page, per_page: perPage, skipFetchThreads: !fetchThreads, collapsedThreads, collapsedThreadsExtended})}`,
            {method: 'get'},
        );
    };

    getPostsUnread = (topicId: string, wspaceHostOrgId: string, userId: string, limitAfter = DEFAULT_LIMIT_AFTER, limitBefore = DEFAULT_LIMIT_BEFORE, fetchThreads = true, collapsedThreads = false, collapsedThreadsExtended = false) => {
        return this.doFetch<PostList>(
            `${this.getUserRoute(userId)}/topics/${wspaceHostOrgId}/ids/${topicId}/posts/unread${buildQueryString({limit_after: limitAfter, limit_before: limitBefore, skipFetchThreads: !fetchThreads, collapsedThreads, collapsedThreadsExtended})}`,
            {method: 'get'},
        );
    }

    getPostsSince = (topicId: string, hostOrgId: string, since: number, fetchThreads = true, collapsedThreads = false, collapsedThreadsExtended = false) => {
        return this.doFetch<PostList>(
            `${this.getPostsRoute(topicId, hostOrgId)}${buildQueryString({since, skipThreads: !fetchThreads, collapsedThreads, collapsedThreadsExtended})}`,
            {method: 'get'},
        );
    };

    getPostsBefore = (topicId: string, hostOrgId: string, postId: string, page = 0, perPage = PER_PAGE_DEFAULT,  fetchThreads = true, collapsedThreads = false, collapsedThreadsExtended = false) => {
        return this.doFetch<PostList>(
            `${this.getPostsRoute(topicId, hostOrgId)}${buildQueryString({before: postId, page, per_page: perPage,  skipThreads: !fetchThreads, collapsedThreads, collapsedThreadsExtended})}`,
            {method: 'get'},
        );
    };

    getPostsAfter = (topicId: string, hostOrgId: string, postId: string, page = 0, perPage = PER_PAGE_DEFAULT,  fetchThreads = true, collapsedThreads = false, collapsedThreadsExtended = false) => {
        return this.doFetch<PostList>(
            `${this.getPostsRoute(topicId, hostOrgId)}${buildQueryString({after: postId, page, per_page: perPage,  skipThreads: !fetchThreads, collapsedThreads, collapsedThreadsExtended})}`,
            {method: 'get'},
        );
    };

    createTopicThread = async (
        topicId: string, 
        hostOrgId: string,
        userId: string,
        thread: TopicThread
    ) => {

        const result = await this.doFetch<TopicThread>(
            `${this.getTopicThreadsRouteForUser(topicId, hostOrgId, userId)}`,
            {method: 'post', body: JSON.stringify(thread)},
        );

        //const analyticsData = {topic_id: result.topic_id, post_id: result.id, user_actual_id: result.user_id, user_hostorg_id: result.hostorg_id};
        //this.trackEvent('api', 'api_posts_create', analyticsData);

        return result;
    }

    getTopicThreads = (
        topicId: string, 
        wspaceHostOrgId: string,
        userId: string,
        {
            before = '',
            after = '',
            perPage = PER_PAGE_DEFAULT, 
            extended = false, 
            deleted = false, 
            unread = false, 
            since = 0, 
            totalsOnly = false, 
            threadsOnly = false,
        },
    ) => {
        return this.doFetch<TopicThreadList>(
            `${this.getTopicThreadsRouteForUser(topicId, wspaceHostOrgId, userId)}${buildQueryString({before, after, per_page: perPage, extended, deleted, unread, since, totalsOnly, threadsOnly})}`,
            {method: 'get'},
        );
    };

    searchTopicThreads = (
        topicId: string, 
        wspaceHostOrgId: string,
        userId: string,
        {
          
            terms = '',
            is_or_search = true,
            page = 1,
            per_page = PER_PAGE_DEFAULT,
            
        },
    ) => {
        return this.doFetch<TopicThreadList>(
            `${this.getTopicThreadsSearchRouteForUser(topicId, wspaceHostOrgId, userId)}${buildQueryString({per_page, page, is_or_search, terms})}`,
            {method: 'get'},
        );
    };


    // This is the thread with root post
    getTopicThread = (topicId: string, wspaceHostOrgId: string, userId: string, threadId: string, extended = false) => {
        const url = `${this.getTopicThreadRouteForUser(topicId, wspaceHostOrgId, userId, threadId)}/posts`;
        return this.doFetch<TopicThreadWithPost>(
            `${url}${buildQueryString({extended})}`,
            {method: 'get'},
        );
    };


    updateThreadsReadForUser = (topicId: string, wspaceHostOrgId: string, userId: string) => {
        const url = `${this.getTopicThreadsRouteForUser(topicId, wspaceHostOrgId, userId)}/read`;
        return this.doFetch<StatusOK>(
            url, 
            {method: 'put'},
        );
    };

    updateThreadReadForUser = (topicId: string, wspaceHostOrgId: string, threadId: string, userId: string, timestamp: number) => {
        const url = `${this.getTopicThreadRouteForUser(topicId, wspaceHostOrgId, userId, threadId)}/read/${timestamp}`;
        return this.doFetch<TopicThread>(
            url, 
            {method: 'put'},
        );
    };

    markThreadAsUnreadForUser = (topicId: string, wspaceHostOrgId: string, threadId: string, postId: string, userId: string) => {
        const url = `${this.getTopicThreadRouteForUser(topicId, wspaceHostOrgId, userId, threadId)}/set_unread/${postId}`;
        return this.doFetch<TopicThread>(
            url,
            {method: 'post'},
        );
    };

    updateThreadFollowForUser = (topicId: string, wspaceHostOrgId: string, threadId: string, userId: string, state: boolean) => {
        const url = this.getTopicThreadRouteForUser(topicId, wspaceHostOrgId, userId, threadId) + '/following';
        return this.doFetch<StatusOK>(
            url, 
            {method: state ? 'put' : 'delete'},
        );
    };

getThreadPosts = (topicId: string, hostOrgId: string, threadId: string, fetchThreads = true, collapsedThreads = false, collapsedThreadsExtended = false) => {
        return this.getPaginatedThreadPosts(topicId, hostOrgId, threadId, {fetchThreads, collapsedThreads, collapsedThreadsExtended});
    };


    getPaginatedThreadPostsForUser = async (topicId: string, hostOrgId: string, userId: string, threadId: string, options: FetchPaginatedThreadOptions): Promise<PaginatedPostList> => {
        // getting all option parameters with defaults from options object and spread the rest.
        const {
            fetchThreads = true,
            collapsedThreads = false, 
            collapsedThreadsExtended = false, 
            direction = 'down',
            fetchAll = false, 
            perPage = fetchAll ? undefined : PER_PAGE_DEFAULT, 
            ...rest
        } = options;
   
        return this.doFetch<PaginatedPostList>(
            `${this.getThreadPostsRouteForUser(topicId, hostOrgId, userId, threadId)}${buildQueryString({skipFetchThreads: !fetchThreads, collapsedThreads, collapsedThreadsExtended, direction, perPage, ...rest})}`,
            {method: 'get'},
        );
    };


 getPaginatedThreadPosts = async (topicId: string, hostOrgId: string, threadId: string, options: FetchPaginatedThreadOptions): Promise<PaginatedPostList> => {
     // getting all option parameters with defaults from options object and spread the rest.
     const {
         fetchThreads = true,
         collapsedThreads = false, 
         collapsedThreadsExtended = false, 
         direction = 'down',
         fetchAll = false, 
         perPage = fetchAll ? undefined : PER_PAGE_DEFAULT, 
         ...rest
     } = options;

     return this.doFetch<PaginatedPostList>(
         `${this.getThreadPostsRoute(topicId, hostOrgId,threadId)}${buildQueryString({skipFetchThreads: !fetchThreads, collapsedThreads, collapsedThreadsExtended, direction, perPage, ...rest})}`,
         {method: 'get'},
     );
 };



    getFileInfosForPost = (topicId: string, hostOrgId: string, postId: string) => {
        return this.doFetch<FileInfo[]>(
            `${this.getPostRoute(topicId, hostOrgId, postId)}/files/info`,
            {method: 'get'},
        );
    };

    markPostAsUnread = (topicId: string, wspaceHostOrgId: string, threadId: string, userId: string, postId: string) => {

        return this.doFetch<TopicUnread>(
            `${this.getThreadMemberRoute(topicId, wspaceHostOrgId, threadId, userId)}/set_unread/${postId}`,
            {method: 'post', body: JSON.stringify({collapsed_threads_supported: true})}
        );
    };

    addReaction = (topicId: string, hostOrgId: string, userId: string, postId: string, emojiName: string) => {

        return this.doFetch<Reaction>(
            `${this.getReactionsRoute(topicId, hostOrgId)}`,
            {method: 'post', body: JSON.stringify({topic_id: topicId, workspace_hostorg_id: hostOrgId, user_id: userId, post_id: postId, emoji_name: emojiName})}
        );
    };

    
    removeReaction = (topicId: string, hostOrgId: string, userId: string, postId: string, emojiName: string) => {

        return this.doFetch<StatusOK>(
            `${this.getReactionsRoute(topicId, hostOrgId)}/${emojiName}/posts/${postId}/users/${userId}`,
            {method: 'delete'},
        );
    };

    getReactionForPost = (topicId: string, hostOrgId: string, postId: string) => {
        return this.doFetch<Reaction[]>(
            `${this.getPostRoute(topicId, hostOrgId, postId)}/reactions`,
            {method: 'get'}
        )
    }

    searchPostsWithParams = (topicId: string, hostOrgId: string, params: any) => {

        const route = `${this.getPostsRoute(topicId, hostOrgId)}/search`;

        return this.doFetch<PostSearchResults>(
            route, 
            {method: 'post', body: JSON.stringify(params)},
        );
    };

    searchPosts = (topicId: string, hostOrgId: string, terms: string, isOrSearch: boolean) => {
        return this.searchPostsWithParams(topicId, hostOrgId, {terms, is_or_search: isOrSearch});
    }

    searchFilesWithParams = (topicId: string, hostOrgId: string, params: any) => {

        return this.doFetch<FileSearchResults>(
            `${this.getTopicRoute(hostOrgId, topicId)}/files/search`,
            {method: 'post', body: JSON.stringify(params)},
        );
    };


    searchFiles = (topicId: string, hostOrgId: string, terms: string, isOrSearch: boolean) => {
        return this.searchFilesWithParams(topicId, hostOrgId, {terms, is_or_search: isOrSearch});
    }

    // Files Routes
    getFileUrl(topicId: string, hostOrgId: string, fileId: string, timestamp: number) {
        let url = `${this.getFileRoute(topicId, hostOrgId, fileId)}`;
        if (timestamp) {
            url += `?${timestamp}`;
        }

        return url;
    }

    getFileThumbnailUrl(topicId: string, hostOrgId: string, fileId: string, timestamp: number) {
        let url = `${this.getFileRoute(topicId, hostOrgId, fileId)}/thumbnail`;
        if (timestamp) {
            url += `?${timestamp}`;
        }

        return url;
    }

    getFilePreviewUrl(topicId: string, hostOrgId: string, fileId: string, timestamp: number) {
        let url = `${this.getFileRoute(topicId, hostOrgId, fileId)}/preview`;
        if (timestamp) {
            url += `?${timestamp}`;
        }

        return url;
    }

    uploadFile = (topicId: string, hostOrgId: string, fileFormData: any, formBoundary: string) => {
       // this.trackEvent('api', 'api_files_upload');
        const request: any = {
            method: 'post',
            body: fileFormData,
        };

        if (formBoundary) {
            request.headers = {
                'Content-Type': `multipart/form-data; boundary=${formBoundary}`,
            };
        }

        return this.doFetch<FileUploadResponse>(
            `${this.getFilesRoute(topicId, hostOrgId)}`,
            request,
        );
    };

    getFilePublicLink = (topicId: string, hostOrgId: string, fileId: string) => {
        return this.doFetch<{
            link: string;
        }>(
            `${this.getFileRoute(topicId, hostOrgId, fileId)}/link`,
            {method: 'get'},
        );
    }
    

    // Emoji Routes

    getSystemEmojiImageUrl = (filename: string) => {
        const extension = filename.endsWith('.png') ? '' : '.png';
        return `${this.url}/static/emoji/${filename}${extension}`;
    };

    getCustomEmojiImageUrl = (id: string) => {
        return `${this.getEmojiRoute(id)}/image`;
    }

    // cloud routes (b2b subscription and billing management)
    getCloudProducts = () => {
        let query = '';

        return this.doFetch<Product[]>(
            `${this.getCloudRoute()}/products`, {method: 'get'},
        );
    };

    createPaymentMethod = async () => {
        return this.doFetch(
            `${this.getCloudRoute()}/payment`, 
            {method: 'post'},
        );
    }

    getCloudCustomer = () => {
        return this.doFetch<CloudCustomer>(
            `${this.getCloudRoute()}/customer`, {method: 'get'},
        );
    }

    updateCloudCustomer = (customerPatch: CloudCustomerPatch) => {
        return this.doFetch<CloudCustomer>(
            `${this.getCloudRoute()}/customer`,
            {method: 'put', body: JSON.stringify(customerPatch)},
        );
    }

    updateCloudCustomerAddress = (address: Address) => {
        return this.doFetch<CloudCustomer>(
            `${this.getCloudRoute()}/customer/address`, 
            {method: 'put', body: JSON.stringify(address)},
        );
    }

    notifyAdmin = (req: NotifyAdminRequest) => {
        return this.doFetchWithResponse<StatusOK>(
            `${this.getUsersRoute()}/notify-admin`, 
            {method: 'post', body: JSON.stringify(req)},
        );
    }

    confirmPaymentMethod = async (stripeSetupIntentID: string) => {
        return this.doFetch(
            `${this.getCloudRoute()}/payment/confirm`, 
            {method: 'post', body: JSON.stringify({stripe_setup_intent_id: stripeSetupIntentID})},
        );
    }

    subscribeCloudProduct = (productId: string, shippingAddress?: Address, seats = 0, downgradeFeedback?: Feedback) => {
        const body = {
            product_id: productId, 
            seats, 
            downgrade_feedback: downgradeFeedback,
        } as any;
        if (shippingAddress) {
            body.shipping_address = shippingAddress;
        }

        return this.doFetch<Subscription>(
            `${this.getCloudRoute()}/subscription`,
            {method: 'put', body: JSON.stringify(body)},
        );
    }

    requestCloudTrial = (subscriptionId: string, email = '') => {
        return this.doFetchWithResponse<Subscription>(
            `${this.getCloudRoute()}/request-trial`,
            {method: 'put', body: JSON.stringify({email, subscription_id: subscriptionId})},
        );
    }


    validateBusinessEmail = (email = '') => {
        return this.doFetchWithResponse<ValidBusinessEmail>(
            `${this.getCloudRoute()}/validate-business-email`, 
            {method: 'post', body: JSON.stringify({email})},
        );
    }

    validateOrgBusinessEmail = () => {
        return this.doFetchWithResponse<ValidBusinessEmail>(
            `${this.getCloudRoute()}/validate-org-business-email`,
            {method: 'post'},
        );
    }

    getSubscription = () => {
        return this.doFetch<Subscription>(
            `${this.getCloudRoute()}/subscription`,
            {method: 'get'},
        );
    }

    getInvoices = () => {
        return this.doFetch<Invoice[]>(
                `${this.getCloudRoute()}/subscription/invoices`,
                {method: 'get'},
        );
    }

    getInvoicePdfUrl = (invoiceId: string) => {
        return `${this.getCloudRoute()}/subscription/invoices/${invoiceId}/pdf`;
    }

    getCloudLimits = () => {
        return this.doFetch<Limits>(
            `${this.getCloudRoute()}/limits`, 
            {method: 'get'},
        );
    }

    getThreadsUsage = () => {
        return this.doFetch<ThreadsUsageResponse>(
            `${this.getUsageRoute()}/threads`, 
            {method: 'get'},
        );
    }

    getFilesUsage = () => {
        return this.doFetch<FileUsageResponse>(
            `${this.getUsageRoute()}/storage`, 
            {method: 'get'},
        );
    }

    getWorkspacesUsage = () => {
        return this.doFetch<WorkspacesUsageResponse>(
            `${this.getUsageRoute()}/workspaces`, 
            {method: 'get'},
        );
    }




        /**
         * @param query string query of graphQL, pass the json stringified version of the query
         * e.g. const query = JSON.stringify({query: `{license, config}`});
         * ClientSf.fetchWithGraphQL(query);
         */

        fetchWithGraphQL = async <DataResponse>(query: string) => {
            return this.doFetch<DataResponse>(this.getGraphQLUrl(), {method: 'post', body: query});
        };

        // Client helpers
        
        doFetch = async <ClientDataResponse>(url: string, options: Options) : Promise<ClientDataResponse> => {
            const {data} = await this.doFetchWithResponse<ClientDataResponse>(url, options);

            return data;
        }

        doFetchWithResponse = async <ClientDataResponse>(url: string, options: Options): Promise<ClientResponse<ClientDataResponse>> => {
            const response = await fetch(url, this.getOptions(options));
            const headers = parseAndMergeNestedHeaders(response.headers);


            let data;

            try {
                data = await response.json();
            } catch (err) {
                throw new ClientError(this.getUrl(), {
                    message: 'Received invalid response from the server.',
                    intl: {
                        id: 'mobile.request.invalid_response',
                        defaultMessage: 'Received invalid response from the server.',
                    },
                    url,
                });
            }

            if (headers.has(HEADER_X_VERSION_ID) && !headers.get('Cache-Control')) {
                const serverVersion = headers.get(HEADER_X_VERSION_ID);
                if (serverVersion && this.serverVersion !== serverVersion) {
                    this.serverVersion = serverVersion;
                }
            }

            if (response.ok) {
                return {
                    response, 
                    headers,
                    data,
                };
            }

            const msg = data.message || '';

            if (this.logToConsole) {
                console.error(msg); // eslint-disable-line no-console
            }

            throw new ClientError(this.getUrl(), {
                message: msg, 
                server_error_id: data.id, 
                status_code: data.status_code, 
                url,
            });
        }

        trackEvent(category: string, event: string, props?: any) {
            if (this.telemetryHandler) {
                this.telemetryHandler.trackEvent(this.userId, this.userRoles, category, event, props);
            }
        }

     }


     export function parseAndMergeNestedHeaders(originalHeaders: any) {
         const headers = new Map();
         let nestedHeaders = new Map();
         originalHeaders.forEach((val: string, key: string) => {
             const capitalizedKey = key.replace(/\b[a-z]/g, (l) => l.toUpperCase());
             let realVal = val;
             if (val && val.match(/\n\S+:\s\S+/)) {
                 const nestedHeaderStrings = val.split('\n');
                 realVal = nestedHeaderStrings.shift() as string;
                 const moreNestedHeaders = new Map(
                     nestedHeaderStrings.map((h: any) => h.split(/:\s/)),  
                 );
                 nestedHeaders = new Map([...nestedHeaders, ...moreNestedHeaders]);
             }
             headers.set(capitalizedKey, realVal);
         });
         return new Map([...headers, ...nestedHeaders]);
     }
 
     export class ClientError extends Error implements ServerError {
         url?: string;
         intl?: {
             id: string;
             defaultMessage: string;
             values?: any;
         };

         server_error_id?: string;
         status_code?: number;

         constructor(baseUrl: string, data: ServerError) {
            // super(data.message + ': ' + cleanUrlForLogging(baseUrl, data.url || ''));

            super(data.message + ': ' + data.url || '');

             this.message = data.message;
             this.url = data.url;
             this.intl = data.intl;
             this.server_error_id = data.server_error_id;
             this.status_code = data.status_code;

             // Ensure message is treated as a property of this class when object
             // spreading. Without this, copying the object by using `{...error}` would not
             // include message.
             Object.defineProperty(this, 'message', {enumerable: true})
         }
    
}