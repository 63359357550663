import { combineReducers } from "redux";

import {Timezone} from 'timezones.json';

import {GeneralTypes, UserTypes} from 'sigmaflow-redux/action_types';
import {GenericAction} from 'sigmaflow-redux/types/actions';
import {ClientLicense, ClientConfig} from 'sigmaflow-redux/types/config';

function config(state: Partial<ClientConfig> = {}, action: GenericAction) {
    switch (action.type) {
        case GeneralTypes.CLIENT_CONFIG_RECEIVED:
            return Object.assign({}, state, action.data);

        case UserTypes.LOGIN: // used by mobile app.
        case GeneralTypes.SET_CONFIG_AND_LICENSE:
            return Object.assign({}, state, action.data.config);

        case GeneralTypes.CLIENT_CONFIG_RESET:
        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

function appState(state = false, action: GenericAction) {
    switch (action.type) {
        case GeneralTypes.RECEIVED_APP_STATE:
            return action.data;

        default:
            return state;
    }
}

function credentials(state: any = {}, action: GenericAction) {
    switch (action.type) {
        case GeneralTypes.RECEIVED_APP_CREDENTIALS:
            return Object.assign({}, state, action.data);

        case UserTypes.LOGIN: // used by mobile app.
          return {
              url: action.data.url,
          };

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}


function deviceToken(state = '', action: GenericAction) {
    switch (action.type) {
        case GeneralTypes.RECEIVED_APP_DEVICE_TOKEN:
            return action.data;
        default:
            return state;
    }
}


function license(state: ClientLicense = {}, action: GenericAction) {
    switch (action.type) {
        case GeneralTypes.CLIENT_LICENSE_RECEIVED:
            return action.data;
        case GeneralTypes.SET_CONFIG_AND_LICENSE:
            return Object.assign({}, state, action.data.license);
        case GeneralTypes.CLIENT_LICENSE_RESET:
        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    } 
}

function timezones(state: Timezone[] = [], action: GenericAction) {
    switch (action.type) {
        case GeneralTypes.SUPPORTED_TIMEZONES_RECEIVED:
            return action.data;
        case UserTypes.LOGOUT_SUCCESS:
            return [];
        default:
            return state;
    }
}


function serverVersion(state = '', action: GenericAction) {
    switch (action.type) {
        case GeneralTypes.RECEIVED_SERVER_VERSION:
            return action.data;

        case UserTypes.LOGOUT_SUCCESS:
            return '';
        
        default:
            return state;
    }
}

function firstAdminVisitMarketplaceStatus(state = false, action: GenericAction) {
    switch (action.type) {
        case GeneralTypes.FIRST_ADMIN_VISIT_MARKETPLACE_STATUS_RECEIVED:
            return action.data;

        default:
            return state;
    }
}


function firstAdminCompleteSetup(state = false, action: GenericAction) {
    switch (action.type) {
        case GeneralTypes.FIRST_ADMIN_COMPLETE_SETUP_RECEIVED:
            return action.data;

        default:
            return state;
    }
}


export default combineReducers({
    appState,
    credentials,
    config,
    deviceToken,
    license,
    serverVersion,
    timezones,
    firstAdminCompleteSetup,
    firstAdminVisitMarketplaceStatus,
});