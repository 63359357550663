import { combineReducers } from "redux";

import {UserTypes} from 'sigmaflow-redux/action_types';
import { GenericAction } from "sigmaflow-redux/types/actions";

import {ActionTypes} from 'utils/constants';

export function isOpen(state = false, action: GenericAction) {
    switch (action.type) {
        case ActionTypes.STATUS_DROPDOWN_TOGGLE:
            return action.open;
        case UserTypes.LOGOUT_SUCCESS:
            return false;
        default:
            return state;
    }
}

export default combineReducers({
    isOpen,
});