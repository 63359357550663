import { Topic } from "./topics";
import { UserProfile } from "./users";
import { Workspace } from "./workspaces";
import { IDMappedObjects, RelationOneToOne } from "./utilities";
import { Org } from "./orgs";


export type TopicCategoryType = 'favorites' | 'internal' | 'cross-org' | 'external-b2b' | 'external-b2c' | 'direct_messages' | 'custom';

export enum CategorySorting {
    Alphabetical = 'alpha',
    Default = '', // behaves same as manual
    Recency = 'recent',
    Manual = 'manual',
}

export type SidebarTopic = {
    topic_id: Topic['id'];
    workspace_id: Workspace['id'];
    workspace_hostorg_id: Org['id'];
}

export type TopicCategory = {
    id: string;
    org_id: string;
    user_id: UserProfile['id'];
    // workspace_id: Workspace['id'];
    type: TopicCategoryType;
    display_name: string;
    sorting: CategorySorting;
    topic_ids: Array<Topic['id']>;
    //topic_ids: SidebarTopic[];

    muted: boolean;
    collapsed: boolean,
};

export type OrderedTopicCategories = {
    categories: TopicCategory[];
    order: string[];
};

export type TopicCategoriesState = {
    byId: IDMappedObjects<TopicCategory>;
    orderForUser: Array<TopicCategory['id']>;
   // orderByWorkspace: RelationOneToOne<Workspace, Array<TopicCategory['id']>>;
};