import keyMirror from "sigmaflow-redux/utils/key_mirror";

// This file's contents belong to the Apps framework feature.
// Apps Framework feature is experimental, and other contents of this file are succeptible to
// breaking changes without pushing the major version of this package.
export default keyMirror({
    RECEIVED_APP_BINDINGS: null,
    FAILED_TO_FETCH_APP_BINDINGS: null,
    RECEIVED_APP_RHS_BINDINGS: null,
    RECEIVED_APP_COMMAND_FORM: null,
    RECEIVED_APP_RHS_COMMAND_FORM: null,
    APPS_PLUGIN_ENABLED: null,
    APPS_PLUGIN_DISABLED: null,
})