import { combineReducers } from 'redux';

import { UserTypes } from 'sigmaflow-redux/action_types';

import type { GenericAction } from 'sigmaflow-redux/types/actions';

import type { ViewsState } from 'types/store/views';

import { SearchTypes } from 'utils/constants';

function modalSearch(state = '', actions: GenericAction) {
    switch (actions.type) {
        case SearchTypes.SET_MODAL_SEARCH: {
            return actions.data.trim();
        }

        case UserTypes.LOGIN_SUCCESS:
            return '';
        default:
            return state;
    }
}


 function popoverSearch(state = '', action: GenericAction) {
    switch (action.type) {
        case SearchTypes.SET_POPOVER_SEARCH: {
            return action.data.trim();
        }
        default:
            return state;
    }
}

function topicMembersRhsSearch(state = '', action: GenericAction) {
    switch (action.type) {
        case SearchTypes.SET_TOPIC_MEMBERS_RHS_SEARCH: {
            return action.data;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return '';
        default:
            return state;
    }
}

function topicThreadsSearch(state = '', action: GenericAction) {
    switch (action.type) {
        case SearchTypes.SET_TOPIC_THREAD_SEARCH: {
            return action.data;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return '';
        default:
            return state;
    }
}

function modalFilters(state: ViewsState['search']['modalFilters'] = {}, action: GenericAction) {
    switch (action.type) {
        case SearchTypes.SET_MODAL_FILTERS: {
            const filters = action.data;
            return {
                ...filters, 
            };
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default: 
            return state;
    }
}

function systemUsersSearch(state: Partial<ViewsState['search']['systemUsersSearch']> = {}, action: GenericAction) {
    switch (action.type) {
        case SearchTypes.SET_SYSTEM_USERS_SEARCH: {
            return action.data;
        }

        case UserTypes.LOGOUT_SUCCESS: 
           return {};
        default:
            return state;
    }
}

function userGridSearch(state: Partial<ViewsState['search']['userGridSearch']> = {}, action: GenericAction) {
    switch (action.type) {
        case SearchTypes.SET_USER_GRID_SEARCH: {
            const term = action.data.trim();
            return {
                ...state, 
                term, 
            };
        }

        case SearchTypes.SET_USER_GRID_FILTERS: {
            const filters = action.data;
            return {
                ...state, 
                filters, 
            };
        }

        case UserTypes.LOGOUT_SUCCESS: 
               return {};
        default: 
            return state;
    }
}

function workspaceListSearch(state = '', action: GenericAction) {
    switch (action.type) {
        case SearchTypes.SET_WORKSPACE_LIST_SEARCH: {
            return action.data.trim();
        }

        case UserTypes.LOGOUT_SUCCESS:
            return '';
        default: 
            return state;
    }
}

function topicListSearch(state: Partial<ViewsState['search']['topicListSearch']> = {}, action: GenericAction) {
    switch (action.type) {
        case SearchTypes.SET_TOPIC_LIST_SEARCH: {
            const term = action.data.trim();
            return {
                ...state, 
                term, 
            };
        }

        case SearchTypes.SET_TOPIC_LIST_FILTERS: {
            const filters = action.data;
            return {
                ...state, 
                filters,
            };
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};

        default: 
           return state;
    }
}

function orgGridSearch(state: Partial<ViewsState['search']['orgGridSearch']> = {}, action: GenericAction) {
    switch (action.type) {
        case SearchTypes.SET_ORG_GRID_SEARCH: {
            const term = action.data.trim();
            return {
                ...state, 
                term,
            };
        }
        case SearchTypes.SET_ORG_GRID_FILTERS: {
            const filters = action.data;
            return {
                ...state, 
                filters,
            };
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}


function workspaceGridSearch(state: Partial<ViewsState['search']['workspaceGridSearch']> = {}, action: GenericAction) {
    switch (action.type) {
        case SearchTypes.SET_WORKSPACE_GRID_SEARCH: {
            const term = action.data.trim();
            return {
                ...state, 
                term,
            };
        }

        case SearchTypes.SET_WORKSPACE_GRID_FILTERS: {
            const filters = action.data;
            return {
                ...state, 
                filters,
            };
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}


function topicGridSearch(state: Partial<ViewsState['search']['topicGridSearch']> = {}, action: GenericAction) {
    switch (action.type) {
        case SearchTypes.SET_TOPIC_GRID_SEARCH: {
            const term = action.data.trim();
            return {
                ...state, 
                term,
            };
        }

        case SearchTypes.SET_TOPIC_GRID_FILTERS: {
            const filters = action.data;
            return {
                ...state, 
                filters,
            };
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

export default combineReducers({
    modalSearch, 
    popoverSearch, 
    topicMembersRhsSearch, 
    topicThreadsSearch,
    modalFilters, 
    systemUsersSearch, 
    userGridSearch, 
    orgGridSearch,
    workspaceGridSearch,
    topicGridSearch,
    workspaceListSearch, 
    topicListSearch,
});