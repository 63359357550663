import keyMirror from "sigmaflow-redux/utils/key_mirror";

export default keyMirror({
    RECEIVED_CATEGORY: null,
    RECEIVED_CATEGORIES: null, 
    RECEIVED_CATEGORY_ORDER: null, 

    CATEGORY_COLLAPSED: null, 
    CATEGORY_EXPANDED: null, 

    CATEGORY_DELETED: null,
});