import { combineReducers } from "redux";

import { AdminTypes, TopicTypes, WorkspaceTypes, UserTypes, OrgTypes } from "sigmaflow-redux/action_types";
import {workspaceListToMap} from 'sigmaflow-redux/utils/workspace_utils';
import { Workspace, WorkspaceMembership, WorkspaceOrgMembership } from "sigmaflow-redux/types/workspaces";
import { UserProfile } from "sigmaflow-redux/types/users";
import { Org } from "sigmaflow-redux/types/orgs";
import { RelationOneToOne, IDMappedObjects, RelationOneToMany } from "sigmaflow-redux/types/utilities";
import { GenericAction } from "sigmaflow-redux/types/actions";

function currentWorkspaceId(state = '', action: GenericAction) {
    switch (action.type) {
        case WorkspaceTypes.SELECT_WORKSPACE:
            return action.data;

        case UserTypes.LOGOUT_SUCCESS:
            return '';

        default:
            return state;
    }
}

function workspaces(state: IDMappedObjects<Workspace> = {},  action: GenericAction) {
    switch (action.type) {
        case WorkspaceTypes.RECEIVED_WORKSPACES_LIST:
        case AdminTypes.RECEIVED_DATA_RETENTION_CUSTOM_POLICY_WORKSPACES_SEARCH:
            return Object.assign({}, state, workspaceListToMap(action.data));
        case AdminTypes.RECEIVED_DATA_RETENTION_CUSTOM_POLICY_WORKSPACES:
        case UserTypes.LOGIN: // Used by the mobile app.
             return Object.assign({}, state, workspaceListToMap(action.data.workspacces));
        case WorkspaceTypes.RECEIVED_WORKSPACES:
            return Object.assign({}, state, action.data);
        case WorkspaceTypes.CREATED_WORKSPACE:
        case WorkspaceTypes.UPDATED_WORKSPACE:
        case WorkspaceTypes.PATCHED_WORKSPACE:
        case WorkspaceTypes.REGENERATED_WORKSPACE_INVITE_ID:
        case WorkspaceTypes.RECEIVED_WORKSPACE:
            return {
                ...state, 
                [action.data.id]: action.data,
            };
        case WorkspaceTypes.RECEIVED_WORKSPACE_DELETED: {
            const nextState = {...state};
            const workspaceId = action.data.id;
            if (nextState.hasOwnProperty(workspaceId)) {
                Reflect.deleteProperty(nextState, workspaceId);
                return nextState;
            }

            return state;
        }

        case WorkspaceTypes.RECEIVED_WORKSPACE_UNARCHIVED: {
            const workspace = action.data;

            return {...state, [workspace.id]: workspace};
        }

        /*
        case AdminTypes.REMOVE_DATA_RETENTION_CUSTOM_POLICY_WORKSPACES_SUCCESS: {
            const {workspaces} = action.data;
            const nextState = {...state};
            workspaces.forEach((wspaceId: string) => {
                if (nextState[wspaceId]) {
                    nextState[wspaceId] = {
                        ...nextState[wspaceId],
                        //policy_id: null,
                    };
                }
            });

            return nextState;
        }
        */

        case UserTypes.LOGOUT_SUCCESS:
            return {};

        default:
            return state;
    }
}


function myMembers(state: RelationOneToOne<Workspace, WorkspaceMembership> = {}, action: GenericAction) {
    function updateState(receivedWorkspaces: IDMappedObjects<Workspace> = {}, currentState: RelationOneToOne<Workspace, WorkspaceMembership> = {}) {
        return Object.keys(receivedWorkspaces).forEach((wspaceId) => {
            if (receivedWorkspaces[wspaceId].delete_at > 0 && currentState[wspaceId]) {
                Reflect.deleteProperty(currentState, wspaceId);
            }
        });
    }

    switch (action.type) {
        case WorkspaceTypes.RECEIVED_MY_WORKSPACE_MEMBER: {
            const nextState = {...state};
            const member = action.data;
            if (member.delete_at === 0) {
                nextState[member.workspace_id] = member;
            }

            return nextState;
        }

    case WorkspaceTypes.RECEIVED_MY_WORKSPACE_MEMBERS: {
        const nextState: RelationOneToOne<Workspace, WorkspaceMembership> = {};
        const members = action.data;
        for (const m of members) {
            if (m.delete_at == null || m.delete_at === 0) {
                const prevMember = state[m.workspace_id] // {mention_count: 0, msg_count: 0, mention_count_root: 0, msg_count_root: 0};
                nextState[m.workspace_id] = {
                    ...prevMember, 
                    ...m, 
                };
            }
        }
        return nextState;
    }
    case WorkspaceTypes.RECEIVED_WORKSPACES_LIST: {
        const nextState = {...state};
        const receivedWorkspaces = workspaceListToMap(action.data);
        updateState(receivedWorkspaces, nextState);
        return nextState;
    }

    case WorkspaceTypes.RECEIVED_WORKSPACES: {
        const nextState = {...state};
        const receivedWorkspaces = action.data;
        updateState(receivedWorkspaces, nextState);
        return nextState;
    }
    case WorkspaceTypes.LEAVE_WORKSPACE:
    case WorkspaceTypes.RECEIVED_WORKSPACE_DELETED: {
        const nextState = {...state};
        const data = action.data;
        Reflect.deleteProperty(nextState, data.id);
        return nextState;
    }
    case UserTypes.LOGIN: { // Used by mobile app.
        const {workspaceMembers} = action.data;
        const nextState = {...state};

        for (const m of workspaceMembers) {
            if (m.delete_at == null || m.delete_at === 0) {
                nextState[m.workspace_id] = m;
            }
        }
        return nextState;
    }
    case UserTypes.LOGOUT_SUCCESS:
        return {};
    default:
        return state;
    }
}

function myOrgMembers(state: RelationOneToOne<Workspace, WorkspaceOrgMembership> = {}, action: GenericAction) {
    function updateState(receivedWorkspaces: IDMappedObjects<Workspace> = {}, currentState: RelationOneToOne<Workspace, WorkspaceOrgMembership> = {}) {
        return Object.keys(receivedWorkspaces).forEach((wspaceId) => {
            if (receivedWorkspaces[wspaceId].delete_at > 0 && currentState[wspaceId]) {
                Reflect.deleteProperty(currentState, wspaceId);
            }
        });
    }

    switch (action.type) {
        case WorkspaceTypes.RECEIVED_MY_WORKSPACE_ORGMEMBER: {
            const nextState = {...state};
            const member = action.data;
            if (member.delete_at === 0) {
                nextState[member.workspace_id] = member;
            }

            return nextState;
        }
        case WorkspaceTypes.RECEIVED_MY_WORKSPACE_ORGMEMBERS: {
            const nextState: RelationOneToOne<Workspace, WorkspaceOrgMembership> = {};
            const members = action.data;
            for (const m of members) {
                if (m.delete_at == null || m.delete_at === 0) {
                    const prevMember = state[m.workspace_id];
                    nextState[m.workspace_id] = {
                        ...prevMember,
                        ...m, 
                    };
                }
            }
            return nextState;
        }
        case WorkspaceTypes.RECEIVED_WORKSPACES_LIST: {
            const nextState = {...state};
            const receivedWorkspaces = workspaceListToMap(action.data);
            updateState(receivedWorkspaces, nextState);
            return nextState;
        }

        case WorkspaceTypes.RECEIVED_WORKSPACES: {
            const nextState = {...state};
            const receivedWorkspaces = action.data;
            updateState(receivedWorkspaces, nextState);
            return nextState;
        }
        case WorkspaceTypes.RECEIVED_WORKSPACE_DELETED: {
            const nextState = {...state};
            const data = action.data;
            Reflect.deleteProperty(nextState, data.id);
            return nextState;
        }
        case UserTypes.LOGIN: { // Used by the mobile app.
            const {workspaceOrgMembers} = action.data;
            const nextState = {...state};

            for (const m of workspaceOrgMembers) {
                if (m.delete_at == null || m.delete_at === 0) {
                    nextState[m.workspace_id] = m;
                }
            }

            return nextState;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
    
}

function orgMembersInWorkspace(state: RelationOneToOne<Workspace, RelationOneToOne<Org, WorkspaceOrgMembership>> = {}, action: GenericAction) {
    switch (action.type) {
        case WorkspaceTypes.RECEIVED_ORGMEMBER_IN_WORKSPACE: {
            const data = action.data;
            const members = {...(state[data.workspace_id] || {})};
            members[data.org_id] = data;
            return {
                ...state, 
                [data.workspace_id]: members,
            };
        }

        case WorkspaceTypes.RECEIVED_MY_WORKSPACE_ORGMEMBERS: {
            const data = action.data;
            if (data && data.length) {
                const nextState = {...state};
                for (const member of data) {
                    if (nextState[member.workspace_id]) {
                        nextState[member.workspace_id] = {...nextState[member.workspace_id]};
                    } else {
                        nextState[member.workspace_id] = {};
                    }

                    nextState[member.workspace_id][member.org_id] = member;
                }

                return nextState;
            }

            return state;
        }

        case WorkspaceTypes.RECEIVED_ORGMEMBERS_IN_WORKSPACE: {
            const data = action.data;
            if (data && data.length) {
                const wspaceId = data[0].workspace_id;
                const members = {...(state[wspaceId] || {})};
                for (const member of data) {
                    members[member.member_org_id] = member;
                }

                return {
                    ...state, 
                    [wspaceId]: members,
                };
            }

            return state;
        }

        case WorkspaceTypes.REMOVE_ORGMEMBER_FROM_WORKSPACE: {
            const data = action.data;
            const members = state[data.workspace_id];
            if (members) {
                const nextState = {...members};
                Reflect.deleteProperty(nextState, data.org_id);
                return {
                    ...state, 
                    [data.workspace_id]: nextState, 
                };
            }

            return state;
        }

        case WorkspaceTypes.RECEIVED_WORKSPACE_DELETED: {
            const nextState = {...state};
            const wspaceId = action.data.id; 
            if (nextState.hasOwnProperty(wspaceId)) {
                Reflect.deleteProperty(nextState, wspaceId);
                return nextState;
            }

            return state;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}


function membersInWorkspace(state: RelationOneToOne<Workspace, RelationOneToOne<UserProfile, WorkspaceMembership>> = {}, action: GenericAction) {
    switch (action.type) {
        case WorkspaceTypes.RECEIVED_MEMBER_IN_WORKSPACE: {
            const data = action.data;
            const members = {...(state[data.workspace_id] || {})};
            members[data.user_id] = data;

            return {
                ...state,
                [data.workspace_id]: members,
            };
        }

        case WorkspaceTypes.RECEIVED_WORKSPACE_MEMBERS: {
            const data = action.data;
            if (data && data.length) {
                const nextState = {...state};
                for (const member of data) {
                    if (nextState[member.workspace_id]) {
                        nextState[member.workspace_id] = {...nextState[member.workspace_id]};
                    } else {
                        nextState[member.workspace_id] = {};
                    }

                    nextState[member.workspace_id][member.user_id] = member;
                }

                return nextState;
            }

            return state;
        }

        case WorkspaceTypes.RECEIVED_MEMBERS_IN_WORKSPACE: {
            const data = action.data;
            if (data && data.length) {
                const wspaceId = data[0].workspace_id;
                const members = {...(state[wspaceId] || {})};
                for (const member of data) {
                    members[member.user_id] = member;
                }

                return {
                    ...state,
                    [wspaceId]: members,
                };
            }

            return state;
        }
        case WorkspaceTypes.REMOVE_MEMBER_FROM_WORKSPACE: {
            const data = action.data;
            const members = state[data.workspace_id];
            if (members) {
                const nextState = {...members};
                Reflect.deleteProperty(nextState, data.user_id);
                return {
                    ...state,
                    [data.workspace_id]: nextState,
                };
            }

            return state;
        }

        case WorkspaceTypes.RECEIVED_WORKSPACE_DELETED: {
            const nextState = {...state};
            const wspaceId = action.data.id;
            if (nextState.hasOwnProperty(wspaceId)) {
                Reflect.deleteProperty(nextState, wspaceId);
                return nextState;
            }

            return state;
        }

        case UserTypes.LOGOUT_REQUEST:
            return {};
        default:
            return state;

    }
}

function addWorkspaceToSet(state: RelationOneToMany<Org, Workspace>, id: string, workspaceId: string) {
    if (state[id]) {
        // the type definitions for this function expect state[id] to be an array, but we seem to use
        // Sets, so handle both of these just in case.
        if (Array.isArray(state[id]) && state[id].includes(workspaceId)) {
            return state;
        } else if (!Array.isArray(state[id]) && (state[id] as unknown as Set<string>).has(workspaceId)) {
            return state;
        }
    }

    const nextSet = new Set(state[id]);
    nextSet.add(workspaceId);

    return {
        ...state, 
        [id]: nextSet,
    } as RelationOneToMany<Org, Workspace>;
}

function workspacesToSet(state: RelationOneToMany<Org, Workspace>, action: GenericAction) {
    const id = action.id;
    const workspaces: Workspace[] = Object.values(action.data);

    return workspaces.reduce((nextState, workspace) => addWorkspaceToSet(nextState, id, workspace.id), state);
}

function workspaceListToSet(state: RelationOneToMany<Org, Workspace>, action: GenericAction, replace = false) {
    const id = action.id;
    const workspaces: Workspace[] = action.data || [];

    if (replace) {
        return {
            ...state, 
            [id]: new Set(workspaces.map((workspace) => workspace.id)),
        };
    }

    return workspaces.reduce((nextState, workspace) => addWorkspaceToSet(nextState, id, workspace.id), state);
}

function removeXWorkspaceFromOrgs(state: RelationOneToMany<Org, Workspace>, action: GenericAction) {
    const newState = {...state};
    let removed = false;
    Object.keys(state).forEach((key) => {
        if (newState[key][action.data.workspace_id]) {
            delete newState[key][action.data.workspace_id];
            removed = true;
        }
    });

    return removed ? newState : state;
}

function xworkspacesForOrg(state: RelationOneToMany<Org,Workspace> = {}, action: GenericAction) {
    switch (action.type) {
        case WorkspaceTypes.RECEIVED_XWORKSPACE_IN_ORG:
            return addWorkspaceToSet(state, action.data.id, action.workspace_id);
        
        case WorkspaceTypes.RECEIVED_XWORKSPACES_LIST_FOR_ORG:
            return workspaceListToSet(state, action);

        case WorkspaceTypes.RECEIVED_XWORKSPACES_FOR_ORG:
            return workspacesToSet(state, action);
        case UserTypes.LOGOUT_SUCCESS:
            return {};

        case WorkspaceTypes.XWORKSPACE_NO_LONGER_VISIBLE:
            return removeXWorkspaceFromOrgs(state, action);
        default:
            return state;
    }
}

    function xworkspacesStats(state: any = {}, action: GenericAction) {
        switch (action.type) {
            case WorkspaceTypes.RECEIVED_XWORKSPACES_STATS: {
                const stat = action.data;
                return {
                    ...state, 
                    [stat.org_id]: {...stat},
                };
            }
            default:
                return state;
        }
    }

function filteredXWorkspacesStats(state = {}, action: GenericAction) {
    switch (action.type) {
        case WorkspaceTypes.RECEIVED_FILTERED_XWORKSPACES_STATS: {
            const stat = action.data;
            return {
                ...state, 
                [stat.org_id]: {...stat},
            };
        }

        default:
            return state;
    }
}

function stats(state: any = {}, action: GenericAction) {
    switch (action.type) {
        case WorkspaceTypes.RECEIVED_WORKSPACE_STATS: {
            const stat = action.data;
            return {
                ...state, 
                [stat.workspace_id]: stat,
            };
        }

        case WorkspaceTypes.RECEIVED_WORKSPACE_DELETED: {
            const nextState = {...state};
            const wspaceId = action.data.id;
            if (nextState.hasOwnProperty(wspaceId)) {
                Reflect.deleteProperty(nextState, wspaceId);
                return nextState;
            }
            return state;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

function totalCount(state = 0, action: GenericAction) {
    switch (action.type) {
        case WorkspaceTypes.RECEIVED_TOTAL_WORKSPACE_COUNT: {
            return action.data;
        }
        default:
            return state;
    }
}

function removeWorkspaceForUsers(state: RelationOneToMany<UserProfile, Workspace>, action: GenericAction) {
    const newState = {...state};
    let removed = false;
    Object.keys(state).forEach((key) => {
        if (newState[key][action.data.workspace_id]) {
            delete newState[key][action.data.workspace_id];
            removed = true;
        }
    });

    return removed ? newState : state;
}

function workspacesForUser(state: RelationOneToMany<UserProfile, Workspace> = {}, action: GenericAction) {
    switch (action.type) {
        case WorkspaceTypes.RECEIVED_WORKSPACE_FOR_USER: 
            return addWorkspaceToSet(state, action.data.id, action.workspace_id);
        
        case WorkspaceTypes.RECEIVED_WORKSPACES_LIST_FOR_USER:
            const wspaceListToSet = workspaceListToSet(state, action);
            console.log('ishaan and vivaan 209:: ', wspaceListToSet);
           // return workspaceListToSet(state, action);
           return wspaceListToSet;
        case WorkspaceTypes.RECEIVED_WORKSPACES_FOR_USER:
            return workspacesToSet(state, action);

        case UserTypes.LOGOUT_SUCCESS:
            return {};

        case WorkspaceTypes.WORKSPACE_NO_LONGER_VISIBLE:
            return removeWorkspaceForUsers(state, action);
        default:
            return state;
    }
}   

function workspacesStatsForUser(state: any = {}, action: GenericAction) {
    switch (action.type) {
        case WorkspaceTypes.RECEIVED_WORKSPACES_STATS_FOR_USER: {
            const stat = action.data;
            return {
                ...state, 
                [stat.user_id]: {...stat},
            };
        }
        default:
            return state;
    }
}

function filteredWorkspacesStatsForUser(state = {}, action: GenericAction) {
    switch (action.type) {
        case WorkspaceTypes.RECEIVED_FILTERED_WORKSPACES_STATS_FOR_USER: {
            const stat = action.data;
            return {
                ...state, 
                [stat.user_id]: {...stat},
            };
        }

        default:
            return state;
    }
}

function workspaceMembersForUser(state: RelationOneToOne<UserProfile, RelationOneToOne<Workspace, WorkspaceMembership>> = {}, action: GenericAction) {
    switch (action.type) {
        case WorkspaceTypes.RECEIVED_WORKSPACE_MEMBER_FOR_USER: {
            const data = action.data;
            const members = {...(state[data.user_id] || {})};
            members[data.workspace_id] = data;

            return {
                ...state, 
                [data.user_id]: members,
            };
        }

        case WorkspaceTypes.RECEIVED_WORKSPACE_MEMBERS_FOR_USER: {
            const data = action.data;
            if (data && data.length) {
                const nextState = {...state};
                for (const member of data) {
                    if (nextState[member.user_id]) {
                        nextState[member.user_id] = {...nextState[member.user_id]};
                    } else {
                        nextState[member.user_id] = {};
                    }

                    nextState[member.user_id][member.workspace_id] = member;
                }

                return nextState;
            }

            return state;
        }

        case WorkspaceTypes.REMOVE_WORKSPACE_MEMBER: 
        case WorkspaceTypes.WORKSPACE_NO_LONGER_VISIBLE: 
        case WorkspaceTypes.RECEIVED_WORKSPACE_DELETED:
            case WorkspaceTypes.REMOVE_MEMBER_FROM_WORKSPACE: {
            const data = action.data;
            const members = state[data.user_id];
            if (members) {
                const nextState = {...members};
                Reflect.deleteProperty(nextState, data.workspace_id);
                return {
                    ...state, 
                    [data.user_id]: nextState,
                };
            }

            return state;
        }

        case UserTypes.RECEIVED_USER_DELETED: {
            const nextState = {...state};
            const userId = action.data.id;
            if (nextState.hasOwnProperty(userId)) {
                Reflect.deleteProperty(nextState, userId);
                return nextState;
            }
        }

        case UserTypes.LOGOUT_SUCCESS: 
            return {};
        default:
            return state;
    }
}

export default combineReducers({
    // the current selected workspace.
    currentWorkspaceId, 

    // object where every key is the workspace id and has object with the workspace detail.
    workspaces, 

    // object where every key is the workspace_id and has object with workspaceMember details.
    myMembers,

    // object where every key is the workspace_id and has an object of members in the workspace
    // where the user_id.
    membersInWorkspace, 

    orgMembersInWorkspace,

    // object where every key is the workspace_id and has an object with the workspace stats
    stats, 

    xworkspacesForOrg,

    xworkspacesStats,

    filteredXWorkspacesStats,

    totalCount,


    workspacesForUser,
    workspaceMembersForUser,
    workspacesStatsForUser,
    filteredWorkspacesStatsForUser, 


});