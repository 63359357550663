import {GlobalState} from 'sigmaflow-redux/types/store';

const state: GlobalState = {
    entities: {
        general: {
            appState: false,
            credentials: {},
            config: {},
            dataRetentionPolicy: {},
            deviceToken: '',
            license: {},
            serverVersion: '',
            warnMetricsStatus: {},
            firstAdminVisitMarketplaceStatus: false,
            firstAdminCompleteSetup: false,
        },
        orgs: {
            currentOrgId: '',
            orgs: {},
            stats: {},
            totalCount: 0,
        },
        users: {
            currentUserId: '',
            isManualStatus: {},
            mySessions: [],
            myAudits: [],
            profiles: {},
            profilesInWorkspace: {},
            profilesNotInWorkspace: {},
            profilesInTopic: {},
            profilesNotInTopic: {},
            statuses: {},
            stats: {},
            myUserAccessTokens: {},
        },
        workspaces: {
            currentWorkspaceId: '',
            workspaces: {},
            myMembers: {},
            myOrgMembers: {},
            membersInWorkspace: {},
            orgMembersInWorkspace: {},
            stats: {},
            totalCount: 0,
        },
        topics: {
            currentTopicId: '',
            topics: {},
            topicsInWorkspace: {},
            myMembers: {},
            membersInTopic: {},
            stats: {},
            roles: {},
            groupsAssociatedToTopic: {},
            totalCount: 0,
            manuallyUnread: {},
            messageCounts: {},
        },
        threads: {
            threadsInTopic: {},
            unreadThreadsInTopic: {},
            threads: {},
            counts:  {},
            threadsFirstLoadCompleted: {},
            pendingThreadIds: [],
        },
        posts: {
            posts: {},
            postsReplies:{},
            postsInTopic: {},
            postsInThread: {},
            reactions: {},
            openGraph: {},
            pendingPostIds: [],
            selectedPostId: '',
            messageHistory: {
                messages: [],
                index: {
                    post: -1,
                    comment: -1,
                },
            },
            expandedURLs: {},
        },
        roles: {
            roles: {},
            pending: new Set(),
        },
        preferences: {
            myPreferences: {},
        },
        gifs: {
            categories: {
                tagsList: [],
                tagsDict: {},
            },
            cache: {
                gifs:  {},
                updating: false,
            },
            search: {
                searchText: '',
                searchBarText: '',
                resultsByWorkspace: {},
                scrollPosition: 0,
                priorLocation: null,
            },
        },
        topicCategories: {
            byId: {},
            order: [],
        },
    },
    errors: [],
    requests: {
        topics: {
            getAllTopics: {
                status: 'not_started',
                error: null,
            },
            getTopics: {
                status: 'not_started',
                error: null,
            },
            myTopics: {
                status: 'not_started',
                error: null,
            },
            createTopic: {
                status: 'not_started',
                error: null,
            },
            updateTopic: {
                status: 'not_started',
                error: null,
            },
        },
        general: {
            websocket: {
                status: 'not_started',
                error: null,
            },
        },
        posts: {
            createPost: {
                status: 'not_started',
                error: null,
            },
            editPost: {
                status: 'not_started',
                error: null,
            },
            getPostThread: {
                status: 'not_started',
                error: null,
            },
        },
        workspaces: {
            getMyWorkspaces: {
                status: 'not_started',
                error: null,
            },
            getWorkspaces: {
                status: 'not_started',
                error: null,
            },
            joinWorkspace: {
                status: 'not_started',
                error: null,
            },
        },
        users: {
            checkMfa: {
                status: 'not_started',
                error: null,
            },
            login: {
                status: 'not_started',
                error: null,
            },
            logout: {
                status: 'not_started',
                error: null,
            },
            autocompleteUsers: {
                status: 'not_started',
                error: null,
            },
            updateMe: {
                status: 'not_started',
                error: null,
            },
        },
    admin: {
        getLogs: {
            status: 'not_started',
            error: null,
        },
        getAudits: {
            status: 'not_started',
            error: null,
        },
        getConfig: {
            status: 'not_started',
            error: null,
        },
        updateConfig: {
            status: 'not_started',
            error: null,
        },
        reloadConfig: {
            status: 'not_started',
            error: null,
        },
        testEmail: {
            status: 'not_started',
            error: null,
        },
        testSiteURL: {
            status: 'not_started',
            error: null,
        },
        invalidateCaches: {
            status: 'not_started',
            error: null,
        },
        createCompliance: {
            status: 'not_started',
            error: null,
        },
        getCompliance: {
            status: 'not_started',
            error: null,
        },
        deleteBrandImage: {
            status: 'not_started',
            error: null,
        },
        disablePlugin: {
            status: 'not_started',
            error: null,
        },
        enablePlugin: {
            status: 'not_started',
            error: null,
        },
        getPluginStatuses: {
            status: 'not_started',
            error: null,
        },
        getPlugins: {
            status: 'not_started',
            error: null,
        },
        getSamlCertificateStatus: {
            status: 'not_started',
            error: null,
        },
        installPluginFromUrl: {
            status: 'not_started',
            error: null,
        },
        removeIdpSamlCertificate: {
            status: 'not_started',
            error: null,
        },
        removePlugin: {
            status: 'not_started',
            error: null,
        },
        removePrivateSamlCertificate: {
            status: 'not_started',
            error: null,
        },
        removePublicSamlCertificate: {
            status: 'not_started',
            error: null,
        },
        syncLdap: {
            status: 'not_started',
            error: null,
        },
        testLdap: {
            status: 'not_started',
            error: null,
        },
        uploadBrandImage: {
            status: 'not_started',
            error: null,
        },
        uploadIdpSamlCertificate: {
            status: 'not_started',
            error: null,
        },
        uploadPlugin: {
            status: 'not_started',
            error: null,
        },
        uploadPrivateSamlCertificate: {
            status: 'not_started',
            error: null,
        },
        uploadPublicSamlCertificate: {
            status: 'not_started',
            error: null,
        },
        getLdapGroups: {
            status: 'not_started',
            error: null,
        },
        unlinkLdapGroup: {
            status: 'not_started',
            error: null,
        },
        linkLdapGroup: {
            status: 'not_started',
            error: null,
        },
    },
    files: {
        uploadFiles: {
            status: 'not_started',
            error: null,
        },
    },
    roles: {
        getRolesByNames: {
            status: 'not_started',
            error: null,
        },
        getRoleByName: {
            status: 'not_started',
            error: null,
        },
        getRole: {
            status: 'not_started',
            error: null,
        },
        editRole: {
            status: 'not_started',
            error: null,
        },
    },
},

    websocket: {
        connected: false,
        lastConnectAt: 0,
        lastDisconnectAt: 0,
        connectionId: '',
    },
};

export default state;