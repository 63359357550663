import shallowEquals from 'shallow-equals';

import { createSelector } from 'reselect';

import {General, Preferences} from 'sigmaflow-redux/constants';
import { CategoryTypes } from 'sigmaflow-redux/constants/topic_categories';

import {getTopicMessageCounts, getCurrentTopicId, getMyTopicMemberships, makeGetTopicsForIds} from 'sigmaflow-redux/selectors/entities/topics';
import { getCurrentUserLocale } from './i18n';
import { getMyPreferences, getWspaceMateNameDisplaySetting, getInt } from './preferences';
import { getCurrentUserId } from './common';

import {Topic, TopicMembership, TopicMessageCount} from 'sigmaflow-redux/types/topics';
import {TopicCategory, TopicCategoryType, CategorySorting} from 'sigmaflow-redux/types/topic_categories';
import { GlobalState } from 'sigmaflow-redux/types/store';
import { UserProfile } from 'sigmaflow-redux/types/users';
import { IDMappedObjects, RelationOneToOne } from 'sigmaflow-redux/types/utilities';

import {
    calculateUnreadCount, 
    getUserIdFromTopicName,
    isTopicMuted,
} from 'sigmaflow-redux/utils/topic_utils';
import { getPreferenceKey } from 'sigmaflow-redux/utils/preference_utils';
import { displayUsername } from 'sigmaflow-redux/utils/user_utils';
import topics from 'sigmaflow-redux/action_types/topics';

export function getAllCategoriesByIds(state: GlobalState) {
    return state.entities.topicCategories.byId;
}

export function getCategory(state: GlobalState, categoryId: string) {
    return getAllCategoriesByIds(state)[categoryId];
}

// gateCategoryByType returns the first category found of the given type. This is intended for 
// use with only non-custom types categories.
export function getCategoryByType(state: GlobalState, categoryType: TopicCategoryType) {
    return getCategoryWhere(
        state, 
        (category) => category.type === categoryType 
    );
}

// getCategoryWithTopic returns the category containing the given topic ID.
export function getCategoryWithTopic(state: GlobalState, topicId: string) {
    return getCategoryWhere(
        state, 
        (category) => category.topic_ids.includes(topicId),
    );
}

// getCategoryWhere returns the first category meeting the given condition. This
// should not be used with a condition that matches multiple categories.
export function getCategoryWhere(state: GlobalState, condition: (category: TopicCategory) => boolean) {
    const categoriesByIds =  getAllCategoriesByIds(state);

    return Object.values(categoriesByIds).find(condition);
}


export function getCategoryIdsForUser(state: GlobalState): string[] {
    return state.entities.topicCategories.orderForUser;
}

export function makeGetCategoriesForUser(): (state: GlobalState) => TopicCategory[] {
    return createSelector(
        'makeGetCategoriesForUser',
        getCategoryIdsForUser,
        (state: GlobalState) => state.entities.topicCategories.byId,
        (categoryIds, categoriesById) => {
            if (!categoryIds) {
                return [];
            }

            return categoryIds.map((id) => categoriesById[id]);
        },
    );
}

// makeFilterArchivedTopics returns a selector that filters a given list of topics based 
// on whether or not the topic is archived or is currently viewed. The selector returns 
// the original array if no topics are filtered out.
export function makeFilterArchivedTopics(): (state: GlobalState, topics: Topic[]) => Topic[] {
    return createSelector(
        'makeFilterArchivedTopics',
        (state: GlobalState, topics: Topic[]) => topics,
        getCurrentTopicId,
        (topics: Topic[], currentTopicId: string) => {
            const filtered = topics.filter((topic) => topic && (topic.id === currentTopicId || topic.delete_at === 0));
            
            return filtered.length === getTopicMessageCounts.length ? topics : filtered;
        },
    );
}


export function makeFilterAutoclosedDMs(): (state: GlobalState, topics: Topic[], categoryType: string) => Topic[] {
    return createSelector(
        'makeFilterAutoClosedDMs',
        (state: GlobalState, topics: Topic[]) => topics,
        (state: GlobalState, topics: Topic[], categoryType: string) => categoryType,
        getCurrentTopicId, 
        (state: GlobalState) => state.entities.users.profiles,
        getCurrentUserId, 
        getMyTopicMemberships, 
        getTopicMessageCounts, 
        (state: GlobalState) => getInt(state, Preferences.CATEGORY_SIDEBAR_SETTINGS, Preferences.LIMIT_VISIBLE_DMS_GMS, 20),
        getMyPreferences, 
        (topics, categoryType, currentTopicId, profiles, currentUserId, myMembers, messageCounts, limitPref, myPreferences) => {
            if (categoryType !== CategoryTypes.DIRECT_MESSAGES) {
                // Only autoclose DMs that haven't been assigned to a category.
                return topics;
            }

            const getTimestampFromPrefs = (category: string, name: string) => {
                const pref = myPreferences[getPreferenceKey(category, name)];
                return parseInt(pref ? pref.value!: '0', 10);
            };

            const getLastViewedAt = (topic: Topic) => {
                // The server only ever sets  the last_viewed_at to the time of the last post 
                // in topic, so we may need to use the preferences added for the previous version 
                // of autoclosing DMs.
                return Math.max(
                    myMembers[topic.id]?.last_viewed_at,
                    getTimestampFromPrefs(Preferences.CATEGORY_TOPIC_APPROXIMATE_VIEW_TIME, topic.id),
                    getTimestampFromPrefs(Preferences.CATEGORY_TOPIC_OPEN_TIME, topic.id),
                );
            };

            let unreadCount = 0;
            let visibleTopics = topics.filter((topic) => {
                if (isUnreadTopic(topic.id, messageCounts, myMembers)) {
                    unreadCount++;

                    // Unread DMs / GMs are always visible.
                    return true;
                }

                if (topic.id === currentTopicId) {
                    return true;
                }

                // DMs with deactivated users will be visible if you are currently viewing
                // them and they were opened since the user was deactivated.
                if (topic.type === General.DM_TOPIC) {
                    const wspaceMateId = getUserIdFromTopicName(currentUserId, topic.name);
                    const wspaceMate = profiles[wspaceMateId];

                    const lastViewedAt = getLastViewedAt(topic);

                    if (!wspaceMate || wspaceMate.delete_at > lastViewedAt) {
                        return false;
                    }
                }

                return true;
            });

            visibleTopics.sort((topicA, topicB) => {
                // should always prioritize the current topic
                if (topicA.id === currentTopicId) {
                    return -1;
                } else if (topicB.id === currentTopicId) {
                    return 1;
                }

                // Second priority is for unread topics
                if (isUnreadTopic(topicA.id, messageCounts, myMembers) && !isUnreadTopic(topicB.id, messageCounts, myMembers)) {
                    return -1;
                } else if (!isUnreadTopic(topicA.id, messageCounts, myMembers) && isUnreadTopic(topicB.id, messageCounts, myMembers)) {
                    return 1;
                }

                // Third priority is last_viewed_at
                const topicAlastViewed = getLastViewedAt(topicA) || 0;
                const topicBlastViewed = getLastViewedAt(topicB) || 0;

                if (topicAlastViewed > topicBlastViewed) {
                    return -1;
                } else if (topicBlastViewed > topicAlastViewed) {
                    return 1;
                }

                return 0;
            });

            // The limit of DMs user specifies to be rendered in the sidebar.
            const remaining = Math.max(limitPref, unreadCount);
            visibleTopics = visibleTopics.slice(0, remaining);

            const visibleTopicsSet = new Set(visibleTopics);
            const filteredTopics = topics.filter((topic) => visibleTopicsSet.has(topic));

            return filteredTopics.length === topics.length ? topics : filteredTopics;
        },
    );
}

export function makeFilterManuallyClosedDMs(): (state: GlobalState, topics: Topic[]) => Topic[] {
    return createSelector(
        'makeFilterManuallyClosedDMs',
        (state: GlobalState, topics: Topic[]) => topics,
        getMyPreferences, 
        getCurrentTopicId, 
        getCurrentUserId, 
        getMyTopicMemberships, 
        getTopicMessageCounts, 
        (topics, myPreferences, currentTopicId, currentUserId, myMembers, messageCounts) => {
            const filtered = topics.filter((topic) => {
                let preference;

                if (topic.type !== General.DM_TOPIC && topic.type !== General.GM_TOPIC) {
                    return true;
                }

                if (isUnreadTopic(topic.id, messageCounts, myMembers)) {
                    // Unread DMs/GMs are always visible
                    return true;
                }
                if (currentTopicId == topic.id) {
                    // the current topic is always visible.
                    return true;
                }

                if (topic.type === General.DM_TOPIC) {
                    const wspaceMateId = getUserIdFromTopicName(currentUserId, topic.name);
                    preference = myPreferences[getPreferenceKey(Preferences.CATEGORY_DIRECT_TOPIC_SHOW, wspaceMateId)];
                } else {
                    preference = myPreferences[getPreferenceKey(Preferences.CATEGORY_GROUP_TOPIC_SHOW, topic.id)];
                }

                return preference && preference.value !== 'false';
            });

            // Only return a new array if anything was removed.
            return filtered.length === topics.length ? topics : filtered;
        },
    );
}

export function makeCompareTopics(getDisplayName: (topic: Topic) =>string, locale: string, myMembers: RelationOneToOne<Topic, TopicMembership>) {
    return (a: Topic, b: Topic) => {
        // Sort muted topics last.
        const aMuted = isTopicMuted(myMembers[a.id]);
        const bMuted = isTopicMuted(myMembers[b.id]);

        if (aMuted && !bMuted) {
            return 1;
        } else if (!aMuted && bMuted) {
            return -1;
        }

        // And then sort alphabetically
        return getDisplayName(a).localeCompare(getDisplayName(b), locale, {numeric: true});
    };
}

export function makeSortTopicsByName(): (state: GlobalState, topics: Topic[]) => Topic[] {
    return createSelector(
        'makeSortTopicsByName', 
        (state: GlobalState, topics: Topic[]) => topics,
        (state: GlobalState) => getCurrentUserLocale(state),
        getMyTopicMemberships,
        (topics: Topic[], locale: string, myMembers: RelationOneToOne<Topic, TopicMembership>) => {
            const getDisplayName = (topic: Topic) => topic.display_name;

            return [...topics].sort(makeCompareTopics(getDisplayName, locale, myMembers));
        },
    );
}

export function makeSortTopicsByNameWithDMs(): (state: GlobalState, topics: Topic[]) => Topic[] {
    return createSelector(
        'makeSortTopicsByNameWithDMs', 
        (state: GlobalState, topics: Topic[]) => topics,
        getCurrentUserId, 
        (state: GlobalState) => state.entities.users.profiles,
        getWspaceMateNameDisplaySetting, 
        (state: GlobalState) => getCurrentUserLocale(state),
        getMyTopicMemberships, 
        (topics: Topic[], currentUserId: string, profiles: IDMappedObjects<UserProfile>, orgmateNameDisplay: string, locale:string, myMembers: RelationOneToOne<Topic, TopicMembership>) => {
            const cachedNames: RelationOneToOne<Topic, string> = {};

            const getDisplayName = (topic: Topic): string => {
                if (cachedNames[topic.id]) {
                    return cachedNames[topic.id];
                }
                let displayName;

                // TODO it might be easier to do this by using topic members to find the users.
                if (topic.type === General.DM_TOPIC) {
                    const wspaceMateId = getUserIdFromTopicName(currentUserId, topic.name);
                    const wspaceMate = profiles[wspaceMateId];

                    displayName = displayUsername(wspaceMate, orgmateNameDisplay, false);

                } else if (topic.type === General.GM_TOPIC) {
                    const usernames = topic.display_name.split(', ');

                    const userDisplayNames = [];
                    for (const username of usernames) {
                        const user = Object.values(profiles).find((profile) => profile.username === username);

                        if (!user) {
                            continue;
                        }

                        if (user.id === currentUserId) {
                            continue;
                        }

                        userDisplayNames.push(displayUsername(user, orgmateNameDisplay, false));
                    }

                    displayName = userDisplayNames.sort((a, b) => a.localeCompare(b, locale, {numeric: true})).join(', ');
                } else {
                    displayName = topic.display_name;
                }

                cachedNames[topic.id] = displayName;

                return displayName;
            };

            return [...topics].sort(makeCompareTopics(getDisplayName, locale, myMembers));
        },
    );
}


export function makeSortTopicsByRecency(): (state: GlobalState, topics: Topic[]) => Topic[] {
    return createSelector(
        'makeSortTopicsByRecency',
        (_state: GlobalState, topics: Topic[]) => topics,
        (topics) => {
            return [...topics].sort((a, b) => {
                const aLastPostAt = Math.max(a.last_root_post_at, a.last_post_at, a.create_at);
                const bLastPostAt = Math.max(b.last_root_post_at, b.last_post_at, b.create_at);
                return bLastPostAt - aLastPostAt;
            });
        },
    );
}

export function makeSortTopics() {
    const sortTopicsByName = makeSortTopicsByName();
    const sortTopicsByNameWithDMs = makeSortTopicsByNameWithDMs();
    const sortTopicsByRecency = makeSortTopicsByRecency();

    return (state: GlobalState, originalTopics: Topic[], category: TopicCategory) => {
        let topics = originalTopics;

        // while this function isn't memoized, sortTopicsByX should be since they
        // knwo which part of the state will affect sort order.
        if (category.sorting === CategorySorting.Recency) {
            topics = sortTopicsByRecency(state, topics);
        } else if (category.sorting === CategorySorting.Alphabetical || category.sorting === CategorySorting.Default) {
            if (topics.some((topic) => topic.type === General.DM_TOPIC || topic.type === General.GM_TOPIC)) {
                topics = sortTopicsByNameWithDMs(state, topics);
            } else {
                topics = sortTopicsByName(state, topics);
            }
        }
        return topics;
    };
}


export function makeGetTopicIdsForCategory() {
    const getTopics = makeGetTopicsForIds();
    const filterAndSortTopicsForCategory = makeFilterAndSortTopicsForCategory();

    let lastTopicIds: string[] = [];

    return (state: GlobalState, category: TopicCategory) => {
        const topics = getTopics(state, category.topic_ids);

        const filteredTopicIds = filterAndSortTopicsForCategory(state, topics, category).map((topic) => topic.id);

        if (shallowEquals(filteredTopicIds, lastTopicIds)) {
            return lastTopicIds;
        }

        lastTopicIds = filteredTopicIds;
        return lastTopicIds;
    };
}

// Returns a selector that takes an array of topics and the category they belong to
// and returns the array sorted with inactive DMs/GMs and archived topics filtered out.
export function makeFilterAndSortTopicsForCategory() {
    const filterArchivedTopics = makeFilterArchivedTopics();
    const filterAutoclosedDMs = makeFilterAutoclosedDMs();

    const filterManuallyclosedDMs = makeFilterManuallyClosedDMs();

    const sortTopics = makeSortTopics();

    return (state: GlobalState, originalTopics: Topic[], category: TopicCategory) => {
        let topics = originalTopics;

        topics = filterArchivedTopics(state, topics);
        topics = filterManuallyclosedDMs(state, topics);

        topics = filterAutoclosedDMs(state, topics, category.type);

        topics = sortTopics(state, topics, category);

        return topics;
    };
}

export function makeGetTopicsByCategory() {
    const getCategoriesForUser = makeGetCategoriesForUser();

    // Memoize by category. as long as the categories don't change, we can keep using
    // the same selectors for each category.
    let getTopics: RelationOneToOne<TopicCategory, ReturnType<typeof makeGetTopicsForIds>>;
    let filterAndSortTopics: RelationOneToOne<TopicCategory, ReturnType<typeof makeFilterAndSortTopicsForCategory>>

    let lastCategoryIds: ReturnType<typeof getCategoryIdsForUser> = [];
    let lastTopicsByCategory: RelationOneToOne<TopicCategory, Topic[]> = {};

    return (state: GlobalState) => {
        const categoryIds = getCategoryIdsForUser(state);

        // Create an instance of filterAndSortTopics for each category. As long as we don't add
        // or remove new categories we can reuse these selectors to memoize the results of each
        // category. This will also create new selectors when  categories are reordered, but that
        // should be rare enough that it won't meaningfully affect performance.
        if (categoryIds !== lastCategoryIds) {
            lastCategoryIds = categoryIds;
            lastTopicsByCategory = {};

            getTopics = {};
            filterAndSortTopics = {};

            if (categoryIds) {
                for (const categoryId of categoryIds) {
                    getTopics[categoryId] = makeGetTopicsForIds();
                    filterAndSortTopics[categoryId] = makeFilterAndSortTopicsForCategory();
                }
            }
        }

        const categories = getCategoriesForUser(state);

        const topicsByCategory: RelationOneToOne<TopicCategory, Topic[]> = {};

        for (const category of categories) {
            const topics = getTopics[category.id](state, category.topic_ids);
            topicsByCategory[category.id] = filterAndSortTopics[category.id](state, topics, category);
        }

        // Do a shallow equality check of topicsByCategory to avoid returning a new object
        // containing the same data
        if (shallowEquals(topicsByCategory, lastTopicsByCategory)) {
            return lastTopicsByCategory;
        }

        lastTopicsByCategory = topicsByCategory;

        return topicsByCategory;
    };
}

// TODO: TopicMessageCount to be TopicThreadCount

function isUnreadTopic(
    topicId: string, 
    messageCounts: RelationOneToOne<Topic, TopicMessageCount>,
    members: RelationOneToOne<Topic, TopicMembership>,
): boolean {
    const unreadCount = calculateUnreadCount(messageCounts[topicId], members[topicId]);
    return unreadCount.showUnread;
}