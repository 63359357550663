import { combineReducers } from 'redux';

import remove from 'lodash/remove';

import { UserTypes } from 'sigmaflow-redux/action_types';
import type { GenericAction } from 'sigmaflow-redux/types/actions';
import { IDMappedObjects } from 'sigmaflow-redux/types/utilities';
import { ClientPluginManifest } from 'sigmaflow-redux/types/plugins';

import type { PluginsState, PluginComponent, AdminConsolePluginComponent, Menu } from 'types/store/plugins';

import { ActionTypes } from 'utils/constants';

function hasMenuId(menu: Menu|PluginComponent, menuId: string) {
    if (!menu.subMenu) {
        return false;
    }

    if (menu.id === menuId) {
        return true;
    }

    for (const subMenu of menu.subMenu) {
        // Recursively check if subMenu contains menuId.
        if (hasMenuId(subMenu, menuId)) {
            return true;
        }
    }

    return false;
}


const initialComponents: PluginsState['components'] = {
    AppBar: [],
    CallButton: [],
    FilePreview: [],
    LinkTooltip: [],
    MainMenu: [],
    TopicHeaderButton: [],
    MobileTopicHeaderButton: [],
    PostDropdownMenu: [],
    Product: [],
    RightHandSidebarComponent: [],
    UserGuideDropdownItem: [],
    FilesWillUploadHook: [],
};

function components(state: PluginsState['components'] = initialComponents, action: GenericAction) {
    switch (action.type) {
        case UserTypes.LOGOUT_SUCCESS:
            return initialComponents;
        default:
            return state;
    }
}

export default combineReducers({
    components,
})