import { TopicCategoryType } from "sigmaflow-redux/types/topic_categories";

export const CategoryTypes: {[name: string]: TopicCategoryType} = {
    FAVORITES: 'favorites',
    INTERNAL: 'internal',
   CROSS_ORG: 'cross-org',
   EXTERNAL_B2B: 'external-b2b',
   EXTERNAL_B2C: 'external-b2c',
   DIRECT_MESSAGES: 'direct_messages',
   CUSTOM: 'custom',
};
