import { combineReducers } from "redux";

import { TopicCategoryTypes, WorkspaceTypes, UserTypes, TopicTypes } from "sigmaflow-redux/action_types";

import { GenericAction } from "sigmaflow-redux/types/actions";
import { TopicCategory } from "sigmaflow-redux/types/topic_categories";
import { Workspace } from "sigmaflow-redux/types/workspaces";
import { IDMappedObjects, RelationOneToOne } from "sigmaflow-redux/types/utilities";

import {removeItem} from 'sigmaflow-redux/utils/array_utils';

export function byId(state: IDMappedObjects<TopicCategory> = {}, action: GenericAction) {
    switch (action.type) {
        case TopicCategoryTypes.RECEIVED_CATEGORIES: {
            const categories: TopicCategory[] = action.data;

            return categories.reduce((nextState, category) => {
                return {
                    ...nextState, 
                    [category.id]: {
                        ...nextState[category.id],
                        ...category,
                    },
                };
            }, state);
        }

        case TopicCategoryTypes.RECEIVED_CATEGORY: {
            const category: TopicCategory = action.data;

            return {
                ...state, 
                [category.id]: {
                    ...state[category.id],
                    ...category,
                },
            };
        }

        case TopicCategoryTypes.CATEGORY_DELETED: {
            const categoryId: TopicCategory['id'] = action.data;

            const nextState = {...state};

            Reflect.deleteProperty(nextState, categoryId);

            return nextState;
        }

        case TopicTypes.LEAVE_TOPIC: {
            const topicId: string = action.data.id;

            const nextState = {...state};
            let changed = false;

            for (const category of Object.values(state)) {
                const index = category.topic_ids.indexOf(topicId);
                
                if (index === -1) {
                    continue;
                }

                const nextTopicIds = [...category.topic_ids];
                nextTopicIds.splice(index, 1);

                nextState[category.id] = {
                    ...category, 
                    topic_ids: nextTopicIds,
                };

                changed = true;
            }

            return changed ? nextState : state;
        }

        case WorkspaceTypes.LEAVE_WORKSPACE: {
            // TODO: server data to provide the topic Ids belonging to this workspace that
            // the user is member of. remove those topics from the category (xorg, external etc.)
            /*
            const workspace: Workspace = action.data;

            const nextState = {...state};
            let changed = false;

            for (const category of Object.values(state)) {
                if (category.workspace_id !== )
            }
            */
           return state;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

export function orderForUser(state: Array<TopicCategory['id']> = [], action: GenericAction) {
// export function orderForUser(action: GenericAction) {
    switch (action.type) {
        case TopicCategoryTypes.RECEIVED_CATEGORY_ORDER: {
            const order: string[] = action.data.order;

            return order;
        }

        case TopicCategoryTypes.CATEGORY_DELETED: {
            const categoryId: TopicCategory['id'] = action.data;

            return removeItem(state, categoryId);
        }

        case WorkspaceTypes.LEAVE_WORKSPACE: {
            // TODO
            return state;
        }

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

export default combineReducers({
    byId, 
    orderForUser,
})