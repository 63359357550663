import keyMirror from 'key-mirror';
import { combineReducers } from "redux";

import {findKey} from 'lodash';

import { PostTypes, UserTypes} from 'sigmaflow-redux/action_types';
//import { Threads, ActionTypes} from 'utils/constants';

import {GenericAction} from 'sigmaflow-redux/types/actions';

import {ViewsState} from 'types/store/views';

const Threads = {
    CHANGED_SELECTED_THREAD: 'changed_selected_thread',
    CHANGED_LAST_VIEWED_AT: 'changed_last_viewed_at',
    MANUALLY_UNREAD_THREAD: 'manually_unread_thread',
};

const ActionTypes = keyMirror({
    RECEIVED_FOCUSED_POST: null,
    SELECT_POST: null,
    HIGHLIGHT_REPLY: null,
    CLEAR_HIGHLIGHT_REPLY: null,
    SELECT_POST_CARD: null,
    LOADING_POSTS: null,
    INCREASE_POST_VISIBILITY: null,

    UPDATE_RHS_STATE: null,
    UPDATE_RHS_SEARCH_TERMS: null,
    UPDATE_RHS_SEARCH_TYPE: null, 
    UPDATE_RHS_SEARCH_RESULTS_TERMS: null,

    RHS_GO_BACK: null,

    SET_RHS_EXPANDED: null,
    TOGGLE_RHS_EXPANDED: null, 

    UPDATE_MOBILE_VIEW: null,

    SET_NAVIGATION_BLOCKED: null,
    DEFER_NAVIGATION: null,
    CANCEL_NAVIGATION: null,
    CONFIRM_NAVIGATION: null,

    TOGGLE_IMPORT_THEME_MODAL: null,
    TOGGLE_DELETE_POST_MODAL: null,
    TOGGLE_EDITING_POST: null,

    BROWSER_CHANGE_FOCUS: null,
    BROWSER_WINDOW_RESIZED: null,

    RECEIVED_ADMIN_CONSOLE_REDUCER: null, 
    REMOVED_ADMIN_CONSOLE_REDUCER: null,
    RECEIVED_ADMIN_CONSOLE_CUSTOM_COMPONENT: null, 
    RECEIVED_PLUGIN_STATS_HANDLER: null,

    MODAL_OPEN: null,
    MODAL_CLOSE: null,

    SELECT_TOPIC_WITH_MEMBER: null, 
    SET_LAST_UNREAD_TOPIC: null, 
    UPDATE_TOPIC_LAST_VIEWED_AT: null,

    INCREMENT_EMOJI_PICKER_PAGE: null, 
    SET_RECENT_SKIN: null,


    STATUS_DROPDOWN_TOGGLE: null,
    ADD_TOPIC_DROPDOWN_TOGGLE: null,

    TOGGLE_LHS: null,
    OPEN_LHS: null,
    CLOSE_LHS: null,

    SET_SHOW_PREVIEW_ON_CREATE_COMMENT: null, 
    SET_SHOW_PREVIEW_ON_CREATE_POST: null, 
    SET_SHOW_PREVIEW_ON_EDIT_TOPIC_HEADER_MODAL: null, 

    TOGGLE_RHS_MENU: null, 
    OPEN_RHS_MENU: null, 
    CLOSE_RHS_MENU: null,

    DISMISS_NOTICE: null,
    SHOW_NOTICE: null,

    SELECT_ATTACHMENT_MENU_ACTION: null,

    RECEIVED_TRANSLATIONS: null,

    INCREMENT_WS_ERROR_COUNT: null, 
    RESET_WS_ERROR_COUNT: null, 
    RECEIVED_POSTS_FOR_TOPIC_AT_TIME: null, 
    TOPIC_POSTS_STATUS: null, 
    TOPIC_SYNC_STATUS: null,
    ALL_TOPIC_SYNC_STATUS: null,

    UPDATE_ACTIVE_SECTION: null,

    POST_UNREAD_SUCCESS: null,

    SET_UNREAD_FILTER_ENABLED: null, 
    UPDATE_TOAST_STATUS: null,
    UPDATE_THREAD_TOAST_STATUS: null,


    SIDEBAR_DRAGGING_SET_STATE: null,
    SIDEBAR_DRAGGING_STOP: null,
    ADD_NEW_CATEGORY_ID: null,
    MULTISELECT_TOPIC: null, 
    MULTISELECT_TOPIC_ADD: null, 
    MULTISELECT_TOPIC_TO: null, 
    MULTISELECT_TOPIC_CLEAR: null, 


    TRACK_ANNOUNCEMENT_BAR: null,
    DISMISS_ANNOUNCEMENT_BAR: null,


    PREFETCH_POSTS_FOR_TOPIC: null,

    SET_SHOW_NEXT_STEPS_VIEW: null,
    SET_FILES_FILTER_BY_EXT: null,

    SUPPRESS_RHS: null,
    UNSUPPRESS_RHS: null,

    FIRST_TOPIC_NAME: null,

    RECEIVED_BOARDS_INSIGHTS: null, 
    SET_EDIT_TOPIC_MEMBERS: null,

});


export const selectedThreadIdInTopic = (state: ViewsState['threads']['selectedThreadIdInTopic'] = {}, action: GenericAction)  => {
    switch (action.type) {
        case PostTypes.POST_REMOVED: {
            const key = findKey(state, (id) => id === action.data.id);
            if (key) {
                return {
                    ...state,
                    [key]: '',
                };
            }
            return state;
        }
        
        case Threads.CHANGED_SELECTED_THREAD:
            return {
                ...state, 
                [action.data.topic_id]: action.data.thread_id,
            };

        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
};

export const lastViewedAt = (state: ViewsState['threads']['lastViewedAt'] = {}, action: GenericAction) => {
    switch (action.type) {
        case Threads.CHANGED_LAST_VIEWED_AT:
            return {
                ...state, 
                [action.data.threadId]: action.data.lastViewedAt,
            };
            case UserTypes.LOGOUT_SUCCESS:
                return {};
            default:
                return state;
    }
};

export function manuallyUnread(state: ViewsState['threads']['manuallyUnread'] = {}, action: GenericAction) {
    switch (action.type) {
        case Threads.CHANGED_LAST_VIEWED_AT:
            return {
                ...state,
                [action.data.threadId]: false,
            };
        case Threads.MANUALLY_UNREAD_THREAD:
            return {
                ...state, 
                [action.data.threadId]: true,
            };
        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

export function toastStatus(state: ViewsState['threads']['toastStatus'] = false,  action: GenericAction) {
    switch (action.type) {
        case ActionTypes.SELECT_POST:
            return false;
        case ActionTypes.UPDATE_THREAD_TOAST_STATUS:
            return action.data;
        case UserTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

export default combineReducers({
    selectedThreadIdInTopic,
    lastViewedAt,
    manuallyUnread,
    toastStatus,
});