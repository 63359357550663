import { PostType } from "sigmaflow-redux/types/posts";

export const PostTypes = {
    TOPIC_DELETED: 'system_topic_deleted' as PostType, 
    TOPIC_UNARCHIVED: 'system_topic_restored' as PostType, 
    DISPLAYNAME_CHANGE: 'system_displayname_change' as PostType,
    EPHEMERAL: 'system_ephemeral' as PostType, 
    EPHEMERAL_ADD_TO_TOPIC: 'system_ephemeral_add_to_topic' as PostType, 
    HEADER_CHANGE: 'system_header_change' as PostType, 
    PURPOSE_CHANGE: 'system_purpose_change' as PostType, 

    JOIN_LEAVE: 'system_join_leave' as PostType, 
    JOIN_TOPIC: 'system_join_topic' as PostType, 
    LEAVE_TOPIC: 'system_leave_topic' as PostType, 
    ADD_REMOVE: 'system_add_remove' as PostType, 
    ADD_TO_TOPIC: 'system_add_to_topic' as PostType, 
    REMOVE_FROM_TOPIC: 'system_remove_from_topic' as PostType, 

    JOIN_WORKSPACE: 'system_join_workspace' as PostType, 
    LEAVE_WORKSPACE: 'system_leave_workspace' as PostType, 
    ADD_TO_WORKSPACE: 'system_add_to_workspace' as PostType, 
    REMOVE_FROM_WORKSPACE: 'system_remove_from_workspace' as PostType, 

    COMBINED_USER_ACTIVITY: 'system_combined_user_activity' as PostType, 
    ME: 'me' as PostType, 
};

export default {
    POST_CHUNK_SIZE: 60, 
    POST_DELETED: 'DELETED',
    SYSTEM_MESSAGE_PREFIX: 'system_',
    SYSTEM_AUTO_RESPONDER: 'system_auto_responder',
    POST_TYPES: PostTypes,
    MESSAGE_TYPES: {
        POST: 'post', 
        COMMENT: 'comment',
    },
    MAX_PREV_MSGS: 100, 
    POST_COLLAPSE_TIMEOUT: 1000 * 60 * 5, // five minutes
    IGNORE_POST_TYPES: [
        PostTypes.ADD_REMOVE,
        PostTypes.ADD_TO_TOPIC, 
        PostTypes.TOPIC_DELETED, 
        PostTypes.TOPIC_UNARCHIVED, 
        PostTypes.JOIN_LEAVE, 
        PostTypes.JOIN_TOPIC, 
        PostTypes.LEAVE_TOPIC, 
        PostTypes.REMOVE_FROM_TOPIC, 
        PostTypes.JOIN_WORKSPACE, 
        PostTypes.LEAVE_WORKSPACE, 
        PostTypes.ADD_TO_WORKSPACE, 
        PostTypes.REMOVE_FROM_WORKSPACE,
    ],
    USER_ACTIVITY_POST_TYPES: [
        PostTypes.ADD_TO_TOPIC, 
        PostTypes.JOIN_TOPIC, 
        PostTypes.LEAVE_TOPIC, 
        PostTypes.REMOVE_FROM_TOPIC, 
        PostTypes.ADD_TO_WORKSPACE, 
        PostTypes.JOIN_WORKSPACE,
        PostTypes.LEAVE_WORKSPACE, 
        PostTypes.REMOVE_FROM_WORKSPACE,
    ],
}