/**
 * Constructs an enumeration with keys equal to their value.
 * For example:
 * 
 * var COLORS = keyMirror({blue: null, red: null});
 * var myColor = COLORS.blue
 * var isColorValid = !!COLORS[myColor]
 * 
 * input: {key1: val1, key2: val2}
 * output: {key1: key1, key2: key2}
 */

export default function keyMirror<T extends Record<string, unknown>>(obj: T): {[K in keyof T]: K } {
    if (!(obj instanceof Object && !Array.isArray(obj))) {
        throw new Error('keyMirror(...): Argument must be an object.');
    }

    const ret: any = {};

    for (const key in obj) {
        if (!obj.hasOwnProperty(key)) {
            continue;
        }

        ret[key] = key;
    }

    return ret;
}