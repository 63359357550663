
import { formatWithRenderer } from "./markdown";
import MentionableRenderer from "./markdown/mentionable_renderer";

export const emoticonPatterns: {[key: string]: RegExp} = {
    slightly_smiling_face: /(^|\B)(:-?\))($|\B)/g, // :)
    wink: /(^|\B)(;-?\))($|\B)/g,
};

export const EMOJI_PATTERN = /(:([a-zA-Z0-9_+-]+):)/g;

export function matchEmoticons(text: string): RegExpMatchArray | null {
    const markdownCleanedText = formatWithRenderer(text, new MentionableRenderer());
    let emojis = markdownCleanedText.match(EMOJI_PATTERN);

    for (const name of Object.keys(emoticonPatterns)) {
        const pattern = emoticonPatterns[name];

        const matches = markdownCleanedText.match(pattern);
        if (matches) {
            if (emojis) {
                emojis = emojis.concat(matches);
            } else {
                emojis = matches;
            }
        }
    }

    return emojis;
}

export function handleEmoticons(
    text: string, 
    tokens: Map<string, {value: string; originalText: string}>,
): string {
    let output = text;

    function replaceEmoticonWithToken(
        fullMatch: string,
        prefix: string, 
        matchText: string, 
        name: string,
    ): string {
        const index = tokens.size; 

        const alias = `$SF_EMOTICONS${index}$`;
        
        tokens.set(alias, {
            value: renderEmoji(name, matchText),
            originalText: fullMatch, 
        });

        return prefix + alias;
    }

    // match named emoticons like :goat:
    output = output.replace(
        EMOJI_PATTERN, 
        (fullMatch: string, matchText: string, name: string): string  => 
            replaceEmoticonWithToken(fullMatch, '', matchText, name),
    );

    // match text smilies like :D

    for (const name of Object.keys(emoticonPatterns)) {
        const pattern = emoticonPatterns[name];

        // this might look a bit funny, but since  the name isn't contained ina ctual match
        // like with the named emoticons, we need to add it manually.
        output = output.replace(pattern, (fullMatch, prefix, matchText) => replaceEmoticonWithToken(fullMatch, prefix, matchText, name));
    }

    return output;
}


export function renderEmoji(name: string, matchText: string): string {
    return `<span data-emoticon="${name.toLowerCase()}">${matchText.toLowerCase()}</span>`;
}